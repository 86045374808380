import React, { Component } from "react";
import PropTypes from "prop-types";
import i18n from "i18next";
import { Row, Col } from "react-bootstrap";

import { FormGroup, Button } from "../Common";
import SpecificAgreementExtensionModal from "./SpecificAgreementExtensionModal";
import SpecificAgreementTerminationModal from "./SpecificAgreementTerminationModal";
import SpecificAgreementAlterationActionButtons from "./SpecificAgreementAlterationActionButtons";
import SpecificAgreementAlterationFormTitle from "./SpecificAgreementAlterationFormTitle";
import SpecificAgreementAlterationExtensionReason from "./SpecificAgreementAlterationExtensionReason";
import SpecificAgreementAlterationTerminationReason from "./SpecificAgreementAlterationTerminationReason";

import "./SpecificAgreementAlteration.css";

export default class SpecificAgreementAlteration extends Component {
  state = {
    isModifyModalOpen: false,
    isExtensionModalOpen: false,
    isTerminationModalOpen: false,
    editMode: false
  };

  openModifyModal = (withEditMode = false) => {
    this.setState({
      isModifyModalOpen: true,
      editMode: withEditMode
    });
  };

  openExtensionModal = (withEditMode = false) => {
    this.setState({
      isExtensionModalOpen: true,
      editMode: withEditMode
    });
  };

  openTerminationModal = (withEditMode = false) => {
    this.setState({
      isTerminationModalOpen: true,
      editMode: withEditMode
    });
  };

  closeModal = () => {
    this.setState({
      isModifyModalOpen: false,
      isExtensionModalOpen: false,
      isTerminationModalOpen: false,
      editMode: false
    });
  };

  onSubmit = (reason, realDate = null, hoursDone = null) => {
    const { onModify, onExtend, onTerminate, onEditReason } = this.props;
    const {
      isModifyModalOpen,
      isExtensionModalOpen,
      isTerminationModalOpen,
      editMode
    } = this.state;

    if (editMode) {
      onEditReason(reason, realDate, hoursDone);
    } else if (isModifyModalOpen) {
      onModify(reason, realDate);
    } else if (isExtensionModalOpen) {
      onExtend(reason, realDate);
    } else if (isTerminationModalOpen) {
      onTerminate(reason, realDate, hoursDone);
    }
    this.closeModal();
  };

  onEdit = () => {
    const { alterationStatus } = this.props;

    if (alterationStatus === "extended") {
      this.openExtensionModal(true);
    } else if (alterationStatus === "modified") {
      this.openModifyModal(true);
    } else if (alterationStatus === "terminated") {
      this.openTerminationModal(true);
    }
  };

  render() {
    const {
      studentFullName,
      alterationStatus,
      reasonForAlteration,
      alterationCreatedAt,
      alterationId,
      jobRealEndDate,
      hoursDone
    } = this.props;
    const {
      isModifyModalOpen,
      isExtensionModalOpen,
      isTerminationModalOpen
    } = this.state;

    return (
      <Row>
        <Col xs={12}>
          <FormGroup
            className="specific-agreement-alteration__container"
            formTitle={i18n.t(
              "spa:specific_agreements.school_panel.alteration.title"
            )}
            renderTitle={title => (
              <SpecificAgreementAlterationFormTitle
                title={title}
                alterationStatus={alterationStatus}
              />
            )}
          >
            {!alterationStatus && (
              <SpecificAgreementAlterationActionButtons
                onOpenExtend={() => this.openExtensionModal(false)}
                onOpenModify={() => this.openModifyModal(false)}
                onOpenTerminate={() => this.openTerminationModal(false)}
              />
            )}
            {alterationStatus && (
              <Col xs={12}>
                {alterationStatus !== "terminated" && (
                  <SpecificAgreementAlterationExtensionReason
                    alterationCreatedAt={alterationCreatedAt}
                    alterationId={alterationId}
                    alterationStatus={alterationStatus}
                    reasonForAlteration={reasonForAlteration}
                    jobRealEndDate={jobRealEndDate}
                  />
                )}
                {alterationStatus === "terminated" && (
                  <SpecificAgreementAlterationTerminationReason
                    reasonForAlteration={reasonForAlteration}
                    jobRealEndDate={jobRealEndDate}
                    hoursDone={hoursDone}
                  />
                )}
                <Button
                  bsStyle="success"
                  style={{ float: "right" }}
                  onClick={this.onEdit}
                >
                  {i18n.t(
                    "spa:specific_agreements.school_panel.alteration.fields.shared.buttons.edit"
                  )}
                </Button>
              </Col>
            )}
          </FormGroup>
        </Col>
        <SpecificAgreementExtensionModal
          isOpen={isModifyModalOpen || isExtensionModalOpen}
          onCancel={this.closeModal}
          alteration={isModifyModalOpen ? "modification" : "extension"}
          onSubmit={this.onSubmit}
          studentFullName={studentFullName}
          reasonForAlteration={reasonForAlteration}
          jobRealEndDate={jobRealEndDate}
          alterationStatus={alterationStatus}
        />
        <SpecificAgreementTerminationModal
          isOpen={isTerminationModalOpen}
          studentFullName={studentFullName}
          onCancel={this.closeModal}
          onSubmit={this.onSubmit}
          reasonForAlteration={reasonForAlteration}
          jobRealEndDate={jobRealEndDate}
          hoursDone={hoursDone}
          alterationStatus={alterationStatus}
        />
      </Row>
    );
  }
}

SpecificAgreementAlteration.propTypes = {
  onExtend: PropTypes.func,
  onModify: PropTypes.func,
  onTerminate: PropTypes.func,
  studentFullName: PropTypes.string,
  alterationStatus: PropTypes.string,
  reasonForAlteration: PropTypes.string,
  alterationId: PropTypes.number,
  onEditReason: PropTypes.func,
  jobRealEndDate: PropTypes.string,
  alterationCreatedAt: PropTypes.string,
  hoursDone: PropTypes.number
};

SpecificAgreementAlteration.defaultProps = {
  onExtend: () => true,
  onModify: () => true,
  onTerminate: () => true,
  studentFullName: "",
  alterationStatus: null,
  reasonForAlteration: "",
  alterationId: null,
  onEditReason: () => true,
  jobRealEndDate: undefined,
  alterationCreatedAt: null,
  hoursDone: null
};
