import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "react-bootstrap"

import { Button, FormInput, Icon } from "../Common"

import "./DynamicInputGroup.css"

export default class DynamicInputGroup extends Component {
  static propTypes = {
    namePrefix: PropTypes.string,
    onChange: PropTypes.func,
    inputProps: PropTypes.shape({ ...FormInput.propTypes }),
    values: PropTypes.arrayOf(PropTypes.string),
    onRemove: PropTypes.func,
  }

  static defaultProps = {
    namePrefix: "",
    onChange: () => true,
    inputProps: {},
    values: [],
    onRemove: () => true,
  }

  constructor(props) {
    super(props)
    this.state = {
      inputValues: props.values,
    }
  }

  updateInputValues(values) {
    this.setState({ inputValues: values })
  }

  triggerOnChange(inputValues) {
    const { onChange } = this.props

    onChange(inputValues)
  }

  handleChange(e, index) {
    const inputValues = this.state.inputValues.slice()

    inputValues[index] = e.target.value
    this.setState({ inputValues })
    this.triggerOnChange(inputValues)
  }

  addInput() {
    const inputValues = this.state.inputValues.slice()
    inputValues.push("")
    this.setState({ inputValues })
    this.triggerOnChange(inputValues)
  }

  removeInput(index) {
    const { onRemove } = this.props
    const inputValues = this.state.inputValues.slice()

    inputValues.splice(index, 1)
    this.setState({ inputValues })
    onRemove(index)
  }

  renderInput(index) {
    const { inputProps, namePrefix } = this.props
    return (
      <Row key={ index }>
        <Col xs={ 12 } className="dynamic-input-group__input-container">
          <FormInput
            { ...inputProps }
            name={ `${namePrefix}-${index}` }
            onChange={ e => this.handleChange(e, index) }
            value={ this.state.inputValues[index] }
          />
          <Button bsStyle="danger" onClick={ () => this.removeInput(index) }>
            <Icon iconName="times" iconSize={ 20 } />
          </Button>
        </Col>
      </Row>
    )
  }

  render() {
    const { inputValues } = this.state
    return (
      <div className="dynamic-input-group__container">
        {inputValues.map((input, index) => this.renderInput(index))}
        <Button bsStyle="success" onClick={ () => this.addInput() }>
          <Icon iconName="plus" iconSize={ 20 } />
        </Button>
      </div>
    )
  }
}
