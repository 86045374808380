import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactTagsInput from "react-tagsinput"
import "react-tagsinput/react-tagsinput.css"

export default class TagsInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    values: PropTypes.array,
    renderTag: PropTypes.func.isRequired,
    withAutocompletion: PropTypes.bool,
    addOnEnter: PropTypes.bool,
    renderAutocomplete: PropTypes.func,
  }

  static defaultProps = {
    values: [],
    withAutocompletion: false,
    addOnEnter: true,
    renderAutocomplete: null,
  }

  renderAutocomplete = ({ addTag, ...props }) => {
    const { renderAutocomplete } = this.props

    return renderAutocomplete({
      ...props,
      onSelect: (value, item) => addTag(item),
    })
  }

  render() {
    const { onChange, values, addOnEnter, renderTag, withAutocompletion, ...props } = this.props

    const addedProps = {}
    if (withAutocompletion) {
      addedProps.renderInput = this.renderAutocomplete
    }
    return (
      <ReactTagsInput
        value={ values }
        addKeys={ addOnEnter ? ReactTagsInput.defaultProps.addKeys : [] }
        onChange={ onChange }
        renderTag={ renderTag }
        { ...addedProps }
        { ...props }
      />
    )
  }
}
