import React from "react"

import { ListPaginationConsumer } from "../context"

export default Comp =>
  class WithListPagination extends React.Component {
    render() {
      return (
        <ListPaginationConsumer>
          {({ currentPage, changePage }) => <Comp currentPage={ currentPage } changePage={ changePage } { ...this.props } />}
        </ListPaginationConsumer>
      )
    }
  }
