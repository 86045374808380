import PropTypes from "prop-types"
import i18n from "i18next"

import { generateButtonGroup } from "./SpecificAgreementActionButtonsHelper"
import { generateDataButton } from "../../utils"

const ButtonsSchoolReview = ({ canSave, onSave }) => {
  const finalDataButtons = []
  if (canSave) finalDataButtons.push(generateDataButton(i18n.t("spa:specific_agreements.form.buttons.shared.save"), onSave, "success"))

  return generateButtonGroup(finalDataButtons)
}

ButtonsSchoolReview.propTypes = {
  canSave: PropTypes.bool,
  onSave: PropTypes.func,
}

ButtonsSchoolReview.defaultProps = {
  canSave: true,
  onSave: () => true,
}

export default ButtonsSchoolReview
