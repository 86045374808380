import React from "react"
import { connect } from "react-redux"

import SpecificAgreementsList from "../components/SpecificAgreementsList"
import { GET_SPECIFIC_AGREEMENTS_REQUEST } from "../types"

const SpecificAgreementsListContainer = ({ ...props }) => <SpecificAgreementsList { ...props } />

const mapStateToProps = state => ({
  agreements: state.specificAgreements.agreements,
  count: state.sidebar.count.specific_agreement,
})

const mapDispatchToProps = dispatch => ({
  fetchAgreements: (type, page) => dispatch({ type: GET_SPECIFIC_AGREEMENTS_REQUEST, payload: { type, page } }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecificAgreementsListContainer)
