import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_EMAIL_REQUEST,
  RESET_PASSWORD_EMAIL_SUCCESS,
  RESET_PASSWORD_EMAIL_ERROR,
} from "../types"

export const initialState = {
  newPassword: {
    isInProgress: false,
    isRequestDone: false,
    isValid: false,
    errors: {},
  },
  emailRequest: {
    isInProgress: false,
    isRequestDone: false,
    isValid: false,
    errorData: null,
  },
}

export default function resetPasswordReducer(state = initialState, { type, payload }) {
  switch (type) {
    case RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        newPassword: {
          ...state.newPassword,
          isInProgress: true,
          isRequestDone: false,
          errors: {},
        },
      }
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        newPassword: {
          isInProgress: false,
          isRequestDone: true,
          isValid: true,
          errors: {},
        },
      }
    }
    case RESET_PASSWORD_ERROR: {
      const { errors } = payload

      return {
        ...state,
        newPassword: {
          isInProgress: false,
          isRequestDone: true,
          isValid: false,
          errors: errors,
        },
      }
    }
    case RESET_PASSWORD_EMAIL_REQUEST: {
      return {
        ...state,
        emailRequest: {
          ...state.emailRequest,
          isInProgress: true,
          isRequestDone: false,
        },
      }
    }
    case RESET_PASSWORD_EMAIL_SUCCESS: {
      return {
        ...state,
        emailRequest: {
          isInProgress: false,
          isRequestDone: true,
          isValid: true,
        },
      }
    }
    case RESET_PASSWORD_EMAIL_ERROR: {
      return {
        ...state,
        emailRequest: {
          isInProgress: false,
          isRequestDone: true,
          isValid: false,
        },
      }
    }
    default: {
      return { ...state }
    }
  }
}
