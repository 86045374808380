import { call, put, takeEvery, all } from "redux-saga/effects"
import { push } from "react-router-redux"
import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  SIGNUP_RESEND_EMAIL_REQUEST,
  SIGNUP_RESEND_EMAIL_SUCCESS,
  SIGNUP_RESEND_EMAIL_ERROR,
  SIGNUP_ACCEPT_INVITATION_REQUEST,
  SIGNUP_ACCEPT_INVITATION_SUCCESS,
  SIGNUP_ACCEPT_INVITATION_ERROR,
  SIGNUP_FILL_PROFILE_REQUEST,
  SIGNUP_FILL_PROFILE_SUCCESS,
  SIGNUP_FILL_PROFILE_ERROR,
} from "../types"

import Routes from "../routes"

import { signup, resendSignupEmail, acceptInvitation, fillProfile } from "../actions/session"
import { registerUser } from "../redux-token-auth-config"

export function* signupUser({ payload }) {
  const { email, password, locale } = payload
  try {
    const signupFn = yield put(
      registerUser({
        email,
        password,
        passwordConfirmation: password,
        role: "recruiter",
        confirmSuccessUrl: `${window.location.origin}/confirm`,
        host: window.location.origin,
        locale,
      })
    )
    yield call(signup, signupFn)
    yield put({ type: SIGNUP_SUCCESS, payload: { email } })
  } catch (e) {
    yield put({ type: SIGNUP_ERROR, payload: { error: e.errors && e.errors.full_messages[0] } })
  }
}

export function* requestNewEmail({ payload }) {
  const { email } = payload

  try {
    yield call(resendSignupEmail, email)
    yield put({ type: SIGNUP_RESEND_EMAIL_SUCCESS })
  } catch (e) {
    yield put({ type: SIGNUP_RESEND_EMAIL_ERROR, payload: { message: e.message } })
  }
}

export function* confirmInvitation({ payload }) {
  const { password, token } = payload

  try {
    yield call(acceptInvitation, password, token)
    yield put({ type: SIGNUP_ACCEPT_INVITATION_SUCCESS })
    yield put(push(Routes.SIGNIN))
  } catch (e) {
    yield put({ type: SIGNUP_ACCEPT_INVITATION_ERROR })
  }
}

export function* fillUserProfile({ payload }) {
  const { token, data } = payload

  try {
    yield call(fillProfile, token, data)
    yield put({ type: SIGNUP_FILL_PROFILE_SUCCESS })
    yield put(push(Routes.SIGNIN))
  } catch (e) {
    yield put({ type: SIGNUP_FILL_PROFILE_ERROR, payload: { errors: e } })
  }
}

function* signupSaga() {
  yield all([
    takeEvery(SIGNUP_REQUEST, signupUser),
    takeEvery(SIGNUP_RESEND_EMAIL_REQUEST, requestNewEmail),
    takeEvery(SIGNUP_ACCEPT_INVITATION_REQUEST, confirmInvitation),
    takeEvery(SIGNUP_FILL_PROFILE_REQUEST, fillUserProfile),
  ])
}

export default signupSaga
