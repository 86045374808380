import React from "react"
import PropTypes from "prop-types"
import { Checkbox } from "react-bootstrap"
import i18n from "i18next"

const GeneralAgreementFormSchoolInputs = ({ data, renderInputs, handleCheckboxChange }) => (
  <React.Fragment>
    {[
      {
        name: "are_specific_agreements_charged",
        className: "checkbox-primary",
        inputComponentClass: Checkbox,
        checked: data.are_specific_agreements_charged,
        children: <span>{i18n.t("spa:general_agreements.form.fields.are_specific_agreements_charged.label")}</span>,
        onChange: handleCheckboxChange,
      },
      {
        name: "are_students_paid_by_school",
        className: "checkbox-primary",
        checked: data.are_students_paid_by_school,
        inputComponentClass: Checkbox,
        children: <span>{i18n.t("spa:general_agreements.form.fields.are_students_paid_by_school.label")}</span>,
        onChange: handleCheckboxChange,
      },
      {
        name: "observations",
        componentClass: "textarea",
        rows: 5,
      },
    ].map((elem, index) => React.cloneElement(renderInputs(elem), { key: index }))}
  </React.Fragment>
)

GeneralAgreementFormSchoolInputs.propTypes = {
  data: PropTypes.object.isRequired,
  renderInputs: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func,
}

GeneralAgreementFormSchoolInputs.defaultProps = {
  handleCheckboxChange: () => true,
}

export default GeneralAgreementFormSchoolInputs
