import React from "react"
import PropTypes from "prop-types"
import pickBy from "lodash/pickBy"
import moment from "moment"

const SpecificAgreementFormContext = React.createContext()

export const stepIndexes = {
  SCHOOL: 0,
  COMPANY: 1,
  JOB: 2,
  STUDENT: 3,
  TUTOR: 4,
  REVIEW: 5,
}

export class SpecificAgreementFormContextProvider extends React.Component {
  static propTypes = {
    agreementId: PropTypes.number,
  }

  static defaultProps = {
    agreementId: undefined,
  }

  state = {
    currentStep: this.props.agreementId
      ? null
      : this.props.location.state && this.props.location.state.bySchoolAdmin
        ? 1
        : 0,
    data: {
      job: {
        job_started_at: moment().toDate(),
        job_ended_at: moment()
          .add(4, "month")
          .toDate(),
      },
      student: {},
      company: {},
      school: {},
      tutor: {},
      approvals: [],
      state: "draft",
      agreementId: this.props.agreementId,
      signature: {},
    },
  }

  getData = (keys = []) => pickBy(this.state.data, (data, key) => keys.includes(key))

  navigateTo = (step) => {
    this.setState(state => ({
      ...state,
      currentStep: step,
    }))
  }

  updateData = (key, data) => {
    this.setState(state => ({
      ...state,
      data: {
        ...state.data,
        [key]: {
          ...state.data[key],
          ...data,
        },
      },
    }))
  }

  updateMultiData = (data) => {
    this.setState(state => ({
      ...state,
      data: {
        ...state.data,
        ...data,
      },
    }))
  }

  render() {
    return (
      <SpecificAgreementFormContext.Provider
        value={ {
          ...this.state,
          getData: this.getData,
          updateData: this.updateData,
          navigateTo: this.navigateTo,
          updateMultiData: this.updateMultiData,
        } }
      >
        {this.props.children}
      </SpecificAgreementFormContext.Provider>
    )
  }
}

SpecificAgreementFormContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

export const SpecificAgreementContextConsumer = SpecificAgreementFormContext.Consumer
