import { call, put, takeEvery, all } from "redux-saga/effects"
import { push } from "react-router-redux"
import i18n from "i18next"

import { SIGNIN_SUCCESS, SIGNIN_ERROR, SIGNIN_REQUEST, SIGNOUT_REQUEST, SIGNOUT_SUCCESS, SIGNOUT_ERROR } from "../types"
import { signin, signout } from "../actions/session"
import { signInUser } from "../redux-token-auth-config"
import Session from "../helpers/SessionHelper"

import Routes from "../routes"

export function* signinUser({ payload }) {
  // Temp, waiting to have an API
  const { email, password } = payload
  try {
    const signinFn = yield put(signInUser({ email, password }))
    yield call(signin, signinFn)
    yield put({ type: SIGNIN_SUCCESS })
    if (!Session.isAdmin()) {
      let toType
      if (Session.isRecruiter()) toType = "draft"
      else if (Session.isSchoolAdmin()) toType = "in_school_review"
      else if (Session.isOAP()) toType = "pending_approvals"
      if (Session.isRecruiter() && (!Session.firstName && !Session.lastName)) {
        yield put(push(Routes.PROFILE_USER))
      } else {
        yield put(
          push(Routes.SPECIFIC_AGREEMENT_INDEX.replace(":category", "specific_agreement").replace(":type", toType))
        )
      }
    } else yield put(push(Routes.ADMIN_INDEX))
  } catch (e) {
    const error = e.response.data
    yield put({
      type: SIGNIN_ERROR,
      payload: { error: error.errors ? error.errors[0] : i18n.t("spa:shared.error.bad_request") },
    })
  }
}

export function* signoutUser() {
  try {
    yield call(signout)
    Session.resetPrimaryColor().resetLogos()
    Session.useGeneralAgreement = true
    yield put({ type: SIGNOUT_SUCCESS })
    yield put(push(Routes.SIGNIN))
  } catch (e) {
    yield put({ type: SIGNOUT_ERROR })
  }
}

function* signinSaga() {
  yield all([ takeEvery(SIGNIN_REQUEST, signinUser), takeEvery(SIGNOUT_REQUEST, signoutUser) ])
}

export default signinSaga
