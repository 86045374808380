import React, { Fragment } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { GenericFormInput, GenericDatePickerRange } from "../GenericInputs"

const GeneralAgreementFormMainFields = ({ handleChange, editable, data }) => (
  <Fragment>
    <GenericDatePickerRange
      onStartDateChange={ day => handleChange("start_date", moment(day).toDate()) }
      onEndDateChange={ day => handleChange("end_date", moment(day).toDate()) }
      data={ data }
      editable={ editable }
    />
    <GenericFormInput
      name="reference"
      componentClass="textarea"
      rows={ 3 }
      data={ data }
      baseTranslationKey="general_agreements.form.fields"
      editable={ editable }
      onChange={ e => handleChange(e.target.name, e.target.value) }
    />
  </Fragment>
)

GeneralAgreementFormMainFields.propTypes = {
  handleChange: PropTypes.func,
  editable: PropTypes.bool,
  data: PropTypes.object.isRequired,
}

GeneralAgreementFormMainFields.defaultProps = {
  handleChange: () => true,
  editable: true,
}

export default GeneralAgreementFormMainFields
