import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import i18n from "i18next";
import moment from "moment";
import classname from "classnames";

import Session from "../../helpers/SessionHelper";

import { Title, Icon, ToolTip, Badge, Logo, Button } from "../Common";
import UserContact from "../UserContact";

import "./AgreementCard.css";
import SpecificAgreementExtensionStatus from "../SpecificAgreementExtensionStatus";

export default class AgreementCard extends Component {
  static propTypes = {
    title: PropTypes.string,
    company: PropTypes.string,
    showGeneralAgreementData: PropTypes.bool,
    requestBy: PropTypes.shape({
      name: PropTypes.string,
      pictureUrl: PropTypes.string,
      phoneNumber: PropTypes.string,
      emailAddress: PropTypes.string
    }),
    agreementId: PropTypes.string,
    onRequestGeneralAgreement: PropTypes.func,
    agreementState: PropTypes.string,
    agreementStateBadgeStyle: PropTypes.oneOf([
      "default",
      "success",
      "primary",
      "danger",
      null
    ]),
    generalAgreementData: PropTypes.shape({
      state: PropTypes.oneOf([
        "company_draft",
        "school_review",
        "sent_for_signature",
        "active",
        "expired",
        "cancelled",
      ]),
      end_date: PropTypes.string
    }),
    studentName: PropTypes.string,
    schoolName: PropTypes.string,
    alterationData: PropTypes.shape({
      alterationId: PropTypes.number,
      alterationStatus: PropTypes.string,
      alterationCreatedAt: PropTypes.string,
      alteratedId: PropTypes.number,
      alteratedStatus: PropTypes.string,
      alteratedCreatedAt: PropTypes.string
    }),
    showStatus: PropTypes.bool,
    organizationLogo: PropTypes.string,
    onDelete: PropTypes.func,
    showDeleteButton: PropTypes.bool,
    onUnsetAlterationStatus: PropTypes.func,
    showUnsetAlterationStatusButton: PropTypes.bool,
    onUndoCancel: PropTypes.func,
    showUndoCancelButton: PropTypes.bool,
  };

  static defaultProps = {
    title: "",
    company: "",
    requestBy: null,
    showGeneralAgreementData: false,
    agreementId: null,
    onRequestGeneralAgreement: () => true,
    agreementState: null,
    agreementStateBadgeStyle: null,
    approvalState: null,
    generalAgreementData: null,
    studentName: null,
    schoolName: null,
    alterationData: null,
    showStatus: true,
    organizationLogo: null,
    onDelete: () => true,
    showDeleteButton: false,
    onUnsetAlterationStatus: () => true,
    showUnsetAlterationStatusButton: false,
    onUndoCancel: () => true,
    showUndoCancelButton: false,
  };

  renderSpecificAgreementStateBadge() {
    const { agreementState, agreementStateBadgeStyle } = this.props;
    return (
      <Badge
        badgeStyle={agreementStateBadgeStyle}
        className="agreement-card__badge"
      >
        {agreementState}
      </Badge>
    );
  }

  getGeneralAgreementInfo() {
    const { generalAgreementData } = this.props;

    return {
      text: i18n.t(
        `spa:card.general_agreement_state.${
          !generalAgreementData.state ? "none" : generalAgreementData.state
        }.full_state`,
        {
          end_date: moment(generalAgreementData.end_date).format("DD/MM/YYYY"),
          interpolation: { escapeValue: false }
        }
      ),
      redirectToId: generalAgreementData.state === "company_draft"
    };
  }

  renderGeneralAgreementInfo() {
    const { onRequestGeneralAgreement, generalAgreementData } = this.props;
    const { text, redirectToId } = this.getGeneralAgreementInfo();

    return (
      <div className="agreement-card__general-agreement-warn">
        <ToolTip
          id="tooltip-ga-create"
          renderTooltip={
            (!generalAgreementData.state ||
              generalAgreementData.state === "company_draft")
              ? () => (
                  <span>
                    {i18n.t(
                      "spa:card.shared.tooltip.general_agreement_required"
                    )}
                  </span>
                )
              : null
          }
        >
          <span
            className={classname(
              `text-${
                generalAgreementData.state === "active" ? "info" : "danger"
              }`,
              {
                clickable:
                  (!generalAgreementData.state ||
                    generalAgreementData.state === "company_draft")
              }
            )}
            onClick={() =>
              (!generalAgreementData.state ||
                generalAgreementData.state === "company_draft") &&
              onRequestGeneralAgreement(redirectToId)
            }
          >
            <Icon
              iconName={
                generalAgreementData.state === "active" ? "check" : "warning"
              }
            />
            <span
              className="agreement-card__warn-text"
              onClick={() =>
                (generalAgreementData && generalAgreementData.state !== "company_draft") &&
                onRequestGeneralAgreement(generalAgreementData.state !== undefined)
              }
            >
              {text}
            </span>
          </span>
        </ToolTip>
      </div>
    );
  }

  render() {
    const {
      title,
      company,
      showGeneralAgreementData,
      requestBy,
      agreementId,
      agreementState,
      schoolName,
      studentName,
      generalAgreementData,
      alterationData,
      showStatus,
      organizationLogo,
      onDelete,
      showDeleteButton,
      onUnsetAlterationStatus,
      showUnsetAlterationStatusButton,
      onUndoCancel,
      showUndoCancelButton,
    } = this.props;

    return (
      <Col xs={12} className="agreement-card__container clearfix">
        <Col xs={12} sm={8} className="agreement-card__left-container">
          {organizationLogo && (
            <div style={{ display: "flex" }}>
              <Logo
                width={80}
                height={80}
                style={{ margin: 0 }}
                src={organizationLogo}
              />
            </div>
          )}
          <div style={{ flexDirection: "column", marginLeft: 10 }}>
            <Title size={24} className="agreement-card__title">
              {[studentName, title].filter(elem => !!elem).join(" - ")}
              {agreementState &&
                showStatus &&
                this.renderSpecificAgreementStateBadge()}
            </Title>
            {agreementId && (
              <div className="agreement-card__agreement-id">
                <span>N° {agreementId}</span>
                {alterationData &&
                  (alterationData.alteratedId ||
                    alterationData.alterationId) && (
                    <Fragment>
                      <span> - </span>
                      <SpecificAgreementExtensionStatus
                        alteratedId={alterationData.alteratedId}
                        alteratedStatus={alterationData.alteratedStatus}
                        alteratedCreatedAt={alterationData.alteratedCreatedAt}
                        alterationId={alterationData.alterationId}
                        alterationStatus={alterationData.alterationStatus}
                        alterationCreatedAt={alterationData.alterationCreatedAt}
                      />
                    </Fragment>
                  )}
              </div>
            )}
            <div style={{ marginTop: 10 }}>
              <span className="agreement-card__company-name">
                {[schoolName, company].filter(elem => !!elem).join(" - ")}
              </span>
              {showGeneralAgreementData &&
                (Session.isRecruiter() ||
                  (Session.isSchoolAdmin() && Session.useGeneralAgreement)) &&
                generalAgreementData &&
                this.renderGeneralAgreementInfo()}
            </div>
          </div>
        </Col>
        {requestBy && (
          <Col xs={12} sm={4} className="agreement-card__right-container">
            <div>
              <span>
                {i18n.t("spa:card.shared.request_by")}{" "}
                <span className="agreement-card__request-by-name">
                  {requestBy.title}
                </span>
              </span>
            </div>
            <div className="agreement-card__action-container">
              <UserContact
                emailAddress={requestBy.emailAddress}
                avatarUrl={requestBy.pictureUrl}
                phoneNumber={requestBy.phoneNumber}
                fullName={requestBy.name}
              />
            </div>
          </Col>
        )}
        {Session.isAdmin() && showDeleteButton && (
          <Col xs={1} sm={1} className="agreement-card__jtadmin">
            <Button block bsStyle="danger" onClick={onDelete}>
              Delete
            </Button>
          </Col>
        )}
        {Session.isAdmin() && showUnsetAlterationStatusButton && (
          <Col xs={2} sm={3} className="agreement-card__jtadmin">
            <Button block bsStyle="danger" onClick={onUnsetAlterationStatus}>
              Undo terminated Early
            </Button>
          </Col>
        )}
        {Session.isAdmin() && showUndoCancelButton && (
          <Col xs={2} sm={2} className="agreement-card__jtadmin">
          <Button block bsStyle="danger" onClick={onUndoCancel}>
            Undo cancel
          </Button>
        </Col>
        )}
      </Col>
    );
  }
}
