import { toast } from "react-toastify"

export default class Toaster {
  static POSITIONS = {
    TOP_LEFT: toast.POSITION.TOP_LEFT,
    TOP_CENTER: toast.POSITION.TOP_CENTER,
    TOP_RIGHT: toast.POSITION.TOP_RIGHT,
    BOTTOM_LEFT: toast.POSITION.BOTTOM_LEFT,
    BOTTOM_CENTER: toast.POSITION.BOTTOM_CENTER,
    BOTTOM_RIGHT: toast.POSITION.BOTTOM_RIGHT,
  }

  static TYPES = {
    SUCCESS: toast.TYPE.SUCCESS,
    ERROR: toast.TYPE.ERROR,
    INFO: toast.TYPE.INFO,
    WARNING: toast.TYPE.WARNING,
    DEFAULT: toast.TYPE.DEFAULT,
  }

  static get Builder() {
    const Builder = class {
      position = Toaster.POSITIONS.TOP_RIGHT
      type = Toaster.TYPES.DEFAULT
      time = 5000
      message = ""
      options = {}

      setPosition(position) {
        this.position = position
        return this
      }

      setType(type) {
        this.type = type
        return this
      }

      setTime(time) {
        this.time = time
        return this
      }

      setMessage(message) {
        this.message = message
        return this
      }

      setOptions(opts) {
        this.options = opts
        return this
      }

      build() {
        return new Toaster(this.message, {
          position: this.position,
          type: this.type,
          autoClose: this.time,
          ...this.options,
        })
      }
    }

    return Builder
  }

  static getBuilder() {
    return new Toaster.Builder()
  }

  constructor(message, options) {
    this.message = message
    this.options = options

    this.toaster = null
  }

  show() {
    this.toaster = toast(this.message, this.options)
  }

  dismiss() {
    toast.dismiss(this.toaster)
  }
}
