import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import moment from "moment"
import { Col } from "react-bootstrap"

import { Activity } from "../Common"

import { computeFullName } from "../../utils"

export default class SpecificAgreementActivities extends Component {
  static propTypes = {
    activities: PropTypes.array,
  }

  static defaultProps = {
    activities: [],
  }

  getActivityData(activity) {
    const { state, user_first_name: firstName, user_last_name: lastName, created_at: createdAt, reason_for_cancellation: reasonForCancellation } = activity
    const content = i18n.t(`spa:specific_agreements.school_panel.activity.status_message.${state}`, {
      name: computeFullName({ first_name: firstName, last_name: lastName }),
      reason_for_cancellation: reasonForCancellation,
    })
    const header = moment(createdAt).format("DD/MM/YYYY HH:mm")
    const generateData = (iconName, bgStyle = null, props) => ({
      content,
      header,
      iconName,
      bgStyle,
      ...props,
    })

    switch (state) {
      case "draft":
        return generateData("file")
      case "student_info_completion":
        return generateData("edit")
      case "pending_approvals":
        return generateData("comment")
      case "rejected":
        return generateData("times", "bg-danger", { badgeClassName: "specific-agreement__activity-rejected" })
      case "approved":
        return generateData("check", "bg-primary")
      case "sent_for_signature":
        return generateData("paper-plane")
      case "signature_cancelled":
        return generateData("times", "bg-warning")
      case "signed":
        return generateData("pencil")
      case "cancelled":
        return generateData("times", "bg-danger", { badgeClassName: "specific-agreement__activity-rejected" })
      default:
        break
    }
    return null
  }

  render() {
    const { activities } = this.props
    return (
      <Fragment>
        <Col xs={ 12 }>
          {activities.length > 0 &&
            activities.reverse().map((activity, index) => {
              const data = this.getActivityData(activity)
              if (!data) return null
              return <Activity key={ index } { ...data } />
            })}
          {activities.length === 0 && (
            <p className="text-primary">
              {i18n.t("spa:specific_agreements.school_panel.activity.status_message.no_activity")}
            </p>
          )}
        </Col>
        <div className="clearfix" />
      </Fragment>
    )
  }
}
