import React from "react"
import i18n from "i18next"
import { Col } from "react-bootstrap"

import { Modal, Icon, ButtonGroup } from "../Common"

export default class CancelSignatureModal extends React.PureComponent {
  state = {
    isOpen: false,
  }

  open = () => {
    this.setState({ isOpen: true })
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  onConfirm = () => {
    this.props.onConfirmCancellation()
    this.close()
  }

  render() {
    return (
      <Modal isOpen={ this.state.isOpen } className="electronic-signature__modal">
        <Modal.Title>
          <Icon iconName="graduation-cap" style={ { marginRight: 10 } } />
          {i18n.t("spa:electronic_signature.cancel_modal.title")}
        </Modal.Title>
        <Modal.Content>
          <Col xs={ 12 } className="electronic-signature__modal-content">
            <p>{i18n.t("spa:electronic_signature.cancel_modal.content")}</p>
          </Col>
        </Modal.Content>
        <Modal.Footer>
          <Col xs={ 12 }>
            <ButtonGroup
              buttonSpacing={ 10 }
              buttonsData={ [
                {
                  title: i18n.t("spa:electronic_signature.cancel_modal.buttons.cancel"),
                  bsStyle: "default",
                  onClick: this.close,
                },
                {
                  title: i18n.t("spa:electronic_signature.cancel_modal.buttons.confirm"),
                  bsStyle: "danger",
                  onClick: this.onConfirm,
                },
              ] }
            />
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }
}
