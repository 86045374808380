import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import { Col, Row } from "react-bootstrap"

import { Button } from "../Common"

const ApprovalActionButtons = ({ onApprove, onDeny }) => (
  <Col xs={ 12 } className="approval__action-buttons-container">
    <Row>
      {[
        {
          type: "deny",
          onClick: onDeny,
          style: "danger",
        },
        {
          type: "approve",
          onClick: onApprove,
          style: "primary",
        },
      ].map(elem => (
        <Col xs={ 12 } sm={ 6 } key={ elem.type }>
          <Button bsStyle={ elem.style } block onClick={ elem.onClick }>
            {i18n.t(`spa:specific_agreements.form.buttons.approval.${elem.type}`)}
          </Button>
        </Col>
      ))}
    </Row>
  </Col>
)

ApprovalActionButtons.propTypes = {
  onApprove: PropTypes.func,
  onDeny: PropTypes.func,
}

ApprovalActionButtons.defaultProps = {
  onApprove: () => true,
  onDeny: () => true,
}

export default ApprovalActionButtons
