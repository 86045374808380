import React from "react"
import { connect } from "react-redux"
import { Switch, Route } from "react-router"

import SpecificAgreementForm from "../components/SpecificAgreementForm"
import AgreementPrintingTemplate from "../components/AgreementPrintingTemplate"

import Routes from "../routes"

import {
  LIST_REQUEST,
  SPECIFIC_AGREEMENT_EDIT_REQUEST,
  APPROVAL_ASK_REQUEST,
  APPROVAL_EDIT_REQUEST,
  SPECIFIC_AGREEMENT_CREATE_REQUEST,
  GET_SPECIFIC_AGREEMENT_DATA_REQUEST,
  SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_REQUEST,
  SIGN_SPECIFIC_AGREEMENT_REQUEST,
  PRINT_AGREEMENT_REQUEST,
  COMMENT_CREATE_REQUEST,
  COMMENT_DELETE_REQUEST,
  GET_COMMENTS_SUCCESS,
  REMOVE_SPECIFIC_AGREEMENT_REQUEST,
  MODIFY_SPECIFIC_AGREEMENT_REQUEST,
  CANCEL_SPECIFIC_AGREEMENT_REQUEST,
  EXTEND_SPECIFIC_AGREEMENT_REQUEST,
  TERMINATE_SPECIFIC_AGREEMENT_REQUEST,
  SAVE_SPECIFIC_AGREEMENT_PRINTINT_TEMPLATE_REQUEST,
  SKIP_APPROVAL_VALIDATION_REQUEST,
  CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_REQUEST,
  SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_REQUEST,
  GET_COMPANY_SECTORS_REQUEST,
  SEND_REMINDER_SPECIFIC_AGREEMENT_REQUEST,
} from "../types"
import { SpecificAgreementFormContextProvider } from "../context"

const withSpecificAgreementProvider = (node, props) => (
  <SpecificAgreementFormContextProvider { ...props }>{node}</SpecificAgreementFormContextProvider>
)

const SpecificAgreementFormContainer = ({ ...props }) => (
  <Switch>
    <Route
      exact
      path={ Routes.SPECIFIC_AGREEMENT_CREATE }
      render={ ({ location }) => withSpecificAgreementProvider(<SpecificAgreementForm { ...props } />, { location }) }
    />
    <Route
      path={ Routes.SPECIFIC_AGREEMENT_PRINTING_TEMPLATE_PREVIEW }
      render={ ({ match }) => (
        <AgreementPrintingTemplate { ...props } agreementId={ Number(match.params.id) } type="specific_agreement" />
      ) }
    />
    <Route
      path={ Routes.SPECIFIC_AGREEMENT_SHOW }
      render={ ({ match, location }) =>
        withSpecificAgreementProvider(<SpecificAgreementForm { ...props } agreementId={ Number(match.params.id) } />, {
          agreementId: Number(match.params.id),
          location,
        })
      }
    />
  </Switch>
)

const mapStateToProps = state => ({
  schools: state.admin.schools.list,
  isSendingSignature: state.specificAgreements.isSendingSignature,
  editErrors: state.specificAgreements.agreementEdit.errors,
  agreement: state.specificAgreements.agreementData,
  comments: state.comments.comments,
  functions: state.jt.functions,
  companySectors: state.jt.companySectors,
  activities: state.specificAgreements.activities,
  curriculums: state.jt.curriculums,
})

const mapDispatchToProps = dispatch => ({
  getSchools: () => dispatch({ type: LIST_REQUEST, payload: { type: "schools" } }),
  editAgreement: (data, action, updateUrl, redirect = false) =>
    dispatch({
      type: SPECIFIC_AGREEMENT_EDIT_REQUEST,
      payload: { data, action, updateUrl, redirect, resetPage: true },
    }),
  requestApproval: (data, action, state, agreementId, userIds, updateUrl) =>
    dispatch({ type: APPROVAL_ASK_REQUEST, payload: { data, action, state, agreementId, userIds, updateUrl } }),
  editApproval: (agreementId, userId, state, id, agreementData, updateUrl) =>
    dispatch({ type: APPROVAL_EDIT_REQUEST, payload: { agreementId, userId, state, id, agreementData, updateUrl } }),
  createAgreement: (needCompanyCreation, companyData, agreementData) =>
    dispatch({ type: SPECIFIC_AGREEMENT_CREATE_REQUEST, payload: { needCompanyCreation, companyData, agreementData } }),
  getAgreementData: id => dispatch({ type: GET_SPECIFIC_AGREEMENT_DATA_REQUEST, payload: { id } }),
  sendForSignature: (url, id, data, byPass = false) =>
    dispatch({ type: SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_REQUEST, payload: { url, id, data, byPass } }),
  signAgreement: (url, data) => dispatch({ type: SIGN_SPECIFIC_AGREEMENT_REQUEST, payload: { url, data } }),
  printAgreement: url => dispatch({ type: PRINT_AGREEMENT_REQUEST, payload: { url } }),
  createComment: (id, body) => dispatch({ type: COMMENT_CREATE_REQUEST, payload: { id, body } }),
  deleteComment: (id, specific_agreement_id) =>
    dispatch({ type: COMMENT_DELETE_REQUEST, payload: { id, specific_agreement_id } }),
  getComments: specific_agreement_id => dispatch({ type: GET_COMMENTS_SUCCESS, payload: { specific_agreement_id } }),
  removeSpecificAgreement: id => dispatch({ type: REMOVE_SPECIFIC_AGREEMENT_REQUEST, payload: { id } }),
  modifySpecificAgreement: (id, reason, jobRealEndDate) =>
    dispatch({ type: MODIFY_SPECIFIC_AGREEMENT_REQUEST, payload: { id, reason, jobRealEndDate } }),
  cancelSpecificAgreement: (id, reason) =>
    dispatch({ type: CANCEL_SPECIFIC_AGREEMENT_REQUEST, payload: { id, reason } }),
  extendSpecificAgreement: (id, reason, jobRealEndDate) =>
    dispatch({ type: EXTEND_SPECIFIC_AGREEMENT_REQUEST, payload: { id, reason, jobRealEndDate } }),
  terminateSpecificAgreement: (id, reason, realEndDate, hoursDone) =>
    dispatch({ type: TERMINATE_SPECIFIC_AGREEMENT_REQUEST, payload: { id, reason, realEndDate, hoursDone } }),
  savePrintingTemplate: (data, updateUrl) =>
    dispatch({ type: SAVE_SPECIFIC_AGREEMENT_PRINTINT_TEMPLATE_REQUEST, payload: { data, updateUrl } }),
  skipApprovalValidation: (id, data, updateUrl) =>
    dispatch({ type: SKIP_APPROVAL_VALIDATION_REQUEST, payload: { id, data, updateUrl } }),
  cancelSignature: id => dispatch({ type: CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_REQUEST, payload: { id } }),
  sendEmailToStudentSpecificAgreement: (data) =>
    dispatch({
      type: SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_REQUEST,
      payload: { data }
    }),
  getCompanySectors: () => dispatch({ type: GET_COMPANY_SECTORS_REQUEST, payload: {} }),
  sendReminder: (id) => dispatch({ type: SEND_REMINDER_SPECIFIC_AGREEMENT_REQUEST, payload: { id } }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecificAgreementFormContainer)
