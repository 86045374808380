import { LOCATION_CHANGE } from "react-router-redux"

import {
  GET_SPECIFIC_AGREEMENTS_REQUEST,
  GET_SPECIFIC_AGREEMENTS_SUCCESS,
  SPECIFIC_AGREEMENT_EDIT_REQUEST,
  SPECIFIC_AGREEMENT_EDIT_ERROR,
  SPECIFIC_AGREEMENT_EDIT_SUCCESS,
  APPROVAL_ASK_SUCCESS,
  SPECIFIC_AGREEMENT_CREATE_SUCCESS,
  SPECIFIC_AGREEMENT_CREATE_ERROR,
  SPECIFIC_AGREEMENT_CREATE_REQUEST,
  GET_SPECIFIC_AGREEMENT_DATA_REQUEST,
  GET_SPECIFIC_AGREEMENT_DATA_SUCCESS,
  GET_SPECIFIC_AGREEMENT_DATA_ERROR,
  GET_SPECIFIC_AGREEMENT_ACTIVITIES_SUCCESS,
  GET_STUDENT_INFO_REQUEST,
  GET_STUDENT_INFO_SUCCESS,
  GET_STUDENT_INFO_ERROR,
  MODIFY_SPECIFIC_AGREEMENT_SUCCESS,
  CANCEL_SPECIFIC_AGREEMENT_SUCCESS,
  EXTEND_SPECIFIC_AGREEMENT_SUCCESS,
  UPDATE_STUDENT_INFO_SUCCESS,
  TERMINATE_SPECIFIC_AGREEMENT_SUCCESS,
  SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_ERROR,
  SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_REQUEST,
  SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_SUCCESS,
  SIGN_SPECIFIC_AGREEMENT_ERROR,
  UPDATE_STUDENT_INFO_ERROR,
  CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_SUCCESS,
  SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_REQUEST,
  SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_SUCCESS,
  SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_ERROR,
  MODIFY_SPECIFIC_AGREEMENT_ERROR,
  EXTEND_SPECIFIC_AGREEMENT_ERROR,
  TERMINATE_SPECIFIC_AGREEMENT_ERROR,
  SEND_REMINDER_SPECIFIC_AGREEMENT_SUCCESS,
  SEND_REMINDER_SPECIFIC_AGREEMENT_ERROR,
} from "../types"

export const initialState = {
  agreements: { data: [], isLoading: false },
  agreementData: {
    data: {},
    isLoading: false,
    magicNumber: null,
  },
  agreementEdit: {
    errors: {},
    isLoading: false,
  },
  activities: [],
  studentInfos: {},
  studentCompletionError: null,
  studentCompletionFormErrors: {},
}

export default function agreementsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_SPECIFIC_AGREEMENTS_REQUEST: {
      return { ...state, agreements: { ...state.agreements, isLoading: true } }
    }
    case GET_SPECIFIC_AGREEMENTS_SUCCESS: {
      const { agreements } = payload
      return { ...state, agreements: { ...state.agreements, data: agreements, isLoading: false } }
    }
    case GET_SPECIFIC_AGREEMENT_DATA_REQUEST: {
      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          data: {},
          isLoading: true,
        },
      }
    }
    case GET_SPECIFIC_AGREEMENT_DATA_ERROR: {
      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          isLoading: false,
        },
      }
    }
    case GET_SPECIFIC_AGREEMENT_DATA_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        agreementData: {
          data,
          isLoading: false,
          magicNumber: new Date().getTime(),
        },
      }
    }
    case SPECIFIC_AGREEMENT_CREATE_REQUEST: {
      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          data: {},
          isLoading: true,
        },
      }
    }
    case SPECIFIC_AGREEMENT_CREATE_SUCCESS: {
      const { agreement } = payload
      return {
        ...state,
        agreementData: {
          isLoading: false,
          data: agreement,
          magicNumber: new Date().getTime(),
        },
        agreementEdit: {
          ...state.agreementEdit,
          errors: {},
          isLoading: false,
        },
      }
    }
    case SPECIFIC_AGREEMENT_CREATE_ERROR: {
      const { errors, data } = payload
      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          data: {
            specific_agreement: data,
          },
          isLoading: false,
          magicNumber: new Date().getTime(),
        },
        agreementEdit: {
          ...state.agreementEdit,
          errors,
          isLoading: false,
        },
      }
    }
    case SPECIFIC_AGREEMENT_EDIT_REQUEST: {
      return {
        ...state,
        agreementEdit: {
          ...state.agreementEdit,
          isLoading: true,
        },
      }
    }
    case SPECIFIC_AGREEMENT_EDIT_ERROR: {
      const { errors } = payload
      return {
        ...state,
        agreementEdit: {
          errors,
          isLoading: false,
        },
        agreementData: {
          ...state.agreementData,
          isLoading: false,
        },
      }
    }
    case SPECIFIC_AGREEMENT_EDIT_SUCCESS: {
      const { agreement } = payload
      return {
        ...state,
        agreementEdit: {
          errors: {},
          isLoading: false,
        },
        agreementData: {
          data: agreement,
          isLoading: false,
          magicNumber: new Date().getTime(),
        },
      }
    }
    case APPROVAL_ASK_SUCCESS: {
      return {
        ...state,
        agreementEdit: {
          errors: {},
        },
      }
    }
    case GET_SPECIFIC_AGREEMENT_ACTIVITIES_SUCCESS: {
      const { data } = payload
      return {
        ...state,
        activities: data,
      }
    }
    case GET_STUDENT_INFO_REQUEST: {
      return {
        ...state,
        studentInfos: {},
        studentCompletionError: null,
      }
    }
    case GET_STUDENT_INFO_SUCCESS: {
      const { data } = payload
      return {
        ...state,
        studentInfos: data,
      }
    }
    case GET_STUDENT_INFO_ERROR: {
      const { error } = payload
      return {
        ...state,
        studentCompletionError: error,
      }
    }
    case MODIFY_SPECIFIC_AGREEMENT_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          data,
          magicNumber: new Date().getTime(),
        },
      }
    }
    case CANCEL_SPECIFIC_AGREEMENT_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          data,
          magicNumber: new Date().getTime(),
        },
      }
    }
    case EXTEND_SPECIFIC_AGREEMENT_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          data,
          magicNumber: new Date().getTime(),
        },
      }
    }
    case UPDATE_STUDENT_INFO_SUCCESS: {
      const { message } = payload

      return {
        ...state,
        studentCompletionError: message,
        studentCompletionFormErrors: {},
      }
    }
    case UPDATE_STUDENT_INFO_ERROR: {
      const { errors } = payload

      return {
        ...state,
        studentCompletionFormErrors: errors,
      }
    }
    case TERMINATE_SPECIFIC_AGREEMENT_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          data,
          magicNumber: new Date().getTime(),
        },
      }
    }
    case SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_REQUEST: {
      return {
        ...state,
        isSendingSignature: true,
      }
    }
    case SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_SUCCESS: {
      return {
        ...state,
        isSendingSignature: false,
      }
    }
    case SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_ERROR: {
      const { errors } = payload

      return {
        ...state,
        isSendingSignature: false,
        agreementEdit: {
          errors,
        },
      }
    }
    case MODIFY_SPECIFIC_AGREEMENT_ERROR: {
      const { errors } = payload

      return {
        ...state,
        agreementEdit: {
          errors,
        },
      }
    }
    case EXTEND_SPECIFIC_AGREEMENT_ERROR: {
      const { errors } = payload

      return {
        ...state,
        agreementEdit: {
          errors,
        },
      }
    }
    case TERMINATE_SPECIFIC_AGREEMENT_ERROR: {
      const { errors } = payload

      return {
        ...state,
        agreementEdit: {
          errors,
        },
      }
    }
    case SIGN_SPECIFIC_AGREEMENT_ERROR: {
      const { errors } = payload

      return {
        ...state,
        agreementEdit: {
          errors,
        },
      }
    }
    case CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_SUCCESS: {
      const { agreement } = payload

      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          data: agreement,
          magicNumber: new Date().getTime(),
        },
      }
    }
    case SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_REQUEST: {
      return {
        ...state,
        agreementEdit: {
          ...state.agreementEdit,
          isLoading: true,
        },
      }
    }
    case SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_ERROR: {
      const { errors } = payload
      return {
        ...state,
        agreementEdit: {
          errors,
          isLoading: false,
        },
        agreementData: {
          ...state.agreementData,
          isLoading: false,
        },
      }
    }
    case SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_SUCCESS: {
      const { agreement } = payload
      return {
        ...state,
        agreementEdit: {
          errors: {},
          isLoading: false,
        },
        agreementData: {
          data: agreement,
          isLoading: false,
          magicNumber: new Date().getTime(),
        },
      }
    }
    case SEND_REMINDER_SPECIFIC_AGREEMENT_ERROR: {
      const { errors } = payload
      return {
        ...state,
        agreementEdit: {
          errors,
        },
        agreementData: {
          ...state.agreementData,
        },
      }
    }
    case SEND_REMINDER_SPECIFIC_AGREEMENT_SUCCESS: {
      const { errors } = payload
      return {
        ...state,
      }
    }
    case LOCATION_CHANGE: {
      return {
        ...state,
        agreementEdit: { errors: {}, isLoading: false },
        agreementData: { data: {}, isLoading: false, magicNumber: null },
      }
    }
    default:
      return { ...state }
  }
}
