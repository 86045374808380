import React from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import i18n from "i18next"

import { Button, Title } from "../Common"
import Routes from "../../routes"
import Session from "../../helpers/SessionHelper"

import "./TopBar.css"

const TopBar = ({ canCreateSpecificAgreement, title, subtitle, canCreateCompany }) => (
  <div className="topbar__container">
    <Col xs={ 12 } sm={ 7 }>
      <Title className="topbar__title">{title}</Title>
      <span className="topbar__subtitle">{subtitle}</span>
    </Col>
    {canCreateSpecificAgreement && (
      <Col xs={ 12 } sm={ 5 }>
        <Link
          to={ {
            pathname: Routes.SPECIFIC_AGREEMENT_CREATE,
            state: Session.isSchoolAdmin() && {
              bySchoolAdmin: true,
              school_id: Session.schoolId,
            },
          } }
        >
          <Button bsStyle="primary" className="topbar__new-internship-btn">
            {i18n.t(
              `spa:specific_agreements.index.search.company.new_specific_agreement.${
                Session.isRecruiter() ? "recruiter" : "not_recruiter"
              }`
            )}
          </Button>
        </Link>
      </Col>
    )}
    {canCreateCompany && (
      <Col xs={ 12 } sm={ 5 }>
        <Link to={ Routes.COMPANY_CREATION }>
          <Button bsStyle="primary" className="topbar__new-internship-btn">
            {i18n.t("spa:specific_agreements.index.search.company.create_company")}
          </Button>
        </Link>
      </Col>
    )}
  </div>
)

TopBar.propTypes = {
  canCreateSpecificAgreement: PropTypes.bool,
  canCreateCompany: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

TopBar.defaultProps = {
  canCreateSpecificAgreement: false,
  canCreateCompany: false,
  title: "",
  subtitle: "",
}

export default TopBar
