import React, { Component } from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import { Col, Form } from "react-bootstrap"
import isEmpty from "lodash/isEmpty"

import Session from "../../helpers/SessionHelper"

import { Button, ImageUpload, Alert } from "../Common"
import { GenericFormInput } from "../GenericInputs"
import PhoneInput from "../PhoneInput"

import { ConnectWithSidebar, withErrorManager } from "../../hoc"

import "./ProfileForm.css"
import { defaultRenderOptions } from "../../utils"

export class ProfileForm extends Component {
  static propTypes = {
    userData: PropTypes.object,
    getProfileById: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
    editErrors: PropTypes.object.isRequired,
    getValidationState: PropTypes.func.isRequired,
    getErrorMessage: PropTypes.func.isRequired,
  }

  static defaultProps = {
    userData: {},
  }

  constructor(props) {
    super(props)
    this.state = {
      data: {
        id: Session.id,
        last_name: Session.lastName,
        first_name: Session.firstName,
        organization_name: Session.organizationName,
        position: Session.position,
        phone_number: Session.phoneNumber,
        email: Session.email,
        picture_url: Session.logoUrl,
        locale: Session.locale,
      },
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.imageUpload = React.createRef()
    this.getValidationState = inputName => props.getValidationState(inputName)
    this.getErrorMessage = inputName => props.getErrorMessage(inputName)
  }

  componentDidMount() {
    const { getProfileById } = this.props

    getProfileById(Session.id)
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.userData) !== JSON.stringify(this.props.userData)) {
      this.setState({
        data: nextProps.userData,
      })
    }
  }

  async onSubmit(e) {
    const data = this.state.data

    e.preventDefault()
    const logoUrl = await this.imageUpload.current.triggerUpload()
    this.props.editUser({ ...data, picture_url: logoUrl })
  }

  handleChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    })
  }

  handlePhoneInput = (name, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    })
  }

  renderInput({ name, options, ...props }) {
    const { data } = this.state

    return (
      <GenericFormInput
        name={ name }
        options={ options }
        data={ data }
        onChange={ e => this.handleChange(e) }
        getValidationState={ this.getValidationState }
        getErrorMessage={ this.getErrorMessage }
        baseTranslationKey="informations.form"
        renderOptions={ () =>
          defaultRenderOptions({
            options,
            keyProperty: "value",
            valueKey: "value",
            contentKey: "name",
          })
        }
        { ...props }
      />
    )
  }

  renderMap(elem) {
    return React.cloneElement(this.renderInput(elem), { key: elem.name })
  }

  render() {
    const { data } = this.state
    const { editErrors } = this.props

    return (
      <Col xs={ 12 } sm={ 6 } className="container-profile">
        {!isEmpty(editErrors) && <Alert type="danger">{i18n.t("spa:informations.form.alerts.has_error")}</Alert>}
        <Form onSubmit={ e => this.onSubmit(e) }>
          <ImageUpload
            ref={ this.imageUpload }
            defaultSource={ data.picture_url }
            label={ i18n.t("spa:profile.form.fields.avatar.label") }
          />
          {[
            { name: "first_name" },
            { name: "last_name" },
            { name: "organization_name" },
            { name: "position" },
            { name: "phone_number", component: PhoneInput, onChange: this.handlePhoneInput },
            { name: "email", disabled: true },
            {
              name: "locale",
              componentClass: "select",
              options: [
                { name: "English", value: "en" },
                { name: "Catalan", value: "ca" },
                { name: "Spanish", value: "es" },
              ],
            },
          ].map(elem => this.renderMap(elem))}
          <Col className="pull-right">
            <Button block bsStyle="primary" type="submit">
              {i18n.t("spa:profile.form.buttons.save")}
            </Button>
          </Col>
        </Form>
      </Col>
    )
  }
}

export default ConnectWithSidebar(withErrorManager(props => props.editErrors)(ProfileForm))
