import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import zipObject from "lodash/zipObject"

import PlacesAutocomplete from "../PlacesAutocomplete"

const GenericPlacesAutocomplete = ({
  keyPrefix,
  getValidationState,
  getErrorMessage,
  data,
  commonInputProps,
  onChange,
  baseTranslationKey,
  useFallback,
}) => {
  const placesAutocompleteKeys = Object.keys(PlacesAutocomplete.defaultProps.inputProps)
  const finalKeys = placesAutocompleteKeys.map(key => `${keyPrefix}_${key}`)

  return (
    <PlacesAutocomplete
      inputProps={ zipObject(
        placesAutocompleteKeys,
        finalKeys.map((finalKey, index) => ({
          name: finalKey,
          value: (useFallback && data[placesAutocompleteKeys[index]]) || data[finalKey] || "",
          validationState: getValidationState(finalKey),
          errorMessage: getErrorMessage(finalKey),
          ...((baseTranslationKey && {
            label: i18n.t(`spa:${baseTranslationKey}.${finalKey}.label`),
            placeholder: i18n.t(`spa:${baseTranslationKey}.${finalKey}.placeholder`),
          }) ||
            {}),
        }))
      ) }
      commonInputProps={ commonInputProps }
      onSelect={ (...args) => {
        finalKeys.map((key, idx) => onChange(key, args[idx]))
      } }
    />
  )
}

GenericPlacesAutocomplete.propTypes = {
  keyPrefix: PropTypes.string,
  getValidationState: PropTypes.func,
  getErrorMessage: PropTypes.func,
  data: PropTypes.object,
  commonInputProps: PropTypes.object,
  onChange: PropTypes.func,
  baseTranslationKey: PropTypes.string,
  useFallback: PropTypes.bool,
}

GenericPlacesAutocomplete.defaultProps = {
  keyPrefix: "",
  getValidationState: () => null,
  getErrorMessage: () => "",
  data: {},
  commonInputProps: {},
  onChange: () => true,
  baseTranslationKey: null,
  useFallback: true,
}

export const renderGenericPlacesAutocomplete = ({ ...props }) => <GenericPlacesAutocomplete { ...props } />

export default GenericPlacesAutocomplete
