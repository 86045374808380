import React, { Component } from "react"
import PropTypes from "prop-types"
import find from "lodash/find"
import each from "lodash/each"
import i18n from "i18next"
import Toaster from "../../helpers/Toaster"

import { Logo } from "../Common"
import SideBarCategory from "./SideBarCategory"

import Session from "../../helpers/SessionHelper"
import { StateVisibility } from "../../Constants"

import { SpecificAgreements, GeneralAgreements } from "../../api"

import LogoWhite from "../../assets/logo_white.png"

import "./SideBar.css"
import { withSpecificAgreementsFilters } from "../../hoc"

export class SideBar extends Component {
  static contextTypes = {
    primaryColor: PropTypes.func,
  }

  constructor() {
    super()
    const visibility = StateVisibility
    const toCategoryObj = (categoryName, elem) => ({
      label: i18n.t(`spa:sidebar.${categoryName}.${elem.state}${elem.withRole ? `.${Session.role}` : ""}`),
      agreementState: elem.state,
      visible: Session.role ? visibility[categoryName][Session.role].includes(elem.state) : false,
      count: 0,
    })
    this.state = {
      currentState: "",
      exportingStatus: { general_agreement: false, specific_agreement: false },
      dataCategories: [
        {
          title: i18n.t("spa:sidebar.specific_agreement.title"),
          category: "specific_agreement",
          visible: true,
          elements: [
            { state: "draft" },
            { state: "student_info_completion" },
            {
              state: "in_school_review",
              withRole: true,
              // selectFromState: state =>
              //   Session.isRecruiter() &&
              //   (state === "pending_approvals" ||
              //     state === "approved" ||
              //     state === "denied" ||
              //     state === "student_info_completion"),
            },
            { state: "pending_approvals", withRole: true },
            {
              state: "approved",
              // selectFromState: state => Session.isOAP() && (state === "sent_for_signature" || state === "signed"),
            },
            { state: "rejected" },
            { state: "sent_for_signature", withRole: true },
            { state: "signed" },
            { state: "cancelled" },
          ].map(elem => toCategoryObj("specific_agreement", elem)),
        },
        {
          title: i18n.t("spa:sidebar.general_agreement.title.ga_enabled"),
          category: "general_agreement",
          visible:
            Session.isRecruiter() || ((Session.isSchoolAdmin() || Session.isOAP()) && Session.useGeneralAgreement),
          elements: [
            { state: "company_draft" },
            { state: "school_review", withRole: true },
            { state: "sent_for_signature", withRole: true },
            { state: "active", withRole: true },
            { state: "expired" },
            { state: "cancelled" },
            { state: "company_without_general_agreement" },
          ].map(elem => toCategoryObj("general_agreement", elem)),
        },
        {
          title: i18n.t("spa:sidebar.general_agreement.title.ga_disabled"),
          category: "general_agreement",
          visible: (Session.isSchoolAdmin() || Session.isOAP()) && !Session.useGeneralAgreement,
          elements: [ { state: "company_without_general_agreement" } ].map(elem => ({
            ...toCategoryObj("general_agreement", elem),
            label: i18n.t("spa:sidebar.general_agreement.list_of_companies"),
          })),
        },
      ],
    }
  }

  selectElement(state, category = "specific_agreement") {
    this.setState({ currentState: state })
    const newDataCategories = this.state.dataCategories.slice()

    const cat = newDataCategories.find(categ => categ.category === category && !!categ.visible)
    if (cat) {
      const elem = find(cat.elements, element => state === element.agreementState && !!element.visible)
      const selectedElem = find(cat.elements, element => element.selected)
      if (selectedElem) delete selectedElem.selected

      if (elem) elem.selected = true
      // else {
      //   each(cat.elements, (element) => {
      //     if (element.selectFromState && element.selectFromState(state)) {
      //       element.selected = true
      //       return false
      //     }
      //     return true
      //   })
      // }
    }
    const notCat = newDataCategories.find(categ => categ.category !== category && !!categ.visible)
    if (notCat) {
      const selectedElem = find(notCat.elements, element => element.selected)
      if (selectedElem) delete selectedElem.selected
    }

    this.setState({
      dataCategories: newDataCategories,
    })
  }

  updateCounts(counts) {
    const dataCategoriesCpy = this.state.dataCategories.slice()

    each(dataCategoriesCpy.filter(data => !!data.visible), (value) => {
      const countsElem = counts[value.category]

      each(value.elements, (elem) => {
        // eslint-disable-next-line
        if (elem.visible) elem.count = countsElem[elem.agreementState]
      })
    })
    this.setState({
      dataCategories: dataCategoriesCpy,
    })
  }

  getSelectedCategoryData() {
    const categorySelected = this.state.dataCategories.filter((category) => {
      let hasSelectedItem = false
      each(category.elements, (elem) => {
        if (elem.selected) {
          hasSelectedItem = true
          return false
        }
        return true
      })
      return hasSelectedItem
    })
    if (categorySelected.length > 0) {
      const categoryData = categorySelected[0].elements.find(elem => elem.selected)
      return {
        category: categorySelected[0].title,
        subcategory: categoryData.label,
      }
    }
    return null
  }

  async exportExcelCategory(category, filterByState) {
    const { specificAgreementsFilters } = this.props
    this.setState({ exportingStatus: { ...this.state.exportingStatus, [category]: true } })
    const ApiClass = category === "general_agreement" ? GeneralAgreements : SpecificAgreements

    let states = filterByState === true ? [ this.state.currentState ] : []

    const data = await ApiClass.export({
      ...specificAgreementsFilters,
      states: states,
    })
    Toaster.getBuilder()
      .setType(Toaster.TYPES.SUCCESS)
      .setPosition(Toaster.POSITIONS.TOP_RIGHT)
      .setMessage(i18n.t("spa:export.success_toast.message"))
      .setOptions({
        className: "toaster-success",
      })
      .build()
      .show()
    this.setState({ exportingStatus: { ...this.state.exportingStatus, [category]: false } })
  }


  render() {
    return (
      <nav className="navbar-default navbar-static-side sidebar-container" style={ { position: "relative" } }>
        <div className="fixed-sidebar" style={ { backgroundColor: "white" } }>
          <div className="sidebar-collapse">
            <ul className="nav">
              <li className="nav-header" style={ { backgroundColor: this.context.primaryColor(), textAlign: "center" } }>
                <Logo
                  height={ 100 }
                  src={ Session.isRecruiter() || Session.isAdmin() ? LogoWhite : Session.schoolLogoUrl }
                />
              </li>
              {this.state.dataCategories
                .filter(cat => cat.visible)
                .map(elem => (
                  <SideBarCategory
                    key={ elem.category }
                    title={ elem.title }
                    category={ elem.category }
                    elements={ elem.elements }
                    onExport={ () => this.exportExcelCategory(elem.category, false) }
                    onExportByState={ () => this.exportExcelCategory(elem.category, true) }
                    isExporting={ this.state.exportingStatus[elem.category] }
                  />
                ))}
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}

export default withSpecificAgreementsFilters(SideBar)
