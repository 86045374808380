import React from "react"
import PropTypes from "prop-types"

import { Card } from "../Common"
import DefaultUserAvatar from "../../assets/default-avatar.png"

const AssigneeInfo = ({
  fullName,
  pictureUrl,
  schoolShortName,
}) => (
  <Card avatarUrl={ pictureUrl || DefaultUserAvatar } title={ fullName } description={ schoolShortName } />
)

AssigneeInfo.propTypes = {
  fullName: PropTypes.string,
  pictureUrl: PropTypes.string,
  schoolShortName: PropTypes.string,
}

AssigneeInfo.defaultProps = {
  fullName: "",
  pictureUrl: null,
  schoolShortName: "",
}

export default AssigneeInfo
