import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import find from "lodash/find"
import { Col, Row } from "react-bootstrap"

import Session from "../../helpers/SessionHelper"

import { RadioGroup, DatePicker, NumericInput } from "../Common"

import { GenericDatePickerRange, GenericFormInput, GenericPlacesAutocomplete } from "../GenericInputs"

import "./ActivityForm.css"
import { defaultRenderOptions } from "../../utils"
import SpecificCompetencies from "../SpecificCompetencies/SpecificCompetencies"
import currencies from "../../currencies.json"

export default class ActivityForm extends Component {
  static propTypes = {
    defaultData: PropTypes.object,
    getValidationState: PropTypes.func.isRequired,
    getErrorMessage: PropTypes.func.isRequired,
    editable: PropTypes.bool,
    onChange: PropTypes.func,
    functions: PropTypes.array,
    showRealEndDate: PropTypes.bool,
  }

  static defaultProps = {
    defaultData: {
      course_type: "",
      job_title: "",
      job_started_at: "",
      job_ended_at: "",
      job_street_address: "",
      job_city: "",
      job_postal_code: "",
      job_country: "",
      job_description: "",
      job_total_hours: "",
      job_salary: "",
      job_hourly_salary: "",
      invoicing_status: "not_invoiced",
      job_is_curricular: "curricular",
      job_awarded_ects: "",
      work_timetable: "",
      job_number_of_weekly_hours: "",
      job_other_benefits: "",
      job_total_days_worked: "",
      specific_competencies: "",
      job_function_id: null,
      other_information: "",
    },
    editable: true,
    onChange: () => true,
    functions: [],
    curriculums: [],
  }

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props)
  }

  handleChange(e) {
    const { onChange } = this.props

    onChange(e.target.name, e.target.value)
  }

  handleDayChange(name, value) {
    const { onChange } = this.props

    onChange(name, value)
  }

  handleSpecificCompetenciesTemplateChange = (template) => {
    const { onChange, defaultData } = this.props

    console.log("handleSpecificCompetenciesTemplateChange", template)
    onChange({
      ...defaultData,
      specific_competencies: template.content,
      specific_competencies_template_id: template.id,
    })
  }

  handleSpecificCompetenciesTemplateContentChange = (content) => {
    const { onChange } = this.props

    onChange("specific_competencies", content)
  }

  renderPlacesAutocompleteInput() {
    const { getValidationState, getErrorMessage, onChange, editable, defaultData: data } = this.props

    return (
      <GenericPlacesAutocomplete
        keyPrefix="job"
        getValidationState={ getValidationState }
        getErrorMessage={ getErrorMessage }
        commonInputProps={ {
          onChange: e => this.handleChange(e),
          disabled: !editable,
          withLabel: true,
          required: true,
        } }
        data={ data }
        onChange={ onChange }
      />
    )
  }

  renderDatePickerRange() {
    const { getValidationState, getErrorMessage, editable, defaultData: data } = this.props

    const startedAtKey = "job_started_at"
    const endedAtKey = "job_ended_at"
    return (
      <GenericDatePickerRange
        startedAtKey={ startedAtKey }
        endedAtKey={ endedAtKey }
        onStartDateChange={ day => this.handleDayChange(startedAtKey, day) }
        onEndDateChange={ day => this.handleDayChange(endedAtKey, day) }
        getValidationState={ getValidationState }
        getErrorMessage={ getErrorMessage }
        data={ data }
        editable={ editable }
        required
      />
    )
  }

  renderGenericInput(props, onChange = e => this.handleChange(e), withDefaultOption = false, renderOptions = null) {
    const { getValidationState, getErrorMessage, editable, defaultData: data } = this.props

    return (
      <GenericFormInput
        data={ data }
        getValidationState={ getValidationState }
        getErrorMessage={ getErrorMessage }
        editable={ editable }
        onChange={ onChange }
        withDefaultOption={ withDefaultOption }
        renderOptions={ renderOptions }
        { ...props }
      />
    )
  }

  renderDatePicker = ({ name, ...props }) => {
    const { getValidationState, getErrorMessage, editable, defaultData: data } = this.props

    return (
      <DatePicker
        inputProps={ {
          withLabel: true,
          label: i18n.t(`spa:specific_agreements.form.fields.${name}.label`),
          placeholder: i18n.t(`spa:specific_agreements.form.fields.${name}.placeholder`),
          getValidationState,
          getErrorMessage,
          disabled: !editable,
        } }
        defaultValue={ data[name] }
        onDayChange={ day => this.handleDayChange(name, day) }
        { ...props }
      />
    )
  }

  render() {
    const {
      getValidationState,
      defaultData: data,
      functions,
      specificCompetenciesTemplates,
      jobNumberOfWeeklyHoursRequired,
      jobOtherBenefitsEnabled,
      jobTotalDaysWorkedEnabled,
      jobSalaryMode,
    } = this.props

    return (
      <Fragment>
        {[
          { name: "job_title", required: true },
          {
            name: "job_is_curricular",
            inputComponentClass: RadioGroup,
            required: Session.isSchoolAdmin() || Session.isOAP(),
            inline: true,
            labelClassName: "activity-form__radio-label",
            titleClassName: "activity-form__radio-title",
            radioData: (Session.isSchoolAdmin() || Session.isOAP()) ? [
              {
                label: i18n.t("spa:specific_agreements.form.fields.job_is_curricular.curricular"),
                value: "curricular",
                checked: data.job_is_curricular === "curricular",
              },
              {
                label: i18n.t("spa:specific_agreements.form.fields.job_is_curricular.not_curricular"),
                value: "extracurricular",
                checked: data.job_is_curricular === "extracurricular",
              },
              {
                label: i18n.t("spa:specific_agreements.form.fields.job_is_curricular.both"),
                value: "both",
                checked: data.job_is_curricular === "both",
              },
            ] : [
              {
                label: i18n.t("spa:specific_agreements.form.fields.job_is_curricular.curricular"),
                value: "curricular",
                checked: data.job_is_curricular === "curricular",
              },
              {
                label: i18n.t("spa:specific_agreements.form.fields.job_is_curricular.not_curricular"),
                value: "extracurricular",
                checked: data.job_is_curricular === "extracurricular",
              },
            ],
          },
          // {
          //   name: "course_type",
          //   visible: (Session.isSchoolAdmin() || Session.isOAP()) && (data.job_is_curricular === "curricular" || data.job_is_curricular === "both"),
          //   inputComponentClass: RadioGroup,
          //   inline: true,
          //   labelClassName: "activity-form__radio-label",
          //   titleClassName: "activity-form__radio-title",
          //   radioData: [
          //     {
          //       label: i18n.t("spa:specific_agreements.form.fields.course_type.compulsory"),
          //       value: "compulsory",
          //       defaultChecked: data.course_type === "compulsory"
          //     },
          //     {
          //       label: i18n.t("spa:specific_agreements.form.fields.course_type.optional"),
          //       value: "optional",
          //       defaultChecked: data.course_type === "optional"
          //     }
          //   ]
          // },
          {
            isRow: true,
            children: [
              {
                name: "job_curricular_hours",
                withIcon: true,
                iconName: "clock-o",
                component: NumericInput,
                visible: data.job_is_curricular === "curricular" || data.job_is_curricular === "both",
              },
              {
                name: "job_extracurricular_hours",
                withIcon: true,
                iconName: "clock-o",
                component: NumericInput,
                visible: data.job_is_curricular === "extracurricular" || data.job_is_curricular === "both",
              }
            ],
          },
          {
            name: "job_total_days_worked",
            component: NumericInput,
            min: "0",
            visible: jobTotalDaysWorkedEnabled
          },
          {
            name: "job_awarded_ects",
            visible: Session.isSchoolAdmin() || Session.isOAP(),
            component: NumericInput,
            min: "0",
          },
          { isDatePickerRange: true },
          { isPlacesAutocomplete: true },
          { name: "job_description", componentClass: "textarea", rows: 6, required: true },
          {
            name: "specific_competencies",
            component: SpecificCompetencies,
            visible: !Session.isRecruiter(),
            templates: specificCompetenciesTemplates,
            templateId: data.specific_competencies_template_id,
            getValidationStateFix: getValidationState,
            onChangeTemplate: this.handleSpecificCompetenciesTemplateChange,
            onChangeTemplateContent: this.handleSpecificCompetenciesTemplateContentChange,
          },
          { name: "work_timetable", required: true },
          {
            name: "job_number_of_weekly_hours",
            component: NumericInput,
            required: jobNumberOfWeeklyHoursRequired,
            visible: jobNumberOfWeeklyHoursRequired
          },
          {
            name: "job_salary_currency",
            componentClass: "select",
            renderOptions: () =>
              defaultRenderOptions({
                options: Object.keys(currencies).map((key) => currencies[key]),
                keyProperty: "code",
                valueKey: "code",
                contentKey: "select_label",
                withDefaultOption: true,
              }),
          },
          {
            name: jobSalaryMode === 'hourly' ? "job_hourly_salary" : "job_salary",
            component: NumericInput,
            min: "0",
            required: true,
          },
          {
            name: "job_other_benefits",
            componentClass: "textarea",
            rows: 4,
            visible: jobOtherBenefitsEnabled
          },
          {
            name: "management_fee",
            component: NumericInput,
            value: data.management_fee,
            visible: Session.isSchoolAdmin(),
            min: "0",
          },
          {
            isRow: true,
            children: [
              {
                name: "invoicing_status",
                visible: Session.isSchoolAdmin(),
                componentClass: "select",
                options: [ "not_invoiced", "to_be_invoiced", "invoice_sent", "invoice_paid" ],
              },
              data.invoicing_status !== "not_invoiced"
                ? {
                  name: "invoice_number",
                }
                : null,
            ],
          },
          {
            name: "job_function_id",
            componentClass: "select",
            required: true,
            onChange: (e) => {
              const { onChange } = this.props
              const value = e.target.value
              const functionName = find(functions, func => func.id === value)

              onChange(e.target.name, e.target.value)
              setTimeout(() => onChange("job_function_value", value ? functionName.name : null))
            },
            renderOptions: () =>
              defaultRenderOptions({
                options: functions,
                keyProperty: "id",
                valueKey: "id",
                contentKey: "name",
                withDefaultOption: true,
              }),
          },
          {
            name: "other_information",
            componentClass: "textarea",
            rows: 6,
          },
          {
            name: "jobteaser_job_offer_id",
            visible: !Session.isRecruiter(),
          },
        ].map((elem, index) => {
          const {
            visible,
            isPlacesAutocomplete,
            isDatePickerRange,
            onChange,
            withDefaultOption,
            renderOptions,
            isRow,
            isDatePicker,
            ...props
          } = elem
          if (isPlacesAutocomplete) {
            return React.cloneElement(this.renderPlacesAutocompleteInput(), {
              key: index,
            })
          } else if (isDatePickerRange) {
            return React.cloneElement(this.renderDatePickerRange(), {
              key: index,
            })
          } else if (isDatePicker && visible) {
            return React.cloneElement(this.renderDatePicker(props), {
              key: index,
            })
          } else if (isRow) {
            return (
              <Row key={ index }>
                {elem.children
                  .filter(child => !!child && child.visible)
                  .map(({ visible: isVisible, ...child }, idx, arr) => (
                    <Col xs={ 12 } sm={ 12 / arr.length } key={ `${index}-${idx}` }>
                      {React.cloneElement(this.renderGenericInput(child))}
                    </Col>
                  ))}
              </Row>
            )
          }
          if ((elem.hasOwnProperty("visible") && visible) || !elem.hasOwnProperty("visible")) {
            return React.cloneElement(this.renderGenericInput(props, onChange, withDefaultOption, renderOptions), {
              key: props.name,
            })
          }
          return null
        })}
      </Fragment>
    )
  }
}
