/* eslint-disable no-undef */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import find from "lodash/find";
import i18n from "i18next";

import { FormInput } from "../Common";
import countries from "../../countries.json";
import GenericFormInput from "../GenericInputs/GenericFormInput";
import Session from "../../helpers/SessionHelper";

export default class PlacesAutocomplete extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    inputProps: PropTypes.shape({
      street_address: PropTypes.shape({ ...FormInput.propTypes }),
      city: PropTypes.shape({ ...FormInput.propTypes }),
      postal_code: PropTypes.shape({ ...FormInput.propTypes }),
      country: PropTypes.shape({ ...FormInput.propTypes })
    }),
    commonInputProps: PropTypes.shape({ ...FormInput.propTypes })
  };

  static defaultProps = {
    onSelect: () => true,
    autocompleteDebounce: 450,
    inputProps: {
      street_address: {
        withLabel: true,
        label: "",
        placeholder: ""
      },
      city: {
        withLabel: true,
        label: "",
        placeholder: ""
      },
      postal_code: {
        withLabel: true,
        label: "",
        placeholder: ""
      },
      country: {
        withLabel: true,
        label: "",
        placeholder: ""
      }
    },
    commonInputProps: {},
    withCountry: false
  };

  constructor(props) {
    super(props);
    this.state = {
      street_address: "",
      postal_code: "",
      city: "",
      country: ""
    };
    this.autocomplete = React.createRef();
  }

  componentDidMount() {
    this.gMapsAutocomplete = new google.maps.places.Autocomplete(
      this.autocomplete.current,
      { types: ["address"] }
    );
    google.maps.event.addListener(
      this.gMapsAutocomplete,
      "place_changed",
      this.onPlaceChange
    );
  }

  onPlaceChange = () => {
    const { onSelect } = this.props;
    console.log(this.gMapsAutocomplete);
    const place = this.gMapsAutocomplete.getPlace();
    const findIfAddrIncludes = name =>
      find(place.address_components, addrComp => addrComp.types.includes(name));
    const formatComponent = (component, key = "long_name") =>
      (component && component[key]) || "";

    const [postCode, city, country] = [
      "postal_code",
      "locality",
      "country"
    ].map(component => {
      const value = findIfAddrIncludes(component);
      if (component === "country") {
        const getCountryISO3 = require("country-iso-2-to-3");
        return getCountryISO3(formatComponent(value, "short_name"));
      } else {
        return formatComponent(value, "long_name");
      }
    });
    const finalAddress = place.name;
    onSelect(finalAddress, city, postCode, country);
    this.setState({
      address: finalAddress,
      city,
      post_code: postCode,
      country
    });
  };

  onInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  renderInput({ name, ...props }) {
    const { inputProps, commonInputProps } = this.props;

    return (
      <GenericFormInput
        name={name}
        {...props}
        withLabel
        label={i18n.t(`spa:place.autocomplete.${name}.label`)}
        placeholder={i18n.t(`spa:place.autocomplete.${name}.placeholder`)}
        onChange={e => this.onInputChange(e)}
        data={this.state}
        {...inputProps[name]}
        {...commonInputProps}
        autocomplete="new-password"
      />
    );
  }

  render() {
    return (
      <Fragment>
        {[
          {
            name: "street_address",
            inputRef: this.autocomplete
          },
          {
            isRow: true,
            children: [{ name: "city" }, { name: "postal_code" }]
          },
          {
            name: "country",
            componentClass: "select",
            renderOptions: () => (
              <React.Fragment>
                <option value={null} />
                {Object.keys(countries)
                  .sort((a, b) =>
                    countries[a][Session.locale || "en"].localeCompare(
                      countries[b][Session.locale || "en"]
                    )
                  )
                  .map(country => (
                    <option value={country} key={country}>
                      {countries[country][Session.locale || "en"]}
                    </option>
                  ))},
              </React.Fragment>
            )
          }
        ].map((elem, index) => {
          if (elem.isRow) {
            return (
              <Row key={index}>
                {elem.children.map((child, idx) => (
                  <Col xs={12} sm={6} key={`${idx}-${index}`}>
                    {this.renderInput(child)}
                  </Col>
                ))}
              </Row>
            );
          }
          return React.cloneElement(this.renderInput(elem), { key: index });
        })}
      </Fragment>
    );
  }
}
