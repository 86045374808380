import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"

import { computeAgreementId } from "../../utils"

import SpecificAgreementExtensionStatus from "../SpecificAgreementExtensionStatus"

import "./SpecificAgreementInfo.css"

const InternshipInfo = ({ name, id, createdAt, alterationData }) => (
  <div>
    <div>
      <span className="specific-agreement-info__title">{name}</span>
    </div>
    <div>
      <span className="specific-agreement-info__id">N° {computeAgreementId({ agreementId: id, createdAt })}</span>
      <br />
      <SpecificAgreementExtensionStatus
        alteratedId={ alterationData.alteratedId }
        alteratedStatus={ alterationData.alteratedStatus }
        alteratedCreatedAt={ alterationData.alteratedCreatedAt }
        alterationId={ alterationData.alterationId }
        alterationStatus={ alterationData.alterationStatus }
        alterationCreatedAt={ alterationData.alterationCreatedAt }
      />
    </div>
  </div>
)

InternshipInfo.propTypes = {
  name: PropTypes.string,
  id: PropTypes.number,
  createdAt: PropTypes.string.isRequired,
  alterationData: PropTypes.shape({
    alteratedId: PropTypes.number,
    alteratedStatus: PropTypes.string,
    alteratedCreatedAt: PropTypes.string,
    alterationId: PropTypes.number,
    alterationStatus: PropTypes.string,
    alterationCreatedAt: PropTypes.string,
  }),
}

InternshipInfo.defaultProps = {
  name: "",
  id: null,
  alterationData: {},
}

export default InternshipInfo
