import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import i18n from "i18next"

import { FormInput, Button } from "../Common"

const PrintingTemplateForm = ({
  templates,
  selectedId,
  onChangeTemplate,
  withPreviewButton,
  onPreview,
  disabled,
  inputName,
}) => (
  <Fragment>
    <Col xs={ 12 } sm={ 9 } className="float-none inline-block">
      <FormInput
        componentClass="select"
        value={ selectedId }
        withLabel
        name={ inputName }
        label={ i18n.t("spa:printing_templates.form.fields.printing_template.label") }
        onChange={ onChangeTemplate }
        disabled={ disabled }
      >
        {templates.map(template => (
          <option key={ template.id } value={ template.id }>
            {template.name}
          </option>
        ))}
      </FormInput>
    </Col>
    {withPreviewButton && (
      <Col xs={ 12 } sm={ 3 } className="float-none inline-block">
        <Button bsStyle="success" height={ 37 } block onClick={ onPreview }>
          {i18n.t("spa:printing_templates.shared.buttons.preview")}
        </Button>
      </Col>
    )}
  </Fragment>
)

PrintingTemplateForm.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  selectedId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  onChangeTemplate: PropTypes.func,
  withPreviewButton: PropTypes.bool,
  onPreview: PropTypes.func,
  disabled: PropTypes.bool,
  inputName: PropTypes.string,
}

PrintingTemplateForm.defaultProps = {
  templates: [],
  selectedId: null,
  onChangeTemplate: () => true,
  withPreviewButton: true,
  onPreview: () => true,
  disabled: false,
  inputName: "",
}

export default PrintingTemplateForm
