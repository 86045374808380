import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import qs from "querystring-es3";
import i18n from "i18next";
import isEmpty from "lodash/isEmpty";

import StepInformation from "./StepInformation";
import StepForm from "./StepForm";
import "./InformationForm.css";

import { withErrorManager } from "../../hoc";
import { Alert } from "../Common";

export class InformationForm extends Component {
  static propTypes = {
    errors: PropTypes.objectOf(PropTypes.array),
    fillProfile: PropTypes.func.isRequired,
    getValidationState: PropTypes.func.isRequired,
    getErrorMessage: PropTypes.func.isRequired,
    onChange: PropTypes.func
  };

  static defaultProps = {
    errors: {},
    onChange: () => true
  };

  constructor(props) {
    super(props);
    const { location } = this.props;
    const parsed = qs.parse(location.search.substring(1));

    if (parsed) {
      this.token = parsed.confirmation_token;
      i18n.changeLanguage(parsed.locale);
    }
  }

  handleChange = e => {
    const { onChange } = this.props;

    onChange(e.target.name, e.target.value);
  };

  fillProfile = data => {
    const { fillProfile } = this.props;

    fillProfile(this.token, data);
  };

  render() {
    const { errors, onChange } = this.props;

    return (
      <Col
        xs={12}
        sm={12}
        md={8}
        mdPush={2}
        className="information-form-container"
      >
        <Col xs={12} sm={6}>
          <StepInformation title={i18n.t("spa:informations.form.title")} />
        </Col>
        <Col xs={12} sm={6}>
          {!isEmpty(errors) && (
            <Alert type="danger">
              {i18n.t("spa:informations.form.alerts.has_error")}
            </Alert>
          )}
          <StepForm
            onValidate={this.fillProfile}
            getValidationState={this.props.getValidationState}
            getErrorMessage={this.props.getErrorMessage}
            token={this.token}
            onChange={e => this.handleChange(e)}
          />
        </Col>
      </Col>
    );
  }
}

export default withErrorManager(props => props.errors)(InformationForm);
