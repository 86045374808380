import React, { Component } from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import { Form, Panel } from "react-bootstrap"
import TimeAgo from "react-timeago"
import catalanStrings from "react-timeago/lib/language-strings/ca"
import englishStrings from "react-timeago/lib/language-strings/en"
import spanishStrings from "react-timeago/lib/language-strings/es"
import buildFormatter from "react-timeago/lib/formatters/buildFormatter"

import { Button, FormInput, Icon } from "../Common"

import Session from "../../helpers/SessionHelper"

import "./Comments.css"

export default class Comments extends Component {
  static propTypes = {
    onDelete: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    comments: PropTypes.array.isRequired,
  }

  constructor() {
    super()
    this.state = {
      body: "",
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()
    this.props.onSubmit(this.state.body)
    this.setState({ body: "" })
  }

  handleDelete(id) {
    this.props.onDelete(id)
  }

  handleChange(event) {
    this.setState({ body: event.target.value })
  }

  detectLanguage() {
    const languages = i18n.languages[0]
    switch (languages) {
      case "en":
        return buildFormatter(englishStrings)
      case "ca":
        return buildFormatter(catalanStrings)
      case "es":
        return buildFormatter(spanishStrings)
      default:
        return buildFormatter(englishStrings)
    }
  }

  render() {
    const formatter = this.detectLanguage()
    const commentData = this.props.comments.map(comment => (
      <div className="comment-content" key={ comment.id }>
        {comment.user_id == null ? (
          <p>{i18n.t("spa:comments.panel.user_deleted")}</p>
        ) : (
          <p className="comment-author">{`${comment.user.first_name} ${comment.user.last_name}`}</p>
        )}
        {Session.id === comment.user_id && (
          <Icon className="btn-delete pull-right" iconName="trash" onClick={ () => this.handleDelete(comment.id) } />
        )}
        <p>{comment.body}</p>
        <small className="comment-timeago">
          <TimeAgo date={ comment.created_at } formatter={ formatter } />
        </small>
      </div>
    ))

    return (
      <Panel className="comment-panel" defaultExpanded collapsible>
        <Form className="form-send-comment" inline onSubmit={ this.handleSubmit }>
          <FormInput
            type="text"
            value={ this.state.body }
            placeholder={ i18n.t("spa:comments.form.placeholder") }
            onChange={ event => this.handleChange(event) }
          />
          <Button color="primary" type="submit">
            <Icon iconName="paper-plane-o" />
          </Button>
        </Form>
        {commentData.reverse()}
      </Panel>
    )
  }
}
