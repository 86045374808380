export const LIST_REQUEST = "LIST_REQUEST"
export const LIST_SUCCESS = "LIST_SUCCESS"
export const LIST_ERROR = "LIST_ERROR"

export const EDIT_REQUEST = "EDIT_REQUEST"
export const EDIT_SUCCESS = "EDIT_SUCCESS"
export const EDIT_ERROR = "EDIT_ERROR"

export const CREATE_REQUEST = "CREATE_REQUEST"
export const CREATE_SUCCESS = "CREATE_SUCCESS"
export const CREATE_ERROR = "CREATE_ERROR"

export const REMOVE_REQUEST = "REMOVE_REQUEST"
export const REMOVE_SUCCESS = "REMOVE_SUCCESS"
export const REMOVE_ERROR = "REMOVE_ERROR"

export const UNSET_ALTERATION_STATUS_REQUEST = "UNSET_ALTERATION_STATUS_REQUEST"
export const UNSET_ALTERATION_STATUS_SUCCESS = "UNSET_ALTERATION_STATUS_SUCCESS"
export const UNSET_ALTERATION_STATUS_ERROR = "UNSET_ALTERATION_STATUS_ERROR"

export const UNDO_CANCEL_REQUEST = "UNDO_CANCEL_REQUEST"
export const UNDO_CANCEL_SUCCESS = "UNDO_CANCEL_SUCCESS"
export const UNDO_CANCEL_ERROR = "UNDO_CANCEL_ERROR"

export const SEND_INVITATION_EMAIL = "SEND_INVITATION_EMAIL"
