import { push, goBack } from "react-router-redux"
import { takeEvery, call, put, all, select } from "redux-saga/effects"
import i18n from "i18next"

import {
  getSpecificAgreements,
  getSpecificAgreementById,
  editSpecificAgreement as editAgreementAction,
  createApproval,
  editApproval as editApprovalAction,
  createSpecificAgreement as createAgreementAction,
  sendForSignature as sendForSignatureAction,
  signSpecificAgreement as signAgreementAction,
  printSpecificAgreement as printSpecificAgreementAction,
  removeSpecificAgreement as removeSpecificAgreementAction,
  getActivities as getActivitiesAction,
  getStudentInfos as getStudentInfosAction,
  updateStudentInfos as updateStudentInfosAction,
  modifyAgreement as modifyAgreementAction,
  cancelAgreement as cancelAgreementAction,
  extendAgreement as extendAgreementAction,
  terminateSpecificAgreement as terminateAgreementAction,
  skipApprovalValidation as skipApprovalValidationAction,
  cancelSignature as cancelSignatureAction,
  SendEmailToStudent as SendEmailToStudentAction,
  sendReminder as sendReminderAction,
} from "../actions/specificAgreements"
import { getById } from "../actions/admin"
import { createCompany } from "../actions/company"
import { getFunctions as getFunctionsAction, getCompanySectors as getCompanySectorsAction, getCurriculums as getCurriculumsAction, getAssigneds as getAssignedsAction } from "../actions/JTActions"

import Toaster from "../helpers/Toaster"

import Session from "../helpers/SessionHelper"

import {
  GET_SPECIFIC_AGREEMENTS_REQUEST,
  GET_SPECIFIC_AGREEMENTS_SUCCESS,
  GET_SPECIFIC_AGREEMENTS_ERROR,
  GET_SPECIFIC_AGREEMENT_DATA_REQUEST,
  GET_SPECIFIC_AGREEMENT_DATA_SUCCESS,
  GET_SPECIFIC_AGREEMENT_DATA_ERROR,
  SPECIFIC_AGREEMENT_EDIT_REQUEST,
  SPECIFIC_AGREEMENT_EDIT_SUCCESS,
  SPECIFIC_AGREEMENT_EDIT_ERROR,
  APPROVAL_ASK_REQUEST,
  APPROVAL_ASK_SUCCESS,
  APPROVAL_ASK_ERROR,
  APPROVAL_EDIT_REQUEST,
  APPROVAL_EDIT_SUCCESS,
  APPROVAL_EDIT_ERROR,
  SPECIFIC_AGREEMENT_CREATE_REQUEST,
  SPECIFIC_AGREEMENT_CREATE_SUCCESS,
  SPECIFIC_AGREEMENT_CREATE_ERROR,
  SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_REQUEST,
  SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_SUCCESS,
  SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_ERROR,
  SIGN_SPECIFIC_AGREEMENT_REQUEST,
  SIGN_SPECIFIC_AGREEMENT_SUCCESS,
  SIGN_SPECIFIC_AGREEMENT_ERROR,
  PRINT_AGREEMENT_REQUEST,
  GET_COMMENTS_REQUEST,
  REMOVE_SPECIFIC_AGREEMENT_REQUEST,
  REMOVE_SPECIFIC_AGREEMENT_SUCCESS,
  REMOVE_SPECIFIC_AGREEMENT_ERROR,
  GET_SPECIFIC_AGREEMENT_ACTIVITIES_SUCCESS,
  GET_SPECIFIC_AGREEMENT_ACTIVITIES_ERROR,
  GET_FUNCTIONS_SUCCESS,
  GET_FUNCTIONS_ERROR,
  GET_COMPANY_SECTORS_REQUEST,
  GET_COMPANY_SECTORS_SUCCESS,
  GET_COMPANY_SECTORS_ERROR,
  GET_STUDENT_INFO_REQUEST,
  GET_STUDENT_INFO_SUCCESS,
  GET_STUDENT_INFO_ERROR,
  UPDATE_STUDENT_INFO_SUCCESS,
  UPDATE_STUDENT_INFO_ERROR,
  UPDATE_STUDENT_INFO_REQUEST,
  MODIFY_SPECIFIC_AGREEMENT_REQUEST,
  MODIFY_SPECIFIC_AGREEMENT_SUCCESS,
  MODIFY_SPECIFIC_AGREEMENT_ERROR,
  CANCEL_SPECIFIC_AGREEMENT_REQUEST,
  CANCEL_SPECIFIC_AGREEMENT_SUCCESS,
  CANCEL_SPECIFIC_AGREEMENT_ERROR,
  EXTEND_SPECIFIC_AGREEMENT_REQUEST,
  EXTEND_SPECIFIC_AGREEMENT_SUCCESS,
  EXTEND_SPECIFIC_AGREEMENT_ERROR,
  TERMINATE_SPECIFIC_AGREEMENT_REQUEST,
  TERMINATE_SPECIFIC_AGREEMENT_SUCCESS,
  TERMINATE_SPECIFIC_AGREEMENT_ERROR,
  GET_CURRICULUMS_SUCCESS,
  GET_CURRICULUMS_ERROR,
  SAVE_SPECIFIC_AGREEMENT_PRINTINT_TEMPLATE_REQUEST,
  GET_CURRICULUMS_REQUEST,
  SKIP_APPROVAL_VALIDATION_REQUEST,
  CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_ERROR,
  CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_REQUEST,
  CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_SUCCESS,
  GET_ASSIGNEDS_SUCCESS,
  GET_ASSIGNEDS_ERROR,
  GET_ASSIGNEDS_REQUEST,
  LIST_SUCCESS,
  SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_REQUEST,
  SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_SUCCESS,
  SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_ERROR,
  SEND_REMINDER_SPECIFIC_AGREEMENT_REQUEST,
  SEND_REMINDER_SPECIFIC_AGREEMENT_SUCCESS,
  SEND_REMINDER_SPECIFIC_AGREEMENT_ERROR,
} from "../types"

import Routes from "../routes"

import { StateVisibility } from "../Constants"

export function* getFunctions({ payload }) {
  const { school_id: schoolId } = payload

  try {
    const functions = yield call(getFunctionsAction, schoolId)
    yield put({ type: GET_FUNCTIONS_SUCCESS, payload: { data: functions } })
  } catch (e) {
    yield put({ type: GET_FUNCTIONS_ERROR })
  }
}

export function* getCompanySectors({ payload }) {
  try {
    const companySectors = yield call(getCompanySectorsAction)
    yield put({ type: GET_COMPANY_SECTORS_SUCCESS, payload: { data: companySectors } })
  } catch (e) {
    yield put({ type: GET_COMPANY_SECTORS_ERROR })
  }
}

export function* getCurriculums({ payload }) {
  const { school_id: schoolId, id } = payload

  try {
    const curriculums = yield call(getCurriculumsAction, schoolId, id)
    yield put({ type: GET_CURRICULUMS_SUCCESS, payload: { data: curriculums } })
  } catch (e) {
    yield put({ type: GET_CURRICULUMS_ERROR })
  }
}

export function* getAssigneds({ payload }) {
  const { school_id: schoolId } = payload

  try {
    const assigneds = yield call(getAssignedsAction, schoolId)
    yield put({ type: GET_ASSIGNEDS_SUCCESS, payload: { data: assigneds } })
  } catch (e) {
    yield put({ type: GET_ASSIGNEDS_ERROR })
  }
}

export const getSpecificAgreementsFiltersList = state => state.jt.specificAgreementsFilters

export function* getAgreementsList({ payload }) {
  const { type, page } = payload

  try {
    let byField
    let byOrder
    let types

    if (type === "signed") {
      byField = "job_started_at"
      byOrder = "desc"
      types = type
    } else {
      types = [ type ]
      if (Session.isRecruiter() && type === "in_school_review") {
        types = [ ...types, "student_info_completion", "pending_approvals", "rejected", "approved" ]
      } else if (Session.isOAP() && type === "approved") {
        types = [ ...types, "sent_for_signature", "signed" ]
      }
    }
    const filters = yield select(getSpecificAgreementsFiltersList)
    const data = yield call(getSpecificAgreements, types, byField, byOrder, filters, page)
    yield put({
      type: GET_SPECIFIC_AGREEMENTS_SUCCESS,
      payload: { agreements: data },
    })
  } catch (e) {
    yield put({ type: GET_SPECIFIC_AGREEMENTS_ERROR, payload: { error: e } })
  }
}

export function* getActivities({ payload }) {
  const { id } = payload

  try {
    const { activities } = yield call(getActivitiesAction, id)
    yield put({
      type: GET_SPECIFIC_AGREEMENT_ACTIVITIES_SUCCESS,
      payload: { data: activities },
    })
  } catch (e) {
    yield put({ type: GET_SPECIFIC_AGREEMENT_ACTIVITIES_ERROR })
  }
}

export function* getAgreementData({ payload }) {
  const { id } = payload

  try {
    const data = yield call(getSpecificAgreementById, id)
    yield put({
      type: GET_SPECIFIC_AGREEMENT_DATA_SUCCESS,
      payload: { data },
    })

    const otherData = [
      put({
        type: GET_COMMENTS_REQUEST,
        payload: { id },
      }),
      getFunctions({
        payload: { school_id: data.specific_agreement.school_id },
      }),
      getCompanySectors({
        payload: {},
      }),
      getCurriculums({ payload: { school_id: data.specific_agreement.school_id, id } }),
    ]
    if (Session.isSchoolAdmin()) otherData.push(getActivities({ payload: { id } }))
    yield all(otherData)
  } catch (e) {
    yield put({
      type: GET_SPECIFIC_AGREEMENT_DATA_ERROR,
      payload: { error: e },
    })
  }
}

export function* editAgreement({ payload }, canThrowError = false) {
  const { data, action, updateUrl, redirect = false, resetPage = false } = payload

  try {
    const agreement = yield call(editAgreementAction, data, action, updateUrl)
    if (Session.isSchoolAdmin()) yield* getActivities({ payload: { id: agreement.specific_agreement.id } })
    if (redirect) {
      Toaster.getBuilder()
        .setType(Toaster.TYPES.SUCCESS)
        .setPosition(Toaster.POSITIONS.TOP_RIGHT)
        .setMessage(i18n.t("spa:specific_agreements.edit.success_toast.message"))
        .setOptions({
          className: "toaster-success",
        })
        .build()
        .show()
      if (StateVisibility.specific_agreement[Session.role].includes(agreement.specific_agreement.state)) {
        yield put(
          push({
            pathname: Routes.SPECIFIC_AGREEMENT_INDEX.replace(":type", agreement.specific_agreement.state),
            state: {
              resetPage,
            },
          })
        )
      } else {
        yield put(goBack())
      }
    }
    yield put({ type: SPECIFIC_AGREEMENT_EDIT_SUCCESS, payload: { agreement } })
  } catch (e) {
    yield put({ type: SPECIFIC_AGREEMENT_EDIT_ERROR, payload: { errors: e } })
    if (canThrowError) throw e
  }
}

export function* createAgreement({ payload }) {
  const { needCompanyCreation, companyData, agreementData } = payload
  const { redirect_url: redirectUrl, ...finalAgreementData } = agreementData

  try {
    if (needCompanyCreation) {
      const company = yield call(createCompany, companyData)
      finalAgreementData.company_id = company.id
    }
    const agreement = yield call(createAgreementAction, finalAgreementData, redirectUrl)
    const { departments } = yield call(getById, agreement.specific_agreement.school_id, "schools")
    agreement.specific_agreement.school_departments = departments
    yield* getFunctions({
      payload: { school_id: agreement.specific_agreement.school_id },
    })
    yield* getCompanySectors({
      payload: {},
    })
    yield* getCurriculums({ payload: { school_id: agreement.specific_agreement.school_id, id: agreement.specific_agreement.id } })
    if (Session.isSchoolAdmin()) yield* getActivities({ payload: { id: agreement.specific_agreement.id } })
    yield put({
      type: SPECIFIC_AGREEMENT_CREATE_SUCCESS,
      payload: { agreement },
    })
  } catch (e) {
    yield put({ type: SPECIFIC_AGREEMENT_CREATE_ERROR, payload: { errors: e, data: finalAgreementData } })
  }
}

export function* askForApproval({ payload }) {
  const { data, action, state, agreementId, userIds, updateUrl } = payload

  try {
    yield* editAgreement({ payload: { data, action, updateUrl, redirect: false } }, true)
    yield call(createApproval, agreementId, userIds, state)
    yield put({ type: APPROVAL_ASK_SUCCESS })
    Toaster.getBuilder()
      .setType(Toaster.TYPES.SUCCESS)
      .setPosition(Toaster.POSITIONS.TOP_RIGHT)
      .setMessage(i18n.t("spa:toaster.specific_agreement.messages.approval_request.success"))
      .setOptions({ className: "toaster-success" })
      .build()
      .show()
    yield put(
      push({
        pathname: Routes.SPECIFIC_AGREEMENT_INDEX.replace(":type", "pending_approvals"),
        state: {
          resetPage: true,
        },
      })
    )
  } catch (e) {
    yield put({ type: APPROVAL_ASK_ERROR })
  }
}

export function* editApproval({ payload }) {
  const { agreementId, userId, state, id, agreementData, updateUrl, editAgreement: editAgreementRequired } = payload

  try {
    if (editAgreementRequired) {
      yield* editAgreement(
        {
          payload: { data: agreementData, action: undefined, updateUrl },
        },
        true
      )
    }
    const data = yield call(editApprovalAction, agreementId, userId, state, id)
    yield put({ type: APPROVAL_EDIT_SUCCESS })
    let redirectTo = ""
    switch (data.state) {
      case "approved":
        redirectTo = "approved"
        break
      case "denied":
        redirectTo = "rejected"
        break
      default:
        redirectTo = "pending_approvals"
        break
    }
    Toaster.getBuilder()
      .setType(Toaster.TYPES.SUCCESS)
      .setPosition(Toaster.POSITIONS.TOP_RIGHT)
      .setMessage(i18n.t("spa:toaster.specific_agreement.messages.approval_edit.success"))
      .setOptions({ className: "toaster-success" })
      .build()
      .show()
    yield put(
      push({
        pathname: Routes.SPECIFIC_AGREEMENT_INDEX.replace(":type", redirectTo),
        state: {
          resetPage: true,
        },
      })
    )
  } catch (e) {
    yield put({ type: APPROVAL_EDIT_ERROR })
  }
}

export function* sendForSignature({ payload }) {
  const { url, id, data, byPass = false } = payload

  try {
    yield call(sendForSignatureAction, url, id, data, byPass)
    Toaster.getBuilder()
      .setType(Toaster.TYPES.SUCCESS)
      .setPosition(Toaster.POSITIONS.TOP_RIGHT)
      .setMessage(i18n.t("spa:toaster.specific_agreement.messages.sent_for_signature.success"))
      .setOptions({ className: "toaster-success" })
      .build()
      .show()
    yield put(
      push({
        pathname: Routes.SPECIFIC_AGREEMENT_INDEX.replace(":type", "sent_for_signature"),
        state: {
          resetPage: true,
        },
      })
    )
    yield put({ type: SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_SUCCESS })
  } catch (e) {
    yield put({ type: SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_ERROR, payload: { errors: e } })
  }
}

export function* signAgreement({ payload }) {
  const { url, data } = payload

  try {
    yield call(signAgreementAction, url, data)
    Toaster.getBuilder()
      .setType(Toaster.TYPES.SUCCESS)
      .setPosition(Toaster.POSITIONS.TOP_RIGHT)
      .setMessage(i18n.t("spa:toaster.specific_agreement.messages.signed.success"))
      .setOptions({ className: "toaster-success" })
      .build()
      .show()
    yield put(
      push({
        pathname: Routes.SPECIFIC_AGREEMENT_INDEX.replace(":type", "signed"),
        state: {
          resetPage: true,
        },
      })
    )
    yield put({ type: SIGN_SPECIFIC_AGREEMENT_SUCCESS })
  } catch (e) {
    yield put({ type: SIGN_SPECIFIC_AGREEMENT_ERROR, payload: { errors: e } })
  }
}

export function* printAgreement({ payload }) {
  const { url } = payload

  try {
    const toOpen = yield call(printSpecificAgreementAction, url)
    window.open(toOpen.print_url, "_blank")
  } catch (e) {
    // ...
  }
}

export function* removeAgreement({ payload }) {
  const { id } = payload

  try {
    yield call(removeSpecificAgreementAction, id)
    Toaster.getBuilder()
      .setType(Toaster.TYPES.SUCCESS)
      .setPosition(Toaster.POSITIONS.TOP_RIGHT)
      .setMessage(i18n.t("spa:toaster.specific_agreement.messages.remove.success"))
      .setOptions({ className: "toaster-success" })
      .build()
      .show()
    yield put(
      push({
        pathname: Routes.SPECIFIC_AGREEMENT_INDEX.replace(":type", "draft"),
        state: {
          resetPage: true,
        },
      })
    )
    yield put({ type: REMOVE_SPECIFIC_AGREEMENT_SUCCESS })
  } catch (e) {
    yield put({ type: REMOVE_SPECIFIC_AGREEMENT_ERROR })
  }
}

export function* getStudentInfos({ payload }) {
  const { id, token } = payload

  try {
    const data = yield call(getStudentInfosAction, token, id)
    yield* getCurriculums({
      payload: {
        school_id: data.school.id,
      },
    })
    yield put({ type: GET_STUDENT_INFO_SUCCESS, payload: { data } })
  } catch (e) {
    yield put({
      type: GET_STUDENT_INFO_ERROR,
      payload: { error: i18n.t(e.errors[0].replace(/^spa\./, "spa:")) },
    })
  }
}

export function* updateStudentInfos({ payload }) {
  const { data, url } = payload

  try {
    yield call(updateStudentInfosAction, data, url)
    Toaster.getBuilder()
      .setMessage(i18n.t("spa:toaster.student_completion.messages.update.success"))
      .setPosition(Toaster.POSITIONS.TOP_RIGHT)
      .setType(Toaster.TYPES.SUCCESS)
      .setOptions({
        className: "toaster-success",
      })
      .build()
      .show()
    yield put({
      type: UPDATE_STUDENT_INFO_SUCCESS,
      payload: {
        message: i18n.t("spa:specific_agreement.error.student.information_provided"),
      },
    })
  } catch (e) {
    yield put({ type: UPDATE_STUDENT_INFO_ERROR, payload: { errors: e } })
  }
}

export function* extendAgreement({ payload }) {
  const { id, reason, jobRealEndDate } = payload

  try {
    const data = yield call(extendAgreementAction, id, reason, jobRealEndDate)
    yield put(
      push({
        pathname: Routes.SPECIFIC_AGREEMENT_SHOW.replace(":id", data.specific_agreement.id),
        state: {
          resetPage: true,
        },
      })
    )
    yield put({ type: EXTEND_SPECIFIC_AGREEMENT_SUCCESS, payload: { data } })
  } catch (e) {
    yield put({ type: EXTEND_SPECIFIC_AGREEMENT_ERROR, payload: { errors: e } })
  }
}

export function* modifyAgreement({ payload }) {
  const { id, reason, jobRealEndDate } = payload

  try {
    const data = yield call(modifyAgreementAction, id, reason, jobRealEndDate)
    yield put(
      push({
        pathname: Routes.SPECIFIC_AGREEMENT_SHOW.replace(":id", data.specific_agreement.id),
        state: {
          resetPage: true,
        },
      })
    )
    yield put({ type: MODIFY_SPECIFIC_AGREEMENT_SUCCESS, payload: { data } })
  } catch (e) {
    yield put({ type: MODIFY_SPECIFIC_AGREEMENT_ERROR, payload: { errors: e } })
  }
}

export function* cancelAgreement({ payload }) {
  const { id, reason } = payload

  try {
    const data = yield call(cancelAgreementAction, id, reason)
    yield put(
      push({
        pathname: Routes.SPECIFIC_AGREEMENT_SHOW.replace(":id", data.specific_agreement.id),
        state: {
          resetPage: true,
        },
      })
    )
    Toaster.getBuilder()
      .setMessage(i18n.t("spa:toaster.specific_agreement.messages.cancel.success"))
      .setPosition(Toaster.POSITIONS.TOP_RIGHT)
      .setType(Toaster.TYPES.SUCCESS)
      .setOptions({
        className: "toaster-success",
      })
      .build()
      .show()
    yield put({ type: CANCEL_SPECIFIC_AGREEMENT_SUCCESS, payload: { data } })
  } catch (e) {
    yield put({ type: CANCEL_SPECIFIC_AGREEMENT_ERROR })
  }
}

export function* terminateAgreement({ payload }) {
  const { id, reason, realEndDate, hoursDone } = payload

  try {
    const data = yield call(terminateAgreementAction, id, reason, realEndDate, hoursDone)
    yield put({ type: TERMINATE_SPECIFIC_AGREEMENT_SUCCESS, payload: { data } })
  } catch (e) {
    yield put({ type: TERMINATE_SPECIFIC_AGREEMENT_ERROR, payload: { errors: e } })
  }
}

export function* saveSpecificAgreementTemplate({ payload }) {
  const { data, updateUrl } = payload

  yield* editAgreement({
    payload: {
      data,
      updateUrl,
      redirect: false,
    },
  })
  yield put(push(Routes.SPECIFIC_AGREEMENT_PRINTING_TEMPLATE_PREVIEW.replace(":id", data.id)))
}

export function* skipApprovalValidation({ payload }) {
  const { id, data, updateUrl } = payload

  try {
    yield call(editAgreementAction, data, null, updateUrl)
    const agreement = yield call(skipApprovalValidationAction, id)
    yield* getActivities({ payload: { id } })
    yield put({ type: SPECIFIC_AGREEMENT_EDIT_SUCCESS, payload: { agreement } })
  } catch (e) {
    yield put({ type: SPECIFIC_AGREEMENT_EDIT_ERROR, payload: { errors: e } })
  }
}

export function* cancelSignature({ payload }) {
  const { id } = payload

  try {
    const data = yield call(cancelSignatureAction, id)
    yield put({ type: CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_SUCCESS, payload: { agreement: data } })
  } catch (e) {
    yield put({ type: CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_ERROR })
  }
}

export function* sendReminder({ payload }) {
  const { id } = payload

  try {
    const data = yield call(sendReminderAction, id)

    Toaster.getBuilder()
      .setType(Toaster.TYPES.SUCCESS)
      .setPosition(Toaster.POSITIONS.TOP_RIGHT)
      .setMessage(i18n.t("spa:specific_agreements.send_reminder.success_toast.message"))
      .setOptions({
        className: "toaster-success",
      })
      .build()
      .show()
    yield put({ type: SEND_REMINDER_SPECIFIC_AGREEMENT_SUCCESS, payload: { agreement: data } })
  } catch (e) {
    Toaster.getBuilder()
      .setType(Toaster.TYPES.ERROR)
      .setPosition(Toaster.POSITIONS.TOP_RIGHT)
      .setMessage(i18n.t("spa:specific_agreements.send_reminder.error_toast.message"))
      .setOptions({
        className: "toaster-error",
      })
      .build()
      .show()

    yield put({ type: SEND_REMINDER_SPECIFIC_AGREEMENT_ERROR })
  }
}

export function* SendEmailToStudent({ payload }, canThrowError = false) {
  const { data } = payload

  try {
    const agreement = yield call(SendEmailToStudentAction, data)
    // if (Session.isSchoolAdmin()) yield* getActivities({ payload: { id: agreement.specific_agreement.id } })

    Toaster.getBuilder()
      .setType(Toaster.TYPES.SUCCESS)
      .setPosition(Toaster.POSITIONS.TOP_RIGHT)
      .setMessage(i18n.t("spa:specific_agreements.send_email_to_student.success_toast.message"))
      .setOptions({
        className: "toaster-success",
      })
      .build()
      .show()
    if (StateVisibility.specific_agreement[Session.role].includes(agreement.specific_agreement.state)) {
      yield put(
        push({
          pathname: Routes.SPECIFIC_AGREEMENT_INDEX.replace(":type", agreement.specific_agreement.state),
          state: {
            resetPage: true,
          },
        })
      )
    } else {
      yield put(goBack())
    }

    yield put({ type: SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_SUCCESS, payload: { agreement } })
  } catch (e) {
    yield put({ type: SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_ERROR, payload: { errors: e } })
    if (canThrowError) throw e
  }
}

function* agreementsSaga() {
  yield all([
    takeEvery(GET_SPECIFIC_AGREEMENTS_REQUEST, getAgreementsList),
    takeEvery(GET_SPECIFIC_AGREEMENT_DATA_REQUEST, getAgreementData),
    takeEvery(SPECIFIC_AGREEMENT_EDIT_REQUEST, editAgreement),
    takeEvery(APPROVAL_ASK_REQUEST, askForApproval),
    takeEvery(APPROVAL_EDIT_REQUEST, editApproval),
    takeEvery(SPECIFIC_AGREEMENT_CREATE_REQUEST, createAgreement),
    takeEvery(SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_REQUEST, sendForSignature),
    takeEvery(SIGN_SPECIFIC_AGREEMENT_REQUEST, signAgreement),
    takeEvery(PRINT_AGREEMENT_REQUEST, printAgreement),
    takeEvery(REMOVE_SPECIFIC_AGREEMENT_REQUEST, removeAgreement),
    takeEvery(GET_STUDENT_INFO_REQUEST, getStudentInfos),
    takeEvery(UPDATE_STUDENT_INFO_REQUEST, updateStudentInfos),
    takeEvery(MODIFY_SPECIFIC_AGREEMENT_REQUEST, modifyAgreement),
    takeEvery(CANCEL_SPECIFIC_AGREEMENT_REQUEST, cancelAgreement),
    takeEvery(EXTEND_SPECIFIC_AGREEMENT_REQUEST, extendAgreement),
    takeEvery(TERMINATE_SPECIFIC_AGREEMENT_REQUEST, terminateAgreement),
    takeEvery(SAVE_SPECIFIC_AGREEMENT_PRINTINT_TEMPLATE_REQUEST, saveSpecificAgreementTemplate),
    takeEvery(GET_CURRICULUMS_REQUEST, getCurriculums),
    takeEvery(SKIP_APPROVAL_VALIDATION_REQUEST, skipApprovalValidation),
    takeEvery(CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_REQUEST, cancelSignature),
    takeEvery(GET_ASSIGNEDS_REQUEST, getAssigneds),
    takeEvery(LIST_SUCCESS, getCompanySectors),
    takeEvery(GET_COMPANY_SECTORS_REQUEST, getCompanySectors),
    takeEvery(SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_REQUEST, SendEmailToStudent),
    takeEvery(SEND_REMINDER_SPECIFIC_AGREEMENT_REQUEST, sendReminder),
  ])
}

export default agreementsSaga
