import React, { PureComponent } from "react"
import PropTypes from "prop-types"

export default class FormError extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool,
  }

  static defaultProps = {
    visible: false,
  }

  render() {
    const { visible, children } = this.props
    if (!visible) return null
    return (
      <p className="text-danger" style={ { marginBottom: 20 } }>
        {children}
      </p>
    )
  }
}
