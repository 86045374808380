import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import { Row, Col } from "react-bootstrap"

import { DatePicker, FormInput } from "../Common"

const DatePickerRange = ({
  onStartDateChange,
  onEndDateChange,
  startDateInputProps,
  endDateInputProps,
  startDate,
  endDate,
  withChronologicalOrder,
}) => (
  <Row>
    {[
      { key: "start_date", defaultValue: startDate, onDayChange: onStartDateChange, ...startDateInputProps },
      { key: "end_date", defaultValue: endDate, onDayChange: onEndDateChange, ...endDateInputProps },
    ].map(({ key, onDayChange, defaultValue, ...props }, index) => (
      <Col xs={ 12 } sm={ 6 } key={ index }>
        <DatePicker
          inputProps={ {
            withLabel: true,
            label: i18n.t(`spa:date_picker_range.fields.${key}.label`),
            placeholder: i18n.t(`spa:date_picker_range.fields.${key}.placeholder`),
            ...props,
          } }
          defaultValue={ defaultValue }
          onDayChange={ onDayChange }
          modifiers={
            withChronologicalOrder &&
            (index === 0 ? { disabled: { after: endDate } } : { disabled: { before: startDate } })
          }
        />
      </Col>
    ))}
  </Row>
)

DatePickerRange.propTypes = {
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  startDateInputProps: PropTypes.shape({ ...FormInput.propTypes }),
  endDateInputProps: PropTypes.shape({ ...FormInput.propTypes }),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  withChronologicalOrder: PropTypes.bool,
}

DatePickerRange.defaultProps = {
  onStartDateChange: () => true,
  onEndDateChange: () => true,
  startDateInputProps: {},
  endDateInputProps: {},
  startDate: new Date(),
  endDate: new Date(),
  withChronologicalOrder: true,
}

export default DatePickerRange
