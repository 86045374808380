import React, { Component } from "react"
import each from "lodash/each"
import i18n from "i18next"

import FormInput from "./FormInput"
import Tooltip from "./Tooltip"

const pwdRegex = [
  /^(?=.*[A-Z]).+/, // 1 uppercase at least
  /^(?=.*[a-z]).+/, // 1 lowercase at least
  /^(?=.*\d).+/, // 1 digit at least
  /^(?=.*[_\W]).+/, // 1 special character at least
]

export default class PasswordInput extends Component {
  constructor() {
    super()
    this.state = {
      validationState: null,
    }
  }

  validatePwd(pwd) {
    if (pwd.length === 0) return null
    if (pwd.length < 10) return "error"
    let validatedContiditons = 0
    each(pwdRegex, (regex) => {
      if (regex.test(pwd)) validatedContiditons = validatedContiditons + 1
      return true
    })
    return validatedContiditons >= 3 ? null : "error"
  }

  onInputChange(evt) {
    const { onChange } = this.props

    if (onChange) onChange(evt)
    this.setState({ validationState: this.validatePwd(evt.target.value) })
  }

  render() {
    const { onChange, inputRef, ...props } = this.props

    return (
      <Tooltip
        trigger="focus"
        target={ () => this.input }
        renderTooltip={ () => <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={ { __html: i18n.t("spa:shared.tooltip.password_input") } } /> }
      >
        <FormInput
          onChange={ e => this.onInputChange(e) }
          validationState={ this.state.validationState }
          type="password"
          errorMessage={ i18n.t("spa:shared.error.password.format") }
          inputRef={ (ref) => {
            this.input = ref
            if (inputRef) inputRef(ref)
          } }
          { ...props }
        />
      </Tooltip>
    )
  }
}
