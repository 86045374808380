import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"

import { Button, ButtonGroup, StackedAvatars } from "../Common"

import DefaultAvatar from "../../assets/default-avatar.png"

import Session from "../../helpers/SessionHelper"
import { computeFullName } from "../../utils"

const generateButtonData = (title, onClick, bsStyle, className) => ({
  title,
  onClick,
  bsStyle,
  className,
})

const ApprovalStatus = ({ status, userId, onCancel, canCancel, onReAskApproval, canReAskApproval, approvals }) => {
  const finalApprovals = approvals
    .slice()
    .map(approval => computeFullName({ first_name: approval.user.first_name, last_name: approval.user.last_name }))
  let lastApproval = null
  let finalFullName = ""
  if (finalApprovals.length > 1) {
    lastApproval = finalApprovals.pop()
    finalFullName = `${finalApprovals.join(", ")} ${i18n.t("spa:shared.and")} ${lastApproval}`
  } else {
    finalFullName = finalApprovals.pop()
  }

  return (
    <div className="approval-status__container">
      <div className="approval-status__names-container">
        <div className="stacked-avatars__container" style={ { width: 40 + (approvals.length - 1) * 40 * 0.75 } }>
          <StackedAvatars avatars={ approvals.map(approval => approval.picture_url || DefaultAvatar) } />
        </div>
        <span
          className="approval-status__status-text"
          dangerouslySetInnerHTML={ {
            __html: i18n.t(
              `spa:approvals.statuses.${status}.${Session.id === userId ? "from_current_user" : "from_other_user"}`,
              {
                full_name: finalFullName,
                count: approvals.length,
              }
            ),
          } }
        />
      </div>
      {canCancel &&
        Session.id === userId && (
        <Button block className="approval-status__cancel-btn approval-cancel-button" onClick={ onCancel }>
          {i18n.t("spa:approvals.buttons.cancel")}
        </Button>
      )}
      {canCancel &&
        Session.id !== userId &&
        Session.isSchoolAdmin() && (
        <ButtonGroup
          buttonSpacing={ 10 }
          className="approval-status__school-admin-action-buttons"
          buttonsData={ [
            generateButtonData(
              i18n.t("spa:approvals.buttons.change_profesor"),
              onCancel,
              "default",
              "approval-cancel-button"
            ),
            (canReAskApproval &&
                status === "denied" &&
                generateButtonData(i18n.t("spa:approvals.buttons.re_ask_approval"), onReAskApproval, "primary")) ||
                null,
          ] }
        />
      )}
    </div>
  )
}

ApprovalStatus.propTypes = {
  status: PropTypes.oneOf([ "pending", "approved", "denied" ]).isRequired,
  userId: PropTypes.number.isRequired,
  onCancel: PropTypes.func,
  canCancel: PropTypes.bool,
  canReAskApproval: PropTypes.bool,
  onReAskApproval: PropTypes.func,
  approvals: PropTypes.array,
}

ApprovalStatus.defaultProps = {
  onCancel: () => true,
  canCancel: true,
  canReAskApproval: true,
  onReAskApproval: () => true,
  approvals: [],
}

export default ApprovalStatus
