import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import { Col, Row } from "react-bootstrap"

import "./StepForm.css"
import { Button, ImageUpload } from "../Common"
import { GenericFormInput } from "../GenericInputs"
import PhoneInput from "../PhoneInput"

export default class StepForm extends PureComponent {
  static propTypes = {
    onValidate: PropTypes.func.isRequired,
    getValidationState: PropTypes.func.isRequired,
    getErrorMessage: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
  }

  constructor() {
    super()
    this.state = {
      data: {
        first_name: "",
        last_name: "",
        phone_number: "",
        organization_name: "",
        position: "",
      },
    }
    this.avatarUpload = React.createRef()
  }

  handleChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    })
  }

  handlePhoneInput = (name, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    })
  }

  renderInput({ name, ...props }) {
    const { data } = this.state
    const { getValidationState, getErrorMessage } = this.props

    return (
      <GenericFormInput
        name={ name }
        data={ data }
        getValidationState={ getValidationState }
        getErrorMessage={ getErrorMessage }
        labelClassName="step-form__label"
        onChange={ e => this.handleChange(e) }
        baseTranslationKey="informations.form"
        { ...props }
      />
    )
  }

  renderForm() {
    return [
      {
        isRow: true,
        children: [ { name: "first_name" }, { name: "last_name" } ],
      },
      { name: "organization_name" },
      { name: "position" },
      { name: "phone_number", component: PhoneInput, onChange: this.handlePhoneInput, required: true },
    ].map((elem, idx) => {
      if (elem.isRow) {
        return (
          <Row key={ idx }>
            {elem.children.map((child, index) => (
              <Col xs={ 12 } sm={ 6 } key={ `${idx}-${index}` }>
                {this.renderInput(child)}
              </Col>
            ))}
          </Row>
        )
      }
      return React.cloneElement(this.renderInput(elem), { key: idx })
    })
  }

  onSubmit = async () => {
    const { data } = this.state
    const { onValidate, token } = this.props

    const logoUrl = await this.avatarUpload.current.triggerUpload({
      byToken: true,
      token,
    })
    onValidate({ ...data, picture_url: logoUrl })
  }

  render() {
    return (
      <form className="form">
        <ImageUpload label={ i18n.t("spa:informations.form.avatar.label") } ref={ this.avatarUpload } />
        {this.renderForm()}
        <Button bsStyle="primary" onClick={ this.onSubmit } style={ { float: "right", paddingLeft: 30, paddingRight: 30 } }>
          {i18n.t("spa:informations.form.start")}
        </Button>
      </form>
    )
  }
}
