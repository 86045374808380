import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import i18n from "i18next"
import GenericFilter from "../components/GenericFilter"
import { GET_COMPANY_SECTORS_REQUEST, GET_SPECIFIC_AGREEMENTS_REQUEST, SET_SPECIFIC_AGREEMENTS_FILTERS } from "../types"

class CompanySectorsListContainer extends React.PureComponent {
  componentDidMount() {
    const { getCompanySectors } = this.props
    getCompanySectors()
  }

  formatDefaultValues = () => {
    const { companySectors, companySectorsFilters, isMulti } = this.props
    if (isMulti) {
      return companySectorsFilters.map((companySectorUuid) => {
        const selectedCompanySectorObject = companySectors.find(companySector => companySector.uuid === companySectorUuid)
        return selectedCompanySectorObject
      })
    }
    return companySectors.find(companySector => `${companySector.uuid}` === `${companySectorsFilters}`)
  }

  render() {
    const { companySectors, setCompanySectorsFilters, triggerSpecificAgreementListFetch, match, ...props } = this.props
    return (
      <GenericFilter
        onChange={ (value) => {
          setCompanySectorsFilters(value.map(companySector => companySector.uuid))
          setImmediate(() => triggerSpecificAgreementListFetch(match.params.type))
        } }
        options={ companySectors }
        getOptionLabel={ option => option.name }
        getOptionValue={ option => option.uuid }
        value={ this.formatDefaultValues() }
        noOptions={ i18n.t("spa:header.company_sectors_filter.no_options") }
        placeholder={ i18n.t("spa:header.company_sectors_filter.placeholder") }
        { ...props }
      />
    )
  }
}

const mapStateToProps = state => ({
  companySectors: state.jt.companySectors,
  companySectorsFilters: state.jt.specificAgreementsFilters.companySectors,
})

const mapDispatchToProps = dispatch => ({
  getCompanySectors: () => dispatch({ type: GET_COMPANY_SECTORS_REQUEST }),
  setCompanySectorsFilters: filters => dispatch({ type: SET_SPECIFIC_AGREEMENTS_FILTERS, payload: { companySectors: filters } }),
  triggerSpecificAgreementListFetch: type => dispatch({ type: GET_SPECIFIC_AGREEMENTS_REQUEST, payload: { type } }),
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanySectorsListContainer))
