import { Schools } from "../api"

export async function getSchool(id) {
  try {
    const data = await Schools.getSchool(id)
    return data
  } catch (e) {
    throw e
  }
}
