import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import { Title, Badge } from "../Common"

import SpecificAgreementExtensionStatus from "../SpecificAgreementExtensionStatus"

const SpecificAgreementStatus = ({
  agreementId,
  agreementStatus,
  agreementStateBadgeStyle,
  alterationData: {
    alterationId,
    alterationStatus,
    alterationCreatedAt,
    alteratedId,
    alteratedStatus,
    alteratedCreatedAt,
  },
}) => (
  <div className="specific-agreement-status__container">
    <div>
      <Title size={ 20 } className="specific-agreement-status-card__title">
        {i18n.t("spa:specific_agreement_assignee_card.internship_number")} {agreementId}
        <Badge badgeStyle={ agreementStateBadgeStyle } style={ { marginLeft: 14 } }>
          {agreementStatus}
        </Badge>
      </Title>
    </div>
    <div>
      {(alterationStatus || alteratedStatus) && (
        <SpecificAgreementExtensionStatus
          alteratedId={ alteratedId }
          alteratedStatus={ alteratedStatus }
          alterationId={ alterationId }
          alterationStatus={ alterationStatus }
          alteratedCreatedAt={ alteratedCreatedAt }
          alterationCreatedAt={ alterationCreatedAt }
        />
      )}
    </div>
  </div>
)

SpecificAgreementStatus.propTypes = {
  agreementId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  agreementStatus: PropTypes.string,
  alterationData: PropTypes.shape({
    alterationId: PropTypes.number,
    alterationStatus: PropTypes.string,
    alteratedId: PropTypes.number,
    alteratedStatus: PropTypes.string,
  }),
  agreementStateBadgeStyle: PropTypes.string,
}

SpecificAgreementStatus.defaultProps = {
  agreementId: null,
  agreementStatus: null,
  alterationData: {},
  agreementStateBadgeStyle: "",
}

export default SpecificAgreementStatus
