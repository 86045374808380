import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"

import { Button, TagsInput } from "../Common"

const ApprovalSelectionForm = ({ onApprove, canAskForApproval, rootRef, onSkipValidation, ...props }) => (
  <div ref={ rootRef }>
    <TagsInput { ...props } />
    <Button
      className="approval-selection-form__approve-button"
      bsStyle="primary"
      block
      onClick={ onApprove }
      disabled={ !canAskForApproval }
    >
      {i18n.t("spa:approvals.buttons.ask_for_approval")}
    </Button>
    <p className="approval-selection-form__skip-validation" onClick={ onSkipValidation }>
      {i18n.t("spa:approvals.buttons.approval_not_needed")}
    </p>
  </div>
)

ApprovalSelectionForm.propTypes = {
  ...TagsInput.propTypes,
  canAskForApproval: PropTypes.bool,
  rootRef: PropTypes.any,
  onSkipValidation: PropTypes.func,
}

ApprovalSelectionForm.defaultProps = {
  ...TagsInput.defaultProps,
  canAskForApproval: true,
  rootRef: undefined,
  onSkipValidation: () => true,
}

export default ApprovalSelectionForm
