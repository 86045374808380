export const GET_SPECIFIC_AGREEMENTS_REQUEST = "GET_SPECIFIC_AGREEMENTS_REQUEST"
export const GET_SPECIFIC_AGREEMENTS_SUCCESS = "GET_SPECIFIC_AGREEMENTS_SUCCESS"
export const GET_SPECIFIC_AGREEMENTS_ERROR = "GET_SPECIFIC_AGREEMENTS_ERROR"

export const GET_SPECIFIC_AGREEMENT_DATA_REQUEST = "GET_SPECIFIC_AGREEMENT_DATA_REQUEST"
export const GET_SPECIFIC_AGREEMENT_DATA_SUCCESS = "GET_SPECIFIC_AGREEMENT_DATA_SUCCESS"
export const GET_SPECIFIC_AGREEMENT_DATA_ERROR = "GET_SPECIFIC_AGREEMENT_DATA_ERROR"

export const SPECIFIC_AGREEMENT_EDIT_REQUEST = "SPECIFIC_AGREEMENT_EDIT_REQUEST"
export const SPECIFIC_AGREEMENT_EDIT_SUCCESS = "SPECIFIC_AGREEMENT_EDIT_SUCCESS"
export const SPECIFIC_AGREEMENT_EDIT_ERROR = "SPECIFIC_AGREEMENT_EDIT_ERROR"

export const APPROVAL_ASK_REQUEST = "APPROVAL_ASK_REQUEST"
export const APPROVAL_ASK_SUCCESS = "APPROVAL_ASK_SUCCESS"
export const APPROVAL_ASK_ERROR = "APPROVAL_ASK_ERROR"

export const APPROVAL_EDIT_REQUEST = "APPROVAL_EDIT_REQUEST"
export const APPROVAL_EDIT_SUCCESS = "APPROVAL_EDIT_SUCCESS"
export const APPROVAL_EDIT_ERROR = "APPROVAL_EDIT_ERROR"

export const SPECIFIC_AGREEMENT_CREATE_REQUEST = "SPECIFIC_AGREEMENT_CREATE_REQUEST"
export const SPECIFIC_AGREEMENT_CREATE_SUCCESS = "SPECIFIC_AGREEMENT_CREATE_SUCCESS"
export const SPECIFIC_AGREEMENT_CREATE_ERROR = "SPECIFIC_AGREEMENT_CREATE_ERROR"

export const SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_REQUEST = "SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_REQUEST"
export const SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_SUCCESS = "SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_SUCCESS"
export const SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_ERROR = "SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_ERROR"

export const SIGN_SPECIFIC_AGREEMENT_REQUEST = "SIGN_SPECIFIC_AGREEMENT_REQUEST"
export const SIGN_SPECIFIC_AGREEMENT_SUCCESS = "SIGN_SPECIFIC_AGREEMENT_SUCCESS"
export const SIGN_SPECIFIC_AGREEMENT_ERROR = "SIGN_SPECIFIC_AGREEMENT_ERROR"

export const PRINT_AGREEMENT_REQUEST = "PRINT_AGREEMENT_REQUEST"

export const REMOVE_SPECIFIC_AGREEMENT_REQUEST = "REMOVE_SPECIFIC_AGREEMENT_REQUEST"
export const REMOVE_SPECIFIC_AGREEMENT_SUCCESS = "REMOVE_SPECIFIC_AGREEMENT_SUCCESS"
export const REMOVE_SPECIFIC_AGREEMENT_ERROR = "REMOVE_SPECIFIC_AGREEMENT_ERROR"

export const GET_SPECIFIC_AGREEMENT_ACTIVITIES_SUCCESS = "GET_SPECIFIC_AGREEMENT_ACTIVITIES_SUCCESS"
export const GET_SPECIFIC_AGREEMENT_ACTIVITIES_ERROR = "GET_SPECIFIC_AGREEMENT_ACTIVITIES_ERROR"

export const GET_STUDENT_INFO_REQUEST = "GET_STUDENT_INFO_REQUEST"
export const GET_STUDENT_INFO_SUCCESS = "GET_STUDENT_INFO_SUCCESS"
export const GET_STUDENT_INFO_ERROR = "GET_STUDENT_INFO_ERROR"

export const UPDATE_STUDENT_INFO_REQUEST = "UPDATE_STUDENT_INFO_REQUEST"
export const UPDATE_STUDENT_INFO_SUCCESS = "UPDATE_STUDENT_INFO_SUCCESS"
export const UPDATE_STUDENT_INFO_ERROR = "UPDATE_STUDENT_INFO_ERROR"

export const MODIFY_SPECIFIC_AGREEMENT_REQUEST = "MODIFY_SPECIFIC_AGREEMENT_REQUEST"
export const MODIFY_SPECIFIC_AGREEMENT_SUCCESS = "MODIFY_SPECIFIC_AGREEMENT_SUCCESS"
export const MODIFY_SPECIFIC_AGREEMENT_ERROR = "MODIFY_SPECIFIC_AGREEMENT_ERROR"

export const CANCEL_SPECIFIC_AGREEMENT_REQUEST = "CANCEL_SPECIFIC_AGREEMENT_REQUEST"
export const CANCEL_SPECIFIC_AGREEMENT_SUCCESS = "CANCEL_SPECIFIC_AGREEMENT_SUCCESS"
export const CANCEL_SPECIFIC_AGREEMENT_ERROR = "CANCEL_SPECIFIC_AGREEMENT_ERROR"

export const EXTEND_SPECIFIC_AGREEMENT_REQUEST = "EXTEND_SPECIFIC_AGREEMENT_REQUEST"
export const EXTEND_SPECIFIC_AGREEMENT_SUCCESS = "EXTEND_SPECIFIC_AGREEMENT_SUCCESS"
export const EXTEND_SPECIFIC_AGREEMENT_ERROR = "EXTEND_SPECIFIC_AGREEMENT_ERROR"

export const TERMINATE_SPECIFIC_AGREEMENT_REQUEST = "TERMINATE_SPECIFIC_AGREEMENT_REQUEST"
export const TERMINATE_SPECIFIC_AGREEMENT_SUCCESS = "TERMINATE_SPECIFIC_AGREEMENT_SUCCESS"
export const TERMINATE_SPECIFIC_AGREEMENT_ERROR = "TERMINATE_SPECIFIC_AGREEMENT_ERROR"

export const SAVE_SPECIFIC_AGREEMENT_PRINTINT_TEMPLATE_REQUEST = "SAVE_SPECIFIC_AGREEMENT_PRINTINT_TEMPLATE_REQUEST"

export const SKIP_APPROVAL_VALIDATION_REQUEST = "SKIP_APPROVAL_VALIDATION_REQUEST"

export const CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_REQUEST = "CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_REQUEST"
export const CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_SUCCESS = "CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_SUCCESS"
export const CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_ERROR = "CANCEL_SPECIFIC_AGREEMENT_SIGNATURE_ERROR"

export const SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_REQUEST = "SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_REQUEST"
export const SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_SUCCESS = "SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_SUCCESS"
export const SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_ERROR = "SPECIFIC_AGREEMENT_SEND_EMAIL_TO_STUDENT_ERROR"

export const SEND_REMINDER_SPECIFIC_AGREEMENT_REQUEST = "SEND_REMINDER_SPECIFIC_AGREEMENT_REQUEST"
export const SEND_REMINDER_SPECIFIC_AGREEMENT_SUCCESS = "SEND_REMINDER_SPECIFIC_AGREEMENT_SUCCESS"
export const SEND_REMINDER_SPECIFIC_AGREEMENT_ERROR = "SEND_REMINDER_SPECIFIC_AGREEMENT_ERROR"
