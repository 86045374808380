import { takeEvery, call, put, all } from "redux-saga/effects"

import Session from "../helpers/SessionHelper"

import {
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_ERROR,
  GET_COMMENTS_SUCCESS,
  COMMENT_CREATE_REQUEST,
  COMMENT_CREATE_SUCCESS,
  COMMENT_CREATE_ERROR,
  COMMENT_DELETE_REQUEST,
  COMMENT_DELETE_SUCCESS,
  COMMENT_DELETE_ERROR,
} from "../types"

import {
  getComments as getCommentsAction,
  createComment as createCommentAction,
  deleteComment as deleteCommentAction,
} from "../actions/comments"

export function* getComments({ payload }) {
  const { id } = payload
  if (!Session.isRecruiter()) {
    try {
      const data = yield call(getCommentsAction, id)
      yield put({
        type: GET_COMMENTS_SUCCESS,
        payload: { data },
      })
    } catch (e) {
      yield put({ type: GET_COMMENTS_ERROR, payload: { error: e } })
    }
  }
}

export function* createComment({ payload }) {
  const { id, body } = payload

  try {
    const data = yield call(createCommentAction, id, body)
    yield put({
      type: COMMENT_CREATE_SUCCESS,
      payload: { data },
    })
  } catch (e) {
    yield put({ type: COMMENT_CREATE_ERROR, payload: { error: e } })
  }
}

export function* deleteComment({ payload }) {
  const { id, specific_agreement_id } = payload

  try {
    yield call(deleteCommentAction, id)
    yield put({ type: COMMENT_DELETE_SUCCESS, payload: { id } })
    yield put({ type: GET_COMMENTS_REQUEST, payload: { id: specific_agreement_id } })
  } catch (e) {
    yield put({ type: COMMENT_DELETE_ERROR })
  }
}

function* commentsSaga() {
  yield all([
    takeEvery(GET_COMMENTS_REQUEST, getComments),
    takeEvery(COMMENT_CREATE_REQUEST, createComment),
    takeEvery(COMMENT_DELETE_REQUEST, deleteComment),
  ])
}

export default commentsSaga
