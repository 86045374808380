import React from "react";
import PropTypes from "prop-types";
import i18n from "i18next";
import moment from "moment";

const SpecificAgreementAlterationTerminationReason = ({
  reasonForAlteration,
  jobRealEndDate,
  hoursDone
}) => (
  <div className="specific-agreement-alteration__description-container">
    <span
      dangerouslySetInnerHTML={{
        __html: i18n.t(
          "spa:specific_agreements.school_panel.alteration.descriptions.titles.termination",
          {
            termination_date: moment(jobRealEndDate).format("LL")
          }
        )
      }}
    />
    <br />
    <br />
    <span className="specific-agreement-alteration__description-label">
      {i18n.t(
        "spa:specific_agreements.school_panel.alteration.descriptions.labels.termination"
      )}{" "}
      :
    </span>
    <br />
    <span>{reasonForAlteration}</span>
    <br />
    <br />
    <span className="specific-agreement-alteration__description-label">
      {i18n.t(
        "spa:specific_agreements.school_panel.alteration.hours_done.labels.termination"
      )}{" "}
      :
    </span>
    <br />
    <span>{hoursDone}</span>
  </div>
);

SpecificAgreementAlterationTerminationReason.propTypes = {
  reasonForAlteration: PropTypes.string,
  jobRealEndDate: PropTypes.string.isRequired
};

SpecificAgreementAlterationTerminationReason.defaultProps = {
  reasonForAlteration: ""
};

export default SpecificAgreementAlterationTerminationReason;
