import React from "react"
import PropTypes from "prop-types"

import { Button, Icon, FormInput } from "../../Common"

export default class AdminSchoolsSpecificCompetenciesTemplates extends React.Component {
  static propTypes = {
    templates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        content: PropTypes.string,
      })
    ),
    onAddTemplate: PropTypes.func.isRequired,
    onRemoveTemplate: PropTypes.func.isRequired,
    onChangeData: PropTypes.func.isRequired,
  }

  static defaultProps = {
    templates: [],
  }

  onChangeInput = (evt, index) => {
    const { onChangeData } = this.props

    onChangeData(evt.target.name, evt.target.value, index)
  }

  renderTemplateForm = (template, index) => {
    const { onRemoveTemplate } = this.props

    return (
      <div key={ index } className="admin-schools-form__templates-form-container">
        <div className="admin-schools-form__templates-form-main-infos" style={ { flex: 0.9 } }>
          <FormInput
            name="title"
            withLabel
            label="Title"
            placeholder="Title"
            value={ template.title }
            onChange={ evt => this.onChangeInput(evt, index) }
          />
          <FormInput
            name="content"
            withLabel
            label="Content"
            placeholder="Content"
            value={ template.content }
            onChange={ evt => this.onChangeInput(evt, index) }
            componentClass="textarea"
            rows={ 4 }
          />
        </div>
        <Button bsStyle="danger" onClick={ () => onRemoveTemplate(index) }>
          <Icon iconName="times" iconSize={ 20 } />
        </Button>
      </div>
    )
  }

  render() {
    const { templates, onAddTemplate } = this.props

    return (
      <div className="admin-schools-form__templates-list-container">
        <form>{templates.map(this.renderTemplateForm)}</form>
        <Button bsStyle="success" onClick={ onAddTemplate }>
          <Icon iconName="plus" iconSize={ 20 } />
        </Button>
      </div>
    )
  }
}
