import { fork, all } from "redux-saga/effects"

import signinSaga from "./signinSaga"
import signupSaga from "./signupSaga"
import commentsSaga from "./commentsSaga"
import profileSaga from "./profileSaga"
import specificAgreementsSaga from "./specificAgreementsSaga"
import generalAgreementsSaga from "./generalAgreementsSaga"
import schoolsSaga from "./schoolsSaga"
import resetPasswordSaga from "./resetPasswordSaga"
import adminSaga from "./adminSaga"
import companySaga from "./companySaga"
import sidebarSaga from "./sidebarSaga"

function* rootSaga() {
  yield all([
    fork(signinSaga),
    fork(signupSaga),
    fork(commentsSaga),
    fork(profileSaga),
    fork(specificAgreementsSaga),
    fork(generalAgreementsSaga),
    fork(schoolsSaga),
    fork(resetPasswordSaga),
    fork(adminSaga),
    fork(companySaga),
    fork(sidebarSaga),
  ])
}

export default rootSaga
