import React from "react"
import { connect } from "react-redux"
import i18n from "i18next"
import GenericFilter from "../components/GenericFilter"
import { GET_ASSIGNEDS_REQUEST, GET_SPECIFIC_AGREEMENTS_REQUEST, SET_SPECIFIC_AGREEMENTS_FILTERS } from "../types"

import Session from "../helpers/SessionHelper"

class AssignedsListContainer extends React.PureComponent {
  componentDidMount() {
    const { getAssigneds } = this.props
    getAssigneds(Session.schoolId)
  }

  formatDefaultValues = () => {
    const { assigneds, assignedsFilters, isMulti } = this.props
    if (isMulti) {
      return assignedsFilters.map((assignedId) => {
        const relatedAssignedObject = assigneds.find(assigned => assigned.id === assignedId)
        return relatedAssignedObject
      })
    }
    return assigneds.find(assigned => `${assigned.id}` === `${assignedsFilters}`)
  }

  render() {
    const { assigneds, setAssignedsFilters, triggerSpecificAgreementListFetch, match, ...props } = this.props
    return (
      <GenericFilter
        onChange={ (value) => {
          setAssignedsFilters(value.map(assigned => assigned.id))
          setImmediate(() => triggerSpecificAgreementListFetch(match.params.type))
        } }
        options={ assigneds }
        getOptionLabel={ option => `${option.first_name} ${option.last_name}` }
        getOptionValue={ option => option.id }
        value={ this.formatDefaultValues() }
        noOptions={ i18n.t("spa:header.assigneds_filter.no_options") }
        placeholder={ i18n.t("spa:header.assigneds_filter.placeholder") }
        { ...props }
      />
    )
  }
}

const mapStateToProps = state => ({
  assigneds: state.jt.assigneds,
  assignedsFilters: state.jt.specificAgreementsFilters.assigneds,
})

const mapDispatchToProps = dispatch => ({
  getAssigneds: schoolId => dispatch({ type: GET_ASSIGNEDS_REQUEST, payload: { school_id: schoolId } }),
  setAssignedsFilters: filters => dispatch({ type: SET_SPECIFIC_AGREEMENTS_FILTERS, payload: { assigneds: filters } }),
  triggerSpecificAgreementListFetch: type => dispatch({ type: GET_SPECIFIC_AGREEMENTS_REQUEST, payload: { type } }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignedsListContainer)
