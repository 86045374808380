import { call, takeEvery, put, all } from "redux-saga/effects"
import { push } from "react-router-redux"
import i18n from "i18next"

import {
  GENERAL_AGREEMENTS_LIST_REQUEST,
  GENERAL_AGREEMENTS_LIST_SUCCESS,
  GENERAL_AGREEMENTS_LIST_ERROR,
  GENERAL_AGREEMENT_DATA_REQUEST,
  GENERAL_AGREEMENT_DATA_SUCCESS,
  GENERAL_AGREEMENT_DATA_ERROR,
  GENERAL_AGREEMENT_CREATE_REQUEST,
  GENERAL_AGREEMENT_CREATE_SUCCESS,
  GENERAL_AGREEMENT_CREATE_ERROR,
  GENERAL_AGREEMENT_EDIT_REQUEST,
  GENERAL_AGREEMENT_EDIT_SUCCESS,
  GENERAL_AGREEMENT_EDIT_ERROR,
  GENERAL_AGREEMENT_CANCEL_REQUEST,
  GENERAL_AGREEMENT_CANCEL_SUCCESS,
  GENERAL_AGREEMENT_CANCEL_ERROR,
  COMPANIES_WITHOUT_GENERAL_AGREEMENT_REQUEST,
  COMPANIES_WITHOUT_GENERAL_AGREEMENT_SUCCESS,
  COMPANIES_WITHOUT_GENERAL_AGREEMENT_ERROR,
  GET_COMPANY_SECTORS_SUCCESS,
  GET_COMPANY_SECTORS_ERROR,
  SAVE_GENERAL_AGREEMENT_PRINTINT_TEMPLATE_REQUEST,
} from "../types"

import Routes from "../routes"

import Toaster from "../helpers/Toaster"

import {
  getAgreements as getAgreementsAction,
  getAgreement as getAgreementAction,
  createAgreement as createAgreementAction,
  editAgreement as editAgreementAction,
  updateAgreementState as updateAgreementStateAction,
  cancelAgreement as cancelAgreementAction,
} from "../actions/generalAgreements"
import { getCompanies as getCompaniesAction } from "../actions/company"
import { getCompanySectors as getCompanySectorsAction } from "../actions/JTActions"

export function* getCompanySectors({ payload }) {
  try {
    const companySectors = yield call(getCompanySectorsAction)
    yield put({ type: GET_COMPANY_SECTORS_SUCCESS, payload: { data: companySectors } })
  } catch (e) {
    yield put({ type: GET_COMPANY_SECTORS_ERROR })
  }
}

export function* getAgreements({ payload }) {
  const { type } = payload

  try {
    const data = yield call(getAgreementsAction, type)
    yield put({ type: GENERAL_AGREEMENTS_LIST_SUCCESS, payload: { data } })
  } catch (e) {
    yield put({ type: GENERAL_AGREEMENTS_LIST_ERROR })
  }
}

export function* getAgreementData({ payload }) {
  const { id } = payload

  try {
    const data = yield call(getAgreementAction, id)
    yield* getCompanySectors({
      payload: {},
    })
    yield put({ type: GENERAL_AGREEMENT_DATA_SUCCESS, payload: { data } })
  } catch (e) {
    yield put({ type: GENERAL_AGREEMENT_DATA_ERROR })
  }
}

export function* createAgreement({ payload }) {
  const { data, review = false } = payload

  try {
    let dataCreate = yield call(createAgreementAction, data)
    if (review) {
      const reviewData = yield call(updateAgreementStateAction, dataCreate.review_url)
      dataCreate = reviewData
    }
    new Toaster.Builder()
      .setMessage(i18n.t("spa:general_agreements.create.success_toast.message"))
      .setOptions({
        className: "toaster-success",
      })
      .setType(Toaster.TYPES.SUCCESS)
      .build()
      .show()
    yield* getCompanySectors({
      payload: {},
    })
    yield put({ type: GENERAL_AGREEMENT_CREATE_SUCCESS, payload: { data: dataCreate } })
  } catch (e) {
    yield put({ type: GENERAL_AGREEMENT_CREATE_ERROR, payload: { errors: e } })
  }
}

export function* editAgreement({ payload }) {
  const { data, url, updateStateUrl = null, previewPrint = false } = payload

  try {
    let dataEdit = yield call(editAgreementAction, data, url)

    if (!previewPrint) {
      let actionKey = "edit"

      if (updateStateUrl) {
        const reviewData = yield call(updateAgreementStateAction, updateStateUrl)
        actionKey = "send"

        dataEdit = reviewData
      }
      new Toaster.Builder()
        .setMessage(i18n.t(`spa:general_agreements.${actionKey}.success_toast.message`))
        .setOptions({
          className: "toaster-success",
        })
        .setType(Toaster.TYPES.SUCCESS)
        .build()
        .show()
    }
    yield put({ type: GENERAL_AGREEMENT_EDIT_SUCCESS, payload: { data: dataEdit } })
  } catch (e) {
    yield put({ type: GENERAL_AGREEMENT_EDIT_ERROR, payload: { errors: e } })
  }
}

export function* cancelAgreement({ payload }) {
  const { id } = payload

  try {
    const data = yield call(cancelAgreementAction, id)
    new Toaster.Builder()
      .setMessage(i18n.t("spa:general_agreements.cancel.success_toast.message"))
      .setOptions({
        className: "toaster-success",
      })
      .setType(Toaster.TYPES.SUCCESS)
      .build()
      .show()
    yield put({ type: GENERAL_AGREEMENT_CANCEL_SUCCESS, payload: { data } })
  } catch (e) {
    yield put({ type: GENERAL_AGREEMENT_CANCEL_ERROR, payload: { errors: e } })
  }
}

export function* getCompaniesWithoutGeneralAgreement() {
  try {
    const data = yield call(getCompaniesAction, { without_general_agreement: true })
    yield put({ type: COMPANIES_WITHOUT_GENERAL_AGREEMENT_SUCCESS, payload: { data } })
  } catch (e) {
    yield put({ type: COMPANIES_WITHOUT_GENERAL_AGREEMENT_ERROR })
  }
}

export function* saveGeneralAgreementTemplate({ payload }) {
  const { data, updateUrl } = payload

  yield* editAgreement({
    payload: {
      data,
      url: updateUrl,
      previewPrint: true,
    },
  })
  yield put(push(Routes.GENERAL_AGREEMENT_PRINTING_TEMPLATE_PREVIEW.replace(":id", data.id)))
}

function* generalAgreementsSaga() {
  yield all([
    takeEvery(GENERAL_AGREEMENTS_LIST_REQUEST, getAgreements),
    takeEvery(GENERAL_AGREEMENT_DATA_REQUEST, getAgreementData),
    takeEvery(GENERAL_AGREEMENT_CREATE_REQUEST, createAgreement),
    takeEvery(GENERAL_AGREEMENT_EDIT_REQUEST, editAgreement),
    takeEvery(GENERAL_AGREEMENT_CANCEL_REQUEST, cancelAgreement),
    takeEvery(COMPANIES_WITHOUT_GENERAL_AGREEMENT_REQUEST, getCompaniesWithoutGeneralAgreement),
    takeEvery(SAVE_GENERAL_AGREEMENT_PRINTINT_TEMPLATE_REQUEST, saveGeneralAgreementTemplate),
  ])
}

export default generalAgreementsSaga
