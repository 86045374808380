import { generateAuthActions } from "redux-token-auth"

// We need to override setItem in order to bypass a vicious bug of redux-token-auth
// Which store blank value when back-end send blank values (in case of batch request for instance)
const originalSetItem = localStorage.setItem

// eslint-disable-next-line
localStorage.__proto__.setItem = function(key, value) {
  if ([ "access-token", "uid", "client" ].indexOf(key) > -1 && value && value !== "") {
    originalSetItem.call(this, key, value)
  }
}

const config = {
  authUrl: `${process.env.REACT_APP_API_URL}/auth`,
  userAttributes: {
    first_name: "first_name",
    last_name: "last_name",
    organization_name: "organization_name",
    position: "position",
    phone_number: "phone_number",
    email: "email",
    role: "role",
    companies: "companies",
    id: "id",
    locale: "locale",
    school: "school",
    primary_color: "primary_color",
    picture_url: "picture_url",
  },
  userRegistrationAttributes: {
    confirmSuccessUrl: "confirm_success_url",
    host: "host",
    role: "role",
    locale: "locale",
  },
  storage: localStorage,
}

const { registerUser, signInUser, signOutUser, verifyCredentials } = generateAuthActions(config)

export { registerUser, signInUser, signOutUser, verifyCredentials }
