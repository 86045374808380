import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

const Icon = ({ iconName, iconSize, style, className, componentClass, ...props }) => {
  const finalClassName = classnames(`fa fa-${iconName}`, className)
  const Comp = componentClass

  return <Comp className={ finalClassName } style={ { fontSize: iconSize, ...style } } { ...props } />
}

Icon.propTypes = {
  iconName: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  componentClass: PropTypes.string,
}

Icon.defaultProps = {
  iconSize: 15,
  componentClass: "span",
}

export default Icon
