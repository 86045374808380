import { all, takeEvery, call, put } from "redux-saga/effects"
import { push } from "react-router-redux"

import { resetPasswordEmailRequest, resetPassword } from "../actions/resetPassword"

import {
  RESET_PASSWORD_EMAIL_REQUEST,
  RESET_PASSWORD_EMAIL_SUCCESS,
  RESET_PASSWORD_EMAIL_ERROR,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_REQUEST,
} from "../types"

import Routes from "../routes"

export function* requestEmail({ payload }) {
  const { email } = payload
  try {
    yield call(resetPasswordEmailRequest, email)
    yield put({ type: RESET_PASSWORD_EMAIL_SUCCESS })
  } catch (e) {
    yield put({ type: RESET_PASSWORD_EMAIL_ERROR, payload: { message: e.message } })
  }
}

export function* requestPasswordReset({ payload }) {
  const { password, client, uid, token } = payload

  try {
    yield call(resetPassword, password, uid, client, token)
    yield put({ type: RESET_PASSWORD_SUCCESS })
    yield put(push(Routes.SIGNIN))
  } catch (e) {
    yield put({ type: RESET_PASSWORD_ERROR, payload: { errors: e.errors } })
  }
}

function* resetPasswordSaga() {
  yield all([
    takeEvery(RESET_PASSWORD_EMAIL_REQUEST, requestEmail),
    takeEvery(RESET_PASSWORD_REQUEST, requestPasswordReset),
  ])
}

export default resetPasswordSaga
