import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import classname from "classnames"

import { Icon } from "../Common"

const SpecificAgreementAlterationFormTitle = ({ title, alterationStatus }) => (
  <div className="specific-agreement-alteration__form-title-container">
    <span>
      <Icon iconName="bookmark" />
      <span>{title}</span>
    </span>
    {alterationStatus && (
      <span
        className={ classname("specific-agreement-alteration__alteration-badge badge outline", {
          "badge-success": alterationStatus !== "terminated",
          "badge-danger": alterationStatus === "terminated",
        }) }
      >
        {i18n.t(`spa:specific_agreements.school_panel.alteration.badge.${alterationStatus}`)}
      </span>
    )}
  </div>
)

SpecificAgreementAlterationFormTitle.propTypes = {
  alterationStatus: PropTypes.string,
  title: PropTypes.string,
}

SpecificAgreementAlterationFormTitle.defaultProps = {
  alterationStatus: null,
  title: "",
}

export default SpecificAgreementAlterationFormTitle
