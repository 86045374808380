import { SidebarAPI } from "../api"

export async function getSidebarCount(filters) {
  try {
    const data = await SidebarAPI.getAgreementCounts(filters)
    return data
  } catch (e) {
    throw e
  }
}
