import React from "react"
import PropTypes from "prop-types"

import Avatar from "./Avatar"

import "./StackedAvatars.css"

const StackedAvatars = ({ avatars, ...props }) => {
  const [ currentAvatar, ...otherAvatars ] = avatars || []
  return currentAvatar ? (
    <div className="stacked-avatars__item" style={ { width: props.size, height: props.size } }>
      <Avatar src={ currentAvatar } { ...props } />
      <StackedAvatars avatars={ otherAvatars } { ...props } />
    </div>
  ) : null
}

StackedAvatars.propTypes = {
  avatars: PropTypes.arrayOf(PropTypes.string),
  ...Avatar.propTypes,
}

StackedAvatars.defaultProps = {
  avatars: null,
  ...Avatar.defaultProps,
}

export default StackedAvatars
