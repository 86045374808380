import React from "react"
import PropTypes from "prop-types"

import Avatar from "./Avatar"

import DefaultAvatar from "../../assets/default-avatar.png"
import { computeFullName } from "../../utils"

import "./UserItem.css"

const UserItem = ({ firstName, lastName, pictureUrl, position }) => (
  <div className="user-item__container">
    <Avatar src={ pictureUrl || DefaultAvatar } size={ 30 } />
    <div className="user-item__description">
      <span>{computeFullName({ first_name: firstName, last_name: lastName })}</span>
      {position && (
        <React.Fragment>
          <br />
          <span className="user-item__position">{position}</span>
        </React.Fragment>
      )}
    </div>
  </div>
)

UserItem.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  pictureUrl: PropTypes.string,
  position: PropTypes.string,
}

UserItem.defaultProps = {
  firstName: "",
  lastName: "",
  pictureUrl: null,
  position: null,
}

export default UserItem
