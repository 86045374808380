export const COMPANY_CREATE_REQUEST = "COMPANY_CREATE_REQUEST"
export const COMPANY_CREATE_SUCCESS = "COMPANY_CREATE_SUCCESS"
export const COMPANY_CREATE_ERROR = "COMPANY_CREATE_ERROR"

export const COMPANY_DATA_REQUEST = "COMPANY_DATA_REQUEST"
export const COMPANY_DATA_SUCCESS = "COMPANY_DATA_SUCCESS"
export const COMPANY_DATA_ERROR = "COMPANY_DATA_ERROR"

export const COMPANY_UPDATE_REQUEST = "COMPANY_UPDATE_REQUEST"
export const COMPANY_UPDATE_SUCCESS = "COMPANY_UPDATE_SUCCESS"
export const COMPANY_UPDATE_ERROR = "COMPANY_UPDATE_ERROR"
