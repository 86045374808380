import React from "react"
import PropTypes from "prop-types"

import { Card } from "../Common"

import DefaultCompanyAvatar from "../../assets/company_logo.png"

const SchoolInfo = ({ name, avatarUrl }) => <Card avatarUrl={ avatarUrl || DefaultCompanyAvatar } title={ name } />

SchoolInfo.propTypes = {
  name: PropTypes.string,
  avatarUrl: PropTypes.string,
}

SchoolInfo.defaultProps = {
  name: "",
  avatarUrl: DefaultCompanyAvatar,
}

export default SchoolInfo
