import React, { Component } from "react"
import PropTypes from "prop-types"
import map from "lodash/map"
import classname from "classnames"
import { Row, Col } from "react-bootstrap"

import { ButtonGroup } from "../Common"
import { consumeSpecificAgreementForm } from "../../hoc"

export class SpecificAgreementFormNavigator extends Component {
  static propTypes = {
    titles: PropTypes.object.isRequired,
    onNavigate: PropTypes.func,
    selectedIndex: PropTypes.number,
  }

  static defaultProps = {
    onNavigate: () => true,
    selectedIndex: 0,
    disabled: () => false,
  }

  static contextTypes = {
    primaryColor: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedStep: props.selectedIndex,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ selectedStep: nextProps.currentStep })
  }

  onNavigate(newIdx) {
    const { onNavigate } = this.props

    onNavigate(newIdx)
  }

  goToNext() {
    this.onNavigate(this.state.selectedStep + 1)
  }

  goBack() {
    this.onNavigate(this.state.selectedStep - 1)
  }

  render() {
    const {
      titles,
      currentStep,
      data: { agreementId },
    } = this.props

    return (
      <Row>
        <Col xs={ 12 } className="agreement-form__navigator-container">
          <ButtonGroup
            buttonSpacing={ 10 }
            className="agreement-form__button-group-container"
            buttonsData={ map(titles, (title, idx) => {
              const finalIdx = parseInt(idx, 10)
              return {
                title: `${finalIdx + 1}. ${title.btnTitle}`,
                bsStyle: "default",
                onClick: () => currentStep !== finalIdx && this.onNavigate(finalIdx),
                className: classname("agreement-form__navigator-button", {
                  "agreement-form__navigator-button-unselected": finalIdx < currentStep,
                  "agreement-form__navigator-button-next": finalIdx === currentStep + 1,
                }),
                disabled:
                  finalIdx > currentStep + 1 ||
                  (finalIdx <= 1 && finalIdx !== currentStep) ||
                  (finalIdx >= 2 && !agreementId),
                style: finalIdx <= currentStep && { backgroundColor: this.context.primaryColor() },
              }
            }) }
          />
        </Col>
      </Row>
    )
  }
}

export default consumeSpecificAgreementForm(SpecificAgreementFormNavigator, [ "agreementId" ])
