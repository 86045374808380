import React from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import { Link } from "react-router-dom"

import { TableRow, Button } from "../../Common"
import Routes from "../../../routes"

const AdminUsersListItem = ({ data, onEdit, onDelete, onSendRegistrationEmail, canSendEmail }) => (
  <TableRow>
    <td className="col-xs-1">{`${data.first_name || ""} ${data.last_name || ""}`}</td>
    <td className="col-xs-1">{data.email}</td>
    <td className="col-xs-1">{data.role}</td>
    <td className="col-xs-1">{data.school && data.school.name}</td>
    <td className="col-xs-1">{data.status}</td>
    <td className="col-xs-4">
      <Col xs={ 4 }>
        {canSendEmail && (
          <Button block bsStyle="success" onClick={ onSendRegistrationEmail }>
            Send registration email
          </Button>
        )}
      </Col>
      <Col xs={ 4 }>
        <Link to={ Routes.ADMIN_USERS_UPDATE.replace(":id", data.id) }>
          <Button block bsStyle="primary" onClick={ onEdit }>
            Edit
          </Button>
        </Link>
      </Col>
      <Col xs={ 4 }>
        <Button block bsStyle="danger" onClick={ onDelete }>
          Delete
        </Button>
      </Col>
    </td>
  </TableRow>
)

AdminUsersListItem.propTypes = {
  data: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

AdminUsersListItem.defaultProps = {
  data: {},
  onEdit: () => true,
  onDelete: () => true,
}

export default AdminUsersListItem
