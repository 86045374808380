import Network from "../helpers/Network"

const DIRECT_UPLOAD_ROUTE = "/directupload"
const DIRECT_UPLOAD_BY_TOKEN_ROUTE = "/directupload_by_token"

export default class DirectUploadAPI {
  static getDirectUploadUrl(filename, acl, { byToken, token }) {
    return Network.get(byToken ? DIRECT_UPLOAD_BY_TOKEN_ROUTE : DIRECT_UPLOAD_ROUTE, {
      params: {
        filename,
        acl,
        token,
      },
    })
  }

  static uploadFromUrl(url, data) {
    return Network.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }
}
