import React, { Component } from "react"
import PropTypes from "prop-types"
import { Col, Row, Checkbox } from "react-bootstrap"
import find from "lodash/find"
import omit from "lodash/omit"
import i18n from "i18next"

import { Admin } from "../../../api"

import { ButtonGroup, EmailInput, FormError } from "../../Common"
import { GenericFormInput } from "../../GenericInputs"
import PhoneInput from "../../PhoneInput"

import { Roles } from "../../../Constants"
import { defaultRenderOptions } from "../../../utils"

export default class AdminUsersForm extends Component {
  static propTypes = {
    defaultData: PropTypes.object,
    isInvitation: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCloseForm: PropTypes.func,
    hasError: PropTypes.bool,
    userId: PropTypes.string,
    schools: PropTypes.array,
  }

  static defaultProps = {
    defaultData: {},
    isInvitation: false,
    onSubmit: () => true,
    onCloseForm: () => true,
    hasError: false,
    userId: null,
    schools: [],
  }

  constructor(props) {
    super(props)
    this.state = this.getInitialState()
    if (!props.isInvitation && props.userId) {
      this.retrieveUserData(props.userId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.hasError) this.setState({ hasError: nextProps.hasError })
    if (nextProps.userId !== this.props.userId) this.retrieveUserData(nextProps.userId)
    if (nextProps.schools.length > 0 && JSON.stringify(nextProps.schools) !== JSON.stringify(this.props.schools)) {
      this.setState({
        data: {
          ...this.state.data,
          school_id: !this.props.userId
            ? (nextProps.schools.length > 0 && nextProps.schools[0].id) || ""
            : this.state.data.school_id,
          department_id: "",
        },
      })
    }
  }

  async retrieveUserData(id) {
    const data = await Admin.getById(id, "users")
    this.setState({
      data: {
        ...this.state.data,
        ...data,
        department_id: (data.department && data.department.id) || "",
        school_id: data.school_id,
      },
    })
  }

  getInitialState() {
    const { defaultData, schools } = this.props
    return {
      submitBtnDisabled: true,
      hasError: false,
      data: {
        role: "school_admin",
        school_id: (schools.length > 0 && schools[0].id) || "",
        department_id: "",
        locale: "es",
        ...defaultData,
      },
    }
  }

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]:
          e.target.name === "school_id" || e.target.name === "department_id"
            ? parseInt(e.target.value, 10)
            : e.target.value,
      },
    })
  }

  handleChangeCheckbox = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.checked,
      },
    })
  }

  handlePhoneNumberChange = (name, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    })
  }

  onSubmit = (evt) => {
    const { onSubmit } = this.props
    const { data } = this.state

    evt.preventDefault()
    const finalData =
      data.role === Roles.RECRUITER || data.role === Roles.ADMIN ? omit(data, [ "department_id", "school_id" ]) : data
    onSubmit(finalData)
  }

  renderInput({ name, options, onChange = this.handleChange, ...props }) {
    const { data } = this.state

    return (
      <GenericFormInput
        name={ name }
        options={ options }
        data={ data }
        onChange={ onChange }
        renderOptions={ () =>
          defaultRenderOptions({
            options,
            keyProperty: "value",
            valueKey: "value",
            contentKey: "name",
          })
        }
        { ...props }
      />
    )
  }

  render() {
    const { onCloseForm, isInvitation, schools } = this.props
    const { data, hasError } = this.state

    const selectedSchoolData = find(schools, school => school.id === data.school_id)

    return (
      <Col xs={ 12 } sm={ 6 } smOffset={ 3 }>
        <form name="user" onSubmit={ this.onSubmit }>
          {[
            {
              isRow: true,
              children: [
                {
                  name: "first_name",
                  required: true,
                  label: "First name *",
                  placeholder: "First name",
                },
                {
                  name: "last_name",
                  required: true,
                  label: "Last name *",
                  placeholder: "Last name",
                },
              ],
            },
            {
              name: "organization_name",
              label: "Organization name",
              placeholder: "Organization name",
            },
            {
              name: "position",
              label: "Position",
              placeholder: "Position",
            },
            {
              name: "email",
              label: "Email *",
              placeholder: "Email",
              component: EmailInput,
            },
            {
              name: "phone_number",
              label: "Phone number",
              placeholder: "Phone number",
              component: PhoneInput,
              onChange: this.handlePhoneNumberChange,
            },
            {
              name: "locale",
              label: "Locale",
              placeholder: "Locale",
              componentClass: "select",
              options: [
                { value: "es", name: "Spanish" },
                { value: "ca", name: "Catalan" },
                { value: "en", name: "English" },
              ],
            },
            {
              name: "role",
              label: "Role",
              componentClass: "select",
              options: [
                { value: "school_admin", name: "School admin" },
                { value: "other_approving_party", name: "OAP" },
                { value: "recruiter", name: "Recruiter" },
                { value: "jt_admin", name: "JT Admin" },
                { value: "school_tutor", name: "School Tutor" },
              ],
            },
            {
              name: "school_id",
              label: "School",
              componentClass: "select",
              options: schools.map(school => ({
                name: school.name,
                value: school.id,
              })),
              visible: data.role === "school_admin" || data.role === "other_approving_party" || data.role === "school_tutor",
            },
            {
              name: "department_id",
              label: "Faculty / Department",
              componentClass: "select",
              options: [
                { value: "" },
                ...((schools.length > 0 &&
                  selectedSchoolData &&
                  selectedSchoolData.departments.length > 0 &&
                  selectedSchoolData.departments.map(dept => ({
                    value: dept.id,
                    name: dept.name,
                  }))) ||
                  []),
              ],
              visible: data.role === "school_admin" || data.role === "other_approving_party" || data.role === "school_tutor",
            },
            {
              name: "receive_sa_approval_request_email",
              inputComponentClass: Checkbox,
              checked: data.receive_sa_approval_request_email,
              inputClassName: "checkbox-primary",
              children: <span>Receive SA approval request email</span>,
              onChange: this.handleChangeCheckbox,
              visible: data.role === "school_admin" || data.role === "other_approving_party",
              label: "",
            },
          ].map((elem, idx) => {
            if (elem.isRow) {
              return (
                <Row key={ idx }>
                  {elem.children.map((child, index) => (
                    <Col xs={ 12 } sm={ 12 / elem.children.length } key={ index }>
                      {this.renderInput(child)}
                    </Col>
                  ))}
                </Row>
              )
            }
            if (elem.hasOwnProperty("visible") && !elem.visible) return null
            return React.cloneElement(this.renderInput(elem), { key: idx })
          })}
          <FormError visible={ hasError }>{i18n.t("spa:shared.error.bad_request")}</FormError>
          <ButtonGroup
            style={ { float: "right" } }
            buttonSpacing={ 10 }
            buttonsData={ [
              {
                title: isInvitation ? "Send invitation" : "Save",
                type: "submit",
                bsStyle: "primary",
              },
              {
                title: "Cancel",
                bsStyle: "danger",
                onClick: onCloseForm,
              },
            ] }
          />
        </form>
      </Col>
    )
  }
}
