import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import i18n from "i18next";
import { withRouter } from "react-router";
import each from "lodash/each";
import omit from "lodash/omit";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import KeyboardEventHandler from "react-keyboard-event-handler";

import { ConnectWithSidebar, withErrorManager } from "../../hoc";
import { GenericFormInput, GenericPlacesAutocomplete } from "../GenericInputs";

import { FormGroup, Alert } from "../Common";

import CompanyForm from "../CompanyForm";
import AgreementCard from "../AgreementCard";
import PrintingTemplateForm from "../PrintingTemplateForm";
import GeneralAgreementFormActionButtons from "./GeneralAgreementFormActionButtons";
import GeneralAgreementFormMainFields from "./GeneralAgreementFormMainFields";
import GeneralAgreementFormSchoolInputs from "./GeneralAgreementFormSchoolInputs";

import CompanyDebitFields from "../CompanyDebitFields";

import Session from "../../helpers/SessionHelper";

import Routes from "../../routes";

import {
  computeAgreementId,
  computeAgreementRequestedBy,
  defaultRenderOptions,
  scrollTopAnimated
} from "../../utils";

import "./GeneralAgreementsForm.css";

export class GeneralAgreementsForm extends Component {
  static propTypes = {
    agreement: PropTypes.object.isRequired,
    school: PropTypes.object.isRequired,
    agreementId: PropTypes.string,
    onUpdateSidebar: PropTypes.func,
    getAgreementData: PropTypes.func.isRequired,
    getSchoolData: PropTypes.func.isRequired,
    createAgreement: PropTypes.func.isRequired,
    editAgreement: PropTypes.func.isRequired,
    getValidationState: PropTypes.func.isRequired,
    getErrorMessage: PropTypes.func.isRequired,
    companySectors: PropTypes.array
  };

  static defaultProps = {
    schools: [],
    agreementId: null,
    companySectors: [],
    onUpdateSidebar: () => true
  };

  constructor(props) {
    super(props);
    this.state = {
      state: "company_draft",
      data: {
        school_id:
          (props.location.state && props.location.state.schoolId) || null,
        start_date: moment().toDate(),
        end_date: moment()
          .add(4, "years")
          .toDate(),
        company_id:
          props.location.state &&
          props.location.state.company &&
          props.location.state.company.company_id,
        reference: "",
        company_attributes: {
          payment_method: "direct_debit",
          payment_direct_debit_recurring: false,
          are_specific_agreements_charged: false,
          are_students_paid_by_school: false,
          ...((props.location.state &&
            props.location.state.company &&
            this.removePrefix(props.location.state.company)) ||
            {})
        }
      }
    };
    this.getValidationState = key =>
      props.getValidationState(key, this.formatCompanyKeyForError);
    this.getErrorMessage = key =>
      props.getErrorMessage(key, this.formatCompanyKeyForError);
    this.companyForm = React.createRef();
  }

  removePrefix(company) {
    const newObj = {};

    each(company, (value, key) => {
      let newKey = key;
      if (key.startsWith("company_")) {
        newKey = key.replace("company_", "");
      }
      newObj[newKey] = value;
      return true;
    });
    return newObj;
  }

  componentDidMount() {
    const { agreementId, getAgreementData, getSchoolData } = this.props;
    const { data } = this.state;

    if (!agreementId) {
      this.updateSidebar("company_draft");
      getSchoolData(data.school_id);
    } else {
      getAgreementData(agreementId);
    }
  }

  formatCompanyKeyForError(companyKey) {
    let key = companyKey;

    if (key.startsWith("company_")) {
      key = key.replace("company_", "company.");
    } else if (!key.startsWith("company.")) {
      key = `company.${key}`;
    }
    return key;
  }

  updateSidebar(state) {
    const { onUpdateSidebar } = this.props;

    onUpdateSidebar(state, "general_agreement");
  }

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.agreement.data) !==
      JSON.stringify(this.props.agreement.data)
    ) {
      if (nextProps.agreement.data.general_agreement) {
        this.updateSidebar(nextProps.agreement.data.general_agreement.state);
        this.setState({
          state: nextProps.agreement.data.general_agreement.state,
          data: {
            ...this.state.data,
            ...omit(nextProps.agreement.data.general_agreement, [
              "company",
              "state"
            ]),
            start_date: moment(
              nextProps.agreement.data.general_agreement.start_date
            ).toDate(),
            end_date: moment(
              nextProps.agreement.data.general_agreement.end_date
            ).toDate(),
            general_agreement_printing_template_id: this.getDefaultPrintingTemplateId(
              nextProps.agreement.data.general_agreement
            ),
            company_attributes: {
              ...nextProps.agreement.data.general_agreement.company
            }
          }
        });
      }
    }
    if (!isEmpty(nextProps.agreement.editErrors)) {
      scrollTopAnimated(
        document.querySelector("#main-container > .clearfix.col-xs-12"),
        2000
      );
    }
  }

  areFieldsEditable() {
    const { agreement, agreementId } = this.props;

    if (!Session.isSchoolAdmin() && agreementId && !agreement.data.update_url) {
      return false;
    }
    return true;
  }

  handleDataChange = (name, value) => {
    if (name === "start_date") {
      this.setState({
        data: {
          ...this.state.data,
          [name]: value,
          end_date: moment(value)
            .add(4, "years")
            .toDate()
        }
      });
    } else {
      this.setState({
        data: {
          ...this.state.data,
          [name]: value
        }
      });
    }
  };

  handleCompanyDataChange = (name, value) => {
    if (name) {
      name = name.replace("company_", "")
    }

    this.setState({
      data: {
        ...this.state.data,
        company_attributes: {
          ...this.state.data.company_attributes,
          [name]: value
        }
      }
    });
  };

  handleInputChange = e => {
    this.handleCompanyDataChange(e.target.name, e.target.value);
  };

  handleCheckboxChange = e => {
    this.handleCompanyDataChange(e.target.name, e.target.checked);
  };

  getDefaultPrintingTemplateId(agreement) {
    const { data } = this.state;

    let selectedTemplateId = null;
    if (data.general_agreement_printing_template_id) {
      selectedTemplateId = data.general_agreement_printing_template_id;
    } else if (agreement.general_agreement_printing_template_id) {
      selectedTemplateId = agreement.general_agreement_printing_template_id;
    } else if (
      agreement.school.general_agreement_printing_templates.length > 0
    ) {
      const defaultTemplate = find(
        agreement.school.general_agreement_printing_templates,
        template => template.is_default
      );
      if (defaultTemplate) {
        selectedTemplateId = defaultTemplate.id;
      } else {
        selectedTemplateId =
          agreement.school.general_agreement_printing_templates[0].id;
      }
    }
    return selectedTemplateId;
  }

  renderInvoicingPlacesAutocomplete = () => {
    const { company_attributes: data } = this.state.data;

    return (
      <GenericPlacesAutocomplete
        keyPrefix="invoicing"
        getValidationState={this.getValidationState}
        getErrorMessage={this.getErrorMessage}
        data={data}
        commonInputProps={{
          onChange: this.handleInputChange,
          withLabel: true,
          disabled: !this.areFieldsEditable()
        }}
        onChange={this.handleCompanyDataChange}
        baseTranslationKey="general_agreements.form.fields"
        useFallback={false}
      />
    );
  };

  renderInputs = inputProps => {
    const { company_attributes: data } = this.state.data;
    const { name, visible, options, ...props } = inputProps;

    return (
      <GenericFormInput
        name={name}
        options={options}
        data={data}
        getValidationState={this.getValidationState}
        getErrorMessage={this.getErrorMessage}
        onChange={this.handleInputChange}
        editable={this.areFieldsEditable()}
        baseTranslationKey="general_agreements.form.fields"
        renderOptions={() =>
          defaultRenderOptions({
            options,
            keyProperty: "value",
            valueKey: "value",
            contentKey: "name"
          })
        }
        {...props}
      />
    );
  };

  renderCompanyAdditionalInputs() {
    const { company_attributes: data } = this.state.data;

    return (
      <CompanyDebitFields
        data={data}
        handleCheckboxChange={this.handleCheckboxChange}
        onChange={this.handleInputChange}
        editable={this.areFieldsEditable()}
        onChangePlace={this.handleCompanyDataChange}
        getValidationState={this.getValidationState}
        getErrorMessage={this.getErrorMessage}
        handlePhoneNumberChange={this.handleCompanyDataChange}
      />
    );
  }

  renderSchoolAdditionalInputs() {
    const { company_attributes: data } = this.state.data;

    return (
      <GeneralAgreementFormSchoolInputs
        renderInputs={this.renderInputs}
        data={data}
        handleCheckboxChange={this.handleCheckboxChange}
      />
    );
  }

  async getFinalData() {
    const logoUrl = await this.getAvatarUrl();
    const data = omit(this.state.data, "state");
    return {
      ...data,
      company_attributes: {
        ...data.company_attributes,
        logo_url: logoUrl
      }
    };
  }

  getStateUpdateUrl() {
    const { state } = this.state;
    const { agreement } = this.props;
    const {
      review_url: reviewUrl,
      activate_url: activateUrl,
      send_for_signature_url: sendForSignatureUrl
    } = agreement.data;

    switch (state) {
      case "company_draft":
        return reviewUrl;
      case "school_review":
        return sendForSignatureUrl;
      case "sent_for_signature":
        return activateUrl;
      default:
        return null;
    }
  }

  getAvatarUrl = async () => {
    const url = await this.companyForm.current.triggerAvatarUpload();
    return url;
  };

  onSave = async () => {
    const { createAgreement, editAgreement, agreement, location } = this.props;
    const { data } = this.state;
    const { update_url: updateUrl } = agreement.data;
    let finalData = await this.getFinalData();
    if (data.id) {
      editAgreement(finalData, updateUrl);
    } else {
      finalData.specific_agreement_id = location.state.specificAgreementId;
      createAgreement(finalData);
    }
  };

  onSend = async () => {
    const { createAgreement, editAgreement, agreement } = this.props;
    const { data } = this.state;
    const { update_url: updateUrl } = agreement.data;
    const finalData = await this.getFinalData();
    if (data.id) {
      editAgreement(finalData, updateUrl, this.getStateUpdateUrl());
    } else {
      createAgreement(finalData, true);
    }
  };

  renderActionButtons() {
    const { agreement, history, agreementId, cancelGeneralAgreement } = this.props;
    const { data, state } = this.state;
    const {
      update_url: updateUrl,
      general_agreement: generalAgreement
    } = agreement.data;

    return (
      <GeneralAgreementFormActionButtons
        canSave={!data.id || updateUrl}
        canSend={
          !(Session.isSchoolAdmin() && !(data.id || updateUrl)) &&
          (!data.id || updateUrl) &&
          state !== "active"
        }
        canPrint={
          (Session.isRecruiter() &&
            generalAgreement &&
            generalAgreement.state === "active") ||
          Session.isSchoolAdmin()
        }
        canCancel={!!data.id && generalAgreement.state !== "cancelled" && Session.isSchoolAdmin()}
        state={state}
        onSave={this.onSave}
        onSend={this.onSend}
        onPrint={() =>
          history.push(
            Routes.GENERAL_AGREEMENT_PRINTING_TEMPLATE_PREVIEW.replace(
              ":id",
              agreementId
            )
          )
        }
        onCancel={() => {
          if (
            window.confirm(
              `Are you sure you want to cancel general agreement with ID ${agreementId} ?`
            )
          ) {
            cancelGeneralAgreement(agreementId);
          }
        }}
      />
    );
  }

  renderGeneralAgreementFields() {
    const { data } = this.state;

    return (
      <GeneralAgreementFormMainFields
        handleChange={this.handleDataChange}
        editable={this.areFieldsEditable()}
        data={data}
      />
    );
  }

  renderPrintingTemplateSelection() {
    const { history, agreementId, savePrintingTemplate } = this.props;
    const {
      general_agreement: agreement,
      print_url: printUrl,
      update_url: updateUrl
    } = this.props.agreement.data;
    const { data: school } = this.props.school;
    const { data } = this.state;

    if (!Session.isSchoolAdmin()) return null;

    const selectedTemplateId =
      agreement && this.getDefaultPrintingTemplateId(agreement);

    return (
      <FormGroup
        formTitle={i18n.t(
          "spa:general_agreements.form.titles.general_agreement_printing_template"
        )}
        className="general-agreement-form__form"
      >
        <PrintingTemplateForm
          templates={
            (school && school.general_agreement_printing_templates) ||
            (agreement && agreement.school.general_agreement_printing_templates)
          }
          inputName="general_agreement_printing_template_id"
          selectedId={selectedTemplateId}
          onChangeTemplate={e =>
            this.handleDataChange(e.target.name, e.target.value)
          }
          onPreview={() => savePrintingTemplate(data, updateUrl)}
          withPreviewButton={!!printUrl}
          disabled={!this.areFieldsEditable()}
        />
      </FormGroup>
    );
  }

  getAgreementStateForCard() {
    const { state, data } = this.state;
    const { end_date: endDate } = data;

    return i18n.t(`spa:card.general_agreement_state.${state}.badge`, {
      end_date: moment(endDate).format("DD/MM/YYYY"),
      interpolation: { escapeValue: false }
    });
  }

  getCardTitle() {
    const { agreement, location } = this.props;
    const { company_attributes: companyAttributes } = this.state.data;

    const companyName = companyAttributes.name;
    let schoolName;
    if (!location.state && !agreement.data.general_agreement) {
      return "";
    } else if (location.state && location.state.schoolName) {
      schoolName = location.state.schoolName;
    } else if (agreement.data.general_agreement.school) {
      schoolName = agreement.data.general_agreement.school.name;
    }
    return `${schoolName} - ${companyName}`;
  }

  render() {
    const { data, state } = this.state;
    const {
      agreementId,
      agreement,
      companySectors,
      cancelGeneralAgreement,
      undoCancelGeneralAgreement
    } = this.props;

    return (
      <div className="general-agreement-form__container">
        <KeyboardEventHandler
          handleKeys={["ctrl+s", "meta+s"]}
          handleFocusableElements
          onKeyEvent={(key, e) => {
            e.preventDefault();
            this.onSave();
          }}
        />
        <AgreementCard
          title={this.getCardTitle()}
          agreementId={
            agreement.data.general_agreement &&
            computeAgreementId({
              agreementId,
              createdAt: agreement.data.general_agreement.created_at
            })
          }
          agreementState={this.getAgreementStateForCard()}
          agreementStateBadgeStyle={
            state === "company_draft" || state === "expired" || state === "cancelled"
              ? "danger"
              : "success"
          }
          requestBy={
            agreement.data.general_agreement &&
            computeAgreementRequestedBy(agreement.data.general_agreement.user)
          }
          showDeleteButton={true}
          onDelete={() => {
            if (
              window.confirm(
                `Are you sure you want to cancel general agreement with ID ${agreementId} ?`
              )
            ) {
              cancelGeneralAgreement(agreementId);
            }
          }}
          showUndoCancelButton={state==="cancelled"}
          onUndoCancel={() => {
            if (
              window.confirm(
                `Are you sure you want to undo cancellation for general_agreement with ID ${agreementId} ?`
              )
            ) {
              undoCancelGeneralAgreement(agreementId);
            }
          }}
        />
        {!isEmpty(agreement.errors) && (
          <Alert type="danger" style={{ marginBottom: 0 }}>
            <span>
              {i18n.t("spa:general_agreements.form.alerts.has_error")}
            </span>
          </Alert>
        )}
        <Row>
          <Col xs={12} sm={7}>
            {this.renderPrintingTemplateSelection()}
            <FormGroup
              formTitle={i18n.t("spa:general_agreements.form.titles.company")}
              className="general-agreement-form__form"
            >
              <Col xs={12} sm={10}>
                <CompanyForm
                  ref={this.companyForm}
                  mode="form"
                  onChange={this.handleCompanyDataChange}
                  defaultData={data.company_attributes}
                  getValidationState={this.getValidationState}
                  getErrorMessage={this.getErrorMessage}
                  editable={this.areFieldsEditable()}
                  companySectors={companySectors}
                  showAvatarInput
                />
                {Session.isSchoolAdmin() && this.renderGeneralAgreementFields()}
                {(Session.isRecruiter() || Session.isSchoolAdmin()) &&
                  this.renderCompanyAdditionalInputs()}
                {Session.isSchoolAdmin() && this.renderSchoolAdditionalInputs()}
              </Col>
            </FormGroup>
            {this.renderActionButtons()}
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(
  ConnectWithSidebar(
    withErrorManager(props => props.agreement.errors)(GeneralAgreementsForm)
  )
);
