import PropTypes from "prop-types"
import i18n from "i18next"

import { generateButtonGroup } from "./SpecificAgreementActionButtonsHelper"
import { generateDataButton } from "../../utils"

const ButtonsApproved = ({
  canSave,
  onSave,
  canSendForSignature,
  onSendForSignature,
  canPrint,
  onPrint,
  hasElectronicSignature,
}) => {
  const finalDataButtons = []
  if (canSave) {
    finalDataButtons.push(
      generateDataButton(i18n.t("spa:specific_agreements.form.buttons.shared.save"), onSave, "success")
    )
  }
  if (canPrint) {
    finalDataButtons.push(
      generateDataButton(i18n.t("spa:specific_agreements.form.buttons.shared.print"), onPrint, "success")
    )
  }
  if (canSendForSignature && !hasElectronicSignature) {
    finalDataButtons.push(
      generateDataButton(
        i18n.t("spa:specific_agreements.form.buttons.approved.send_for_signature"),
        onSendForSignature,
        "primary"
      )
    )
  }

  return generateButtonGroup(finalDataButtons)
}

ButtonsApproved.propTypes = {
  canSave: PropTypes.bool,
  onSave: PropTypes.func,
  canSendForSignature: PropTypes.bool,
  onSendForSignature: PropTypes.func,
  canPrint: PropTypes.bool,
  onPrint: PropTypes.func,
}

ButtonsApproved.defaultProps = {
  canSave: true,
  onSave: () => true,
  canSendForSignature: true,
  onSendForSignature: () => true,
  canPrint: true,
  onPrint: () => true,
}

export default ButtonsApproved
