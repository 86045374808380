import React, { Component } from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import { Route, Switch } from "react-router"
import { Link } from "react-router-dom"

import { Icon, Button } from "../../Common"

import AdminSchoolsList from "./AdminSchoolsList"
import AdminSchoolsFrom from "./AdminSchoolsForm"

import Routes from "../../../routes"

import "./AdminSchoolsManagement.css"

export default class AdminSchoolsManagement extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    getSchoolsList: PropTypes.func.isRequired,
    createSchool: PropTypes.func.isRequired,
    editSchool: PropTypes.func.isRequired,
    schoolsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  constructor() {
    super()
    this.state = {
      selectedSchoolData: {},
    }
  }

  componentDidMount() {
    this.getSchoolsList()
  }

  displaySchoolForm(data) {
    const { history } = this.props
    if (data.id) {
      this.setState({ selectedSchoolData: data }, () =>
        history.push(Routes.ADMIN_SCHOOLS_UPDATE.replace(":id", data.id))
      )
    }
  }

  closeSchoolForm() {
    const { history } = this.props

    history.push(Routes.ADMIN_SCHOOLS)
  }

  getSchoolsList() {
    const { getSchoolsList } = this.props

    getSchoolsList()
  }

  onSubmit(data) {
    const { editSchool, createSchool, location } = this.props

    if (location.pathname === Routes.ADMIN_SCHOOLS_CREATE) createSchool(data)
    else editSchool(data)
  }

  render() {
    const { editionRequestData, schoolsList, logout } = this.props
    const { selectedSchoolData } = this.state
    const { errors } = editionRequestData

    return (
      <Col xs={ 12 } className="admin-schools-management-container">
        <div className="logout-button-container" onClick={ logout }>
          <Icon iconName="power-off" iconSize={ 20 } />
        </div>
        <Switch>
          <Route
            exact
            path={ Routes.ADMIN_SCHOOLS }
            render={ () => (
              <Col xs={ 12 }>
                <Col xs={ 12 }>
                  <h3 style={ { textAlign: "center" } }>Schools list</h3>
                  <Link to={ Routes.ADMIN_SCHOOLS_CREATE }>
                    <Button bsStyle="primary" style={ { float: "right" } }>
                      <Icon iconName="plus" /> Create new school
                    </Button>
                  </Link>
                </Col>
                <Col xs={ 12 } className="admin-schools-list-container">
                  <AdminSchoolsList
                    onSchoolSelected={ userData => this.displaySchoolForm(userData) }
                    schools={ schoolsList }
                  />
                </Col>
              </Col>
            ) }
          />
          <Route
            path={ Routes.ADMIN_SCHOOLS_UPDATE }
            render={ ({ match }) => (
              <Col xs={ 12 } className="admin-schools-management-form">
                <h3 style={ { textAlign: "center" } }>School form</h3>
                <AdminSchoolsFrom
                  defaultData={ (match.params.id !== "new" && selectedSchoolData) || undefined }
                  onCloseForm={ () => this.closeSchoolForm() }
                  isCreation={ match.params.id === "new" }
                  onSubmit={ data => this.onSubmit(data) }
                  schoolId={ match.params.id }
                  errors={ errors }
                />
              </Col>
            ) }
          />
        </Switch>
      </Col>
    )
  }
}
