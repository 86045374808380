import React, { Component } from "react"
import PropTypes from "prop-types"
import classname from "classnames"
import isArray from "lodash/isArray"

import defaultLogo from "../../assets/company_logo.png"

import { Avatar } from "../Common"

import "./SchoolForm.css"

export default class SchoolForm extends Component {
  static propTypes = {
    mode: PropTypes.oneOf([ "select", "form" ]),
    onSelectSchool: PropTypes.func,
    schoolData: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.object), PropTypes.object ]),
  }

  static defaultProps = {
    mode: "select",
    onSelectSchool: () => true,
    schoolData: null,
  }

  constructor() {
    super()
    this.state = {
      selectedSchool: {},
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.mode !== this.props.mode ||
      JSON.stringify(nextProps.schoolData) !== JSON.stringify(this.props.schoolData) ||
      JSON.stringify(nextState.selectedSchool) !== JSON.stringify(this.state.selectedSchool)
    )
  }

  renderSelect() {
    const { onSelectSchool, schoolData } = this.props
    const dataIsArray = isArray(schoolData)

    return (
      <div className="school-form-select__container">
        {dataIsArray &&
          schoolData.map(school => (
            <div
              className={ classname("school-form-select__item-container", {
                selected: school.id === this.state.selectedSchool.id,
              }) }
              onClick={ () => {
                onSelectSchool(school)
                this.setState({ selectedSchool: school })
              } }
              key={ school.id }
            >
              <div className="school-form-select__logo-container">
                <Avatar src={ school.logo_url || defaultLogo } size={ 80 } rounded={ false } />
              </div>
              <div className="school-form-select__short-name">
                <span>{school.short_name}</span>
              </div>
            </div>
          ))}
      </div>
    )
  }

  render() {
    const { mode } = this.props

    switch (mode) {
      case "select":
        return this.renderSelect()
      default:
        return null
    }
  }
}
