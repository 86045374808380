import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import { Col } from "react-bootstrap"
import moment from "moment"

import { Modal, Icon, FormInput, ButtonGroup, DatePicker } from "../Common"

export default class SpecificAgreementExtensionModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    alteration: PropTypes.oneOf([ "modification", "extension" ]).isRequired,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    studentFullName: PropTypes.string,
    reasonForAlteration: PropTypes.string,
    jobRealEndDate: PropTypes.string,
    alterationStatus: PropTypes.string,
  }

  static defaultProps = {
    isOpen: false,
    onSubmit: () => true,
    onCancel: () => true,
    studentFullName: "",
    reasonForAlteration: "",
    jobRealEndDate: undefined,
    alterationStatus: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      reason_for_alteration: props.reasonForAlteration,
      job_real_ended_at: props.jobRealEndDate || moment().toDate(),
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
      // eslint-disable-next-line
      this.setState({ reason_for_alteration: this.props.reasonForAlteration })
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleDayChange = (day) => {
    this.setState({
      job_real_ended_at: day,
    })
  }

  resetText() {
    this.setState({ reason_for_alteration: "", job_real_ended_at: moment().toDate() })
  }

  onSubmit = () => {
    this.resetText()
    this.props.onSubmit(this.state.reason_for_alteration, this.state.job_real_ended_at)
  }

  onCancel = () => {
    this.resetText()
    this.props.onCancel()
  }

  submitDisabled = () => {
    return !(
      this.state.reason_for_alteration &&
      this.state.reason_for_alteration.length > 0
    );
  };

  render() {
    const { isOpen, alteration, studentFullName, alterationStatus } = this.props
    const { reason_for_alteration: reason, job_real_ended_at: endDate } = this.state

    let submitButtonLabel = i18n.t(
      "spa:specific_agreements.school_panel.alteration.fields.shared.buttons.create_new_version"
    )
    let submitButtonStyle = "primary"
    let modalTitleLabel = alteration === "modification" ? "create_modified_agreement" : "create_agreement_extension"
    if (alterationStatus && alterationStatus.length > 0) {
      modalTitleLabel = alteration === "modification" ? "modified_agreement" : "extended_agreement"
      submitButtonLabel = i18n.t(
        "spa:specific_agreements.school_panel.alteration.fields.shared.buttons.update"
      )
      submitButtonStyle = "success"
    }

    return (
      <Modal isOpen={ isOpen } className="specific-agreement-alteration__modal">
        <Modal.Title>
          <Icon
            iconName={ alteration === "modification" ? "files-o" : "clock-o" }
            className="specific-agreement-alteration__icon-title"
          />
          {i18n.t(
            `spa:specific_agreements.school_panel.alteration.${
              modalTitleLabel
            }`
          )}
        </Modal.Title>
        <Modal.Content>
          <Col xs={ 12 } className="specific-agreement-alteration__modal-extension-content">
            {(!alterationStatus || alterationStatus.length === 0) && (
              <React.Fragment>
                <span>
                  {i18n.t(`spa:specific_agreements.school_panel.alteration.${alteration}_title`, {
                    student_name: studentFullName,
                  })}
                </span>
                <br />
                <br />
              </React.Fragment>
            )}
            <DatePicker
              inputProps={ {
                name: "job_real_ended_at",
                withLabel: true,
                label: `${i18n.t("spa:specific_agreements.school_panel.alteration.fields.real_end_date.label")} :`,
              } }
              defaultValue={ endDate }
              onDayChange={ this.handleDayChange }
            />
            <FormInput
              name="reason_for_alteration"
              value={ reason }
              withLabel
              label={ `* ${i18n.t("spa:specific_agreements.school_panel.alteration.fields.reason.label")} :` }
              onChange={ this.handleInputChange }
              componentClass="textarea"
              rows={ 6 }
            />
          </Col>
        </Modal.Content>
        <Modal.Footer>
          <Col xs={ 12 }>
            <ButtonGroup
              buttonSpacing={ 10 }
              buttonsData={ [
                {
                  title: i18n.t("spa:specific_agreements.school_panel.alteration.fields.shared.buttons.cancel"),
                  onClick: this.onCancel,
                  bsStyle: "default",
                },
                {
                  title: submitButtonLabel,
                  onClick: this.onSubmit,
                  bsStyle: submitButtonStyle,
                  disabled: this.submitDisabled,
                },
              ] }
            />
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }
}
