import React from "react"
import { connect } from "react-redux"
import { Switch, Route } from "react-router"

import Routes from "../routes"

import CompanyEdition from "../components/CompanyEdition"

import {
  COMPANY_DATA_REQUEST,
  COMPANY_UPDATE_REQUEST,
  COMPANY_CREATE_REQUEST,
  GET_COMPANY_SECTORS_REQUEST,
} from "../types"

const CompanyEditionContainer = ({ ...props }) => (
  <Switch>
    <Route exact path={ Routes.COMPANY_CREATION } render={ () => <CompanyEdition { ...props } /> } />
    <Route
      exact
      path={ Routes.COMPANY_EDITION }
      render={ ({ match }) => <CompanyEdition id={ match.params.id } { ...props } /> }
    />
  </Switch>
)

const mapStateToProps = state => ({
  companyData: state.company.companyData,
  errors: state.company.editionErrors,
  companySectors: state.jt.companySectors,
})

const mapDispatchToProps = dispatch => ({
  getCompanyData: id => dispatch({ type: COMPANY_DATA_REQUEST, payload: { id } }),
  updateCompany: (id, data) => dispatch({ type: COMPANY_UPDATE_REQUEST, payload: { id, data } }),
  createCompany: data => dispatch({ type: COMPANY_CREATE_REQUEST, payload: { data } }),
  getCompanySectors: () => dispatch({ type: GET_COMPANY_SECTORS_REQUEST, payload: {} }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyEditionContainer)
