import React, { Component } from "react"
import PropTypes from "prop-types"
import i18n from "i18next"

import TopBar from "../TopBar"
import { List } from "../Common"

import SpecificAgreementsListItem from "./SpecificAgreementsListItem"

import { ConnectWithSidebar, withListPagination } from "../../hoc"

import Routes from "../../routes"

import Session from "../../helpers/SessionHelper"

import "./SpecificAgreementsList.css"

export class SpecificAgreementsList extends Component {
  static propTypes = {
    agreements: PropTypes.array.isRequired,
    fetchAgreements: PropTypes.func.isRequired,
    onUpdateSidebar: PropTypes.func,
    category: PropTypes.string,
    subcategory: PropTypes.string,
    count: PropTypes.object,
  }

  static defaultProps = {
    onUpdateSidebar: () => true,
    category: "",
    subcategory: "",
    count: {},
  }

  listTitles = [
    i18n.t("spa:specific_agreements.index.header.name"),
    Session.isSchoolAdmin() || Session.isOAP() ? i18n.t("spa:specific_agreements.index.header.tags") : null,
    i18n.t("spa:specific_agreements.index.header.company"),
    i18n.t("spa:specific_agreements.index.header.job_dates"),
    !(Session.isSchoolAdmin() || Session.isOAP()) ? i18n.t("spa:specific_agreements.index.header.school") : null,
    i18n.t("spa:specific_agreements.index.header.student"),
    Session.isSchoolAdmin() || Session.isOAP() ? i18n.t("spa:specific_agreements.index.header.assigned_to") : null,
  ]

  componentDidMount() {
    const { type } = this.getType()
    const { currentPage, history } = this.props

    if (history.location.search === "" || history.location.search.includes("page=1")) {
      this.getList(type, currentPage)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { type } = this.getType()
    const { type: newType } = nextProps.match.params

    const { currentPage } = this.props
    const { currentPage: newCurrentPage } = nextProps

    if (type !== newType || currentPage !== newCurrentPage) {
      this.getList(newType, newCurrentPage)
    }
  }

  getList(type, page) {
    const { fetchAgreements, onUpdateSidebar } = this.props

    onUpdateSidebar(type)
    fetchAgreements(type, page)
  }

  getType() {
    const { match } = this.props
    const { type } = match.params

    return { type }
  }

  render() {
    const { agreements, count, match, history, category, subcategory, currentPage, changePage } = this.props

    return (
      <div>
        <TopBar
          canCreateSpecificAgreement={ Session.isRecruiter() || Session.isSchoolAdmin() }
          title={ subcategory }
          subtitle={ category }
        />
        <div className="agreements-list-container">
          <List
            className="agreements-list-table"
            currentPage={ currentPage }
            changePage={ changePage }
            totalCount={ count[match.params.type] }
            titlesClassName="agreements-list-table-title"
            titlesContainerClassName="agreements-list-table-header"
            data={ agreements.data }
            isLoading={ agreements.isLoading }
            titles={ this.listTitles }
            renderRow={ item => (
              <SpecificAgreementsListItem
                item={ item }
                key={ item.id }
                onClick={ () => history.push(Routes.SPECIFIC_AGREEMENT_SHOW.replace(":id", item.id)) }
              />
            ) }
          />
        </div>
      </div>
    )
  }
}

export default ConnectWithSidebar(withListPagination(SpecificAgreementsList))
