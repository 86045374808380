import React from "react"
import { connect } from "react-redux"

import { SIGNIN_REQUEST } from "../types"
import Signin from "../components/Signin"

const SigninContainer = ({ signin, ...props }) => <Signin onSignin={ signin } { ...props } />

const mapDispatchToProps = dispatch => ({
  signin: (email, password) => dispatch({ type: SIGNIN_REQUEST, payload: { email, password } }),
})

const mapStateToProps = nextState => ({
  signinData: nextState.signin.signinRequestData,
})

export default connect(mapStateToProps, mapDispatchToProps)(SigninContainer)
