import React from "react"
import PropTypes from "prop-types"
import classname from "classnames"

import Icon from "./Icon"

const Activity = ({ iconName, content, bgStyle, header, badgeClassName }) => (
  <div className="stream">
    <div className="stream-badge">
      <Icon iconName={ iconName } componentClass="i" className={ classname(bgStyle, badgeClassName) } />
    </div>
    <div className="stream-panel">
      {header && <div className="stream-info">{header}</div>}
      <span dangerouslySetInnerHTML={ { __html: content } } />
    </div>
  </div>
)

Activity.propTypes = {
  iconName: PropTypes.string.isRequired,
  content: PropTypes.string,
  bgStyle: PropTypes.string,
  header: PropTypes.string,
  badgeClassName: PropTypes.string,
}

Activity.defaultProps = {
  content: "",
  bgStyle: null,
  header: null,
  badgeClassName: "",
}

export default Activity
