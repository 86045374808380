import { GeneralAgreements } from "../api"

export async function getAgreements(type) {
  try {
    const data = await GeneralAgreements.getAgreements(type)
    return data
  } catch (e) {
    throw e
  }
}

export async function getAgreement(id) {
  try {
    const data = await GeneralAgreements.getAgreement(id)
    return data
  } catch (e) {
    throw e
  }
}

export async function createAgreement(data) {
  try {
    const dataCreate = await GeneralAgreements.createAgreement(data)
    return dataCreate
  } catch (e) {
    throw e
  }
}

export async function editAgreement(data, url) {
  try {
    const dataEdit = await GeneralAgreements.editAgreement(data, url)
    return dataEdit
  } catch (e) {
    throw e
  }
}

export async function updateAgreementState(url) {
  try {
    const dataUpdate = await GeneralAgreements.updateState(url)
    return dataUpdate
  } catch (e) {
    throw e
  }
}

export async function cancelAgreement(id) {
  try {
    const data = await GeneralAgreements.cancelAgreement(id)
    return data
  } catch (e) {
    throw e
  }
}
