import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import i18n from "i18next"
import { GET_SPECIFIC_AGREEMENTS_REQUEST, SET_SPECIFIC_AGREEMENTS_FILTERS } from "../types"

import CountriesFilter from "../components/CountriesFilter"

class CompanyCountriesListContainer extends React.Component {
  render() {
    const { companyCountriesFilters, setCompanyCountriesFilters, triggerSpecificAgreementListFetch, match, ...props } = this.props

    return (
      <CountriesFilter
        onChange={ (value) => {
          setCompanyCountriesFilters(value.map(country => country.value))
          setImmediate(() => triggerSpecificAgreementListFetch(match.params.type))
        } }
        selectedCountries={ companyCountriesFilters }
        noOptions={ i18n.t("spa:header.company_countries_filter.no_options") }
        placeholder={ i18n.t("spa:header.company_countries_filter.placeholder") }
        { ...props }
      />
    )
  }
}

const mapStateToProps = state => ({
  companyCountriesFilters: state.jt.specificAgreementsFilters.companyCountries,
})

const mapDispatchToProps = dispatch => ({
  setCompanyCountriesFilters: filters => dispatch({ type: SET_SPECIFIC_AGREEMENTS_FILTERS, payload: { companyCountries: filters } }),
  triggerSpecificAgreementListFetch: type => dispatch({ type: GET_SPECIFIC_AGREEMENTS_REQUEST, payload: { type } }),
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyCountriesListContainer))
