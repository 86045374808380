import React, { Component } from "react"
import GenericFilter from "../GenericFilter"
import countries from "../../countries.json"
import Session from "../../helpers/SessionHelper"

export default class CountriesFilter extends Component {
  constructor() {
    super()
    this.options = this.formatOptions()
  }

  formatOptions() {
    return Object.keys(countries).sort((a, b) =>
      countries[a][Session.locale || "en"].localeCompare(countries[b][Session.locale || "en"]))
      .map((country) => {
        const countryLabel = countries[country][Session.locale || "en"]
        return ({ label: countryLabel, value: country })
      })
  }

  formatValues() {
    const { selectedCountries } = this.props
    return this.options.filter(country => selectedCountries.includes(country.value))
  }

  render() {
    return (
      <GenericFilter
        options={ this.options }
        getOptionValue={ option => option.value }
        getOptionLabel={ option => option.label }
        value={ this.formatValues() }
        { ...this.props }
      />
    )
  }
}
