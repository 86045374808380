import { Admin } from "../api"

export async function getList(type) {
  try {
    return await Admin.getList(type)
  } catch (e) {
    throw e
  }
}

export async function edit(id, data, type) {
  try {
    return await Admin.edit(id, data, type)
  } catch (e) {
    throw e
  }
}

export async function create(data, type) {
  try {
    return await Admin.create(data, type)
  } catch (e) {
    throw e
  }
}

export async function remove(id, type) {
  try {
    return await Admin.remove(id, type)
  } catch (e) {
    throw e
  }
}

export async function unsetAlterationStatus(id, type) {
  try {
    return await Admin.unsetAlterationStatus(id, type)
  } catch (e) {
    throw e
  }
}

export async function undoCancel(id, type) {
  try {
    return await Admin.undoCancel(id, type)
  } catch (e) {
    throw e
  }
}

export async function getById(id, type) {
  try {
    return await Admin.getById(id, type)
  } catch (e) {
    throw e
  }
}

export async function sendInvitationEmail(id, redirectUrl) {
  try {
    return await Admin.sendInvitationEmail(id, redirectUrl)
  } catch (e) {
    throw e
  }
}

export async function createSchoolTemplateAttributes(file, attributes, type) {
  try {
    return await Admin.createSchoolTemplateAttributes(file, attributes, type)
  } catch (e) {
    throw e
  }
}

export async function updateSchoolTemplateAttributes(file, attributes, type) {
  try {
    return await Admin.updateSchoolTemplateAttributes(file, attributes, type)
  } catch (e) {
    throw e
  }
}

export async function removeSchoolTemplateAttributes(id, type) {
  try {
    return await Admin.removeSchoolTemplateAttributes(id, type)
  } catch (e) {
    throw e
  }
}
