import React from "react"
import PropTypes from "prop-types"
import Autocomplete from "react-autocomplete"
import i18n from "i18next"

import AutocompletionItem from "./AutocompletionItem"

const getItemValue = item => `${item.first_name} ${item.last_name}`

const ApprovalAutocomplete = ({
  items,
  inputValue,
  onBlur,
  onFocus,
  onSelect,
  onChange,
  autocompleteRef,
  menuWidth,
  ...inputProps
}) => (
  <Autocomplete
    ref={ autocompleteRef }
    items={ items }
    getItemValue={ getItemValue }
    value={ inputValue }
    wrapperStyle={ { display: "inline-block", position: "relative" } }
    renderItem={ item => <AutocompletionItem key={ item.id } item={ item } /> }
    menuStyle={ {
      boxShadow: Autocomplete.defaultProps.menuStyle.boxShadow,
      backgroundColor: "white",
      maxHeight: 250,
      width: menuWidth,
      position: "absolute",
      top: 40,
      left: -6,
      zIndex: 9999,
    } }
    inputProps={ {
      ...inputProps,
      placeholder: i18n.t("spa:specific_agreements.form.fields.other_approving_parties.placeholder"),
      onBlur,
      onFocus,
      className: "approval-autocomplete__form-input",
    } }
    onChange={ onChange }
    onSelect={ onSelect }
  />
)

ApprovalAutocomplete.propTypes = {
  items: PropTypes.array,
  inputValue: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  autocompleteRef: PropTypes.func,
  menuWidth: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
}

ApprovalAutocomplete.defaultProps = {
  items: [],
  inputValue: "",
  onBlur: () => true,
  onFocus: () => true,
  onChange: () => true,
  onSelect: () => true,
  autocompleteRef: null,
  menuWidth: "100%",
}

export default ApprovalAutocomplete
