import Network from "../helpers/Network"

function getRouteFromType(type) {
  return `/${type}`
}

function getSchoolTemplateAttributeRouteFromType(type) {
  return `/${type}_printing_templates`
}

function createTemplateAttributesFormData(file, data, type) {
  const formData = new FormData()
  formData.append("file", file)
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })
  return formData
}

export default class AdminAPI {
  static getList(type) {
    return Network.get(getRouteFromType(type))
  }

  static edit(id, data, type) {
    return Network.put(`${getRouteFromType(type)}/${id}`, {
      [type === "users" ? "user" : "school"]: data,
    })
  }

  static create(data, type) {
    return Network.post(`${getRouteFromType(type)}`, {
      [type === "users" ? "user" : "school"]: data,
      redirect_url: `${window.location.origin}/signup/invitation`,
    })
  }

  static remove(id, type) {
    return Network.delete(`${getRouteFromType(type)}/${id}`)
  }

  static unsetAlterationStatus(id, type) {
    return Network.put(`${getRouteFromType(type)}/${id}/unset_alteration_status`)
  }

  static undoCancel(id, type) {
    return Network.put(`${getRouteFromType(type)}/${id}/undo_cancel`)
  }

  static getById(id, type) {
    return Network.get(`${getRouteFromType(type)}/${id}`)
  }

  static sendInvitationEmail(id, redirectUrl) {
    return Network.put(`${getRouteFromType("users")}/${id}/send_registration_email`, {
      redirect_url: redirectUrl,
    })
  }

  static createSchoolTemplateAttributes(file, attributes, type) {
    return Network.post(
      getSchoolTemplateAttributeRouteFromType(type),
      createTemplateAttributesFormData(file, attributes, type),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
  }

  static updateSchoolTemplateAttributes(file, attributes, type) {
    return Network.put(
      `${getSchoolTemplateAttributeRouteFromType(type)}/${attributes.id}`,
      createTemplateAttributesFormData(file, attributes, type),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
  }

  static removeSchoolTemplateAttributes(id, type) {
    return Network.delete(`${getSchoolTemplateAttributeRouteFromType(type)}/${id}`)
  }
}
