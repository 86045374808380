import React from "react"
import { connect } from "react-redux"
import { GET_CURRICULUMS_REQUEST, GET_SPECIFIC_AGREEMENTS_REQUEST, SET_SPECIFIC_AGREEMENTS_FILTERS } from "../types"

import Session from "../helpers/SessionHelper"
import CurriculumsFilter from "../components/CurriculumsFilter"

class CurriculumsListContainer extends React.PureComponent {
  componentDidMount() {
    const { getCurriculums } = this.props
    getCurriculums(Session.schoolId)
  }

  render() {
    const { setCurriculumFilters, triggerSpecificAgreementListFetch, match, ...props } = this.props
    return (
      <CurriculumsFilter
        onChange={ (value) => {
          setCurriculumFilters(value.map(curriculum => curriculum.permalink))
          setImmediate(() => triggerSpecificAgreementListFetch(match.params.type))
        } }
        { ...props }
      />
    )
  }
}

const mapStateToProps = state => ({
  curriculums: state.jt.curriculums,
  curriculumsFilters: state.jt.specificAgreementsFilters.curriculums,
})

const mapDispatchToProps = dispatch => ({
  getCurriculums: schoolId => dispatch({ type: GET_CURRICULUMS_REQUEST, payload: { school_id: schoolId } }),
  setCurriculumFilters: filters => dispatch({ type: SET_SPECIFIC_AGREEMENTS_FILTERS, payload: { curriculums: filters } }),
  triggerSpecificAgreementListFetch: type => dispatch({ type: GET_SPECIFIC_AGREEMENTS_REQUEST, payload: { type } }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurriculumsListContainer)
