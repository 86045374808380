import Network from "../helpers/Network"

const GENERAL_AGREEMENTS_ROUTE = "/general_agreements"

export default class GeneralAgreements {
  static getAgreements(type) {
    return Network.get(GENERAL_AGREEMENTS_ROUTE, {
      params: {
        by_states: [type],
      },
    })
  }

  static getAgreement(id) {
    return Network.get(`${GENERAL_AGREEMENTS_ROUTE}/${id}`)
  }

  static createAgreement(data) {
    return Network.post(GENERAL_AGREEMENTS_ROUTE, {
      general_agreement: data,
    })
  }

  static editAgreement(data, url) {
    return Network.put(url, { general_agreement: data })
  }

  static updateState(url) {
    return Network.put(url)
  }

  static printAgreement(url) {
    return Network.get(url)
  }

  static export() {
    return Network.get(`${GENERAL_AGREEMENTS_ROUTE}/export`)
  }

  static cancelAgreement(id) {
    return Network.post(`${GENERAL_AGREEMENTS_ROUTE}/${id}/cancel`)
  }
}
