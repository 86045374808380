import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "react-bootstrap"
import debounce from "lodash/debounce"
import i18n from "i18next"

import { Icon, FormGroup } from "../Common"

import { Autocomplete as AutocompleteAPI } from "../../api"

import Session from "../../helpers/SessionHelper"

import "./Approval.css"

import { Roles } from "../../Constants"

import ApprovalActionButtons from "./ApprovalActionButtons"
import ApprovalSelectionForm from "./ApprovalSelectionForm"
import ApprovalStatus from "./ApprovalStatus"
import ApprovalAutocomplete from "./ApprovalAutocomplete"
import ApprovalTag from "./ApprovalTag"

export default class Approval extends Component {
  static propTypes = {
    defaultData: PropTypes.arrayOf(PropTypes.object),
    state: PropTypes.oneOf([
      "in_school_review",
      "pending_approvals",
      "approved",
      "denied",
      "sent_for_signature",
      "signed",
    ]),
    onApprove: PropTypes.func,
    onDeny: PropTypes.func,
    onCancel: PropTypes.func,
    onSelectApproval: PropTypes.func,
    onRemoveApproval: PropTypes.func,
    onSkipValidation: PropTypes.func,
  }

  static defaultProps = {
    defaultData: [],
    state: "in_school_review",
    onApprove: () => true,
    onDeny: () => true,
    onCancel: () => true,
    onSelectApproval: () => true,
    onRemoveApproval: () => true,
    onSkipValidation: () => true,
  }

  constructor(props) {
    super(props)
    const hasData = props.defaultData.length > 0 && !!props.defaultData[0].first_name
    this.state = {
      hasData,
      selectedUser: props.defaultData.find(elem => elem.user_id === Session.id) || props.defaultData[0],
      autocompletionData: [],
      inputValue: "",
      approvals: props.defaultData,
      autocompleteMenuWidth: 0,
    }
    this.autocompleteUser = debounce(this.autocompleteUser, 350)
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.defaultData) !== JSON.stringify(this.props.defaultData)) {
      // eslint-disable-next-line
      this.setState({
        selectedUser: nextProps.defaultData.find(elem => elem.user_id === Session.id) || nextProps.defaultData[0],
        hasData: nextProps.defaultData.length > 0,
        approvals: nextProps.defaultData,
      })
    }
  }

  autocompleteUser = async () => {
    const data = await AutocompleteAPI.autocompleteUsers(this.state.inputValue, [ Roles.OAP, Roles.SCHOOL_ADMIN ])
    this.setState({
      autocompletionData: data.filter(elem => !this.state.approvals.map(approval => approval.id).includes(elem.id)),
    })
  }

  onInputChange = (value) => {
    this.setState({ inputValue: value })
    this.autocompleteUser()
  }

  onBlurInput = () => {
    this.setState({ inputValue: "" })
  }

  removeApproval = (idx) => {
    const approvals = this.state.approvals.slice()

    approvals.splice(idx, 1)
    this.setState({ approvals, hasData: false })
  }

  onApprovalsTagsChange = approvals => this.setState({ approvals })

  onApprovalFormRef = (ref) => {
    this.setState({ autocompleteMenuWidth: ref && ref.getBoundingClientRect().width })
  }

  renderApprovalForm() {
    const { autocompletionData, inputValue, approvals, autocompleteMenuWidth } = this.state
    const { onSelectApproval, onSkipValidation } = this.props

    return (
      <Col xs={ 12 }>
        <ApprovalSelectionForm
          onChange={ this.onApprovalsTagsChange }
          values={ approvals }
          onChangeInput={ this.onInputChange }
          inputValue={ inputValue }
          renderAutocomplete={ ({ onSelect, onChange, ref, value }) => (
            <ApprovalAutocomplete
              autocompleteRef={ ref }
              items={ autocompletionData }
              onSelect={ onSelect }
              onChange={ onChange }
              onBlur={ this.onBlurInput }
              onFocus={ this.autocompleteUser }
              inputValue={ value }
              menuWidth={ autocompleteMenuWidth }
            />
          ) }
          onApprove={ () => onSelectApproval(approvals) }
          renderTag={ ({ key, ...props }) => <ApprovalTag { ...props } tagKey={key} onRemove={ this.removeApproval } /> }
          withAutocompletion
          addOnEnter={ false }
          className="approval-autocomplete__tags-input-container bootstrap-tagsinput"
          tagProps={ {
            className: "tag label label-default approval-autocomplete__tag-container",
            classNameRemove: "react-tagsinput-remove",
          } }
          canAskForApproval={ approvals.length > 0 }
          rootRef={ this.onApprovalFormRef }
          onSkipValidation={ onSkipValidation }
        />
      </Col>
    )
  }

  renderApprovalStatusUpdateActionButtons() {
    const { onCancel, onRemoveApproval, state, onSelectApproval } = this.props
    const { selectedUser, status, approvals } = this.state

    let canCancel = true
    if (approvals[0] && Session.id === approvals[0].user_id) {
      canCancel = state === "approved" || state === "rejected"
    } else {
      canCancel = state !== "sent_for_signature" && state !== "signed"
    }
    if (approvals[0] && approvals[0].user_id === Session.id && state === "pending_approvals" && status === "pending") return null
    return (
      <Col xs={ 12 }>
        <ApprovalStatus
          status={ approvals[0] && approvals[0].state }
          canCancel={ canCancel }
          onCancel={ () => {
            if (approvals[0] && Session.id === approvals[0].user_id) {
              onCancel()
            } else {
              onRemoveApproval(selectedUser)
            }
            this.setState({ approvals: [], hasData: false })
          } }
          userId={ approvals[0] && approvals[0].user_id }
          canReAskApproval={ state === "approved" || state === "rejected" }
          onReAskApproval={ () => onSelectApproval(selectedUser) }
          approvals={ approvals }
        />
      </Col>
    )
  }

  render() {
    const { selectedUser, hasData } = this.state
    const { state, onApprove, onDeny } = this.props

    return (
      <Row className="approval__container">
        <Col xs={ 12 }>
          <FormGroup
            className="approval-selection-form__container"
            formTitle={ i18n.t("spa:approvals.form.title") }
            renderTitle={ title => (
              <span>
                <Icon iconName="graduation-cap" /> {title}
              </span>
            ) }
          >
            {!hasData && this.renderApprovalForm()}
            {hasData && this.renderApprovalStatusUpdateActionButtons()}
            {hasData &&
              Session.id === selectedUser.user_id &&
              state === "pending_approvals" &&
              selectedUser.state === "pending" && <ApprovalActionButtons onApprove={ onApprove } onDeny={ onDeny } />}
          </FormGroup>
        </Col>
      </Row>
    )
  }
}
