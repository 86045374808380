import React from "react"
import { connect } from "react-redux"

import GeneralAgreementsList from "../components/GeneralAgreementsList"

import { GENERAL_AGREEMENTS_LIST_REQUEST, COMPANIES_WITHOUT_GENERAL_AGREEMENT_REQUEST } from "../types"

const GeneralAgreementsListContainer = ({ ...props }) => <GeneralAgreementsList { ...props } />

const mapStateToProps = state => ({
  agreements: state.generalAgreements.agreements,
  companiesWithoutGeneralAgreement: state.generalAgreements.companiesWithoutGeneralAgreement,
})

const mapDispatchToProps = dispatch => ({
  getAgreements: type => dispatch({ type: GENERAL_AGREEMENTS_LIST_REQUEST, payload: { type } }),
  getCompaniesWithoutGeneralAgreement: () => dispatch({ type: COMPANIES_WITHOUT_GENERAL_AGREEMENT_REQUEST }),
})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralAgreementsListContainer)
