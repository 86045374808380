import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Radio } from "react-bootstrap"
import omit from "lodash/omit"

export default class RadioGroup extends PureComponent {
  render() {
    const { radioData, name, titleClassName, radioClassName, ...props } = this.props

    return (
      radioData.map(({ value, label, ...dataRest }) => (
        <Radio
          key={ value }
          name={ name }
          value={ value }
          className={ radioClassName }
          { ...omit(props, "value") }
          { ...omit(dataRest, "value") }
        >
          <span className={ titleClassName }>{label}</span>
        </Radio>
      ))
    )
  }
}

RadioGroup.propTypes = {
  radioData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      ...Radio.propTypes,
    })
  ),
  name: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
  radioClassName: PropTypes.string,
}

RadioGroup.defaultProps = {
  radioData: [],
  titleClassName: "",
  radioClassName: "",
}
