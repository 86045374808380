import React from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import classname from "classnames"

import "./FormGroup.css"

const FormGroup = ({ formTitle, children, bordered, renderTitle, ...props }) => (
  <Col xs={ 12 } { ...props }>
    {formTitle && (
      <div className={ classname("form-group__header", { "form-group__header-bordered": bordered }) }>
        {!renderTitle && <span>{formTitle}</span>}
        {renderTitle && renderTitle(formTitle)}
      </div>
    )}
    <div className="form-group__form-content clearfix">{children}</div>
  </Col>
)

FormGroup.propTypes = {
  formTitle: PropTypes.string,
  children: PropTypes.node,
  bordered: PropTypes.bool,
  renderTitle: PropTypes.func,
}

FormGroup.defaultProps = {
  formTitle: null,
  children: null,
  bordered: true,
  renderTitle: null,
}

export default FormGroup
