import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import "./Title.css"

export default class Title extends PureComponent {
  static propTypes = {
    size: PropTypes.number,
  }

  static defaultProps = {
    size: 17,
  }

  render() {
    const { size, className, children, ...props } = this.props

    return (
      <p className={ classnames("title", className) } style={ { fontSize: size, ...props.style } } { ...props }>
        {children}
      </p>
    )
  }
}
