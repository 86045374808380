import React, { Fragment } from "react"
import { ControlLabel } from "react-bootstrap"
import i18n from "i18next"

import { GenericFormInput } from "../GenericInputs"
import { defaultRenderOptions } from "../../utils"

export default class SpecificCompetencies extends React.Component {
  onChangeTemplate = (event) => {
    const { onChangeTemplate, templates } = this.props

    const template = templates.find(tpl => tpl.id === parseInt(event.target.value, 10))
    onChangeTemplate(template)
  }

  onChangeTemplateContent = (event) => {
    const { onChangeTemplateContent } = this.props

    onChangeTemplateContent(event.target.value)
  }

  render() {
    const { templateId, templates, value, getValidationStateFix, getErrorMessage } = this.props
    return (
      <Fragment>
        <ControlLabel>{i18n.t("spa:specific_agreements.form.fields.specific_competencies.label")}</ControlLabel>
        <GenericFormInput

          getErrorMessage={ getErrorMessage }
          componentClass="select"
          onChange={ this.onChangeTemplate }
          renderOptions={ () =>
            defaultRenderOptions({
              options: templates,
              valueKey: "id",
              keyProperty: "id",
              contentKey: "title",
              withDefaultOption: true,
              defaultOptionContent: i18n.t("spa:specific_agreements.form.fields.specific_competencies.select_default"),
            })
          }
          value={ templateId }
          label={ i18n.t("spa:specific_agreements.form.fields.specific_competencies.select_label") }
        />
        <GenericFormInput
          validationState={ getValidationStateFix(this.props.name) }
          getErrorMessage={ getErrorMessage }
          value={ value }
          onChange={ this.onChangeTemplateContent }
          withLabel={ false }
          componentClass="textarea"
          placeholder={ i18n.t("spa:specific_agreements.form.fields.specific_competencies.placeholder") }
          rows={ 6 }
        />
      </Fragment>
    )
  }
}
