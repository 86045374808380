import { GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, EDIT_PROFILE_REQUEST, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_ERROR } from "../types"

export const initialState = {
  users: {},
  editionRequestData: {
    isInProgress: false,
    isValid: false,
    isRequestDone: false,
  },
  editErrors: {},
}

export default function profileReducer(state = initialState, { type, payload }) {
  switch (type) {
    case EDIT_PROFILE_REQUEST: {
      return {
        ...state,
        editionRequestData: {
          isInProgress: true,
          isValid: false,
          isRequestDone: false,
        },
        editErrors: {},
      }
    }
    case EDIT_PROFILE_SUCCESS: {
      return {
        ...state,
        editionRequestData: {
          isInProgress: false,
          isValid: true,
          isRequestDone: true,
        },
        editErrors: {},
      }
    }
    case EDIT_PROFILE_ERROR: {
      const { errors } = payload
      return {
        ...state,
        editionRequestData: {
          isInProgress: false,
          isValid: false,
          isRequestDone: true,
        },
        editErrors: errors,
      }
    }
    case GET_PROFILE_REQUEST: {
      return {
        ...state,
        editErrors: {},
      }
    }
    case GET_PROFILE_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        userData: data,
      }
    }
    default:
      return { ...state }
  }
}
