import each from "lodash/each"

import { SIDEBAR_COUNT_SUCCESS } from "../types"

export const initialState = {
  count: {
    specific_agreement: {
      draft: 0,
      student_info_completion: 0,
      in_school_review: 0,
      pending_approvals: 0,
      approved: 0,
      denied: 0,
      sent_for_signature: 0,
      signed: 0,
    },
    general_agreement: {
      company_draft: 0,
      school_review: 0,
      sent_for_signature: 0,
      active: 0,
      expire: 0,
      company_without_general_agreement: 0,
    },
  },
}

export default function sidebarReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SIDEBAR_COUNT_SUCCESS: {
      const { count } = payload
      const { company_without_general_agreement_count: companyWithoutGeneralAgreementCount, ...counts } = count[0]
      const stateCount = {
        specific_agreement: {},
        general_agreement: {},
      }

      stateCount.general_agreement.company_without_general_agreement = companyWithoutGeneralAgreementCount

      each(counts, (value, key) => {
        const matchSpecific = key.match(/(specific_agreement)_(\w.*)_count/)
        const matchGeneral = key.match(/(general_agreement)_(\w.*)_count/)
        if (matchSpecific) {
          stateCount[matchSpecific[1]][matchSpecific[2]] = value
        } else if (matchGeneral) {
          stateCount[matchGeneral[1]][matchGeneral[2]] = value
        }
      })

      return {
        ...state,
        count: stateCount,
      }
    }
    default:
      return { ...state }
  }
}
