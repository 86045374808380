import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

export default function(Comp) {
  // eslint-disable-next-line
  class ConnectWithSidebarContainer extends Component {
    static contextTypes = {
      onUpdateSidebar: PropTypes.func,
      onUpdateCount: PropTypes.func,
      getSelectedCategoryData: PropTypes.func,
    }

    static propTypes = {
      sidebarCounts: PropTypes.shape({
        specific_agreement: PropTypes.shape({
          draft: PropTypes.number,
          student_info_completion: PropTypes.number,
          in_school_review: PropTypes.number,
          pending_approvals: PropTypes.number,
          approved: PropTypes.number,
          denied: PropTypes.number,
          sent_for_signature: PropTypes.number,
          signed: PropTypes.number,
        }).isRequired,
        general_agreement: PropTypes.shape({
          company_draft: PropTypes.number,
          school_review: PropTypes.number,
          sent_for_signature: PropTypes.number,
          active: PropTypes.number,
          expired: PropTypes.number,
          company_without_general_agreement: 0,
        }),
      }).isRequired,
    }

    constructor(props, context) {
      super(props)
      context.onUpdateCount(props.sidebarCounts)
    }

    componentWillReceiveProps(nextProps) {
      this.context.onUpdateCount(nextProps.sidebarCounts)
    }

    render() {
      const { sidebarCounts, ...props } = this.props
      const { category, subcategory } = this.context.getSelectedCategoryData()

      return (
        <Comp { ...props } onUpdateSidebar={ this.context.onUpdateSidebar } category={ category } subcategory={ subcategory } />
      )
    }
  }

  const mapStateToProps = state => ({
    sidebarCounts: state.sidebar.count,
  })

  return connect(mapStateToProps)(ConnectWithSidebarContainer)
}
