import React from "react"
import PropTypes from "prop-types"
import moment from "moment"

import DatePickerRange from "../DatePickerRange"

const GenericDatePickerRange = ({
  startedAtKey,
  endedAtKey,
  onStartDateChange,
  onEndDateChange,
  data,
  editable,
  getValidationState,
  getErrorMessage,
  ...props
}) => {
  let startedAt
  let endedAt
  startedAt = moment(data[startedAtKey])
  if (startedAt.isValid()) startedAt = startedAt.toDate()
  else startedAt = moment().toDate()
  endedAt = moment(data[endedAtKey])
  if (endedAt.isValid()) endedAt = endedAt.toDate()
  else {
    endedAt = moment()
      .add(1, "d")
      .toDate()
  }

  return (
    <DatePickerRange
      startDate={ startedAt }
      onStartDateChange={ onStartDateChange }
      startDateInputProps={ {
        name: startedAtKey,
        validationState: getValidationState(startedAtKey),
        errorMessage: getErrorMessage(startedAtKey),
        disabled: !editable,
        ...props,
      } }
      endDate={ endedAt }
      onEndDateChange={ onEndDateChange }
      endDateInputProps={ {
        name: endedAtKey,
        validationState: getValidationState(endedAtKey),
        errorMessage: getErrorMessage(endedAtKey),
        disabled: !editable,
        ...props,
      } }
    />
  )
}

GenericDatePickerRange.propTypes = {
  startedAtKey: PropTypes.string,
  endedAtKey: PropTypes.string,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  data: PropTypes.object,
  editable: PropTypes.bool,
  getValidationState: PropTypes.func,
  getErrorMessage: PropTypes.func,
}

GenericDatePickerRange.defaultProps = {
  startedAtKey: "start_date",
  endedAtKey: "end_date",
  onStartDateChange: () => true,
  onEndDateChange: () => true,
  data: {},
  editable: true,
  getValidationState: () => null,
  getErrorMessage: () => "",
}

export default GenericDatePickerRange
