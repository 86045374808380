import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"

import { generateDataButton } from "../../utils"

import { ButtonGroup } from "../Common"

const GeneralAgreementFormActionButtons = ({ canSave, canSend, canPrint, canCancel, state, onSave, onSend, onPrint, onCancel }) => {
  const finalDataButtons = []

  const getSendButtonTitle = () => {
    switch (state) {
      case "company_draft":
        return i18n.t("spa:general_agreements.form.buttons.send")
      case "school_review":
        return i18n.t("spa:general_agreements.form.buttons.send_for_signature")
      case "sent_for_signature":
        return i18n.t("spa:general_agreements.form.buttons.activate")
      default:
        return null
    }
  }

  if (canCancel) {
    finalDataButtons.push(generateDataButton(i18n.t("spa:general_agreements.form.buttons.cancel"), onCancel, "danger"))
  }

  if (canSave) {
    finalDataButtons.push(generateDataButton(i18n.t("spa:general_agreements.form.buttons.save"), onSave, "success"))
  }
  if (canPrint) {
    finalDataButtons.push(
      generateDataButton(i18n.t("spa:specific_agreements.form.buttons.shared.print"), onPrint, "success")
    )
  }
  if (canSend) {
    finalDataButtons.push(generateDataButton(getSendButtonTitle(), onSend, "primary"))
  }
  return <ButtonGroup className="general-agreement__action-buttons" buttonsData={ finalDataButtons } buttonSpacing={ 10 } />
}

GeneralAgreementFormActionButtons.propTypes = {
  canSave: PropTypes.bool,
  canSend: PropTypes.bool,
  canPrint: PropTypes.bool,
  canCancel: PropTypes.bool,
  state: PropTypes.string,
  onSave: PropTypes.func,
  onSend: PropTypes.func,
  onPrint: PropTypes.func,
  onCancel: PropTypes.func,
}

GeneralAgreementFormActionButtons.defaultProps = {
  canSave: true,
  canSend: true,
  canPrint: false,
  canCancel: false,
  state: null,
  onSave: () => true,
  onSend: () => true,
  onPrint: () => true,
  onCancel: () => true,
}

export default GeneralAgreementFormActionButtons
