import Network from "../helpers/Network"
import Session from "../helpers/SessionHelper"

const COMPANIES_ROUTE = "/companies"

export default class CompanyAPI {
  static async autocompleteCompanies(val) {
    return Network.get(COMPANIES_ROUTE, {
      params: {
        by_name_or_tax_id: `%${val}%`,
      },
    })
  }

  static async createCompany(data) {
    return Network.post(COMPANIES_ROUTE, {
      company: data,
    })
  }

  static async getCompanies(params = {}) {
    return Network.get(COMPANIES_ROUTE, {
      params: {
        ...params,
        by_school_id: Session.schoolId,
      },
    })
  }

  static async getCompany(id) {
    return Network.get(`${COMPANIES_ROUTE}/${id}`)
  }

  static async editCompany(id, data) {
    return Network.put(`${COMPANIES_ROUTE}/${id}`, {
      company: data,
    })
  }
}
