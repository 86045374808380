import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FormGroup, Row, Col, Checkbox, ControlLabel } from "react-bootstrap";
import i18n from "i18next";
import find from "lodash/find";

import {
  GenericFormInput,
  renderGenericPlacesAutocomplete
} from "../GenericInputs";
import Session from "../../helpers/SessionHelper";
import PhoneInput from "../PhoneInput";
import { EmailInput, FileUpload } from "../Common";
import { defaultRenderOptions } from "../../utils";
import CurriculumsFilter from "../CurriculumsFilter";
import studentSchoolYears from "../../school-years.json";

export default class StudentForm extends Component {
  static propTypes = {
    departments: PropTypes.arrayOf(PropTypes.object),
    defaultData: PropTypes.object,
    getValidationState: PropTypes.func.isRequired,
    getErrorMessage: PropTypes.func.isRequired,
    editable: PropTypes.bool,
    onChange: PropTypes.func,
    displayEmailAddress: PropTypes.bool,
    showStudentPaymentFields: PropTypes.bool,
    curriculums: PropTypes.array,
    StudentEmailDomain: PropTypes.string
  };

  static defaultProps = {
    departments: [],
    defaultData: {
      student_last_name: "",
      student_first_name: "",
      student_identity_number: "",
      student_social_security_number: "",
      student_department: "",
      student_street_address: "",
      student_city: "",
      student_postal_code: "",
      student_phone_number: "",
      student_email_address: "",
      student_student_number: "",
      student_country: "",
      student_bank_account_owner: "",
      student_bank_account_bic: "",
      student_bank_account_iban: "",
      student_curriculum_specialization: "",
      student_school_year: "",
      is_student_paid_by_school: false
    },
    editable: true,
    onChange: () => true,
    displayEmailAddress: true,
    showStudentPaymentFields: false,
    curriculums: [],
    addressRequired: false,
    StudentEmailDomain: ""
  };

  constructor(props) {
    super(props)

    this.fileUPloadRef = React.createRef()
  }

  componentDidMount() {
    const { defaultData: data, StudentEmailDomain, onChange } = this.props;

    if (data.student_email_address === "" && StudentEmailDomain) {
      onChange("student_email_address", `@${StudentEmailDomain}`);
    }
  }

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  handleChange = e => {
    const { onChange } = this.props;

    onChange(e.target.name, e.target.value);
  };

  handleCheckboxChange(e) {
    const { onChange } = this.props;

    onChange(e.target.name, e.target.checked);
  }

  handlePhoneNumberChange = (name, value) => {
    const { onChange } = this.props;

    onChange(name, value);
  };

  renderPlacesAutocompleteInput() {
    const {
      getValidationState,
      getErrorMessage,
      editable,
      defaultData: data,
      onChange,
      studentCompletion,
      addressRequired
    } = this.props;

    return renderGenericPlacesAutocomplete({
      keyPrefix: "student",
      getValidationState,
      getErrorMessage,
      data,
      commonInputProps: {
        onChange: this.handleChange,
        disabled: !editable,
        withLabel: true,
        required: addressRequired
      },
      onChange
    });
  }

  renderGenericInput({ name, options, ...props }) {
    const {
      getValidationState,
      getErrorMessage,
      editable,
      defaultData: data,
    } = this.props;

    return (
      <GenericFormInput
        name={name}
        options={options}
        data={data}
        getValidationState={getValidationState}
        getErrorMessage={getErrorMessage}
        editable={editable}
        onChange={e => this.handleChange(e)}
        renderOptions={() =>
          defaultRenderOptions({
            options,
            keyProperty: "key",
            valueKey: "value",
            contentKey: "value"
          })
        }
        {...props}
      />
    );
  }

  async triggerUpload(token) {
    if (!this.fileUPloadRef.current) {
      return null;
    }

    return await this.fileUPloadRef.current.triggerUpload({
      byToken: true,
      token
    })
  }

  render() {
    const {
      departments,
      defaultData: data,
      displayEmailAddress,
      showStudentPaymentFields,
      curriculums,
      studentCompletion,
      addressRequired,
      StudentEmailDomain,
      studentSocialSecurityNumberRequired,
      studentIdentityNumberFileKeyRequired,
      studentPhoneNumberRequired,
      getValidationState,
      getErrorMessage,
      editable
    } = this.props;

    return (
      <Fragment>
        {[
          {
            isRow: true,
            children: [
              { name: "student_first_name", required: true },
              { name: "student_last_name", required: true }
            ]
          },
          {
            name: "student_student_number",
            visible: !Session.isRecruiter()
          },
          {
            name: "student_jobteaser_uid",
            visible: Session.isSchoolAdmin() || Session.isOAP()
          },
          {
            name: "student_identity_number",
            required: studentCompletion
          },
          {
            name: "student_identity_number_file_key",
            required: studentCompletion,
            componentClass: FileUpload,
            visible: !Session.isRecruiter() && studentIdentityNumberFileKeyRequired,
          },
          {
            name: "student_social_security_number",
            required: studentCompletion,
            postLabel: i18n.t("spa:specific_agreements.form.fields.student_social_security_number.post_label"),
            visible: studentSocialSecurityNumberRequired || !!data.is_student_paid_by_school
          },
          {
            name: "student_department",
            options: departments.map(dept => ({
              value: dept.name,
              key: dept.id
            })),
            componentClass: "select",
            visible: departments.length > 0,
            required: studentCompletion
          },
          {
            name: "student_curriculum_permalink",
            inputComponentClass: CurriculumsFilter,
            isMulti: false,
            curriculums,
            curriculumsFilters: data.student_curriculum_permalink,
            required: studentCompletion,
            onChange: value => {
              const { onChange } = this.props;

              onChange("student_curriculum_permalink", value.permalink);
              setTimeout(() =>
                onChange("student_curriculum_value", value ? value.title : null)
              );
            }
          },
          {
            name: "student_curriculum_specialization"
          },
          {
            name: "student_school_year",
            componentClass: "select",
            required: !studentCompletion,
            visible: Session.isSchoolAdmin() || Session.isOAP(),
            options: [
              { value: "" },
              ...studentSchoolYears.school_years.map(year => ({
                value: year,
                key: year
              }))
            ]
          },
          {
            isPlacesAutocomplete: true,
            visible: !!data.is_student_paid_by_school || addressRequired
          },
          {
            name: "student_phone_number",
            required: studentPhoneNumberRequired,
            component: PhoneInput,
            onChange: this.handlePhoneNumberChange
          },
          {
            name: "student_email_address",
            visible: displayEmailAddress,
            component: EmailInput,
            required: true,
            placeholder:
              StudentEmailDomain && StudentEmailDomain.length
                ? i18n
                    .t(
                      "spa:specific_agreements.form.fields.student_email_address.placeholder"
                    )
                    .replace(/@.*/g, `@${StudentEmailDomain}`)
                : i18n.t(
                    "spa:specific_agreements.form.fields.student_email_address.placeholder"
                  )
          },
          {
            name: "is_student_paid_by_school",
            visible: Session.isSchoolAdmin(),
            inputComponentClass: Checkbox,
            children: (
              <span>
                {i18n.t(
                  "spa:specific_agreements.form.fields.is_student_paid_by_school.label"
                )}
              </span>
            ),
            onChange: e => this.handleCheckboxChange(e),
            checked: !!data.is_student_paid_by_school,
            inputClassName: "checkbox-primary"
          },
          {
            name: "student_bank_account_owner",
            visible:
              !!data.is_student_paid_by_school &&
              (Session.isSchoolAdmin() || showStudentPaymentFields)
          },
          {
            name: "student_bank_account_bic",
            visible:
              !!data.is_student_paid_by_school &&
              (Session.isSchoolAdmin() || showStudentPaymentFields)
          },
          {
            name: "student_bank_account_iban",
            visible:
              !!data.is_student_paid_by_school &&
              (Session.isSchoolAdmin() || showStudentPaymentFields)
          }
        ].map(({ visible, ...elem }, index) => {
          if (elem.isPlacesAutocomplete && visible) {
            return React.cloneElement(
              this.renderPlacesAutocompleteInput(elem),
              { key: index }
            );
          } else if (elem.isRow) {
            return (
              <Row key={index}>
                {elem.children.map((child, idx) => (
                  <Col xs={12} sm={6} key={`${index}-${idx}`}>
                    {this.renderGenericInput(child)}
                  </Col>
                ))}
              </Row>
            );
          }
          if (visible !== undefined && visible === false) return null;
          if (elem.name === "student_identity_number_file_key") {
            const { name, options, ...props } = elem

            if (Session.isConnected) {
              if (data["student_identity_number_file_url"]?.length > 0) {
                return (
                  <div class="form-group">
                    <a href={data['student_identity_number_file_url']} target="_blank">
                      {i18n.t(`spa:specific_agreements.form.fields.${name}.label`)}
                    </a>
                  </div>
                )
              } else {
                return null;
              }
            }

            return (
              <FormGroup
                className={ "with-label form-input-container" }
                validationState={ getValidationState(name) }
              >
                <FileUpload
                  name={name}
                  key={index}
                  withLabel
                  ref={this.fileUPloadRef}
                  label={ i18n.t(`spa:specific_agreements.form.fields.${name}.label`) + " *" }
                  value={ data[name] || "" }
                  onChange={e => this.handleChange(e)}
                  required={studentCompletion}
                  options={options}
                  helpText={ i18n.t(`spa:specific_agreements.form.fields.${name}.help`) }
                  {...props}
                >
                </FileUpload>
                <Fragment>
                  {getValidationState(name) === "error" && <span className="text-danger">{getErrorMessage(name)}</span>}
                </Fragment>
              </FormGroup>
            )
          }
          return React.cloneElement(this.renderGenericInput(elem), {
            key: index,
          });
        })}
      </Fragment>
    );
  }
}
