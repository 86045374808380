import React, { Component } from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import { Switch, Route } from "react-router"
import { Link } from "react-router-dom"

import AdminUsersList from "./AdminUsersList"
import AdminUsersForm from "./AdminUsersForm"

import { Button, Icon } from "../../Common"

import Routes from "../../../routes"

import "./AdminUsersManagement.css"

export default class AdminUsersManagement extends Component {
  static propTypes = {
    usersList: PropTypes.array.isRequired,
    schoolsList: PropTypes.array.isRequired,
    getUsersList: PropTypes.func.isRequired,
    getSchoolsList: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
    inviteUser: PropTypes.func.isRequired,
    removeUser: PropTypes.func.isRequired,
    editionRequestData: PropTypes.shape({
      isValid: PropTypes.bool,
      isInProgress: PropTypes.bool,
      isRequestDone: PropTypes.bool,
    }).isRequired,
  }

  constructor() {
    super()
    this.state = {
      userSelected: false,
      userSelectedData: null,
    }
  }

  componentDidMount() {
    this.getUsersList()
  }

  getUsersList() {
    const { getUsersList, getSchoolsList } = this.props

    getUsersList()
    getSchoolsList()
  }

  displayUserForm(userData = undefined) {
    const { history } = this.props
    this.setState({ userSelectedData: userData }, () =>
      history.push(Routes.ADMIN_USERS_UPDATE.replace(":id", userData.id))
    )
  }

  closeUserForm() {
    const { history } = this.props

    history.push(Routes.ADMIN_USERS)
  }

  onSubmit(data) {
    const { editUser, inviteUser, location } = this.props

    if (location.pathname !== Routes.ADMIN_USERS_CREATE) editUser(data)
    else inviteUser(data)
  }

  onRemoveUser = (id) => {
    const { removeUser } = this.props
    if (window.confirm(`Are you sure you want to remove user with ID ${id} ?`)) {
      removeUser(id)
    }
  }

  sendRegistrationEmail = (id, status) => {
    const { sendRegistrationEmail } = this.props

    let redirectUrl
    if (status === "invitation pending") redirectUrl = `${window.location.origin}/signup/invitation`
    else if (status === "registration pending") redirectUrl = `${window.location.origin}/confirm`

    sendRegistrationEmail(id, redirectUrl)
  }

  render() {
    const { usersList, removeUser, editionRequestData, logout, schoolsList } = this.props
    const { userSelectedData } = this.state
    const { isValid, isInProgress, isRequestDone } = editionRequestData

    return (
      <Col xs={ 12 } className="admin-users-management-container">
        <div className="logout-button-container" onClick={ logout }>
          <Icon iconName="power-off" iconSize={ 20 } />
        </div>
        <Switch>
          <Route
            exact
            path={ Routes.ADMIN_USERS }
            render={ () => (
              <Col xs={ 12 }>
                <Col xs={ 12 }>
                  <h3 style={ { textAlign: "center" } }>Users list</h3>
                  <Link to="/admin/users/invite">
                    <Button bsStyle="primary" style={ { float: "right" } }>
                      <Icon iconName="plus" /> Invite new user
                    </Button>
                  </Link>
                </Col>
                <Col xs={ 12 } className="admin-users-list-container">
                  <AdminUsersList
                    onUserSelected={ userData => this.displayUserForm(userData) }
                    users={ usersList }
                    onRemoveUser={ id => this.onRemoveUser(id) }
                    onSendRegistrationEmail={ this.sendRegistrationEmail }
                  />
                </Col>
              </Col>
            ) }
          />
          <Route
            path={ Routes.ADMIN_USERS_UPDATE }
            render={ ({ match }) => (
              <Col xs={ 12 } className="admin-users-management-form">
                <h3 style={ { textAlign: "center" } }>User form</h3>
                <AdminUsersForm
                  defaultData={ (match.params.id !== "invite" && userSelectedData) || undefined }
                  onCloseForm={ () => this.closeUserForm() }
                  isInvitation={ match.params.id === "invite" }
                  onSubmit={ data => this.onSubmit(data) }
                  hasError={ !isInProgress && isRequestDone && !isValid }
                  userId={ match.params.id !== "invite" && match.params.id }
                  schools={ schoolsList }
                />
              </Col>
            ) }
          />
        </Switch>
      </Col>
    )
  }
}
