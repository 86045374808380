import moment from "moment"
import each from "lodash/each"
import React from "react"

export const computeAddress = ({ streetAddress, city, postalCode }) =>
  [ streetAddress, postalCode, city ].filter(elem => !!elem).join(", ")

export const computeAgreementId = ({ agreementId, createdAt }) => {
  const year = moment(createdAt).year()
  const id = `${agreementId}`.padStart(4, "0")

  return `${year}-${id}`
}

export const computeFullName = ({ first_name: firstName, last_name: lastName }) =>
  `${firstName || ""} ${lastName || ""}`.trim()

export const computeRecruiterFullTitle = ({ firstName, lastName, organizationName, position, email }) =>
  [ computeFullName({ first_name: firstName, last_name: lastName }) || email, position, organizationName ]
    .filter(elem => !!elem)
    .join(", ")

export const computeAgreementRequestedBy = ({
  first_name: firstName,
  last_name: lastName,
  email,
  phone_number: phoneNumber,
  picture_url: pictureUrl,
  organization_name: organizationName,
  position,
}) => ({
  title: computeRecruiterFullTitle({ firstName, lastName, organizationName, position, email }),
  emailAddress: email,
  phoneNumber,
  pictureUrl,
})

export const generateDataButton = (
  title = "",
  onClick = () => true,
  style = "default",
  disabled = false,
  otherProps = {}
) => ({
  title,
  onClick,
  bsStyle: style,
  disabled,
  ...otherProps,
})

export const parseSpecificAgreementData = (data) => {
  const prefixes = [
    { name: /^(company_tutor|school_tutor)/, stateKey: "tutor" },
    { name: /^company/, stateKey: "company" },
    {
      name: /^(job|invoicing_status|specific_competencies|work_timetable|management_fee|invoice_number|other_information|jobteaser_job_offer_id|course_type)/,
      stateKey: "job",
    },
    { name: /^student/, stateKey: "student" },
    { name: /^school/, stateKey: "school" },
  ]
  const collectedDataKeys = []
  const dataToStateObj = {
    company: {},
    tutor: {},
    school: {},
    job: {},
    student: {},
  }
  each(data, (value, key) => {
    each(prefixes, (prefix) => {
      if (key.match(prefix.name)) {
        collectedDataKeys.push(key)
        if (key === prefix.stateKey) {
          dataToStateObj[key] = {
            ...dataToStateObj[key],
            ...value,
          }
        } else {
          dataToStateObj[prefix.stateKey][key] = value === null ? "" : value
        }
        return false
      }
      return true
    })
  })
  each(data, (value, key) => {
    if (!collectedDataKeys.includes(key)) {
      dataToStateObj[key] = value
    }
  })
  return dataToStateObj
}

export const defaultRenderOptions = ({
  options,
  valueKey,
  keyProperty,
  contentKey,
  withDefaultOption = false,
  defaultOptionContent = null,
}) => (
  <React.Fragment>
    {withDefaultOption && <option value={ null }>{defaultOptionContent}</option>}
    {options.map(option => (
      <option value={ option[valueKey] } key={ option[keyProperty] }>
        {option[contentKey]}
      </option>
    ))}
  </React.Fragment>
)

export const scrollTopAnimated = (element, duration = 1000) => {
  const scrollHeight = element.scrollHeight
  const scrollStep = Math.PI / (duration / 15)
  const cosParameter = scrollHeight / 2
  let scrollCount = 0
  let scrollMargin
  const scrollInterval = setInterval(() => {
    if (element.scrollTop !== 0) {
      scrollCount = scrollCount + 1
      scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep)
      element.scrollTo(0, scrollHeight - scrollMargin)
    } else clearInterval(scrollInterval)
  }, 15)
  return scrollInterval
}
