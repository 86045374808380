export const GENERAL_AGREEMENTS_LIST_REQUEST = "GENERAL_AGREEMENTS_LIST_REQUEST"
export const GENERAL_AGREEMENTS_LIST_SUCCESS = "GENERAL_AGREEMENTS_LIST_SUCCESS"
export const GENERAL_AGREEMENTS_LIST_ERROR = "GENERAL_AGREEMENTS_LIST_ERROR"

export const GENERAL_AGREEMENT_DATA_REQUEST = "GENERAL_AGREEMENT_DATA_REQUEST"
export const GENERAL_AGREEMENT_DATA_SUCCESS = "GENERAL_AGREEMENT_DATA_SUCCESS"
export const GENERAL_AGREEMENT_DATA_ERROR = "GENERAL_AGREEMENT_DATA_ERROR"

export const GENERAL_AGREEMENT_CREATE_REQUEST = "GENERAL_AGREEMENT_CREATE_REQUEST"
export const GENERAL_AGREEMENT_CREATE_SUCCESS = "GENERAL_AGREEMENT_CREATE_SUCCESS"
export const GENERAL_AGREEMENT_CREATE_ERROR = "GENERAL_AGREEMENT_CREATE_ERROR"

export const GENERAL_AGREEMENT_EDIT_REQUEST = "GENERAL_AGREEMENT_EDIT_REQUEST"
export const GENERAL_AGREEMENT_EDIT_SUCCESS = "GENERAL_AGREEMENT_EDIT_SUCCESS"
export const GENERAL_AGREEMENT_EDIT_ERROR = "GENERAL_AGREEMENT_EDIT_ERROR"

export const COMPANIES_WITHOUT_GENERAL_AGREEMENT_REQUEST = "COMPANIES_WITHOUT_GENERAL_AGREEMENT_REQUEST"
export const COMPANIES_WITHOUT_GENERAL_AGREEMENT_SUCCESS = "COMPANIES_WITHOUT_GENERAL_AGREEMENT_SUCCESS"
export const COMPANIES_WITHOUT_GENERAL_AGREEMENT_ERROR = "COMPANIES_WITHOUT_GENERAL_AGREEMENT_ERROR"

export const SAVE_GENERAL_AGREEMENT_PRINTINT_TEMPLATE_REQUEST = "SAVE_GENERAL_AGREEMENT_PRINTINT_TEMPLATE_REQUEST"

export const GENERAL_AGREEMENT_CANCEL_REQUEST = "GENERAL_AGREEMENT_CANCEL_REQUEST"
export const GENERAL_AGREEMENT_CANCEL_SUCCESS = "GENERAL_AGREEMENT_CANCEL_SUCCESS"
export const GENERAL_AGREEMENT_CANCEL_ERROR = "GENERAL_AGREEMENT_CANCEL_ERROR"
