import { LIST_SUCCESS, EDIT_REQUEST, EDIT_SUCCESS, EDIT_ERROR } from "../types"

export const initialState = {
  users: {
    list: [],
  },
  schools: {
    list: [],
  },
  editionRequestData: {
    isInProgress: false,
    isValid: false,
    isRequestDone: false,
  },
}

export default function adminReducer(state = initialState, { type, payload, errors }) {
  switch (type) {
    case EDIT_REQUEST: {
      return {
        ...state,
        editionRequestData: {
          isInProgress: true,
          isValid: false,
          isRequestDone: false,
        },
      }
    }
    case EDIT_SUCCESS: {
      return {
        ...state,
        editionRequestData: {
          isInProgress: false,
          isValid: true,
          isRequestDone: true,
        },
      }
    }
    case EDIT_ERROR: {
      return {
        ...state,
        editionRequestData: {
          isInProgress: false,
          isValid: false,
          isRequestDone: true,
          errors: errors,
        },
      }
    }
    case LIST_SUCCESS: {
      const { data, type: reqType } = payload

      return {
        ...state,
        [reqType]: {
          list: data,
        },
      }
    }
    default:
      return { ...state }
  }
}
