import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"

import "./StepInformation.css"
import { Logo, Title } from "../Common"

const StepInformation = ({ title }) => (
  <div className="step-info">
    <Logo />
    <Title>{title}</Title>
    <p className="step-description" dangerouslySetInnerHTML={{__html: i18n.t("spa:informations.form.description")}}></p>
  </div>
)

StepInformation.propTypes = {
  title: PropTypes.string.isRequired,
}

export default StepInformation
