import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Col } from "react-bootstrap"

import { TableRow, Button } from "../../Common"

import Routes from "../../../routes"

const AdminSchoolsListItem = ({ data, onEdit }) => (
  <TableRow>
    <td className="col-xs-3">{data.name}</td>
    <td className="col-xs-3">{data.short_name}</td>
    <td className="col-xs-6">
      <Col xs={ 6 } xsOffset={ 6 }>
        <Link to={ Routes.ADMIN_SCHOOLS_UPDATE.replace(":id", data.id) }>
          <Button block bsStyle="primary" onClick={ onEdit }>
            Edit
          </Button>
        </Link>
      </Col>
    </td>
  </TableRow>
)

AdminSchoolsListItem.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    short_name: PropTypes.string,
  }),
  onEdit: PropTypes.func,
}

AdminSchoolsListItem.defaultProps = {
  data: {
    name: "",
    short_name: "",
  },
  onEdit: () => true,
}

export default AdminSchoolsListItem
