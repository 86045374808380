import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import classname from "classnames"

import "./SideBarListItem.css"

import Routes from "../../routes"

const getRouteByCategory = (category, type) => {
  switch (category) {
    case "specific_agreement":
      return Routes.SPECIFIC_AGREEMENT_INDEX.replace(":type", type)
    case "general_agreement":
      return Routes.GENERAL_AGREEMENT_INDEX.replace(":type", type)
    default:
      return ""
  }
}

// eslint-disable-next-line
export default class SideBarListItem extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    category: PropTypes.string,
    type: PropTypes.string,
    number: PropTypes.number,
  }

  static defaultProps = {
    number: 0,
    selected: false,
    category: null,
    type: null,
  }

  static contextTypes = {
    primaryColor: PropTypes.func,
  }

  render() {
    const { label, selected, category, type, number } = this.props

    return (
      <div className={ "sidebar-list-item" }>
        <Link
          to={ {
            pathname: getRouteByCategory(category, type),
            state: {
              resetPage: true,
            },
          } }
          className={ classname({ selected }) }
          style={ selected ? { color: this.context.primaryColor() } : {} }
        >
          <p className="sidebar-list-item-label">{label}</p>
          <p className="sidebar-list-item-number">{number}</p>
          <div style={ { clear: "both" } } />
        </Link>
      </div>
    )
  }
}
