import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"

import { FormInput } from "../Common"

const GenericFormInput = ({
  name,
  options,
  component,
  inputProps,
  data,
  getValidationState,
  getErrorMessage,
  editable,
  baseTranslationKey,
  onChange,
  renderOptions,
  withDefaultOption,
  ...props
}) => {
  const propsCpy = props
  if (propsCpy.componentClass === "select") {
    if (!renderOptions) {
      propsCpy.children = (
        <React.Fragment>
          {withDefaultOption && <option value={ null } />}
          {options.map(option => (
            <option value={ option } key={ option }>
              {i18n.t(`spa:${baseTranslationKey}.${name}.${option}`)}
            </option>
          ))}
        </React.Fragment>
      )
    } else {
      propsCpy.children = renderOptions()
    }
  }

  const InputComponent = component

  return (
    <InputComponent
      withLabel
      label={ i18n.t(`spa:${baseTranslationKey}.${name}.label`) }
      name={ name }
      placeholder={ i18n.t(`spa:${baseTranslationKey}.${name}.placeholder`) }
      onChange={ onChange }
      value={ data[name] || "" }
      validationState={ getValidationState(name) }
      errorMessage={ getErrorMessage(name) }
      disabled={ !editable }
      { ...propsCpy }
    />
  )
}

GenericFormInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  component: PropTypes.any,
  inputProps: PropTypes.object,
  data: PropTypes.object,
  getValidationState: PropTypes.func,
  getErrorMessage: PropTypes.func,
  editable: PropTypes.bool,
  baseTranslationKey: PropTypes.string,
  onChange: PropTypes.func,
  renderOptions: PropTypes.func,
  withDefaultOption: PropTypes.bool,
}

GenericFormInput.defaultProps = {
  options: [],
  component: FormInput,
  inputProps: {},
  data: {},
  getValidationState: () => null,
  getErrorMessage: () => "",
  editable: true,
  baseTranslationKey: "specific_agreements.form.fields",
  onChange: () => true,
  renderOptions: null,
  withDefaultOption: false,
}

export default GenericFormInput
