import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import each from "lodash/each";

import Icon from "./Icon";
import Avatar from "./Avatar";

import { DirectUpload } from "../../api";

import "./ImageUpload.css";

export default class ImageUpload extends Component {
  static propTypes = {
    onImageSet: PropTypes.func,
    label: PropTypes.string,
    defaultSource: PropTypes.string
  };

  static defaultProps = {
    onImageSet: () => true,
    label: "",
    defaultSource: null
  };

  constructor(props) {
    super(props);
    this.state = {
      image: props.defaultSource,
      file: null
    };
    this.fileInput = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultSource !== this.props.defaultSource) {
      this.setState({ image: nextProps.defaultSource });
    }
  }

  triggerFileInput() {
    this.fileInput.current.click();
  }

  onImageLoad(event) {
    // eslint-disable-next-line
    this.state.file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      // eslint-disable-next-line
      const reader = new FileReader();
      reader.onload = e => {
        this.setState({ image: e.target.result }, this.onImageSet);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  removeImage(evt) {
    evt.stopPropagation();
    this.setState({ image: null, file: null }, this.onImageSet);
  }

  onImageSet = () => {
    const { onImageSet } = this.props;

    onImageSet({
      base64: this.state.image,
      filename: this.state.file ? this.state.file.name : null
    });
  };

  async triggerUpload({ byToken, token } = {}) {
    const { defaultSource } = this.props;
    if (!this.state.file) return defaultSource;
    const {
      fields,
      upload_url: uploadUrl,
      download_url: downloadUrl
    } = await DirectUpload.getDirectUploadUrl(this.state.file.name, "private", {
      byToken,
      token
    });
    // eslint-disable-next-line
    const formData = new FormData();
    each(fields, (field, key) => {
      if (field) {
        formData.append(key, field);
      }
      return true;
    });
    formData.append("file", this.fileInput.current.files[0]);
    await DirectUpload.uploadFromUrl(uploadUrl, formData);
    return downloadUrl;
  }

  render() {
    const { image } = this.state;
    const { label } = this.props;

    return (
      <div className="image-upload">
        <label>{label}</label>
        <div
          className="image-container"
          onClick={() => this.triggerFileInput()}
        >
          {!image && <Icon iconName="camera" />}
          {image && (
            <Fragment>
              <Avatar size={128} alt="image_upload" src={image} />
              <Icon
                iconName="times"
                className="remove-icon"
                onClick={e => this.removeImage(e)}
              />
            </Fragment>
          )}
        </div>

        <input
          type="file"
          accept="image/*"
          onChange={evt => this.onImageLoad(evt)}
          ref={this.fileInput}
          style={{ display: "none" }}
        />
      </div>
    );
  }
}
