import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import Autocomplete from "react-autocomplete"

export default class CompanyFormAutocompleteMenu extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
    children: PropTypes.node,
  }

  static defaultProps = {
    style: {},
    children: null,
  }

  render() {
    const { style, children } = this.props

    return (
      <div
        className="company-autocomplete__menu-container"
        style={ { ...Autocomplete.defaultProps.menuStyle, ...style } }
      >
        <div style={ { position: "relative", height: "inherit", backgroundColor: "white", zIndex: 9999 } }>
          <div className="company-autocomplete__menu-list-container">{children}</div>
        </div>
      </div>
    )
  }
}
