import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import { Checkbox } from "react-bootstrap"

import PhoneInput from "../PhoneInput"
import { GenericPlacesAutocomplete, GenericFormInput } from "../GenericInputs"
import { defaultRenderOptions } from "../../utils"

export default class CompanyDebitFields extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    getValidationState: PropTypes.func.isRequired,
    getErrorMessage: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    editable: PropTypes.bool,
    onChangePlace: PropTypes.func,
    handleCheckboxChange: PropTypes.func,
    handlePhoneNumberChange: PropTypes.func,
  }

  static defaultProps = {
    data: {},
    onChange: () => true,
    disabled: false,
    onChangePlace: () => true,
    handleCheckboxChange: () => true,
    editable: true,
    handlePhoneNumberChange: () => true,
  }

  renderInvoicingPlacesAutocomplete = () => {
    const { data, onChange, editable, onChangePlace, getValidationState, getErrorMessage } = this.props

    return (
      <GenericPlacesAutocomplete
        keyPrefix="invoicing"
        getValidationState={ getValidationState }
        getErrorMessage={ getErrorMessage }
        data={ data }
        commonInputProps={ {
          onChange,
          withLabel: true,
          disabled: !editable,
          required: true,
        } }
        onChange={ onChangePlace }
        baseTranslationKey="company.form"
        useFallback={ false }
      />
    )
  }

  renderInput = ({ name, options, ...props }) => {
    const { data, onChange, editable, getValidationState, getErrorMessage } = this.props

    return (
      <GenericFormInput
        name={ name }
        options={ options }
        data={ data }
        getValidationState={ getValidationState }
        getErrorMessage={ getErrorMessage }
        onChange={ onChange }
        editable={ editable }
        baseTranslationKey="company.form"
        renderOptions={ () =>
          defaultRenderOptions({
            options,
            keyProperty: "value",
            valueKey: "value",
            contentKey: "name",
          })
        }
        { ...props }
      />
    )
  }

  render() {
    const { data, handleCheckboxChange, handlePhoneNumberChange } = this.props

    return (
      <React.Fragment>
        {[
          { name: "invoicing_contact", required: true },
          { isPlacesAutocomplete: true },
          { name: "invoicing_email", required: true },
          { name: "invoicing_phone_number", component: PhoneInput, onChange: handlePhoneNumberChange, required: true },
          {
            name: "payment_method",
            componentClass: "select",
            options: [
              {
                name: "",
                value: null,
              },
              {
                name: i18n.t("spa:company.form.payment_method.options.direct_debit"),
                value: "direct_debit",
              },
              { name: i18n.t("spa:company.form.payment_method.options.transfer"), value: "transfer" },
            ],
          },
          { name: "payment_direct_debit_account_owner", visible: data.payment_method === "direct_debit", required: data.payment_method === "direct_debit"},
          { name: "payment_direct_debit_bic", visible: data.payment_method === "direct_debit", required: data.payment_method === "direct_debit" },
          { name: "payment_direct_debit_iban", visible: data.payment_method === "direct_debit", required: data.payment_method === "direct_debit" },
          {
            name: "payment_direct_debit_recurring",
            visible: data.payment_method === "direct_debit",
            inputComponentClass: Checkbox,
            children: <span>{i18n.t("spa:company.form.payment_direct_debit_recurring.label")}</span>,
            onChange: handleCheckboxChange,
            checked: !!data.payment_direct_debit_recurring,
            inputClassName: "checkbox-primary",
          },
        ].map((elem, index) => {
          if (elem.isPlacesAutocomplete) {
            return React.cloneElement(this.renderInvoicingPlacesAutocomplete(), { key: index })
          } else if ((elem.hasOwnProperty("visible") && elem.visible) || !elem.hasOwnProperty("visible")) {
            return React.cloneElement(this.renderInput(elem), { key: index })
          }
          return null
        })}
      </React.Fragment>
    )
  }
}
