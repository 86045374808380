import React, { Component } from "react";
import PropTypes from "prop-types";
import i18n from "i18next";
import { withRouter } from "react-router";
import { Col } from "react-bootstrap";
import isObject from "lodash/isObject";
import each from "lodash/each";
import isEmpty from "lodash/isEmpty";

import { FormGroup, Button } from "../Common";
import CompanyForm from "../CompanyForm";
import { withErrorManager } from "../../hoc";

import "./CompanyEdition.css";
import CompanyDebitFields from "../CompanyDebitFields";
import { scrollTopAnimated } from "../../utils";

export class CompanyEdition extends Component {
  static propTypes = {
    companyData: PropTypes.object.isRequired,
    getCompanyData: PropTypes.func.isRequired,
    updateCompany: PropTypes.func.isRequired,
    id: PropTypes.number,
    errors: PropTypes.object.isRequired,
    getValidationState: PropTypes.func,
    getErrorMessage: PropTypes.func,
    getCompanySectors: PropTypes.func.isRequired,
    companySectors: PropTypes.array
  };

  static defaultProps = {
    id: null,
    getValidationState: () => null,
    getErrorMessage: () => "",
    companySectors: []
  };

  constructor(props) {
    super(props);
    this.state = {
      data: {
        payment_method: "direct_debit"
      },
      showDebitFields: !!props.id
    };
    this.getValidationState = name =>
      this.props.getValidationState(name, this.validationKeyFormatter);
    this.getErrorMessage = name =>
      this.props.getErrorMessage(name, this.validationKeyFormatter);
    this.companyForm = React.createRef();
  }

  validationKeyFormatter = name => {
    let finalName = name;
    if (finalName.startsWith("company_")) {
      finalName = finalName.replace("company_", "");
    }
    return finalName;
  };

  componentDidMount() {
    const { id, getCompanyData, getCompanySectors } = this.props;
    if (id) {
      getCompanyData(id);
    }
    getCompanySectors();
  }

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.companyData) !==
      JSON.stringify(this.props.companyData)
    ) {
      this.setState({
        data: nextProps.companyData
      });
    }
    if (!isEmpty(nextProps.errors)) {
      scrollTopAnimated(
        document.querySelector("#main-container > .clearfix.col-xs-12")
      );
    }
  }

  submitForm = async evt => {
    const { updateCompany, id, createCompany } = this.props;
    evt.preventDefault();

    const logoUrl = await this.companyForm.current.triggerAvatarUpload();
    const data = { ...this.state.data, logo_url: logoUrl };
    if (id) {
      updateCompany(id, data);
    } else {
      createCompany(data);
    }
  };

  handleChange = (name, value) => {
    if (isObject(name)) {
      const state = {};
      each(name, (val, key) => {
        state[this.validationKeyFormatter(key)] = val;
      });
      this.setState({
        data: {
          ...this.state.data,
          ...state
        }
      });
    } else {
      this.setState({
        data: {
          ...this.state.data,
          [this.validationKeyFormatter(name)]: value
        }
      });
    }
  };

  handleCheckboxChange = e => {
    this.handleChange(e.target.name, e.target.checked);
  };

  onInputChange = e => {
    this.handleChange(e.target.name, e.target.value);
  };

  onCompanyFormChangeMode = () => {
    this.setState({
      showDebitFields: true
    });
  };

  isSubmitDisabled = () => {
    const { data } = this.state;

    return !data.name || !data.tax_id;
  };

  render() {
    const { data, showDebitFields } = this.state;
    const { id, companySectors } = this.props;
    return (
      <form onSubmit={this.submitForm}>
        <Col xs={12} sm={7} className="company-edition-form__container">
          <FormGroup formTitle={i18n.t("spa:company_edition.form.title")}>
            <CompanyForm
              showAvatarInput
              defaultData={data}
              onChange={this.handleChange}
              getValidationState={this.getValidationState}
              getErrorMessage={this.getErrorMessage}
              ref={this.companyForm}
              mode={id ? "form" : "autocomplete"}
              onChangeMode={this.onCompanyFormChangeMode}
              changeModeOnSelect
              autocompleteBySchoolId={false}
              showJobteaserId
              companySectors={companySectors}
            />
            {showDebitFields && (
              <CompanyDebitFields
                data={data}
                getValidationState={this.getValidationState}
                getErrorMessage={this.getErrorMessage}
                onChange={this.onInputChange}
                onChangePlace={this.handleChange}
                handleCheckboxChange={this.handleCheckboxChange}
                handlePhoneNumberChange={this.handleChange}
              />
            )}
            <Button
              bsStyle="success"
              type="submit"
              style={{ float: "right" }}
              disabled={this.isSubmitDisabled()}
            >
              {i18n.t("spa:company_edition.form.save")}
            </Button>
          </FormGroup>
        </Col>
      </form>
    );
  }
}

export default withRouter(
  withErrorManager(props => props.errors)(CompanyEdition)
);
