import React, { Component } from "react"
import PropTypes from "prop-types"

import "./NavTabs.css"

export default class NavTabs extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.node), PropTypes.node ]).isRequired,
  }

  state = {
    selectedTabIndex: 0,
  }

  constructor() {
    super()
    this.tabsRefs = {}
  }

  render() {
    const { selectedTabIndex } = this.state
    const { children } = this.props

    const childrenArray = React.Children.toArray(children)

    return (
      <div className="nav-tabs__container">
        <div className="nav-tabs__tabs-container">
          {React.Children.map(childrenArray, (child, index) =>
            React.cloneElement(child, {
              onSelect: () => this.setState({ selectedTabIndex: index }),
              selected: index === selectedTabIndex,
            })
          )}
        </div>
        <div className="nav-tabs__content-container">
          {React.Children.only(childrenArray[selectedTabIndex]).props.children}
        </div>
      </div>
    )
  }
}
