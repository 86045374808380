import React from "react"
import pickBy from "lodash/pickBy"

import { SpecificAgreementContextConsumer } from "../context"

const consumeSpecificAgreementForm = (Comp, desiredDataState = []) =>
  React.forwardRef((props, ref) => (
    <SpecificAgreementContextConsumer>
      {({ data, ...rest }) => {
        const dataToPass =
          desiredDataState.length > 0 ? pickBy(data, (elem, key) => desiredDataState.includes(key)) : data

        return <Comp { ...props } ref={ ref } data={ dataToPass } { ...rest } />
      }}
    </SpecificAgreementContextConsumer>
  ))

export default consumeSpecificAgreementForm
