import Network from "../helpers/Network"

const PROFILE_ROUTE = "/users"

export default class Profile {
  static editProfile(data) {
    return Network.put(`${PROFILE_ROUTE}/${data.id}`, {
      user: data,
    })
  }

  static getProfileById(id) {
    return Network.get(`${PROFILE_ROUTE}/${id}`)
  }
}
