import React, { Component } from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import { Form, Panel } from "react-bootstrap"

import { Creatable } from "react-select"

import { Icon, FormGroup } from "../Common"

export default class Tags extends Component {
  static propTypes = {
    onDeleteTag: PropTypes.func,
    onCreateTag: PropTypes.func,
    tagList: PropTypes.array,
  }

  static defaultProps = {
    tagList: [],
    onDeleteTag: () => true,
    onCreateTag: () => true,
  }

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  onChange = (value) => {
    this.props.onCreateTag(value)
  }

  render() {
    const { tagList, onDeleteTag, availableTags } = this.props

    return (
      <FormGroup
        className="approval-selection-form__container"
        formTitle={ i18n.t("spa:tags.form.title") }
        renderTitle={ title => (
          <span>
            <Icon iconName="file" /> {title}
          </span>
        ) }
      >
        <Panel className="tags-panel" defaultExpanded collapsible style={{ border: "none" }}>
          <ul style={{ padding: '0' }}>
            {tagList.map(tag => (
              <li style={ { listStyle: "none", display: "flex", alignItems: "center"  } }>
                <Icon
                  iconName="times"
                  iconSize={ 15 }
                  className={ "react-tagsinput-remove" }
                  onClick={ () => onDeleteTag(tag) }
                  style={ { marginRight: "10px", marginTop: "2px" } }
                />
                {tag}
              </li>
            ))}
          </ul>
          <Form
            className="form-send-comment"
            inline
          >
            <Creatable
              onChange={ this.onChange }
              options={
                (availableTags || []).map((tag) => {
                  return { value: tag, label: tag }
                })
              }
              promptTextCreator={ i18n.t("spa:specific_agreements.form.fields.other_approving_parties.placeholder") }
            />
          </Form>
        </Panel>
      </FormGroup>
    )
  }
}
