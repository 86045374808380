import { Company } from "../api"

export async function createCompany(data) {
  try {
    const dataCompany = await Company.createCompany(data)
    return dataCompany
  } catch (e) {
    throw e
  }
}

export async function getCompanies(params = {}) {
  try {
    const data = await Company.getCompanies(params)
    return data
  } catch (e) {
    throw e
  }
}

export async function getCompany(id) {
  try {
    const data = await Company.getCompany(id)
    return data
  } catch (e) {
    throw e
  }
}

export async function editCompany(id, data) {
  try {
    const dataEdit = await Company.editCompany(id, data)
    return dataEdit
  } catch (e) {
    throw e
  }
}
