import { call, put, all, take, fork, select } from "redux-saga/effects"

import {
  SIDEBAR_COUNT_SUCCESS,
  SIDEBAR_COUNT_ERROR,
  SIGNIN_SUCCESS,
  SPECIFIC_AGREEMENT_CREATE_SUCCESS,
  SPECIFIC_AGREEMENT_EDIT_SUCCESS,
  SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_SUCCESS,
  SIGN_SPECIFIC_AGREEMENT_SUCCESS,
  GET_SPECIFIC_AGREEMENT_DATA_SUCCESS,
  GET_SPECIFIC_AGREEMENTS_SUCCESS,
  GENERAL_AGREEMENT_CREATE_SUCCESS,
  GENERAL_AGREEMENT_DATA_SUCCESS,
  GENERAL_AGREEMENT_EDIT_SUCCESS,
  GENERAL_AGREEMENTS_LIST_SUCCESS,
  GENERAL_AGREEMENT_CANCEL_SUCCESS,
  COMPANY_DATA_REQUEST,
  GET_PROFILE_REQUEST,
  EDIT_PROFILE_REQUEST,
} from "../types"

import { getSidebarCount as getSidebarCountAction } from "../actions/sidebar"

import { getSpecificAgreementsFiltersList } from "./specificAgreementsSaga"

export function* getSidebarCount() {
  while (true) {
    yield take([
      SIGNIN_SUCCESS,
      GENERAL_AGREEMENT_CREATE_SUCCESS,
      SPECIFIC_AGREEMENT_CREATE_SUCCESS,
      SPECIFIC_AGREEMENT_EDIT_SUCCESS,
      SEND_SPECIFIC_AGREEMENT_FOR_SIGNATURE_SUCCESS,
      SIGN_SPECIFIC_AGREEMENT_SUCCESS,
      GET_SPECIFIC_AGREEMENT_DATA_SUCCESS,
      GENERAL_AGREEMENT_DATA_SUCCESS,
      GENERAL_AGREEMENT_EDIT_SUCCESS,
      GENERAL_AGREEMENTS_LIST_SUCCESS,
      GENERAL_AGREEMENT_CANCEL_SUCCESS,
      "redux-token-auth/VERIFY_TOKEN_REQUEST_SUCCEEDED",
      GET_SPECIFIC_AGREEMENTS_SUCCESS,
      COMPANY_DATA_REQUEST,
      GET_PROFILE_REQUEST,
      EDIT_PROFILE_REQUEST,
    ])
    try {
      const filters = yield select(getSpecificAgreementsFiltersList)
      const count = yield call(getSidebarCountAction, filters)
      yield put({ type: SIDEBAR_COUNT_SUCCESS, payload: { count } })
    } catch (e) {
      yield put({ type: SIDEBAR_COUNT_ERROR })
    }
  }
}

export function* sidebarSaga() {
  yield all([ fork(getSidebarCount) ])
}

export default sidebarSaga
