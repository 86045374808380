import Network from "../helpers/Network"

const COMMENTS_ROUTE = "/comments"

export default class CommentsAPI {
  static getComments(specific_agreement_id) {
    return Network.get(COMMENTS_ROUTE, {
      params: {
        by_specific_agreement_id: specific_agreement_id,
      },
    })
  }

  static createComment(specific_agreement_id, body) {
    return Network.post(COMMENTS_ROUTE, {
      comment: {
        specific_agreement_id, 
        body
      }
    })
  }

  static deleteComment(id) {
    return Network.delete(`${COMMENTS_ROUTE}/${id}`)
  }

}
