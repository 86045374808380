import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import i18n from "i18next"

import { Avatar, Icon } from "../Common"
import defaultLogoCompany from "../../assets/company_logo.png"

import { computeAddress } from "../../utils"

export default class CompanyFormAutocompleteItem extends PureComponent {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
      logo_url: PropTypes.string,
      street_address: PropTypes.string,
      city: PropTypes.string,
      postal_code: PropTypes.string,
      tax_id: PropTypes.string,
      isSuggestion: PropTypes.bool,
    }).isRequired,
    onSelect: PropTypes.func.isRequired,
  }

  render() {
    const { item, onSelect } = this.props
    return (
      <div className="company-item__container" key={ item.id } onClick={ onSelect }>
        <div>
          <div className="company-item__avatar-container">
            <Avatar src={ item.logo_url || defaultLogoCompany } size={ 35 } />
          </div>
          <div className="company-item__main-content-container">
            <p className="company-item__main-content company-item__company-name">{item.name}</p>
            <p className="company-item__main-content company-item__company-address">
              {computeAddress({ streetAddress: item.street_address, city: item.city, postalCode: item.postal_code })}
            </p>
            <p className="company-item__main-content company-item__company-cif">{item.tax_id}</p>
          </div>
        </div>
        {item.isSuggestion && (
          <div className="company-item__suggestion-add">
            <span>
              <Icon iconName="plus" /> {i18n.t("spa:company.autocomplete.create.link")}
            </span>
          </div>
        )}
      </div>
    )
  }
}
