import React, { Fragment } from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import { Link } from "react-router-dom"

import Routes from "../../routes"

import { computeAgreementId } from "../../utils"

const SpecificAgreementExtensionStatus = ({
  alterationStatus,
  alterationId,
  alteratedStatus,
  alteratedId,
  alteratedCreatedAt,
  alterationCreatedAt,
}) => {
  const alterations = []
  if (!!alterationId && alterationStatus !== "terminated") {
    alterations.push({
      text: i18n.t(`spa:specific_agreements.alteration_status.${alterationStatus}`),
      id: alterationId,
      createdAt: alterationCreatedAt,
      terminated: false,
    })
  }
  if (!!alteratedId && alteratedStatus !== "terminated") {
    alterations.push({
      text: i18n.t(`spa:specific_agreements.alterated_status.${alteratedStatus}`),
      id: alteratedId,
      createdAt: alteratedCreatedAt,
      terminated: false,
    })
  }
  if (alterationStatus === "terminated") {
    alterations.push({ text: i18n.t("spa:specific_agreements.alteration_status.terminated"), terminated: true })
  }
  return (
    <Fragment>
      {alterations.map(({ text, id, terminated, createdAt }, index) => (
        <span>
          {text}{" "}
          {!terminated && (
            <Link onClick={ e => e.stopPropagation() } to={ Routes.SPECIFIC_AGREEMENT_SHOW.replace(":id", id) }>
              N°{" "}
              {computeAgreementId({
                agreementId: id,
                createdAt,
              })}
            </Link>
          )}
          {index < alterations.length - 1 && " - "}
        </span>
      ))}
    </Fragment>
  )
}

SpecificAgreementExtensionStatus.propTypes = {
  alterationStatus: PropTypes.string,
  alterationId: PropTypes.number,
  alterationCreatedAt: PropTypes.string,
  alteratedStatus: PropTypes.string,
  alteratedId: PropTypes.number,
  alteratedCreatedAt: PropTypes.string,
}

SpecificAgreementExtensionStatus.defaultProps = {
  alterationStatus: null,
  alterationId: null,
  alterationCreatedAt: null,
  alteratedStatus: null,
  alteratedId: null,
  alteratedCreatedAt: null,
}

export default SpecificAgreementExtensionStatus
