import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import i18n from "i18next"

import { TableRow, Card } from "../Common"

import { CompanyInfo, InternshipInfo, StudentInfo, SchoolInfo, AssigneeInfo } from "../AgreementListItems"

import Session from "../../helpers/SessionHelper"

const computeFullName = (firstName, lastName) => `${firstName || ""} ${lastName || ""}`

const SpecificAgreementsListItem = ({ item, onClick }) => (
  <TableRow className="agreements-list-row" onClick={ onClick }>
    <td>
      <InternshipInfo
        name={ item.job_title }
        id={ item.id }
        createdAt={ item.created_at }
        alterationData={ {
          alteratedId: item.alterated_agreement_id,
          alteratedStatus: item.alterated_agreement_status,
          alteratedCreatedAt: item.alterated_agreement_created_at,
          alterationId: item.alteration_agreement_id,
          alterationStatus: item.alteration_status,
          alterationCreatedAt: item.alteration_agreement_created_at,
        } }
      />
    </td>
    {(Session.isSchoolAdmin() || Session.isOAP()) && (
      <td>
        {item.tag_list.map((tag) => {
          return <span className="label label-default" style={ { marginRight: "5px" } }>{tag}</span>
        })}
      </td>
    )}
    <td>
      <CompanyInfo
        name={ item.company_name }
        avatarUrl={ item.company && item.company.logo_url }
        requestBy={ item.user && computeFullName(item.user.first_name, item.user.last_name) }
      />
    </td>
    <td>
      <span>{moment(item.job_started_at).isValid() ? moment(item.job_started_at).format("DD/MM/YYYY") : ""}</span>
      <br/>
      <span>→{moment(item.job_ended_at).isValid() ? moment(item.job_ended_at).format("DD/MM/YYYY") : ""}</span>
    </td>
    {!(Session.isSchoolAdmin() || Session.isOAP()) && (
      <td>
        <SchoolInfo name={ (item.school && item.school.name) || "" } avatarUrl={ item.school && item.school.logo_url } />
      </td>
    )}
    <td>
      <StudentInfo
        name={ computeFullName(item.student_first_name, item.student_last_name) }
        avatarUrl={ item.student_picture_url }
        curriculum={ item.student_curriculum_value }
      />
    </td>
    {(Session.isSchoolAdmin() || Session.isOAP()) && (
      <td>
        {item.assigned && (
          <AssigneeInfo
            fullName={ computeFullName(
              item.assigned.first_name,
              item.assigned.last_name
            ) }
            pictureUrl={ item.assigned.picture_url }
            schoolShortName={ Session.schoolShortName }
          />
        )}
        {!item.assigned && <Card title={ i18n.t("spa:specific_agreements.index.shared.not_assigned") } />}
      </td>
    )}
  </TableRow>
)

SpecificAgreementsListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

SpecificAgreementsListItem.defaultProps = {
  onClick: () => true,
}

export default SpecificAgreementsListItem
