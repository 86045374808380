import React from "react"
import { Alert as BSAlert } from "react-bootstrap"
import PropTypes from "prop-types"

const Alert = ({ type, ...props }) => <BSAlert bsStyle={ type } { ...props } />

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  ...BSAlert.propTypes,
}

export default Alert
