import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactPhoneInput from "react-telephone-input"
import "react-telephone-input/lib/withStyles"
import { FormGroup, ControlLabel } from "react-bootstrap"
import classname from "classnames"

import { FormInput, FormError } from "../Common"

import "./PhoneInput.css"

export default class PhoneInput extends Component {
  static propTypes = {
    ...ReactPhoneInput.propTypes,
    ...FormInput.propTypes,
  }

  handleChange = (value) => {
    const { name, onChange } = this.props
    // if phone number value match just a telephone area code, save it as empty string
    if (value.match(/^\+\d{0,4}$/)) value = ""

    if (onChange) onChange(name, value)
  }

  render() {
    const {
      value,
      onChange,
      disabled,
      withLabel,
      labelClassName,
      label,
      inputClassName,
      validationState,
      errorMessage,
      required,
      ...props
    } = this.props

    return (
      <FormGroup className="form-input-container" validationState={ validationState }>
        {withLabel && (
          <ControlLabel>
            {required && "*"} {label}
          </ControlLabel>
        )}
        <ReactPhoneInput
          value={ value }
          defaultCountry="es"
          preferredCountries={ [
            "es",
            "be",
            "bg",
            "cz",
            "dk",
            "de",
            "ee",
            "ie",
            "gr",
            "fr",
            "hr",
            "it",
            "cy",
            "lv",
            "lt",
            "lu",
            "hu",
            "mt",
            "nl",
            "at",
            "pl",
            "pt",
            "ro",
            "si",
            "sk",
            "fi",
            "se",
            "gb",
          ] }
          autoComplete="do-not-autofill"
          onChange={ this.handleChange }
          disabled={ disabled }
          flagsImagePath="img/flags.png"
          inputProps={ {
            ...props,
            className: classname("form-input form-control", inputClassName),
          } }
        />
        {validationState === "error" && <span className="text-danger">{errorMessage}</span>}
      </FormGroup>
    )
  }
}
