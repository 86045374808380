import React from "react"
import { connect } from "react-redux"

import AdminSchoolsManagement from "../components/Admin/AdminSchoolsManagement"

import { SIGNOUT_REQUEST, LIST_REQUEST, EDIT_REQUEST, CREATE_REQUEST, REMOVE_REQUEST } from "../types"

const AdminSchoolsManagementContainer = ({ ...props }) => <AdminSchoolsManagement { ...props } />

const mapStateToProps = state => ({
  schoolsList: state.admin.schools.list,
  editionRequestData: state.admin.editionRequestData,
})

const mapDispatchToProps = dispatch => ({
  getSchoolsList: () => dispatch({ type: LIST_REQUEST, payload: { type: "schools" } }),
  editSchool: data => dispatch({ type: EDIT_REQUEST, payload: { data, type: "schools" } }),
  createSchool: data => dispatch({ type: CREATE_REQUEST, payload: { data, type: "schools" } }),
  removeSchool: id => dispatch({ type: REMOVE_REQUEST, payload: { id, type: "schools" } }),
  logout: () => dispatch({ type: SIGNOUT_REQUEST }),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminSchoolsManagementContainer)
