import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import each from "lodash/each";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import assign from "lodash/assign";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import i18n from "i18next";
import { compose } from "redux";
import size from "lodash/size";
import KeyboardEventHandler from 'react-keyboard-event-handler';

import AgreementCard from "../AgreementCard";
import SpecificAgreementFormNavigator from "./SpecificAgreementFormNavigator";
import SpecificAgreementActionButtons from "../SpecificAgreementActionButtons";
import SpecificAgreementFormSchoolPanel from "./SpecificAgreementFormSchoolPanel";
import SpecificAgreementStatusCard from "../SpecificAgreementStatusCard";

import ElectronicSignature from "../ElectronicSignature/ElectronicSignature";

import CompanyForm from "../CompanyForm";
import SchoolForm from "../SchoolForm";
import ActivityForm from "../ActivityForm";
import StudentForm from "../StudentForm";
import TutorForm from "../TutorForm";
import PrintingTemplateForm from "../PrintingTemplateForm";
import StudentCard from "../StudentCard";

import Session from "../../helpers/SessionHelper";

import { SpecificAgreements } from "../../api";
import SpecificAgreementsAPI from "../../api/SpecificAgreements";

import { FormGroup, Alert } from "../Common";
import { GenericFormInput } from "../GenericInputs";

import {
  ConnectWithSidebar,
  withErrorManager,
  consumeSpecificAgreementForm
} from "../../hoc";
import { stepIndexes } from "../../context";

import Routes from "../../routes";

import {
  computeAgreementId,
  computeAgreementRequestedBy,
  computeFullName,
  parseSpecificAgreementData,
  scrollTopAnimated
} from "../../utils";

import "./SpecificAgreementForm.css";
import SpecificAgreementCard from "./SpecificAgreementCard";
import SpecificAgreementStudentCard from "./SpecificAgreementStudentCard";
import SpecificAgreementRecruiterDirections from "./SpecificAgreementRecruiterDirections";

const StepFormWrapper = ({ title, children, ...props }) => (
  <FormGroup
    formTitle={title}
    className="agreement-form__step-container"
    {...props}
  >
    <Col xs={12} sm={10} className="clearfix">
      {children}
    </Col>
  </FormGroup>
);

export class SpecificAgreementForm extends Component {
  static propTypes = {
    getSchools: PropTypes.func.isRequired,
    schools: PropTypes.arrayOf(PropTypes.object),
    editAgreement: PropTypes.func.isRequired,
    agreementId: PropTypes.string,
    editErrors: PropTypes.objectOf(PropTypes.array),
    requestApproval: PropTypes.func.isRequired,
    editApproval: PropTypes.func.isRequired,
    agreement: PropTypes.shape({
      data: PropTypes.object,
      isLoading: PropTypes.bool,
      magicNumber: PropTypes.number
    }).isRequired,
    getAgreementData: PropTypes.func.isRequired,
    createAgreement: PropTypes.func.isRequired,
    onUpdateSidebar: PropTypes.func,
    sendForSignature: PropTypes.func.isRequired,
    signAgreement: PropTypes.func.isRequired,
    comments: PropTypes.array.isRequired,
    createComment: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    getValidationState: PropTypes.func.isRequired,
    getErrorMessage: PropTypes.func.isRequired,
    removeSpecificAgreement: PropTypes.func.isRequired,
    functions: PropTypes.array,
    companySectors: PropTypes.array,
    activities: PropTypes.array,
    extendSpecificAgreement: PropTypes.func.isRequired,
    modifySpecificAgreement: PropTypes.func.isRequired,
    cancelSpecificAgreement: PropTypes.func.isRequired,
    terminateSpecificAgreement: PropTypes.func.isRequired,
    curriculums: PropTypes.array.isRequired,
    savePrintingTemplate: PropTypes.func.isRequired,
    sendEmailToStudentSpecificAgreement: PropTypes.func.isRequired,
    getCompanySectors: PropTypes.func.isRequired,
    sendReminder: PropTypes.func.isRequired,
  };

  static defaultProps = {
    schools: [],
    agreementId: null,
    editErrors: {},
    onUpdateSidebar: () => true,
    functions: [],
    companySectors: [],
    activities: []
  };

  titles = {
    [stepIndexes.SCHOOL]: {
      btnTitle: i18n.t("spa:specific_agreements.form.steps.school.label"),
      formTitle: i18n.t("spa:specific_agreements.form.steps.school.title")
    },
    [stepIndexes.COMPANY]: {
      btnTitle: i18n.t("spa:specific_agreements.form.steps.company.label"),
      formTitle: i18n.t("spa:specific_agreements.form.steps.company.title")
    },
    [stepIndexes.JOB]: {
      btnTitle: i18n.t("spa:specific_agreements.form.steps.job.label"),
      formTitle: i18n.t("spa:specific_agreements.form.steps.job.title")
    },
    [stepIndexes.STUDENT]: {
      btnTitle: i18n.t("spa:specific_agreements.form.steps.student.label"),
      formTitle: i18n.t("spa:specific_agreements.form.steps.student.title")
    },
    [stepIndexes.TUTOR]: {
      btnTitle: i18n.t("spa:specific_agreements.form.steps.tutor.label"),
      formTitle: i18n.t("spa:specific_agreements.form.steps.tutor.title")
    },
    [stepIndexes.REVIEW]: {
      btnTitle: i18n.t("spa:specific_agreements.form.steps.review.label"),
      formTitle: i18n.t("spa:specific_agreements.form.steps.review.title")
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      bySchoolAdmin:
        (props.location.state && props.location.state.bySchoolAdmin) || false,
      currentTitle: null,
      editable: true,
      updateUrl: null,
      data: {
        selectedTemplateId: null
      }
    };
    this.getValidationState = inputName =>
      props.getValidationState(inputName, this.validationKeyFormatter);
    this.getErrorMessage = inputName =>
      props.getErrorMessage(inputName, this.validationKeyFormatter);
    this.companyForm = React.createRef();
    this.studentForm = React.createRef();
    this.activityForm = React.createRef();
    this.companyTutorForm = React.createRef();
    this.schoolTutorForm = React.createRef();
    this.navigator = React.createRef();
    this.save = this.saveAgreement.bind(this, false);
    this.send = this.saveAgreement.bind(this, true);
    this.ignoreSignature = this.sendForSignature.bind(this, true);
    if (props.location.state && props.location.state.bySchoolAdmin) {
      props.navigateTo(1);
      props.updateData("school", {
        school_id: props.location.state.school_id
      });
    }
  }

  validationKeyFormatter = name => {
    const { currentStep } = this.props;

    if (currentStep === stepIndexes.COMPANY) {
      return name.replace("company_", "");
    }
    return name;
  };

  componentDidMount() {
    const {
      getSchools,
      getAgreementData,
      onUpdateSidebar,
      getCompanySectors,
      data: { agreementId }
    } = this.props;
    const { bySchoolAdmin } = this.state;

    if (!agreementId) {
      if (!bySchoolAdmin) {
        getSchools();
      } else {
        getCompanySectors();
      }
      onUpdateSidebar("draft");
    }
    if (agreementId) getAgreementData(agreementId);
  }

  componentWillReceiveProps(nextProps) {
    const { bySchoolAdmin } = this.state;
    const {
      navigateTo,
      currentStep,
      getAgreementData,
      data: { company },
      agreement,
      agreementId,
      updateData,
      editErrors
    } = this.props;
    if (nextProps.agreementId !== agreementId) {
      getAgreementData(nextProps.agreementId);
    }
    if (JSON.stringify(nextProps.editErrors) !== JSON.stringify(editErrors)) {
      setTimeout(() => {
        [
          "activityForm",
          "studentForm",
          "companyForm",
          "companyTutorForm",
          "schoolTutorForm"
        ]
          .filter(form => !!this[form].current)
          .map(form => this[form].current)
          .forEach(form => {
            form.forceUpdate();
            return true;
          });
      });
      if (!isEmpty(nextProps.editErrors)) {
        scrollTopAnimated(
          document.querySelector("#main-container > .clearfix.col-xs-12"),
          2000
        );
      }
    }
    if (
      !nextProps.agreement.isLoading &&
      !isEmpty(nextProps.agreement.data) &&
      agreement.magicNumber !== nextProps.agreement.magicNumber
    ) {
      if (
        bySchoolAdmin &&
        currentStep === 1 &&
        !isEmpty(nextProps.editErrors)
      ) {
        updateData("company", {
          id: nextProps.agreement.data.specific_agreement.company_id
        });
        this.setState({
          needCompanyCreation: !nextProps.agreement.data.specific_agreement
            .company_id
        });
      } else if (isEmpty(nextProps.editErrors)) {
        const requireNavigation =
          nextProps.agreement.data.specific_agreement.state === "draft" ||
          (nextProps.agreement.data.specific_agreement.state === "draft" &&
            bySchoolAdmin);
        this.getAgreementData(nextProps.agreement.data, requireNavigation);
        if (!requireNavigation) {
          navigateTo(5);
        }
      }
    }
  }

  getAgreementData(agreement, requireNavigation = false) {
    const {
      updateMultiData,
      onUpdateSidebar,
      currentStep,
      navigateTo,
      data: { company }
    } = this.props;
    const { bySchoolAdmin } = this.state;
    const {
      specific_agreement: specificAgreement,
      update_url: updateUrl,
      print_url: printUrl,
      sign_url: signUrl,
      send_for_signature_url: sendForSignatureUrl
    } = agreement || this.props.agreement;
    const {
      id,
      state = "draft",
      approvals = [],
      specific_agreement_printing_template_id: selectedTemplateId,
      reason_for_alteration: reasonForAlteration,
      is_student_paid_by_school: isStudentPaidBySchool,
      ...data
    } = specificAgreement;
    onUpdateSidebar(state);
    if (approvals.length !== 0) {
      approvals[0] = {
        ...approvals[0],
        ...approvals[0].user
      };
    }
    const dataToStateObj = parseSpecificAgreementData(data);
    if (requireNavigation) {
      if (
        currentStep ||
        (bySchoolAdmin && state === "draft" && currentStep === 1)
      )
        navigateTo(currentStep + 1);
      else navigateTo(5);
    }
    updateMultiData({
      ...dataToStateObj,
      job: {
        ...dataToStateObj.job,
        invoicing_status:
          dataToStateObj.job.invoicing_status === ""
            ? "not_invoiced"
            : dataToStateObj.job.invoicing_status
      },
      student: {
        ...dataToStateObj.student,
        is_student_paid_by_school:
          bySchoolAdmin && state === "in_school_review"
            ? !!company.company_are_students_paid_by_school
            : isStudentPaidBySchool
      },
      agreementId: id,
      approvals,
      updateUrl,
      printUrl,
      signUrl,
      sendForSignatureUrl,
      selectedTemplateId: this.getSelectedTemplateId(specificAgreement),
      reason_for_alteration: reasonForAlteration,
      state,
      signature: {
        student_email: dataToStateObj.student.student_email_address,
        company_signatory_email:
          dataToStateObj.company.company_representative_email_address,
        school_signatory_email: dataToStateObj.school.default_e_signatory_email,
        ...(data.signature || {})
      }
    });
    this.setState({
      canEditForm: !!updateUrl,
      canUseActionButtons:
        !!updateUrl || !!printUrl || !!signUrl || !!sendForSignatureUrl
    });
  }

  getSelectedTemplateId(agreement) {
    if (!agreement.school) return null;
    if (agreement.specific_agreement_printing_template_id)
      return agreement.specific_agreement_printing_template_id;
    if (agreement.school.specific_agreement_printing_templates) {
      const defaultTemplate = find(
        agreement.school.specific_agreement_printing_templates,
        template => template.is_default
      );
      if (defaultTemplate) return defaultTemplate.id;
      return agreement.school.specific_agreement_printing_templates[0];
    }
    return null;
  }

  formatKeys(obj, withName = "", omitKeys = []) {
    each(omit(obj, omitKeys), (value, key) => {
      if (withName.length !== 0 && key.startsWith(withName)) return true;
      if (withName.length === 0) return true;
      const newKey = `${withName}_${key}`;
      // eslint-disable-next-line
      obj[newKey] = value;
      return true;
    });
    return obj;
  }

  onNavigate = (newStep = this.props.currentStep + 1) => {
    const { navigateTo, currentStep } = this.props;

    if (currentStep === 1 && newStep === 2) {
      this.createAgreement();
    } else if (newStep < 2 || newStep < currentStep) {
      navigateTo(newStep);
    } else {
      this.saveAgreement(false);
    }
  };

  handleChange(stateKey, name, value) {
    const { updateData, updateMultiData } = this.props;

    if (!isObject(name) && !isArray(name)) {
      updateData(stateKey, {
        [name]: value
      });
    } else {
      updateMultiData({
        [stateKey]: name
      });
    }
  }

  handleInputChange = e => {
    const { updateMultiData } = this.props;

    updateMultiData({
      [e.target.name]: e.target.value
    });
  };

  renderStudentForm() {
    const { canEditForm } = this.state;
    const {
      curriculums,
      currentStep,
      data: { student, school }
    } = this.props;

    if (!(currentStep === 5 || currentStep === stepIndexes.STUDENT))
      return null;
    return (
      <StepFormWrapper title={this.titles[stepIndexes.STUDENT].formTitle}>
        <StudentForm
          ref={this.studentForm}
          defaultData={student}
          departments={school.departments || []}
          getValidationState={this.getValidationState}
          getErrorMessage={this.getErrorMessage}
          editable={canEditForm}
          curriculums={curriculums}
          onChange={(name, value) => this.handleChange("student", name, value)}
          addressRequired={
            school &&
            school.postal_address_from_student_required &&
            Session.isSchoolAdmin()
          }
          StudentEmailDomain={school && school.student_email_domain}
          studentSocialSecurityNumberRequired={school && school.student_social_security_number_required}
          studentIdentityNumberFileKeyRequired={school && school.student_identity_number_file_key_required}
          studentPhoneNumberRequired={school && school.student_phone_number_required}
        />
      </StepFormWrapper>
    );
  }

  renderActivityStep() {
    const { canEditForm } = this.state;
    const {
      functions,
      currentStep,
      data: { job, alteration_status: alterationStatus, school }
    } = this.props;

    if (
      !(currentStep === stepIndexes.JOB || currentStep === stepIndexes.REVIEW)
    )
      return null;
    return (
      <StepFormWrapper title={this.titles[stepIndexes.JOB].formTitle}>
        <ActivityForm
          defaultData={job}
          ref={this.activityForm}
          getValidationState={this.getValidationState}
          getErrorMessage={this.getErrorMessage}
          editable={canEditForm}
          functions={functions}
          onChange={(name, value) => this.handleChange("job", name, value)}
          showRealEndDate={!!alterationStatus}
          specificCompetenciesTemplates={school.specific_competencies_templates}
          jobNumberOfWeeklyHoursRequired={school.job_number_of_weekly_hours_required}
          jobOtherBenefitsEnabled={school.job_other_benefits_enabled}
          jobTotalDaysWorkedEnabled={school.job_total_days_worked_enabled}
          jobSalaryMode={school.job_salary_mode}
        />
      </StepFormWrapper>
    );
  }

  getKeyCompanyFormField = inputName => {
    const { currentStep } = this.props;

    if (
      currentStep === stepIndexes.COMPANY ||
      inputName.startsWith("company_")
    ) {
      return inputName;
    }
    return `company_${inputName}`;
  };

  renderCompanyStep(mode = "autocomplete") {
    const {
      companySectors,
      currentStep,
      data: { company, school, recruiter_email_address: recruiterEmailAddress },
      updateData
    } = this.props;
    const { canEditForm, bySchoolAdmin } = this.state;

    if (
      !(
        currentStep === stepIndexes.COMPANY ||
        currentStep === stepIndexes.REVIEW
      )
    )
      return null;
    return (
      <StepFormWrapper title={this.titles[stepIndexes.COMPANY].formTitle}>
        <CompanyForm
          ref={this.companyForm}
          mode={mode}
          defaultData={company}
          onChangeMode={() => this.setState({ needCompanyCreation: true })}
          getValidationState={inputName =>
            this.getValidationState(this.getKeyCompanyFormField(inputName))
          }
          getErrorMessage={inputName =>
            this.getErrorMessage(this.getKeyCompanyFormField(inputName))
          }
          editable={canEditForm}
          companySectors={companySectors}
          onChange={(name, val) => {
            let finalName = name;
            if (currentStep !== stepIndexes.COMPANY) {
              if (
                !finalName.startsWith("company") &&
                finalName !== "jobteaser_id"
              )
                finalName = `company_${finalName}`;
            }
            this.handleChange("company", finalName, val);
            if (isObject(name)) {
              updateData("student", {
                is_student_paid_by_school: name.are_students_paid_by_school
              });
            }
          }}
          schoolId={school.id || school.school_id}
          showAvatarInput={currentStep === 1}
          showJobteaserId
          showSectorUuid
        />
        {bySchoolAdmin && currentStep === 1 && (
          <GenericFormInput
            required={true}
            name="recruiter_email_address"
            value={recruiterEmailAddress}
            onChange={this.handleInputChange}
            getValidationState={this.getValidationState}
            getErrorMessage={this.getErrorMessage}
          />
        )}
      </StepFormWrapper>
    );
  }

  renderSchoolStep(mode = "select") {
    const { currentStep, updateData, schools } = this.props;

    if (currentStep !== stepIndexes.SCHOOL) return null;
    return (
      <StepFormWrapper title={this.titles[stepIndexes.SCHOOL].formTitle}>
        <SchoolForm
          mode={mode}
          schoolData={schools}
          getValidationState={this.getValidationState}
          getErrorMessage={this.getErrorMessage}
          onSelectSchool={school => updateData("school", school)}
        />
      </StepFormWrapper>
    );
  }

  renderTutorStep(type = "company") {
    const { canEditForm } = this.state;
    const {
      currentStep,
      data: { tutor, school }
    } = this.props;

    if (!(currentStep === 5 || currentStep === stepIndexes.TUTOR)) return null;
    return (
      <StepFormWrapper
        title={
          type === "school"
            ? i18n.t("spa:specific_agreements.form.steps.school_tutor.title")
            : this.titles[stepIndexes.TUTOR].formTitle
        }
      >
        <TutorForm
          ref={this[`${type}TutorForm`]}
          defaultData={tutor}
          tutorType={type}
          getValidationState={this.getValidationState}
          getErrorMessage={this.getErrorMessage}
          editable={canEditForm}
          onChange={(name, value) => this.handleChange("tutor", name, value)}
          companyTutorDniEnabled={school.company_tutor_dni_enabled}
        />
      </StepFormWrapper>
    );
  }

  async createAgreement() {
    const {
      createAgreement,
      data: { school, recruiter_email_address: recruiterEmailAddress }
    } = this.props;
    const { bySchoolAdmin, needCompanyCreation } = this.state;
    const finalData = this.mergeStatesDatas();
    let companyObj = {};

    if (needCompanyCreation) {
      const newObj = assign(
        {},
        pickBy(
          finalData,
          (value, key) =>
            key.startsWith("company_") && !key.startsWith("company_tutor_")
        )
      );
      each(newObj, (value, key) => {
        const newKey = key.replace(/^company_/, "");
        newObj[newKey] = value;
        delete newObj[key];
        return true;
      });
      companyObj = { ...newObj, school_id: school.school_id };
      companyObj.logo_url = await this.companyForm.current.triggerAvatarUpload();
    }
    if (bySchoolAdmin) {
      finalData.recruiter_email_address = recruiterEmailAddress;
      finalData.redirect_url = `${window.location.origin}/signup/invitation`;
    }
    createAgreement(this.state.needCompanyCreation, companyObj, finalData);
  }

  getActionName() {
    const {
      data: { state }
    } = this.props;

    switch (state) {
      case "draft":
        return "to_student_info_completion";
      case "student_info_completion":
        return "review";
      case "in_school_review":
        return "review_done";
      default:
        return undefined;
    }
  }

  goToNextStep = () => {
    const { currentStep } = this.props;

    if (currentStep === size(this.titles) - 1) {
      this.saveAgreement(true);
    } else this.onNavigate(currentStep + 1);
  };

  mergeStatesDatas() {
    const {
      data: { company, school, job, student, tutor, signature }
    } = this.props;
    return {
      ...this.formatKeys(company, "company", ["jobteaser_id"]),
      ...this.formatKeys(school, "school"),
      ...this.formatKeys(job, "job", [
        "invoicing_status",
        "specific_competencies",
        "work_timetable",
        "management_fee",
        "invoice_number",
        "other_information",
        "jobteaser_job_offer_id"
      ]),
      ...this.formatKeys(student, "student", ["is_student_paid_by_school"]),
      ...this.formatKeys(tutor, ""),
      company_attributes: {
        id: company.id || company.company_id,
        jobteaser_id: company.jobteaser_id
      },
      signature_attributes: signature
    };
  }

  getDataStateAgreement() {
    const { data } = this.props;

    return {
      id: data.agreementId,
      specific_agreement_printing_template_id: data.selectedTemplateId,
      alteration_status: data.alteration_status,
      reason_for_alteration: data.reason_for_alteration,
      ...this.mergeStatesDatas()
    };
  }

  saveAgreement(requireValidation) {
    const {
      currentStep,
      editAgreement,
      data: { updateUrl }
    } = this.props;

    editAgreement(
      this.getDataStateAgreement(),
      requireValidation ? this.getActionName() : null,
      updateUrl,
      currentStep === 5
    );
  }

  editAssignee = assignedId => {
    const {
      editAgreement,
      data: { updateUrl }
    } = this.props;

    editAgreement({ assigned_id: assignedId }, null, updateUrl, false);
  };

  removeAssignee = () => {
    const {
      editAgreement,
      data: { updateUrl }
    } = this.props;

    editAgreement({ assigned_id: null }, null, updateUrl, false);
  };

  askApproval(userIds) {
    const {
      requestApproval,
      data: { updateUrl, agreementId }
    } = this.props;

    requestApproval(
      this.getDataStateAgreement(),
      this.getActionName(),
      "pending",
      agreementId,
      userIds,
      updateUrl
    );
  }

  editApproval(state = "pending", editAgreement = true) {
    const {
      editApproval,
      data: { approvals, agreementId, updateUrl }
    } = this.props;

    editApproval(
      agreementId,
      approvals[0].user_id,
      state,
      approvals[0].id,
      this.getDataStateAgreement(),
      updateUrl,
      editAgreement
    );
  }

  doApprovalAction() {
    const {
      data: { state }
    } = this.props;

    switch (state) {
      case "in_school_review":
        this.askApproval();
        break;
      case "pending_approvals":
        this.editApproval();
        break;
      default:
        break;
    }
  }

  removeSpecificAgreement = () => {
    const {
      removeSpecificAgreement,
      data: { agreementId }
    } = this.props;

    if (
      window.confirm(i18n.t("spa:shared.confirm_box.remove_specific_agreement"))
    ) {
      removeSpecificAgreement(agreementId);
    }
  };

  sendForSignature = (byPass = false) => {
    const {
      sendForSignature,
      data: { sendForSignatureUrl, agreementId }
    } = this.props;

    sendForSignature(
      sendForSignatureUrl,
      agreementId,
      this.getDataStateAgreement(),
      byPass
    );
  };

  signAgreement = () => {
    const {
      signAgreement,
      data: { signUrl }
    } = this.props;

    signAgreement(signUrl, this.getDataStateAgreement());
  };

  sendReminder = () => {
    const {
      sendReminder,
      data: { agreementId },
    } = this.props;

    sendReminder(
      agreementId
    );
  };

  renderButtonNavigator() {
    const {
      history,
      data: {
        state,
        agreementId,
        updateUrl,
        printUrl,
        sendForSignatureUrl,
        signUrl,
        school
      },
      currentStep,
      sendEmailToStudentSpecificAgreement,
    } = this.props;
    const { canUseActionButtons, bySchoolAdmin } = this.state;

    if (!canUseActionButtons && agreementId) return null;

    return (
      <SpecificAgreementActionButtons
        state={state}
        canSave={!!updateUrl}
        onSave={this.save}
        onSend={this.send}
        onSendEmailToStudent={ () => sendEmailToStudentSpecificAgreement(this.getDataStateAgreement()) }
        canSendForSignature={!!sendForSignatureUrl}
        onSendForSignature={this.sendForSignature}
        canPrint={!!printUrl}
        onPrint={() =>
          history.push(
            Routes.SPECIFIC_AGREEMENT_PRINTING_TEMPLATE_PREVIEW.replace(
              ":id",
              agreementId
            )
          )
        }
        canMarkAsSignedAndClosed={!!signUrl}
        onMarkAsSignedAndClosed={this.signAgreement}
        showRemoveButton={!!agreementId && Session.isRecruiter()}
        onRemove={this.removeSpecificAgreement}
        onBack={() => this.navigator.current.goBack()}
        lastStep={currentStep === 5}
        onNext={this.goToNextStep}
        bySchoolAdmin={bySchoolAdmin}
        hasElectronicSignature={school.use_e_signature}
      />
    );
  }

  onSelectApproval(approvals) {
    this.askApproval(approvals.map(approval => approval.id));
  }

  async onRemoveApproval(approval) {
    const {
      updateMultiData,
      data: { approvals }
    } = this.props;
    const approvalsCpy = approvals.slice();

    const approvalIndex = findIndex(
      approvalsCpy,
      appr => appr.user_id === approval.user_id
    );
    if (approvalsCpy[approvalIndex].id)
      await SpecificAgreements.removeApproval(approvalsCpy[approvalIndex].id);
    approvalsCpy.splice(approvalIndex, 1);
    updateMultiData({ approvals: approvalsCpy });
  }

  onCancelSignature = () => {
    const {
      data: { agreementId },
      cancelSignature
    } = this.props;

    cancelSignature(agreementId);
  };

  renderRightContainer() {
    const {
      comments,
      createComment,
      deleteComment,
      activities,
      modifySpecificAgreement,
      cancelSpecificAgreement,
      extendSpecificAgreement,
      editAgreement,
      terminateSpecificAgreement,
      isSendingSignature,
      data: {
        state,
        agreementId,
        job: { job_real_ended_at: jobRealEndedAt },
        signature,
        created_at: createdAt,
        student: {
          student_first_name: studentFirstName,
          student_last_name: studentLastName,
        },
        company: {
          company_name: companyName,
        },
        tag_list: tagList,
        school,
      },
      skipApprovalValidation
    } = this.props;
    const { update_url: updateUrl, update_tags_url: updateTagsUrl } = this.props.agreement.data;

    if (state !== "draft" && !Session.isRecruiter()) {
      return (
        <SpecificAgreementFormSchoolPanel
          comments={comments}
          onApprove={() => this.editApproval("approved", false)}
          onDeny={() => this.editApproval("denied", false)}
          onCancel={() => this.editApproval("pending", false)}
          onSelectApproval={approval => this.onSelectApproval(approval)}
          onRemoveApproval={approval => this.onRemoveApproval(approval)}
          onSubmitComment={value => createComment(agreementId, value)}
          onDeleteComment={id => deleteComment(id, agreementId)}
          onDeleteTag={tag => {
            editAgreement(
              {
                id: agreementId,
                tag_list: tagList.filter((value) => {
                  return value !== tag
                }).join(","),
              },
              undefined,
              updateTagsUrl
            )}
          }
          onCreateTag={tag => {
            tagList.push(tag.value)
            editAgreement(
              {
                id: agreementId,
                tag_list: tagList.join(","),
              },
              undefined,
              updateTagsUrl
            )}
          }
          activities={activities}
          onModify={(reason, endDate) =>
            modifySpecificAgreement(agreementId, reason, endDate)
          }
          onExtend={(reason, endDate) =>
            extendSpecificAgreement(agreementId, reason, endDate)
          }
          onTerminate={(reason, endDate, hoursDone) =>
            terminateSpecificAgreement(agreementId, reason, endDate, hoursDone)
          }
          onEditReason={(reason, endDate, hoursDone) =>
            editAgreement(
              {
                reason_for_alteration: reason,
                id: agreementId,
                job_real_ended_at: endDate || jobRealEndedAt,
                hours_done: hoursDone
              },
              undefined,
              updateUrl
            )
          }
          onSkipValidation={() =>
            skipApprovalValidation(
              agreementId,
              this.getDataStateAgreement(),
              updateUrl
            )
          }
          sendForSignature={this.sendForSignature}
          getValidationState={this.getValidationState}
          getErrorMessage={this.getErrorMessage}
          signAgreement={this.signAgreement}
          cancelSignature={this.onCancelSignature}
          ignoreSignature={this.ignoreSignature}
          isSendingSignature={isSendingSignature}
          sendReminder={this.sendReminder}
        />
      );
    } else if (Session.isRecruiter()) {
      return (
        <Fragment>
          {!!signature.created_at && school.use_e_signature && (
            <Col xs={12} sm={5} className="agreement-form__approval-container">
              <ElectronicSignature
                mode="showForRecruiter"
                {...signature}
                getPDF={() => SpecificAgreementsAPI.getPDF(agreementId)}
                canCancelSignature={false}
                pdfFileName={`${computeAgreementId({
                  agreementId,
                  createdAt,
                })} - ${computeFullName({
                  first_name: studentFirstName,
                  last_name: studentLastName
                })} - ${companyName}.pdf`}
              />
            </Col>
          )}
          <SpecificAgreementRecruiterDirections />
        </Fragment>
      )
    }
    return null;
  }

  renderPrintingTemplateSelection() {
    const {
      savePrintingTemplate,
      data: { agreementId, school, selectedTemplateId },
      updateMultiData
    } = this.props;
    const {
      print_url: printUrl,
      update_url: updateUrl
    } = this.props.agreement.data;
    const { canEditForm } = this.state;

    if (!Session.isSchoolAdmin()) {
      return null;
    }

    return (
      <FormGroup
        formTitle={i18n.t(
          "spa:specific_agreements.form.steps.specific_agreement_printing_template.title"
        )}
        className="agreement-form__step-container"
      >
        <PrintingTemplateForm
          templates={school.specific_agreement_printing_templates}
          selectedId={selectedTemplateId}
          onChangeTemplate={e =>
            updateMultiData({ selectedTemplateId: e.target.value })
          }
          withPreviewButton={!!printUrl}
          onPreview={() =>
            savePrintingTemplate(this.getDataStateAgreement(), updateUrl)
          }
          disabled={!canEditForm}
        />
      </FormGroup>
    );
  }

  getAgreementCardBadgeStyle() {
    const {
      data: { state }
    } = this.props;

    if (state === "cancelled") {
      return "danger";
    }
    if (state !== "approved" && state !== "denied") {
      return state === "draft" ? "default" : "success";
    }
    return state === "approved" ? "primary" : "danger";
  }

  render() {
    const { bySchoolAdmin } = this.state;
    const {
      agreement,
      currentStep,
      editErrors,
      data: { state, school, agreementId },
      cancelSpecificAgreement,
    } = this.props;

    if (
      (isEmpty(agreement.data.specific_agreement) && agreementId) ||
      (state === "draft" && Session.isSchoolAdmin() && agreementId) ||
      (agreement.isLoading && !!agreementId)
    ) {
      return null;
    }

    return (
      <div className="agreement-form__container">
        <KeyboardEventHandler
          handleKeys={ ["ctrl+s", "meta+s"] }
          handleFocusableElements
          onKeyEvent={ (key, e) => {
            e.preventDefault()
            this.saveAgreement(false)
          }}
        />
        <div className="agreement-form__header-container clearfix">
          <div className="agreement-form__header-card clearfix">
            {(Session.isSchoolAdmin() || Session.isOAP()) && state !== "draft" && (
              <SpecificAgreementStatusCard
                onAssign={this.editAssignee}
                onUnassign={this.removeAssignee}
                schoolId={school.school_id}
                agreementStatus={i18n.t(
                  `spa:card.specific_agreement_state.${state}.${Session.role}`
                )}
                agreementState={state}
                agreementStateBadgeStyle={this.getAgreementCardBadgeStyle()}
                agreementId={computeAgreementId({
                  agreementId,
                  createdAt: agreement.data.specific_agreement.created_at
                })}
                studentFullName={ computeFullName({
                  first_name: agreement.data.specific_agreement.student_first_name,
                  last_name: agreement.data.specific_agreement.student_last_name,
                }) }
                onSubmit={(reason) =>
                  cancelSpecificAgreement(agreementId, reason)
                }
              />
            )}
            <SpecificAgreementCard
              showGeneralAgreementData={
                !!(
                  agreement.data.specific_agreement &&
                  agreement.data.specific_agreement.id
                ) && school.use_general_agreement
              }
            />
          </div>
          {Session.isSchoolAdmin() && state !== "draft" && (
            <div className="agreement-form__header-card clearfix">
              <SpecificAgreementStudentCard />
            </div>
          )}
        </div>
        {!isEmpty(editErrors) && (
          <Alert type="danger" style={{ marginBottom: 0 }}>
            <span>
              {i18n.t("spa:specific_agreements.form.alerts.has_error")}
              {editErrors.school_id && ` : ${i18n.t("spa:specific_agreements.form.alerts.school_required")}`}
            </span>
          </Alert>
        )}
        {state === "draft" && Session.isRecruiter() && (
          <SpecificAgreementFormNavigator
            ref={this.navigator}
            titles={this.titles}
            onNavigate={this.onNavigate}
          />
        )}
        <Row style={{ zIndex: 1 }}>
          <Col xs={12} sm={7}>
            {agreementId && this.renderPrintingTemplateSelection()}
            {this.renderSchoolStep()}
            {this.renderCompanyStep(
              currentStep === stepIndexes.COMPANY ? "autocomplete" : "form"
            )}
            {this.renderActivityStep()}
            {this.renderStudentForm()}
            {this.renderTutorStep()}
            {currentStep === stepIndexes.REVIEW &&
              Session.isSchoolAdmin() &&
              this.renderTutorStep("school")}
            {this.renderButtonNavigator()}
          </Col>
          {this.renderRightContainer()}
        </Row>
      </div>
    );
  }
}

export default compose(
  ConnectWithSidebar,
  withErrorManager(props => props.editErrors),
  consumeSpecificAgreementForm
)(SpecificAgreementForm);
