import { Comments } from "../api"

export async function getComments(specific_agreement_id) {
  try {
    const dataComments = await Comments.getComments(specific_agreement_id)
    return dataComments
  } catch (e) {
    throw e
  }
}

export async function createComment(specific_agreement_id, body) {
  try {
    const dataCreate = await Comments.createComment(specific_agreement_id, body)
    return dataCreate
  } catch (e) {
    throw e
  }
}

export async function deleteComment(id) {
  try {
    const dataDelete = await Comments.deleteComment(id)
    return dataDelete
  } catch (e) {
    throw e
  }
}