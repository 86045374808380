import React from "react"
import PropTypes from "prop-types"

const AdminSchoolsApiKeys = ({ clientIds, clientSecrets }) => (
  <div className="admin-schools-api-keys__container">
    <div className="admin-schools-api-keys__list-container">
      {clientIds.map((clientId, index) => (
        <div key={ clientId }>
          <span>Client id: {clientId}</span>
          <span>Client secret: {clientSecrets[index]}</span>
        </div>
      ))}
    </div>
  </div>
)

AdminSchoolsApiKeys.propTypes = {
  clientIds: PropTypes.array,
  clientSecrets: PropTypes.array,
}

AdminSchoolsApiKeys.defaultProps = {
  clientIds: [],
  clientSecrets: [],
}

export default AdminSchoolsApiKeys
