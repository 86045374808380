import React, { Fragment } from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import Autocomplete from "react-autocomplete"
import debounce from "lodash/debounce"

import { FormInput, Button, AutocompleteUserItem, Avatar, Icon } from "../Common"

import { Autocomplete as AutocompleteAPI } from "../../api"

import { Roles } from "../../Constants"

import DefaultAvatar from "../../assets/default-avatar.png"
import { computeFullName } from "../../utils"

export default class AssigneeAutocomplete extends React.Component {
  static propTypes = {
    onAssign: PropTypes.func.isRequired,
    onUnassign: PropTypes.func.isRequired,
    assignee: PropTypes.object,
    schoolId: PropTypes.number.isRequired,
  }

  static defaultProps = {
    fullName: "",
    assignee: null,
  }

  state = {
    users: [],
    inputValue:
      (this.props.assignee &&
        computeFullName({ first_name: this.props.assignee.first_name, last_name: this.props.assignee.last_name })) ||
      "",
    selectedUser: this.props.assignee,
  }

  constructor(props) {
    super(props)
    this.autocompleteUsers = debounce(this.autocompleteUsers, 300)
  }

  autocompleteUsers = async (value = "") => {
    const { schoolId } = this.props
    const data = await AutocompleteAPI.autocompleteUsers(value, [ Roles.SCHOOL_ADMIN, Roles.OAP ], {
      by_school_id: schoolId,
    })
    this.setState({ users: data })
  }

  handleChange = (event, value) => {
    this.setState({
      inputValue: value,
    })
    this.autocompleteUsers(value)
  }

  renderItem = item => (
    <div>
      <AutocompleteUserItem
        key={ item.id }
        firstName={ item.first_name }
        lastName={ item.last_name }
        position={ item.position }
        avatarUrl={ item.picture_url }
      />
    </div>
  )

  renderSelectedUserPicture = (className) => {
    const { selectedUser } = this.state

    if (!selectedUser) return null
    return <Avatar src={ selectedUser.picture_url || DefaultAvatar } size={ 25 } className={ className } />
  }

  renderInput = ({ ref, ...props }) => (
    <FormInput inputRef={ ref } containerStyle={ { marginTop: 0, marginBottom: 0 } } { ...props } />
  )

  resetInput = () => {
    const { onUnassign } = this.props

    this.setState({
      selectedUser: null,
      inputValue: "",
    })
    onUnassign()
  }

  onSelect = (fullName, item) => {
    this.setState({
      inputValue: fullName,
      selectedUser: item,
    })
  }

  onAssign = () => {
    const { onAssign } = this.props
    const { selectedUser } = this.state

    if (selectedUser) {
      onAssign(selectedUser.id)
    }
  }

  onBlur = () => {
    this.autocompleteUsers()
  }

  getItemValue = item => `${item.first_name} ${item.last_name}`

  render() {
    const { users, inputValue, selectedUser } = this.state

    return (
      <div className="specific-agreement-assignee-card__autocomplete-main-container">
        <div className="specific-agreement-assignee-card__autocomplete-container">
          <Autocomplete
            items={ users }
            getItemValue={ this.getItemValue }
            renderItem={ this.renderItem }
            inputProps={ {
              containerClassName: "specific-agreement-assignee-card__autocomplete-input",
              placeholder: i18n.t("spa:specific_agreement_assignee_card.fields.autocomplete.placeholder"),
              renderIcon: this.renderSelectedUserPicture,
              withIcon: !!selectedUser.id,
              iconName: "times",
              onFocus: this.onBlur,
            } }
            renderInput={ this.renderInput }
            onChange={ this.handleChange }
            onSelect={ this.onSelect }
            value={ inputValue }
            wrapperStyle={ { display: "flex", flex: 1, position: "relative" } }
            menuStyle={ {
              backgroundColor: "white",
              maxHeight: 250,
              position: "absolute",
              top: 37,
              left: 0,
              zIndex: 9999,
            } }
          />
          <Button height={ 37 } bsStyle="primary" onClick={ this.onAssign } disabled={ !selectedUser.id }>
            {i18n.t("spa:specific_agreement_assignee_card.shared.buttons.assign")}
          </Button>
        </div>
      </div>
    )
  }
}
