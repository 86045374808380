import React from "react"
import { connect } from "react-redux"

import InformationForm from "../components/InformationsForm"

import { SIGNUP_FILL_PROFILE_REQUEST } from "../types"

const InformationFormContainer = ({ ...props }) => <InformationForm { ...props } />

const mapStateToProps = state => ({
  errors: state.signup.profileEdition.errors,
})

const mapDispatchToProps = dispatch => ({
  fillProfile: (token, data) => dispatch({ type: SIGNUP_FILL_PROFILE_REQUEST, payload: { token, data } }),
})

export default connect(mapStateToProps, mapDispatchToProps)(InformationFormContainer)
