import React, { PureComponent } from "react"
import { Image } from "react-bootstrap"
import PropTypes from "prop-types"

import logo from "../../assets/logo.png"
import "./Logo.css"

export default class Logo extends PureComponent {
  static propTypes = {
    ...Image.propTypes,
    width: PropTypes.number,
    height: PropTypes.number,
  }

  static defaultProps = {
    width: undefined,
    height: 128,
    className: "logo",
    src: logo,
  }

  render() {
    const { width, height, src, ...props } = this.props

    return <Image src={ src } width={ width } height={ height } { ...props } />
  }
}
