import { takeEvery, put, call, all, takeLatest, select } from "redux-saga/effects"
import i18n from "i18next"

import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_ERROR,
} from "../types"

import Session from "../helpers/SessionHelper"
import Toaster from "../helpers/Toaster"

import { getProfileById as getProfilesAction, editProfile as editProfileAction } from "../actions/profile"

export const userDataSelector = state => state.profile.userData

export function* getProfileById({ payload }) {
  const { id } = payload
  try {
    const data = yield call(getProfilesAction, id)
    yield put({ type: GET_PROFILE_SUCCESS, payload: { data } })
  } catch (e) {
    yield put({ type: GET_PROFILE_ERROR, payload: { message: e.message } })
  }
}

export function* editProfile({ payload }) {
  const { data } = payload
  try {
    const newData = yield call(editProfileAction, data)
    Toaster.getBuilder()
      .setMessage(i18n.t("spa:profile.edit.success_toast.message"))
      .setOptions({
        className: "toaster-success",
      })
      .setType(Toaster.TYPES.SUCCESS)
      .build()
      .show()
    if (Session.logoUrl !== newData.picture_url) {
      Session.logoUrl = newData.picture_url
      Session.triggerChange("logo")
    }
    const { locale } = yield select(userDataSelector)
    if (locale !== newData.locale) window.location.reload()
    yield put({ type: EDIT_PROFILE_SUCCESS })
  } catch (e) {
    yield put({ type: EDIT_PROFILE_ERROR, payload: { errors: e } })
  }
}

function* profileSaga() {
  yield all([ takeEvery(GET_PROFILE_REQUEST, getProfileById), takeLatest(EDIT_PROFILE_REQUEST, editProfile) ])
}

export default profileSaga
