import { call, takeEvery, put, all } from "redux-saga/effects"

import {
  SCHOOL_DATA_REQUEST,
  SCHOOL_DATA_SUCCESS,
  SCHOOL_DATA_ERROR,
} from "../types"

import {
  getSchool as getSchoolAction,
} from "../actions/schools"

export function* getSchoolData({ payload }) {
  const { id } = payload

  try {
    const data = yield call(getSchoolAction, id)

    yield put({ type: SCHOOL_DATA_SUCCESS, payload: { data } })
  } catch (e) {
    yield put({ type: SCHOOL_DATA_ERROR })
  }
}

function* schoolsSaga() {
  yield all([
    takeEvery(SCHOOL_DATA_REQUEST, getSchoolData),
  ])
}

export default schoolsSaga
