import { createStore, applyMiddleware } from "redux"
import { routerMiddleware } from "react-router-redux"
import createHistory from "history/createBrowserHistory"
import createSagaMiddleware from "redux-saga"
import logger from "redux-logger"
import thunk from "redux-thunk"

import rootSaga from "./sagas"

import reducers from "./reducers"

export const history = createHistory()

// Build the middleware for intercepting and dispatching navigation actions
const routeMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()

const middlewares = [ routeMiddleware, sagaMiddleware, thunk ]
if (process.env.NODE_ENV !== "production") {
  middlewares.push(logger)
}

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
export default () => {
  const store = createStore(reducers, applyMiddleware(...middlewares))

  sagaMiddleware.run(rootSaga)
  return store
}
