import React, { Component } from "react"
import PropTypes from "prop-types"
import classname from "classnames"

import { Icon } from "../Common"

export default class NavTab extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    selected: PropTypes.bool,
    children: PropTypes.node.isRequired,
    iconName: PropTypes.string.isRequired,
    title: PropTypes.string,
  }

  static defaultProps = {
    onSelect: () => true,
    selected: false,
    title: "",
  }

  onSelectTab() {
    const { onSelect, selected } = this.props

    if (!selected) {
      onSelect()
    }
  }

  render() {
    const { selected, iconName, title } = this.props
    return (
      <div className={ classname("nav-tab__container", { selected }) } onClick={ () => this.onSelectTab() }>
        <Icon iconName={ iconName } iconSize={ 22 } />
        <span className="nav-tab__title">{title}</span>
      </div>
    )
  }
}
