import React, { Component } from "react"

import FormInput from "./FormInput"

export default class NumericInput extends Component {
  constructor() {
    super()
  }

  formatValue(value) {
    return value.replace(/[^0-9\,\.]/g, '')
  }

  onInputChange(evt) {
    const { onChange } = this.props

    evt.target.value = this.formatValue(evt.target.value)

    if (onChange) onChange(evt)
  }

  render() {
    const { onChange, ...props } = this.props

    return (
      <FormInput
        type="text"
        onChange={ e => this.onInputChange(e) }
        { ...props }
      />
    )
  }
}
