import { routerReducer } from "react-router-redux"
import { combineReducers } from "redux"
import { reduxTokenAuthReducer } from "redux-token-auth"

import signin from "./signin"
import signup from "./signup"
import comments from "./comments"
import specificAgreements from "./specificAgreements"
import generalAgreements from "./generalAgreements"
import schools from "./schools"
import resetPassword from "./resetPassword"
import admin from "./admin"
import sidebar from "./sidebar"
import profile from "./profile"
import company from "./company"
import jt from "./jtreducer"
import segment from "./segment"

export default combineReducers({
  router: routerReducer,
  signin,
  signup,
  comments,
  specificAgreements,
  generalAgreements,
  schools,
  resetPassword,
  admin,
  sidebar,
  profile,
  company,
  jt,
  segment,
  reduxTokenAuth: reduxTokenAuthReducer,
})
