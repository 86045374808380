import React from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { GeneralAgreementInfo, SchoolInfo, CompanyInfo } from "../AgreementListItems"

import { TableRow } from "../Common"

import Session from "../../helpers/SessionHelper"

const GeneralAgreementsListItem = ({ item, onClick }) => (
  <TableRow className="agreements-list-row" onClick={ onClick }>
    <td>
      <GeneralAgreementInfo id={ item.id } createdAt={ item.created_at } />
    </td>
    {!(Session.isSchoolAdmin() || Session.isOAP()) && (
      <td>
        <SchoolInfo name={ item.school.name } avatarUrl={ item.school && item.school.logo_url } />
      </td>
    )}
    <td>
      <CompanyInfo
        name={ item.name || item.company.name }
        avatarUrl={ item.company ? item.company.logo_url : item.logo_url }
      />
    </td>
    <td>
      <span>{item.created_at ? moment(item.created_at).format("DD/MM/YYYY") : ""}</span>
    </td>
  </TableRow>
)

GeneralAgreementsListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

GeneralAgreementsListItem.defaultProps = {
  onClick: () => true,
}

export default GeneralAgreementsListItem
