import React from "react"
import { connect } from "react-redux"
import { Switch, Route } from "react-router"

import Routes from "../routes"

import GeneralAgreementsForm from "../components/GeneralAgreementsForm"
import AgreementPrintingTemplate from "../components/AgreementPrintingTemplate"

import {
  GENERAL_AGREEMENT_DATA_REQUEST,
  GENERAL_AGREEMENT_CREATE_REQUEST,
  GENERAL_AGREEMENT_EDIT_REQUEST,
  GENERAL_AGREEMENT_CANCEL_REQUEST,
  SAVE_GENERAL_AGREEMENT_PRINTINT_TEMPLATE_REQUEST,
  SCHOOL_DATA_REQUEST,
  REMOVE_REQUEST,
  UNDO_CANCEL_REQUEST
} from "../types"

const GeneralAgreementFormContainer = ({ ...props }) => (
  <Switch>
    <Route
      path={ Routes.GENERAL_AGREEMENT_PRINTING_TEMPLATE_PREVIEW }
      render={ ({ match }) => (
        <AgreementPrintingTemplate { ...props } agreementId={ match.params.id } type="general_agreement" />
      ) }
    />
    <Route exact path={ Routes.GENERAL_AGREEMENT_CREATE } render={ () => <GeneralAgreementsForm { ...props } /> } />
    <Route
      path={ Routes.GENERAL_AGREEMENT_SHOW }
      render={ ({ match }) => <GeneralAgreementsForm { ...props } agreementId={ match.params.id } /> }
    />
  </Switch>
)

const mapStateToProps = state => ({
  agreement: state.generalAgreements.agreementData,
  school: state.schools.schoolData,
  companySectors: state.jt.companySectors,
})

const mapDispatchToProps = dispatch => ({
  getAgreementData: id => dispatch({ type: GENERAL_AGREEMENT_DATA_REQUEST, payload: { id } }),
  getSchoolData: id => dispatch({ type: SCHOOL_DATA_REQUEST, payload: { id } }),
  createAgreement: (data, review = false) =>
    dispatch({ type: GENERAL_AGREEMENT_CREATE_REQUEST, payload: { data, review } }),
  editAgreement: (data, url, updateStateUrl = null) =>
    dispatch({ type: GENERAL_AGREEMENT_EDIT_REQUEST, payload: { data, url, updateStateUrl } }),
  savePrintingTemplate: (data, updateUrl) =>
    dispatch({ type: SAVE_GENERAL_AGREEMENT_PRINTINT_TEMPLATE_REQUEST, payload: { data, updateUrl } }),
  removeGeneralAgreement: id =>
    dispatch({
      type: REMOVE_REQUEST,
      payload: { id, type: "general_agreements" },
    }),
  cancelGeneralAgreement: id => dispatch({ type: GENERAL_AGREEMENT_CANCEL_REQUEST, payload: { id }}),
  undoCancelGeneralAgreement: id => dispatch({type: UNDO_CANCEL_REQUEST, payload: { id, type: "general_agreements" }})
})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralAgreementFormContainer)
