import {
  COMPANY_DATA_REQUEST,
  COMPANY_DATA_SUCCESS,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_ERROR,
  COMPANY_UPDATE_REQUEST,
  COMPANY_CREATE_ERROR,
  COMPANY_CREATE_SUCCESS,
} from "../types"

export const initialState = {
  companyData: {},
  editionErrors: {},
}

export default function companyReducer(state = initialState, { type, payload }) {
  switch (type) {
    case COMPANY_DATA_REQUEST: {
      return {
        ...state,
        companyData: {},
      }
    }
    case COMPANY_DATA_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        companyData: data,
      }
    }
    case COMPANY_UPDATE_REQUEST: {
      return {
        ...state,
        editionErrors: {},
      }
    }
    case COMPANY_UPDATE_SUCCESS: {
      const { data } = payload
      return {
        ...state,
        companyData: data,
      }
    }
    case COMPANY_UPDATE_ERROR: {
      const { errors } = payload

      return {
        ...state,
        editionErrors: errors,
      }
    }
    case COMPANY_CREATE_SUCCESS: {
      return {
        ...state,
        editionErrors: {},
      }
    }
    case COMPANY_CREATE_ERROR: {
      const { errors } = payload

      return {
        ...state,
        editionErrors: errors,
      }
    }
    default:
      return { ...state }
  }
}
