import { takeEvery, call, put, all } from "redux-saga/effects"
import i18n from "i18next"
import { push } from "react-router-redux"

import {
  COMPANY_CREATE_REQUEST,
  COMPANY_CREATE_SUCCESS,
  COMPANY_CREATE_ERROR,
  COMPANY_DATA_REQUEST,
  COMPANY_DATA_SUCCESS,
  COMPANY_DATA_ERROR,
  COMPANY_UPDATE_REQUEST,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_ERROR,
} from "../types"

import Toaster from "../helpers/Toaster"

import {
  createCompany as createCompanyAction,
  getCompany as getCompanyAction,
  editCompany as editCompanyAction,
} from "../actions/company"
import Routes from "../routes"

export function* createCompany({ payload }) {
  const { data } = payload

  try {
    const dataCompany = yield call(createCompanyAction, data)
    yield put({ type: COMPANY_CREATE_SUCCESS, payload: { data: dataCompany } })
    Toaster.getBuilder()
      .setMessage(i18n.t("spa:company_edition.create.success_toast.message"))
      .setOptions({
        className: "toaster-success",
      })
      .setType(Toaster.TYPES.SUCCESS)
      .build()
      .show()
    yield put(push(Routes.COMPANY_EDITION.replace(":id", dataCompany.id)))
  } catch (e) {
    yield put({ type: COMPANY_CREATE_ERROR, payload: { errors: e } })
  }
}

export function* getCompanyData({ payload }) {
  const { id } = payload

  try {
    const dataCompany = yield call(getCompanyAction, id)
    yield put({ type: COMPANY_DATA_SUCCESS, payload: { data: dataCompany } })
  } catch (e) {
    yield put({ type: COMPANY_DATA_ERROR })
  }
}

export function* editCompany({ payload }) {
  const { id, data } = payload

  try {
    const dataEdit = yield call(editCompanyAction, id, data)
    Toaster.getBuilder()
      .setMessage(i18n.t("spa:company_edition.edit.success_toast.message"))
      .setOptions({
        className: "toaster-success",
      })
      .setType(Toaster.TYPES.SUCCESS)
      .build()
      .show()
    yield put({ type: COMPANY_UPDATE_SUCCESS, payload: { data: dataEdit } })
  } catch (e) {
    yield put({ type: COMPANY_UPDATE_ERROR, payload: { errors: e } })
  }
}

function* companySaga() {
  yield all([
    takeEvery(COMPANY_CREATE_REQUEST, createCompany),
    takeEvery(COMPANY_DATA_REQUEST, getCompanyData),
    takeEvery(COMPANY_UPDATE_REQUEST, editCompany),
  ])
}

export default companySaga
