import React from "react"
import PropTypes from "prop-types"
import classname from "classnames"

import "./Badge.css"

const Badge = ({ badgeStyle, className, ...props }) => (
  <span className={ classname(`badge badge-${badgeStyle} outline`, className) } { ...props } />
)

Badge.propTypes = {
  badgeStyle: PropTypes.string,
}

Badge.defaultProps = {
  badgeStyle: "default",
}

export default Badge
