import React from "react"
import { connect } from "react-redux"

import AdminUsersManagement from "../components/Admin/AdminUsersManagement"

import {
  LIST_REQUEST,
  EDIT_REQUEST,
  CREATE_REQUEST,
  REMOVE_REQUEST,
  SIGNOUT_REQUEST,
  SEND_INVITATION_EMAIL,
} from "../types"

const AdminUsersManagementContainer = ({ ...props }) => <AdminUsersManagement { ...props } />

const mapStateToProps = state => ({
  usersList: state.admin.users.list,
  editionRequestData: state.admin.editionRequestData,
  schoolsList: state.admin.schools.list,
})

const mapDispatchToProps = dispatch => ({
  getUsersList: () => dispatch({ type: LIST_REQUEST, payload: { type: "users" } }),
  editUser: data => dispatch({ type: EDIT_REQUEST, payload: { data, type: "users" } }),
  inviteUser: data => dispatch({ type: CREATE_REQUEST, payload: { data, type: "users" } }),
  removeUser: id => dispatch({ type: REMOVE_REQUEST, payload: { id, type: "users" } }),
  logout: () => dispatch({ type: SIGNOUT_REQUEST }),
  getSchoolsList: () => dispatch({ type: LIST_REQUEST, payload: { type: "schools" } }),
  sendRegistrationEmail: (id, redirectUrl) => dispatch({ type: SEND_INVITATION_EMAIL, payload: { id, redirectUrl } }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersManagementContainer)
