import Network from "../helpers/Network"

export const SIGNIN_ROUTE = "/signin"
export const SIGNUP_ROUTE = "/signup"
export const RESET_PASSWORD_ROUTE = "/auth/password"
export const RESEND_EMAIL_ROUTE = "/auth/confirmation"
export const ACCEPT_INVITATION_ROUTE = "/users/accept_invitation"
export const FILL_PROFILE_ROUTE = "/users/update_by_token"
const SIGNOUT_ROUTE = "/auth/sign_out"

export default class AuthenticationAPI {
  static signin(credentials) {
    return Network.post(SIGNIN_ROUTE, credentials)
  }

  static signup(credentials) {
    return Network.post(SIGNIN_ROUTE, credentials)
  }

  static resetPasswordEmailRequest(email) {
    return Network.post(RESET_PASSWORD_ROUTE, {
      email,
      redirect_url: `${window.location.origin}/password`,
    })
  }

  static resetPassword(password, uid, client, accessToken) {
    return Network.put(
      RESET_PASSWORD_ROUTE,
      {
        password,
        password_confirmation: password,
      },
      {
        headers: {
          uid,
          client,
          "access-token": accessToken,
        },
      }
    )
  }

  static resendSignupEmail(email) {
    return Network.post(RESEND_EMAIL_ROUTE, {
      user: { email },
      redirect_url: `${window.location.origin}/confirm`,
      confirm_success_url: `${window.location.origin}/confirm`,
    })
  }

  static acceptInvitation(password, token) {
    return Network.post(ACCEPT_INVITATION_ROUTE, {
      user: {
        password,
        password_confirmation: password,
      },
      invitation_token: token,
    })
  }

  static updateByToken(token, data) {
    return Network.put(FILL_PROFILE_ROUTE, {
      token,
      user: data,
    })
  }

  static signout() {
    const accessToken = localStorage.getItem("access-token")
    const client = localStorage.getItem("client")
    const uid = localStorage.getItem("uid")

    return Network.delete(SIGNOUT_ROUTE, {
      params: {
        "access-token": accessToken,
        client,
        uid,
      },
    })
  }
}
