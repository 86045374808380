import { JTApi } from "../api"

export async function getFunctions(schoolId) {
  try {
    const data = await JTApi.getFunctions(schoolId)
    return data
  } catch (e) {
    throw e
  }
}

export async function getCompanySectors() {
  try {
    const data = await JTApi.getCompanySectors()
    return data
  } catch (e) {
    throw e
  }
}

export async function getCurriculums(schoolId, specificAgreementId) {
  try {
    const curriculum = await JTApi.getCurriculums(schoolId, specificAgreementId)
    return curriculum
  } catch (e) {
    throw e
  }
}

export async function getAssigneds(schoolId) {
  try {
    const assigned = await JTApi.getAssigneds(schoolId)
    return assigned
  } catch (e) {
    throw e
  }
}
