import React, { Component } from "react"
import { Col, Row } from "react-bootstrap"
import classnames from "classnames"
import i18next from "i18next"
import { withRouter } from "react-router-dom"
import { CompanyCountriesListContainer, JobCountryListContainer, CompanySectorsListContainer, JobStartDatesListContainer, StudentSchoolYearsListContainer, AcademicSchoolYearsListContainer } from "../../containers"
import Session from "../../helpers/SessionHelper"
import { withSpecificAgreementsFilters } from "../../hoc"
import "./SpecificAgreementsFilters.css"
import { Button, Icon } from "../Common"

class SpecificAgreementsFilters extends Component {
  render() {
    const { isOpen, onClose, clearSpecificAgreementsFilters, match, triggerSpecificAgreementListFetch } = this.props

    if (!(Session.isOAP() || Session.isSchoolAdmin())) {
      return null
    }

    return (
      <React.Fragment>
        <div
          className={ classnames("agreements-filters-drawer", { "is-open": isOpen, "is-close": !isOpen })
          }
        >
          <div className="container-fluid">
            <Row className="agreements-filters-row">
              <Col xs={ 12 } sm={ 4 }>
                <JobCountryListContainer />
              </Col>
              <Col xs={ 12 } sm={ 4 }>
                <CompanyCountriesListContainer />
              </Col>
              <Col xs={ 12 } sm={ 4 }>
                <CompanySectorsListContainer isMulti />
              </Col>
              <Col xs={ 12 } sm={ 4 }>
                <StudentSchoolYearsListContainer />
              </Col>
              <Col xs={ 12 } sm={ 4 }>
                <JobStartDatesListContainer />
              </Col>
              <Col xs={ 12 } sm={ 3 }>
                <AcademicSchoolYearsListContainer/>
              </Col>
              <Col xs={ 12 } sm={ 1 }>
                <Button
                  className="agreements-filters-clear"
                  bsStyle="primary"
                  onClick={ () => {
                    clearSpecificAgreementsFilters()
                    setImmediate(() => triggerSpecificAgreementListFetch(match.params.type))
                    onClose()
                  } }
                >
                  <Icon iconName="trash" />
                  {i18next.t("spa:header.clear_filters")}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment >
    )
  }
}


export default withSpecificAgreementsFilters(withRouter(SpecificAgreementsFilters))
