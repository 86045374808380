import React from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import i18n from "i18next"

import AssigneeAutocomplete from "./AssigneeAutocomplete"
import AssigneeShow from "./AssigneeShow"

import "./SpecificAgreementAssignee.css"
import { Icon } from "../Common"

const displayModes = {
  SHOW: "show",
  AUTOCOMPLETE: "autocomplete",
}

export default class SpecificAgreementAssigneeCard extends React.Component {
  static propTypes = {
    onAssign: PropTypes.func.isRequired,
    onUnassign: PropTypes.func.isRequired,
    assignee: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      picture_url: PropTypes.string,
      id: PropTypes.number.isRequired,
    }).isRequired,
    schoolId: PropTypes.number.isRequired,
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.assignee.id !== prevProps.assignee.id) {
      if (this.props.assignee.id) this.setShowMode()
      else this.setAutocompleteMode()
    }
  }

  state = {
    displayMode: this.props.assignee.id ? displayModes.SHOW : displayModes.AUTOCOMPLETE,
  }

  setAutocompleteMode = () => {
    this.setState({
      displayMode: displayModes.AUTOCOMPLETE,
    })
  }

  setShowMode = () => {
    this.setState({
      displayMode: displayModes.SHOW,
    })
  }

  onChange = () => {
    const { onUnassign } = this.props

    onUnassign()
  }

  render() {
    const { onAssign, assignee, schoolId, onUnassign } = this.props
    const { displayMode } = this.state

    return (
      <Col xs={ 12 } className="specific-agreement-assignee-card__container">
        <Icon iconName="user" iconSize={ 20 } style={ { marginRight: 10 } } />
        <span dangerouslySetInnerHTML={ { __html: i18n.t("spa:specific_agreement_assignee_card.title") } } />
        {displayMode === displayModes.AUTOCOMPLETE && (
          <AssigneeAutocomplete onAssign={ onAssign } onUnassign={ onUnassign } assignee={ assignee } schoolId={ schoolId } />
        )}
        {displayMode === displayModes.SHOW && (
          <AssigneeShow
            firstName={ assignee.first_name }
            lastName={ assignee.last_name }
            pictureUrl={ assignee.picture_url }
            onEdit={ this.onChange }
          />
        )}
      </Col>
    )
  }
}
