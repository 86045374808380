import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import each from "lodash/each";
import i18n from "i18next";

import Icon from "./Icon";

import { DirectUpload } from "../../api";

import "./FileUpload.css";

export default class FileUpload extends Component {
  static propTypes = {
    onFileSet: PropTypes.func,
    label: PropTypes.string,
    helpText: PropTypes.string,
  };

  static defaultProps = {
    onFileSet: () => true,
    label: "",
    helpText: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      file: null
    };
    this.fileInput = React.createRef();
  }

  triggerFileInput() {
    this.fileInput.current.click();
  }

  onFileLoad(event) {
    // eslint-disable-next-line
    // this.state.file = event.target.files[0];
    this.setState({ file: event.target.files[0] }, this.onFileSet);
  }

  removeFile(evt) {
    evt.stopPropagation();
    this.setState({ file: null }, this.onFileSet);
  }

  onFileSet = () => {
    const { onFileSet } = this.props;

    onFileSet({
      filename: this.state.file ? this.state.file.name : null
    });
  };

  async triggerUpload({ byToken, token } = {}) {
    if (!this.state.file) return null;
    const {
      fields,
      upload_url: uploadUrl,
      download_url: downloadUrl
    } = await DirectUpload.getDirectUploadUrl(this.state.file.name, "private", {
      byToken,
      token
    });
    // eslint-disable-next-line
    const formData = new FormData();
    each(fields, (field, key) => {
      if (field) {
        formData.append(key, field);
      }
      return true;
    });
    formData.append("file", this.fileInput.current.files[0]);
    await DirectUpload.uploadFromUrl(uploadUrl, formData);
    return downloadUrl.split('/').pop();
  }

  render() {
    const { file } = this.state;
    const { helpText, label } = this.props;

    return (
      <div className="file-upload">
        <label>{label}</label>
        {!file && (
          <div className="file-help-container">
            <div
              className="file-container"
              onClick={() => this.triggerFileInput()}
            >
              <Icon iconName="file" />
            </div>
            <div className="file-help-text"
              dangerouslySetInnerHTML={ {
                __html: helpText
              }}
            />
          </div>
        )}
        {file && (
          <div>
            {file.name}
            <Icon
              iconName="times"
              className="remove-icon"
              onClick={e => this.removeFile(e)}
            />
          </div>
        )}

        <input
          type="file"
          accept="image/*, application/pdf, application/msword"
          onChange={evt => this.onFileLoad(evt)}
          ref={this.fileInput}
          style={{ display: "none" }}
        />
      </div>
    );
  }
}
