import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import { Col } from "react-bootstrap"

import { Modal, Icon, FormInput, ButtonGroup } from "../Common"

export default class SpecificAgreementCancelModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    studentFullName: PropTypes.string,
    reasonForCancellation: PropTypes.string,
  }

  static defaultProps = {
    isOpen: false,
    onSubmit: () => true,
    onCancel: () => true,
    studentFullName: "",
    reasonForCancellation: "",
  }

  constructor(props) {
    super(props)
    this.state = {
      reason_for_cancellation: props.reasonForCancellation,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
      // eslint-disable-next-line
      this.setState({ reason_for_cancellation: this.props.reasonForCancellation })
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  resetText() {
    this.setState({ reason_for_cancellation: "" })
  }

  onSubmit = () => {
    this.resetText()
    this.props.onSubmit(this.state.reason_for_cancellation)
  }

  onCancel = () => {
    this.resetText()
    this.props.onCancel()
  }

  submitDisabled = () => {
    return !(
      this.state.reason_for_cancellation &&
      this.state.reason_for_cancellation.length > 0
    );
  };

  render() {
    const { isOpen, studentFullName } = this.props
    const { reason_for_cancellation: reason } = this.state

    return (
      <Modal isOpen={ isOpen } className="specific-agreement-alteration__modal">
        <Modal.Title>
          <Icon
            iconName={ "times" }
            className="specific-agreement-alteration__icon-title"
          />
          {i18n.t("spa:specific_agreements.school_panel.alteration.cancel_agreement")}
        </Modal.Title>
        <Modal.Content>
          <Col xs={ 12 } className="specific-agreement-alteration__modal-extension-content">
            <span>
              {i18n.t("spa:specific_agreements.school_panel.alteration.cancellation_title", {
                student_name: studentFullName,
              })}
            </span>
            <br />
            <br />
            <FormInput
              name="reason_for_cancellation"
              value={ reason }
              withLabel
              label={ `* ${i18n.t("spa:specific_agreements.school_panel.alteration.fields.reason_for_cancellation.label")} :` }
              onChange={ this.handleInputChange }
              componentClass="textarea"
              rows={ 6 }
            />
          </Col>
        </Modal.Content>
        <Modal.Footer>
          <Col xs={ 12 }>
            <ButtonGroup
              buttonSpacing={ 10 }
              buttonsData={ [
                {
                  title: i18n.t("spa:specific_agreements.school_panel.alteration.fields.shared.buttons.cancel"),
                  onClick: this.onCancel,
                  bsStyle: "default",
                },
                {
                  title: i18n.t(
                    "spa:specific_agreements.school_panel.alteration.fields.shared.buttons.cancel_version"
                  ),
                  onClick: this.onSubmit,
                  bsStyle: "danger",
                  disabled: this.submitDisabled,
                },
              ] }
            />
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }
}
