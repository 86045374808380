import axios from "axios";
import has from "lodash/has";
import MockAdapter from "axios-mock-adapter";

import { history } from "../configureStore";
import Routes from "../routes";

const axiosInstance = axios.create();

if (process.env.REACT_APP_TEST_MODE === "true") {
  const mock = new MockAdapter(axiosInstance);

  mock.onAny().reply(200);
  mock.onPost("/directupload").reply(200, {
    fields: [],
    upload_url: "",
    download_url: ""
  });
}

const validHeaders = headers =>
  has(headers, "client") &&
  has(headers, "uid") &&
  has(headers, "access-token") &&
  headers["access-token"] !== "undefined" &&
  headers.uid !== "undefined" &&
  headers.client !== "undefined" &&
  headers["access-token"].length > 0 &&
  headers.uid.length > 0 &&
  headers.client.length > 0;

/**
 * Wrapper to execute network requests using axios library
 */
class Network {
  /**
   * Defines headers and response interceptor
   */
  static init() {
    axiosInstance.defaults.baseURL = process.env.REACT_APP_API_URL;
    axiosInstance.defaults.headers.common.Accept = "application/json";
    axiosInstance.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
    axiosInstance.interceptors.request.use(config => {
      const accessToken = localStorage.getItem("access-token");
      const uid = localStorage.getItem("uid");
      const client = localStorage.getItem("client");
      if (accessToken && uid && client) {
        return {
          ...config,
          headers: {
            ...config.headers,
            "access-token": accessToken,
            uid,
            client
          }
        };
      }
      return config;
    }, Promise.reject);
    axiosInstance.interceptors.response.use(
      response => {
        const { data, headers } = response;
        if (validHeaders(headers)) {
          updateNetworkHeaders(headers);
        }
        return data;
      },
      error => {
        const { headers, status } = error.response;

        if (status === 401) {
          history.push(Routes.SIGNIN);
        } else if (validHeaders(headers)) {
          updateNetworkHeaders(headers);
        }
        return Promise.reject(error.response.data);
      }
    );
  }

  /**
   *
   * @param {string} headerKey key of the header to add
   * @param {string} headerValue value of the header to add
   * @param {string} method method for which header is added. Defaults to 'common'
   */
  static addDefaultHeader(headerKey, headerValue, method = "common") {
    axiosInstance.defaults.headers[method][headerKey] = headerValue;
  }

  /**
   *
   * @param {string} headerKey key of the header to add
   * @param {string} method method for which header is removed. Defaults to 'common'
   */
  static removeDefaultHeader(headerKey, method = "common") {
    delete axiosInstance.defaults.headers[method][headerKey];
  }

  /**
   * @param {string} path path of the request to execute.
   * Must be the url without the baseUrl defined in the Network#init function
   * @param {object} data data to send
   * @param {object} config optional config
   * @return {AxiosPromise}
   */
  static post(path, data, config = {}) {
    return axiosInstance.post(path, data, config);
  }

  /**
   * @param {string} path path of the request to execute.
   * Must be the url without the baseUrl defined in the Network#init function
   * @param {object} config optional config
   * @return {AxiosPromise}
   */
  static get(path, config = {}) {
    return axiosInstance.get(path, config);
  }

  /**
   * @param {string} path path of the request to execute.
   * Must be the url without the baseUrl defined in the Network#init function
   * @param {object} config optional config
   * @return {AxiosPromise}
   */
  static put(path, data, config = {}) {
    return axiosInstance.put(path, data, config);
  }

  /**
   * @param {string} path path of the request to execute.
   * Must be the url without the baseUrl defined in the Network#init function
   * @param {object} config optional config
   * @return {AxiosPromise}
   */
  static delete(path, config = {}) {
    return axiosInstance.delete(path, config);
  }
}

Network.init();

const updateNetworkHeaders = headers => {
  localStorage.setItem("client", headers.client);
  localStorage.setItem("uid", headers.uid);
  localStorage.setItem("access-token", headers["access-token"]);
};

export default Network;
