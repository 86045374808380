import React, { PureComponent, Fragment } from "react"
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap"
import PropTypes from "prop-types"
import classname from "classnames"

import Icon from "./Icon"

import "./FormInput.css"

export default class FormInput extends PureComponent {
  static propTypes = {
    ...FormControl.propTypes,
    withIcon: PropTypes.bool,
    iconName: PropTypes.string,
    iconSize: PropTypes.number,
    iconClassName: PropTypes.string,
    iconPosition: PropTypes.oneOf([ "left", "right" ]),
    withLabel: PropTypes.bool,
    label: PropTypes.string,
    controlId: PropTypes.string,
    containerStyle: PropTypes.object,
    inputClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    validationState: PropTypes.string,
    errorMessage: PropTypes.string,
    wrapperRef: PropTypes.oneOfType([ PropTypes.func, PropTypes.object ]),
    inputComponentClass: PropTypes.func,
    containerClassName: PropTypes.string,
    renderIcon: PropTypes.func,
  }

  static defaultProps = {
    withIcon: false,
    iconName: "",
    iconSize: 15,
    iconClassName: "",
    iconPosition: "left",
    withLabel: false,
    label: "",
    controlId: "",
    containerStyle: {},
    inputClassName: "",
    labelClassName: "",
    validationState: null,
    errorMessage: "",
    wrapperRef: null,
    inputComponentClass: FormControl,
    containerClassName: "",
    renderIcon: null,
  }

  inputRef = React.createRef()

  componentDidMount() {
    const { wrapperRef } = this.props

    if (wrapperRef) wrapperRef(this)
  }

  hasError() {
    const { validationState } = this.props

    return validationState === "error"
  }

  handleChange = (e) => {
    const { onChange } = this.props

    if (onChange) onChange(e.nativeEvent)
  }

  focus() {
    if (this.inputRef.current.focus) {
      this.inputRef.current.focus()
    }
  }

  render() {
    const {
      label,
      postLabel,
      controlId,
      containerStyle,
      inputClassName,
      labelClassName,
      withLabel,
      withIcon,
      iconName,
      iconSize,
      iconClassName,
      iconPosition,
      validationState,
      errorMessage,
      wrapperRef,
      inputComponentClass,
      containerClassName,
      renderIcon,
      required,
      ...props
    } = this.props

    const classNameInputContainer = classname(withLabel && "with-label", "form-input-container", containerClassName)
    const ControlComp = inputComponentClass
    const classNameInput = classname("form-input", inputClassName)
    const fullIconClassName = classname(
      "form-input-icon",
      { left: iconPosition === "left", right: iconPosition === "right" },
      iconClassName
    )
    const controlComponent = (
      <ControlComp ref={ this.inputRef } onChange={ this.handleChange } className={ classNameInput } { ...props } />
    )

    return (
      <FormGroup
        controlId={ controlId }
        className={ classNameInputContainer }
        style={ containerStyle }
        validationState={ validationState }
      >
        {withLabel && (
          <ControlLabel className={ labelClassName }>
            {label}
            {required && " *"}
          </ControlLabel>
        )}
        {withIcon && (
          <div className="input-wrapper">
            {!renderIcon && <Icon iconName={ iconName } iconSize={ iconSize } className={ fullIconClassName } />}
            {renderIcon && renderIcon(fullIconClassName)}
            {controlComponent}
            {validationState === "error" && <span className="text-danger">{errorMessage}</span>}
          </div>
        )}
        {!withIcon && (
          <Fragment>
            {controlComponent}
            {validationState === "error" && <span className="text-danger">{errorMessage}</span>}
          </Fragment>
        )}
        {postLabel && <ControlLabel className={ labelClassName }>{postLabel}</ControlLabel>}
      </FormGroup>
    )
  }
}
