import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"

import { computeAgreementId } from "../../utils"

const GeneralAgreementInfo = ({ id, createdAt }) => (
  <div>
    <div>
      <span className="general-agreement-infos__description">
        {id && `General agreement n° ${computeAgreementId({ agreementId: id, createdAt })}`}
        {!id && i18n.t("spa:general_agreements.index.shared.no_general_agreement")}
      </span>
    </div>
  </div>
)

GeneralAgreementInfo.propTypes = {
  id: PropTypes.number,
  createdAt: PropTypes.string,
}

GeneralAgreementInfo.defaultProps = {
  id: null,
  createdAt: "",
}

export default GeneralAgreementInfo
