import React from "react"
import PropTypes from "prop-types"

import { AutocompleteUserItem } from "../Common"

export default class AutocompletionItem extends React.PureComponent {
  static propTypes = {
    item: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      avatarUrl: PropTypes.string,
    }).isRequired,
  }

  render() {
    const { item, ...props } = this.props
    return (
      <AutocompleteUserItem
        firstName={ item.first_name }
        lastName={ item.last_name }
        avatarUrl={ item.picture_url }
        position={ item.position }
        { ...props }
      />
    )
  }
}
