import React from "react"
import { connect } from "react-redux"

import { GET_PROFILE_REQUEST, EDIT_PROFILE_REQUEST } from "../types"

import ProfileForm from "../components/ProfileForm"

const ProfileFormContainer = ({ ...props }) => <ProfileForm { ...props } />

const mapStateToProps = state => ({
  userData: state.profile.userData,
  editionRequestData: state.profile.editionRequestData,
  editErrors: state.profile.editErrors,
})

const mapDispatchToProps = dispatch => ({
  getProfileById: (id, data) => dispatch({ type: GET_PROFILE_REQUEST, payload: { id, data } }),
  editUser: data => dispatch({ type: EDIT_PROFILE_REQUEST, payload: { data } }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileFormContainer)
