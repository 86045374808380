import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"

import { ButtonGroup, Icon } from "../Common"

const SpecificAgreementAlterationActionButtons = ({ onOpenModify, onOpenExtend, onOpenTerminate }) => (
  <ButtonGroup
    className="specific-agreement-alteration__buttons-container"
    buttonsData={ [
      {
        onClick: onOpenModify,
        title: i18n.t("spa:specific_agreements.school_panel.alteration.create_modified_agreement"),
        withIcon: true,
        renderIcon: () => <Icon iconName="files-o" />,
        bsStyle: "success",
        block: true,
      },
      {
        onClick: onOpenExtend,
        title: i18n.t("spa:specific_agreements.school_panel.alteration.create_agreement_extension"),
        withIcon: true,
        renderIcon: () => <Icon iconName="clock-o" />,
        bsStyle: "success",
        block: true,
      },
      {
        onClick: onOpenTerminate,
        title: i18n.t("spa:specific_agreements.school_panel.alteration.terminate_internship"),
        withIcon: true,
        renderIcon: () => <Icon iconName="ban" />,
        bsStyle: "danger",
        block: true,
      },
    ] }
  />
)

SpecificAgreementAlterationActionButtons.propTypes = {
  onOpenModify: PropTypes.func,
  onOpenExtend: PropTypes.func,
  onOpenTerminate: PropTypes.func,
}

SpecificAgreementAlterationActionButtons.defaultProps = {
  onOpenModify: () => true,
  onOpenExtend: () => true,
  onOpenTerminate: () => true,
}

export default SpecificAgreementAlterationActionButtons
