import React, { Component } from "react"

export default getErrors => Comp =>
  class extends Component {
    getErrorObject(key) {
      const errors = getErrors(this.props)
      return Object.keys(errors).includes(key)
        ? {
          state: "error",
          message: errors[key][0],
        }
        : {
          state: null,
          message: "",
        }
    }

    getValidationState = (key, keyFormatter = key => key) => this.getErrorObject(keyFormatter(key)).state

    getErrorMessage = (key, keyFormatter = key => key) => this.getErrorObject(keyFormatter(key)).message

    render() {
      return (
        <Comp { ...this.props } getValidationState={ this.getValidationState } getErrorMessage={ this.getErrorMessage } />
      )
    }
  }
