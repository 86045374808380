import PropTypes from "prop-types"
import i18n from "i18next"

import { generateButtonGroup } from "./SpecificAgreementActionButtonsHelper"
import { generateDataButton } from "../../utils"

const SpecificAgreementActionButtonStudentCompletion = ({ canSave, onSave, onSend, onSendEmailToStudent }) => {
  const finalDataButtons = []
  if (canSave) {
    finalDataButtons.push(generateDataButton(i18n.t("spa:specific_agreements.form.buttons.shared.save"), onSave))
    finalDataButtons.push(generateDataButton(i18n.t("spa:specific_agreements.form.buttons.shared.send_email_to_student"), onSendEmailToStudent, "primary"))
  }
  finalDataButtons.push(
    generateDataButton(i18n.t("spa:specific_agreements.form.buttons.shared.send_for_review"), onSend, "primary")
  )

  return generateButtonGroup(finalDataButtons)
}

SpecificAgreementActionButtonStudentCompletion.propTypes = {
  canSave: PropTypes.bool,
  onSave: PropTypes.func,
  onSend: PropTypes.func,
  onSendEmailToStudent: PropTypes.func,
}

SpecificAgreementActionButtonStudentCompletion.defaultProps = {
  canSave: true,
  onSave: () => true,
  onSend: () => true,
  onSendEmailToStudent: () => true,
}

export default SpecificAgreementActionButtonStudentCompletion
