import React, { Component } from "react"
import { Col } from "react-bootstrap"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import i18n from "i18next"

import "./Signin.css"
import { Button, Logo, Title, FormError } from "../Common"
import { GenericFormInput } from "../GenericInputs"

export default class Signin extends Component {
  static propTypes = {
    onSignin: PropTypes.func,
    signinData: PropTypes.shape({
      isInProgress: PropTypes.bool,
      isRequestDone: PropTypes.bool,
      isValid: PropTypes.bool,
      errorMessage: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    onSignin: () => true,
    errorData: null,
  }

  constructor() {
    super()
    this.state = {
      data: {
        email: "",
        password: "",
      },
    }
    i18n.changeLanguage(window.navigator.language.replace(/[_-].*/, ""))
  }

  onSignin(e) {
    e.preventDefault()
    const { onSignin } = this.props

    onSignin(this.state.data.email, this.state.data.password)
  }

  onChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    })
  }

  renderGenericInput({ name, ...props }) {
    const { data } = this.state

    return (
      <GenericFormInput
        name={ name }
        data={ data }
        onChange={ e => this.onChange(e) }
        baseTranslationKey="authentication.shared"
        { ...props }
      />
    )
  }

  render() {
    const { signinData } = this.props
    const { isInProgress, isRequestDone, isValid, errorMessage } = signinData

    return (
      <Col xs={ 12 } sm={ 6 } smPush={ 3 } md={ 4 } mdPush={ 4 } className="center-block text-center signin-container">
        <Logo />
        <Title dangerouslySetInnerHTML={ { __html: i18n.t("spa:authentication.signin.title") } } />
        <Col sm={ 12 }>
          <form className="form" onSubmit={ e => this.onSignin(e) } noValidate>
            {[
              { name: "email", withLabel: false, required: true, type: "email" },
              { name: "password", withLabel: false, type: "password", required: true },
            ].map((elem, index) => React.cloneElement(this.renderGenericInput(elem), { key: index }))}
            <FormError visible={ isRequestDone && !isValid }>
              <span>{errorMessage}</span>
            </FormError>
            {isInProgress && <span>Loading...</span>}
            <Button block type="submit" bsSize="sm" bsStyle="primary">
              {i18n.t("spa:authentication.signin.submit")}
            </Button>
          </form>
          <span className="signin__reset-password">
            <Link to="/password">{i18n.t("spa:authentication.signin.reset_password.link")}</Link>
          </span>
          <span className="descriptor">{i18n.t("spa:authentication.signin.signup.title")}</span>
          <Link to="/signup">
            <Button type="button" block className="signin__signup-button">
              {i18n.t("spa:authentication.signin.signup.link")}
            </Button>
          </Link>
        </Col>
      </Col>
    )
  }
}
