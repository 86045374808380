/* eslint-disable no-undef */
import i18n from "i18next";
import moment from "moment";

import { SIGNIN_ERROR, SIGNIN_SUCCESS, SIGNIN_REQUEST } from "../types";

import Session from "../helpers/SessionHelper";
import { Roles } from "../Constants";
import { history } from "../configureStore";
import Routes from "../routes";

export const initialState = {
  signinRequestData: {
    isInProgress: false,
    isRequestDone: false,
    isValid: false,
    errorMessage: null
  },
  didTryLogin: false
};

const updateSessionFromAttributes = userAttributes => {
  moment.locale(userAttributes.locale);
  Session.locale = userAttributes.locale;
  const analyticsTraits = {};
  Session.role = userAttributes.role;
  analyticsTraits.title = Session.role;
  Session.linkedCompanies = userAttributes.companies;
  Session.id = userAttributes.id;
  Session.lastName = userAttributes.last_name;
  Session.firstName = userAttributes.first_name;
  Session.organizationName = userAttributes.organization_name;
  Session.position = userAttributes.position;
  Session.phoneNumber = userAttributes.phone_number;
  Session.email = userAttributes.email;
  Session.isConnected = true;
  if (
    userAttributes.role === Roles.SCHOOL_ADMIN ||
    userAttributes.role === Roles.OAP
  ) {
    Session.schoolId = userAttributes.school.id;
    if (userAttributes.school.primary_color)
      Session.primaryColor = userAttributes.school.primary_color;
    if (userAttributes.school.logo_url)
      Session.schoolLogoUrl = userAttributes.school.logo_url;
    if (userAttributes.school.short_name) {
      Session.schoolShortName = userAttributes.school.short_name;
      analyticsTraits.company = {};
      analyticsTraits.company.name = userAttributes.school.short_name;
    }
    Session.useGeneralAgreement = userAttributes.school.use_general_agreement;
  }
  if (userAttributes.picture_url) {
    Session.logoUrl = userAttributes.picture_url;
  }
  i18n.changeLanguage(userAttributes.locale);
  const scriptElements = document.getElementsByTagName("script");
  Array.from(scriptElements).forEach(elem => {
    if (elem.src.startsWith("https://maps.googleapis.com/maps/api/js")) {
      elem.remove();
    }
  });
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC9B-aZgpR_cPkPbItnXNtSZmwvAVlOrho&libraries=places&language=${
    userAttributes.locale
  }`;
  script.async = true;
  document.body.appendChild(script);
  analytics.identify(`${Session.id}`, analyticsTraits);
};

export default function signupReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "redux-token-auth/SIGNIN_REQUEST_SUCCEEDED": {
      const { userAttributes } = payload;

      updateSessionFromAttributes(userAttributes);
      return { ...state };
    }
    case "redux-token-auth/VERIFY_TOKEN_REQUEST_SUCCEEDED": {
      const { userAttributes } = payload;

      updateSessionFromAttributes(userAttributes);
      if (Session.isRecruiter() && (!Session.firstName || !Session.lastName)) {
        setTimeout(() => history.push(Routes.PROFILE_USER));
      }
      return { ...state, didTryLogin: true };
    }
    case "redux-token-auth/VERIFY_TOKEN_REQUEST_FAILED": {
      return { ...state, didTryLogin: true };
    }
    case "redux-token-auth/SET_HAS_VERIFICATION_BEEN_ATTEMPTED": {
      return { ...state, didTryLogin: true };
    }
    case SIGNIN_REQUEST: {
      return {
        ...state,
        signinRequestData: {
          ...state.signinRequestData,
          isInProgress: true,
          isRequestDone: false,
          errorMessage: null
        }
      };
    }
    case SIGNIN_SUCCESS: {
      return {
        ...state,
        signinRequestData: {
          isInProgress: false,
          isRequestDone: true,
          isValid: true,
          errorMessage: null
        }
      };
    }
    case SIGNIN_ERROR: {
      const { error } = payload;
      return {
        ...state,
        signinRequestData: {
          isInProgress: false,
          isRequestDone: true,
          isValid: false,
          errorMessage: error
        }
      };
    }
    default:
      return { ...state };
  }
}
