import React, { Component } from "react"
import ReactDOM from "react-dom"
import { Provider, connect } from "react-redux"
import { Route, Switch, Redirect } from "react-router"
import { ConnectedRouter } from "react-router-redux"
import { generateRequireSignInWrapper } from "redux-token-auth"
import i18next from "i18next"
import { ToastContainer } from "react-toastify"
import Modal from "react-modal"

import "./index.css"
import "./toaster.css"
import {
  SigninContainer,
  SignupContainer,
  ResetPasswordContainer,
  InformationFormContainer,
  SpecificAgreementsListContainer,
  SpecificAgreementFormContainer,
  GeneralAgreementFormContainer,
  GeneralAgreementsListContainer,
  ProfileFormContainer,
  CompanyEditionContainer,
  StudentCompletionFormContainer,
} from "./containers"
import MainPage from "./components/MainPage"
import AdminHomePage from "./components/Admin/AdminHomePage"
import ThemeProvider from "./hoc/ThemeProvider"

import { unregister } from "./registerServiceWorker"
import configureStore, { history } from "./configureStore"

import Session from "./helpers/SessionHelper"
import Network from "./helpers/Network"

import { verifyCredentials } from "./redux-token-auth-config"

import { SIGNOUT_REQUEST } from "./types"

import Routes from "./routes"
import { ListPaginationProvider } from "./context"

Modal.setAppElement("#root")

const store = configureStore()

const requireSignIn = generateRequireSignInWrapper({
  redirectPathIfNotSignedIn: "/signin",
})

const requireAdmin = (Comp, { ...props }) => {
  if (Session.isAdmin()) return <Comp { ...props } />
  props.history.replace("/signin")
  return null
}

class App extends Component {
  async componentDidMount() {
    const locales = await Network.get("/translations")
    i18next.init({
      fallbackLng: "en",
      resources: locales,
    })
    verifyCredentials(store)
  }

  redirectToCorrectPath() {
    let toType
    if (Session.isRecruiter()) {
      toType = "draft"
    } else if (Session.isSchoolAdmin()) {
      toType = "in_school_review"
    } else if (Session.isOAP()) {
      toType = "pending_approvals"
    }

    return Routes.SPECIFIC_AGREEMENT_INDEX.replace(":type", toType)
  }

  render() {
    const { didTryLogin, logout } = this.props

    if (!didTryLogin) return null
    return (
      <ThemeProvider>
        <ListPaginationProvider>
          <ConnectedRouter history={ history }>
            <MainPage history={ history } logout={ logout }>
              <Switch>
                <Route exact path="/" render={ () => <Redirect to={ this.redirectToCorrectPath() } /> } />
                <Route exact path={ Routes.SIGNIN } component={ SigninContainer } />
                <Route path={ Routes.SIGNUP } component={ SignupContainer } />
                <Route path={ Routes.NEW_PASSWORD } component={ ResetPasswordContainer } />
                <Route path={ Routes.CONFIRM_ACCOUNT } component={ InformationFormContainer } />

                <Route
                  exact
                  path={ Routes.SPECIFIC_AGREEMENT_STUDENT_FORM }
                  render={ ({ match }) => <StudentCompletionFormContainer agreementId={ match.params.id } /> }
                />
                <Route
                  exact
                  path={ Routes.SPECIFIC_AGREEMENT_INDEX }
                  component={ requireSignIn(SpecificAgreementsListContainer) }
                />
                <Route
                  path={ Routes.SPECIFIC_AGREEMENT_SHOW }
                  component={ requireSignIn(SpecificAgreementFormContainer) }
                />

                <Route
                  exact
                  path={ Routes.GENERAL_AGREEMENT_INDEX }
                  component={ requireSignIn(GeneralAgreementsListContainer) }
                />
                <Route path={ Routes.GENERAL_AGREEMENT_SHOW } component={ requireSignIn(GeneralAgreementFormContainer) } />

                <Route path={ Routes.COMPANY_EDITION } component={ requireSignIn(CompanyEditionContainer) } />

                <Route path={ Routes.ADMIN_INDEX } render={ ({ ...props }) => requireAdmin(AdminHomePage, props) } />
                <Route exact path={ Routes.PROFILE_USER } component={ requireSignIn(ProfileFormContainer) } />
              </Switch>
              <ToastContainer />
            </MainPage>
          </ConnectedRouter>
        </ListPaginationProvider>
      </ThemeProvider>
    )
  }
}

const ConnectedApp = connect(
  state => ({ didTryLogin: state.signin.didTryLogin }),
  dispatch => ({ logout: () => dispatch({ type: SIGNOUT_REQUEST }) })
)(App)

if (!!window.MSInputMethodContext && !!document.documentMode) {
  ReactDOM.render(
    <div className="ie-container">
      <div>
        <span className="ie-title">Please upgrade your browser to use JobTeaser</span>
      </div>
      <div>
        <span className="ie-description">
          We built JobTeaser using the latest technology to make it faster and easier to use. Unfortunately your browser
          doesn't support those tecnologies. Please upgrade to either Chrome or Firefox. Thank you.
        </span>
      </div>
    </div>,
    document.getElementById("root")
  )
} else {
  ReactDOM.render(
    <Provider store={ store }>
      <ConnectedApp />
    </Provider>,
    document.getElementById("root")
  )
}
unregister()
