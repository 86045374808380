import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Button } from "react-bootstrap"
import classnames from "classnames"

import "./Button.css"

export default class extends PureComponent {
  static propTypes = {
    ...Button.propTypes,
    rounded: PropTypes.bool,
    height: PropTypes.number,
  }

  static defaultProps = {
    rounded: false,
    height: 40,
  }

  render() {
    const { rounded, height, style, ...props } = this.props

    let className = classnames("button", props.className)
    className = classnames(className, rounded ? "btn-circle" : "")

    return (
      <Button { ...props } className={ className } style={ { height, width: rounded ? height : null, ...style } }>
        {this.props.children}
      </Button>
    )
  }
}
