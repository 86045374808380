import React from "react"
import PropTypes from "prop-types"

import { Card } from "../Common"

import DefaultUserAvatar from "../../assets/default-avatar.png"

const StudentInfo = ({ name, avatarUrl, curriculum }) => (
  <Card avatarUrl={ avatarUrl } title={ name } description={ curriculum } />
)

StudentInfo.propTypes = {
  name: PropTypes.string,
  avatarUrl: PropTypes.string,
  curriculum: PropTypes.string,
}

StudentInfo.defaultProps = {
  name: "",
  avatarUrl: DefaultUserAvatar,
  curriculum: "",
}

export default StudentInfo
