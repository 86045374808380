import { GET_COMMENTS_SUCCESS, COMMENT_CREATE_SUCCESS } from "../types"

export const initialState = {
  comments: [],
}

export default function commentsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_COMMENTS_SUCCESS: {
      const { data } = payload
      return { ...state, comments: data }
    }
    case COMMENT_CREATE_SUCCESS: {
      const { data } = payload
      return {
        ...state,
        comments: [ ...state.comments, data ],
      }
    }
    default:
      return { ...state }
  }
}
