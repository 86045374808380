import React from "react"
import PropTypes from "prop-types"

import { Avatar, Icon } from "../Common"

import DefaultAvatar from "../../assets/default-avatar.png"
import { computeFullName } from "../../utils"

const ApprovalTag = ({ onRemove, classNameRemove, getTagDisplayValue, tag, tagKey, ...props }) => (
  <span key={ tagKey } { ...props }>
    <Avatar src={ tag.picture_url || DefaultAvatar } size={ 20 } />
    <span className="approval-autocomplete__full-name">
      {computeFullName({ first_name: tag.first_name, last_name: tag.last_name })}
    </span>
    <Icon iconName="times" iconSize={ 15 } className={ classNameRemove } onClick={ () => onRemove(tagKey) } />
  </span>
)

ApprovalTag.propTypes = {
  onRemove: PropTypes.func.isRequired,
  classNameRemove: PropTypes.string.isRequired,
  tag: PropTypes.shape({
    picture_url: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
}

export default ApprovalTag
