import { LOCATION_CHANGE } from "react-router-redux"

import {
  GENERAL_AGREEMENTS_LIST_SUCCESS,
  GENERAL_AGREEMENT_DATA_REQUEST,
  GENERAL_AGREEMENT_DATA_SUCCESS,
  GENERAL_AGREEMENT_DATA_ERROR,
  GENERAL_AGREEMENT_CREATE_SUCCESS,
  GENERAL_AGREEMENT_CREATE_ERROR,
  GENERAL_AGREEMENT_EDIT_SUCCESS,
  GENERAL_AGREEMENT_EDIT_ERROR,
  GENERAL_AGREEMENT_CANCEL_SUCCESS,
  GENERAL_AGREEMENT_CANCEL_ERROR,
  COMPANIES_WITHOUT_GENERAL_AGREEMENT_SUCCESS,
} from "../types"

export const initialState = {
  agreements: [],
  agreementData: {
    data: {},
    isLoading: false,
    errors: {},
  },
  companiesWithoutGeneralAgreement: [],
}

export default function generalAgreementsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GENERAL_AGREEMENTS_LIST_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        agreements: data,
      }
    }
    case GENERAL_AGREEMENT_DATA_REQUEST: {
      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          data: {},
          isLoading: true,
        },
      }
    }
    case GENERAL_AGREEMENT_DATA_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          data,
          isLoading: false,
        },
      }
    }
    case GENERAL_AGREEMENT_DATA_ERROR: {
      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          isLoading: false,
        },
      }
    }
    case GENERAL_AGREEMENT_CREATE_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          data,
          isLoading: false,
          errors: {},
        },
      }
    }
    case GENERAL_AGREEMENT_EDIT_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          data,
          isLoading: false,
          errors: {},
        },
      }
    }
    case GENERAL_AGREEMENT_EDIT_ERROR: {
      const { errors } = payload

      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          errors,
        },
      }
    }
    case GENERAL_AGREEMENT_CREATE_ERROR: {
      const { errors } = payload

      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          errors,
        },
      }
    }
    case GENERAL_AGREEMENT_CANCEL_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          data,
          isLoading: false,
          errors: {},
        },
      }
    }
    case GENERAL_AGREEMENT_CANCEL_ERROR: {
      const { errors } = payload

      return {
        ...state,
        agreementData: {
          ...state.agreementData,
          errors,
        },
      }
    }
    case COMPANIES_WITHOUT_GENERAL_AGREEMENT_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        companiesWithoutGeneralAgreement: data.map((elem) => {
          const newElem = elem
          newElem.company_id = newElem.id
          delete newElem.id
          return newElem
        }),
      }
    }
    case LOCATION_CHANGE: {
      return {
        ...state,
        agreementData: { data: {}, isLoading: false, errors: {} },
      }
    }
    default:
      return { ...state }
  }
}
