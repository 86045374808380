import React, { Component } from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import isEmpty from "lodash/isEmpty"

import SideBar from "../SideBar"
import HeaderBar from "../HeaderBar"

import AdminMainPage from "../Admin/AdminMainPage"

import "./MainPage.css"
import Routes from "../../routes"

export default class MainPage extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    history: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  }

  static childContextTypes = {
    onUpdateSidebar: PropTypes.func,
    onUpdateCount: PropTypes.func,
    getSelectedCategoryData: PropTypes.func,
  }

  constructor() {
    super()
    this.countCache = null
    this.sidebar = React.createRef()
  }

  componentDidMount() {
    this.onSideBarLoaded()
  }

  getChildContext() {
    return {
      onUpdateSidebar: (state, category = "specific_agreement") => this.sidebar.current.selectElement(state, category),
      onUpdateCount: (count) => {
        if (this.sidebar.current) this.sidebar.current.updateCounts(count)
        else this.countCache = count
      },
      getSelectedCategoryData: () => (this.sidebar.current && this.sidebar.current.getSelectedCategoryData()) || {},
    }
  }

  onSideBarLoaded = () => {
    if (this.countCache && !isEmpty(this.countCache)) {
      this.sidebar.current.updateCounts(this.countCache)
      this.countCache = null
    }
  }

  render() {
    const { history, children, logout } = this.props
    const { location } = history

    if (
      location.pathname.includes("signin") ||
      location.pathname.includes("signup") ||
      location.pathname.includes("password") ||
      location.pathname.indexOf("/confirm") === 0 ||
      location.pathname.match(new RegExp(Routes.SPECIFIC_AGREEMENT_STUDENT_FORM.replace(":id", "(\\d*)")))
    ) {
      return <div>{children}</div>
    } else if (location.pathname.indexOf("/admin") === 0) {
      return <AdminMainPage>{children}</AdminMainPage>
    }

    return (
      <div id="wrapper">
        <SideBar
          location={ location }
          forwardedRef={this.sidebar}
        />
        <div id="page-wrapper">
          <HeaderBar logout={ logout } />
          <div id="main-container">
            <Col xs={ 12 } className="clearfix">{children}</Col>
          </div>
        </div>
      </div>
    )
  }
}
