import React from "react"
import GenericFilter from "../GenericFilter"
import i18n from "i18next"
import PropTypes from "prop-types"

export default class CurriculumsFilter extends React.Component {
  static propTypes = {
    getCurriculums: PropTypes.func.isRequired,
    curriculums: PropTypes.array.isRequired,
    curriculumsFilters: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  getLabel = option => option.title

  getValue = option => option.permalink

  formatDefaultValues = () => {
    const { curriculums, curriculumsFilters, isMulti } = this.props
    if (isMulti) {
      return curriculumsFilters.map((curriculumId) => {
        const relatedCurriculumObject = curriculums.find(curr => curr.permalink === curriculumId)
        return relatedCurriculumObject
      })
    }
    return curriculums.find(curr => `${curr.permalink}` === `${curriculumsFilters}`)
  }

  render() {
    const { curriculums, disabled, ...props } = this.props

    return (
      <GenericFilter
        options={ curriculums }
        getOptionLabel={ this.getLabel }
        getOptionValue={ this.getValue }
        placeholder={ i18n.t("spa:header.curriculums_filter.placeholder") }
        noOptions={ i18n.t("spa:header.curriculums_filter.no_options") }
        disabled={ disabled }
        { ...props }
        value={ this.formatDefaultValues() }
      />
    )
  }
}
