import Network from "../helpers/Network"

const FUNCTIONS_ROUTE = "/functions"
const COMPANY_SECTORS_ROUTE = "/company_sectors"
const CURRICULUMS_ROUTE = "/curriculums"

export default class JTFunctions {
  static getFunctions(schoolId) {
    return Network.get(FUNCTIONS_ROUTE, {
      params: {
        school_id: schoolId,
      },
    })
  }

  static getCompanySectors() {
    return Network.get(COMPANY_SECTORS_ROUTE, {})
  }

  static getCurriculums(schoolId, specificAgreementId = null) {
    return Network.get(CURRICULUMS_ROUTE, {
      params: {
        school_id: schoolId,
        specific_agreement_id: specificAgreementId,
      },
    })
  }

  static getAssigneds(schoolId) {
    return Network.get(`/schools/${schoolId}/assigneds`, {})
  }
}
