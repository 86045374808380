import Network from "../helpers/Network"

const COMPANIES_ROUTE = "/companies"
const USERS_ROUTE = "/users"
const SEARCH_ROUTE = "/search"
const CLEARBIT_API_PATH = "https://autocomplete.clearbit.com/v1/companies/suggest"

export default class AutocompleteAPI {
  static async autocompleteCompanies(val, schoolId) {
    return Network.get(COMPANIES_ROUTE, {
      params: {
        by_name_or_tax_id: `%${val}%`,
        by_school_id: schoolId,
      },
    })
  }

  static async autocompleteUsers(val, roles, additionalParams = {}) {
    return Network.get(USERS_ROUTE, {
      params: {
        by_name: `%${val}%`,
        by_roles: roles,
        ...additionalParams,
      },
    })
  }

  static async autocompleteAgreements(value) {
    return Network.post(SEARCH_ROUTE, {
      search: value,
    })
  }

  static async autocompleteCompaniesFromClearbit(value) {
    try {
      // We use fetch and not axios because axios has the redux-token-auth default headers
      // And clearbit does not accept those headers
      const resp = await fetch(`${CLEARBIT_API_PATH}?query=${value}`)
      const data = await resp.json()
      return data
    } catch (e) {
      throw e
    }
  }
}
