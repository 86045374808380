import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router"
import { Col, Checkbox, Alert } from "react-bootstrap"
import i18n from "i18next"
import moment from "moment"
import qs from "querystring-es3";

import StudentForm from "../StudentForm"
import Session from "../../helpers/SessionHelper"

import { FormGroup, Button } from "../Common"
import { withErrorManager } from "../../hoc"
import { GenericFormInput } from "../GenericInputs"

import "./SpecificAgreementStudentCompletion.css"
import { defaultRenderOptions } from "../../utils"

export class SpecificAgreementStudentCompletion extends Component {
  static propTypes = {
    getStudentInfos: PropTypes.func.isRequired,
    studentInfos: PropTypes.object.isRequired,
    agreementId: PropTypes.string.isRequired,
    location: PropTypes.object,
    updateStudentInfos: PropTypes.func.isRequired,
    completionError: PropTypes.string,
    curriculums: PropTypes.array.isRequired,
    getValidationState: PropTypes.func.isRequired,
    getErrorMessage: PropTypes.func.isRequired,
  }

  static defaultProps = {
    location: {
      search: "",
    },
    completionError: null,
  }

  studentFormRef = React.createRef()

  constructor() {
    super()
    this.state = {
      data: {
        locale: "en",
      },
      submitEnabled: false,
    }

    if (!Session.isConnected) {
      const script = document.createElement("script");
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyC9B-aZgpR_cPkPbItnXNtSZmwvAVlOrho&libraries=places&language=en";
      script.async = false;
      document.body.prepend(script);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.studentInfos) !== JSON.stringify(this.props.studentInfos)) {
      this.setState({
        data: {
          ...this.state.data,
          ...nextProps.studentInfos.specific_agreement,
          student_department:
            (nextProps.studentInfos.school.departements[0] && nextProps.studentInfos.school.departements[0].name) || "",
        },
      })
    }
    if (JSON.stringify(nextProps.formErrors) !== JSON.stringify(this.props.formErrors)) {
      this.studentFormRef.current.forceUpdate()
    }
  }

  componentDidMount() {
    const { location, getStudentInfos, agreementId } = this.props

    const params = new URLSearchParams(location.search)
    getStudentInfos({ id: agreementId, token: params.get("token") })
  }

  onChange = (name, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    })
  }

  submit = async () => {
    const { updateStudentInfos, studentInfos } = this.props

    const parsed = qs.parse(location.search.substring(1));
    const token = parsed?.confirmation_token;

    const studentIdentityNumberFileKey = await this.studentFormRef.current?.triggerUpload(token)

    const data = { ...this.state.data, student_identity_number_file_key: studentIdentityNumberFileKey }

    updateStudentInfos({ data: data, url: studentInfos.update_url })
  }

  onLocaleChange = (event) => {
    moment.updateLocale(event.target.value)
    i18n.changeLanguage(event.target.value)
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    })
  }

  render() {
    const { data, submitEnabled } = this.state
    const { completionError, studentInfos, curriculums, getValidationState, getErrorMessage, formErrors } = this.props
    const { school, company } = studentInfos

    const localeOptions = [
      { name: "English", value: "en" },
      { name: "Catalan", value: "ca" },
      { name: "Spanish", value: "es" },
    ]
    if (completionError) {
      return (
        <Col xs={ 12 } sm={ 6 } smOffset={ 3 } className="clearfix student-completion-form__container">
          <p className="student-completion-form__error">{completionError}</p>
        </Col>
      )
    }
    if (!studentInfos.specific_agreement) return null
    if (Session.isConnected) {
      return (
        <Col xs={ 12 } sm={ 6 } smOffset={ 3 } className="clearfix student-completion-form__container">
          <p className="student-completion-form__error">{i18n.t("spa:student_completion.errors.already_connected")}</p>
        </Col>
      )
    }
    return (
      <Col xs={ 12 } sm={ 6 } smOffset={ 3 } className="clearfix student-completion-form__container">
        <FormGroup
          className="student-completion-form__form-container"
          formTitle={ i18n.t("spa:specific_agreements.form.steps.student.title") }
        >
           <Alert variant="info">
              <div dangerouslySetInnerHTML={{__html: school.student_data_form_message.replace(/(<? *script)/gi, 'illegalscript')}} >
              </div>
           </Alert>
          <GenericFormInput
            name="locale"
            options={ localeOptions }
            onChange={ this.onLocaleChange }
            data={ data }
            componentClass="select"
            baseTranslationKey="student_completion.form.fields"
            renderOptions={ () =>
              defaultRenderOptions({
                options: localeOptions,
                keyProperty: "value",
                valueKey: "value",
                contentKey: "name",
              })
            }
          />
          <StudentForm
            defaultData={ data }
            onChange={ this.onChange }
            departments={ (school && school.departements) || [] }
            displayEmailAddress={ false }
            showStudentPaymentFields
            curriculums={ curriculums }
            getValidationState={ getValidationState }
            getErrorMessage={ getErrorMessage }
            ref={ this.studentFormRef }
            studentCompletion= { true }
            addressRequired= { school && school.postal_address_from_student_required }
            studentSocialSecurityNumberRequired={ school && school.student_social_security_number_required }
            studentIdentityNumberFileKeyRequired={ school && school.student_identity_number_file_key_required }
            studentPhoneNumberRequired= {school && school.student_phone_number_required }
          />
          {school &&
            company && (
            <Checkbox
              className="checkbox-primary"
              onChange={ () => this.setState({ submitEnabled: !this.state.submitEnabled }) }
            >
              <span>
                {i18n.t("spa:student_completion.form.fields.consentment.label", {
                  school_name: school.name,
                  company_name: company.name,
                })}
              </span>
            </Checkbox>
          )}
        </FormGroup>
        <Button
          disabled={ !submitEnabled }
          style={ { float: "right", marginTop: 10 } }
          bsStyle="primary"
          onClick={ this.submit }
        >
          {i18n.t("spa:student_completion.form.buttons.submit")}
        </Button>
      </Col>
    )
  }
}

export default withErrorManager(props => props.formErrors)(withRouter(SpecificAgreementStudentCompletion))
