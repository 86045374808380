import React from "react"
import { connect } from "react-redux"

import SpecificAgreementStudentCompletion from "../components/SpecificAgreementStudentCompletion"
import { GET_STUDENT_INFO_REQUEST, UPDATE_STUDENT_INFO_REQUEST } from "../types"

const StudentCompletionFormContainer = ({ ...props }) => <SpecificAgreementStudentCompletion { ...props } />

const mapStateToProps = state => ({
  studentInfos: state.specificAgreements.studentInfos,
  completionError: state.specificAgreements.studentCompletionError,
  curriculums: state.jt.curriculums,
  formErrors: state.specificAgreements.studentCompletionFormErrors,
})

const mapDispatchToProps = dispatch => ({
  getStudentInfos: ({ id, token }) => dispatch({ type: GET_STUDENT_INFO_REQUEST, payload: { id, token } }),
  updateStudentInfos: ({ data, url }) => dispatch({ type: UPDATE_STUDENT_INFO_REQUEST, payload: { data, url } }),
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentCompletionFormContainer)
