import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as qs from "querystring-es3";
import i18n from "i18next";
import isEmpty from "lodash/isEmpty";

import { withErrorManager } from "../../hoc";
import {
  Alert,
  Button,
  FormError,
  FormInput,
  Logo,
  PasswordInput,
  Title
} from "../Common";
import { GenericFormInput } from "../GenericInputs";

import "./ResetPassword.css";

export class ResetPassword extends Component {
  static propTypes = {
    errors: PropTypes.objectOf(PropTypes.array),
    getValidationState: PropTypes.func.isRequired,
    getErrorMessage: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onSendEmail: PropTypes.func.isRequired,
    onResetPassword: PropTypes.func.isRequired,
    resetRequestData: PropTypes.shape({
      isInProgress: PropTypes.bool,
      isRequestDone: PropTypes.bool,
      isValid: PropTypes.bool
    }).isRequired,
    emailRequestData: PropTypes.shape({
      isInProgress: PropTypes.bool,
      isRequestDone: PropTypes.bool,
      isValid: PropTypes.bool
    }).isRequired
  };

  static defaultProps = {
    errors: {},
    onChange: () => true
  };

  constructor(props) {
    // Remove localStorage values in case they are still present
    // after a expired session for example
    localStorage.removeItem("access-token");
    localStorage.removeItem("client");
    localStorage.removeItem("uid");

    super(props);
    const urlParams = qs.parse(window.location.search.substring(1));
    this.token = urlParams.token;
    this.email = urlParams.uid;
    this.client = urlParams.client_id;
    this.state = {
      email: "",
      data: {
        email: "",
        password: "",
        confirmPassword: "",
        isEmailForm: !this.token
      }
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onPasswordInputChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    });
  }

  handleSubmit = () => {
    const { password, confirmPassword } = this.state.data;

    return password !== confirmPassword;
  };

  renderEmailForm() {
    const { emailRequestData, onChange } = this.props;
    const { isInProgress, isRequestDone, isValid } = emailRequestData;

    return (
      <Fragment>
        <FormInput
          name="email"
          placeholder={i18n.t("spa:authentication.shared.email.placeholder")}
          type="email"
          required
          onChange={this.handleChange}
        />
        {isInProgress && <p>Loading...</p>}
        {isRequestDone &&
          isValid && <p>An email has been sent to your email address</p>}
        {isRequestDone && !isValid && <p>An error occured, please try again</p>}
      </Fragment>
    );
  }

  renderPasswordForm() {
    const { errors, resetRequestData, onChange } = this.props;
    const { isInProgress, isRequestDone, isValid } = resetRequestData;

    const { data } = this.state;

    return (
      <Fragment>
        {!isEmpty(errors) && (
          <Alert type="danger">
            {i18n.t("spa:informations.form.alerts.has_error")}
          </Alert>
        )}
        <GenericFormInput
          data={data}
          placeholder="Password"
          type="password"
          required
          name="password"
          onChange={e => this.onPasswordInputChange(e)}
          getValidationState={this.props.getValidationState}
          getErrorMessage={this.props.getErrorMessage}
          withLabel={false}
          component={PasswordInput}
        />
        <GenericFormInput
          data={data}
          name="confirmPassword"
          placeholder="Confirm password"
          type="password"
          required
          onChange={e => this.onPasswordInputChange(e)}
          getValidationState={this.props.getValidationState}
          getErrorMessage={this.props.getErrorMessage}
          withLabel={false}
        />
        {isInProgress && <p>Loading...</p>}
      </Fragment>
    );
  }

  submitForm(evt) {
    const { onSendEmail, onResetPassword } = this.props;
    const { email, password, isEmailForm } = this.state.data;

    evt.preventDefault();

    if (isEmailForm) onSendEmail(this.state.email);
    else onResetPassword(password, this.token, this.client, this.email);
  }

  render() {
    return (
      <Col
        xs={12}
        sm={6}
        smPush={3}
        md={4}
        mdPush={4}
        className="center-block text-center reset-password-container"
      >
        <Logo />
        <Title
          dangerouslySetInnerHTML={{
            __html: i18n.t("spa:authentication.reset_password.title")
          }}
        />
        <Col sm={12}>
          <form className="form" onSubmit={e => this.submitForm(e)}>
            {this.state.data.isEmailForm && this.renderEmailForm()}
            {!this.state.data.isEmailForm && this.renderPasswordForm()}
            <Button
              block
              type="submit"
              bsSize="sm"
              bsStyle="primary"
              disabled={this.handleSubmit()}
            >
              {i18n.t("spa:authentication.reset_password.submit")}
            </Button>
          </form>
          <br />
          <Link to="/signin">
            <Button type="button" block>
              {i18n.t("spa:authentication.reset_password.signin.link")}
            </Button>
          </Link>
        </Col>
      </Col>
    );
  }
}

export default withErrorManager(props => props.errors)(ResetPassword);
