export const Roles = {
  ADMIN: "jt_admin",
  SCHOOL_ADMIN: "school_admin",
  OAP: "other_approving_party",
  RECRUITER: "recruiter",
  SCHOOL_TUTOR: "school_tutor"
}

export const StateVisibility = {
  specific_agreement: {
    [Roles.RECRUITER]: [
      "draft",
      "in_school_review",
      "sent_for_signature",
      "signed",
      "cancelled",
    ],
    [Roles.SCHOOL_ADMIN]: [
      "student_info_completion",
      "in_school_review",
      "pending_approvals",
      "approved",
      "rejected",
      "sent_for_signature",
      "signed",
      "cancelled",
    ],
    [Roles.OAP]: ["pending_approvals", "approved", "rejected"],
    [Roles.ADMIN]: [],
    [Roles.SCHOOL_TUTOR]: [],
  },
  general_agreement: {
    [Roles.RECRUITER]: [
      "company_draft",
      "school_review",
      "sent_for_signature",
      "active",
      "expired",
      "cancelled"
    ],
    [Roles.SCHOOL_ADMIN]: [
      "school_review",
      "sent_for_signature",
      "active",
      "expired",
      "cancelled",
      "company_without_general_agreement",
    ],
    [Roles.OAP]: [],
    [Roles.ADMIN]: [],
    [Roles.SCHOOL_TUTOR]: [],
  },
}
