import React, { Component } from "react"
import PropTypes from "prop-types"

import { List } from "../../Common"

import AdminSchoolsListItem from "./AdminSchoolsListItem"

export default class AdminSchoolsList extends Component {
  static propTypes = {
    schools: PropTypes.arrayOf(PropTypes.object),
    onSchoolSelected: PropTypes.func,
  }

  static defaultProps = {
    schools: [],
    onSchoolSelected: () => true,
  }

  renderRow(item) {
    const { onSchoolSelected } = this.props

    return <AdminSchoolsListItem key={ item.id } data={ item } onEdit={ () => onSchoolSelected(item.id) } />
  }

  render() {
    const { schools } = this.props
    return (
      <List
        data={ schools }
        titles={ [ "Name", "Short name", { title: "", className: "col-xs-6" } ] }
        titlesClassName="col-xs-3"
        renderRow={ item => this.renderRow(item) }
      />
    )
  }
}
