export const GET_COMMENTS_REQUEST = "GET_COMMENTS_REQUEST"
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS"
export const GET_COMMENTS_ERROR = "GET_COMMENTS_ERROR"

export const COMMENT_CREATE_REQUEST = "COMMENT_CREATE_REQUEST"
export const COMMENT_CREATE_SUCCESS = "COMMENT_CREATE_SUCCESS"
export const COMMENT_CREATE_ERROR = "COMMENT_CREATE_ERROR"

export const COMMENT_DELETE_REQUEST = "COMMENT_DELETE_REQUEST"
export const COMMENT_DELETE_SUCCESS = "COMMENT_DELETE_SUCCESS"
export const COMMENT_DELETE_ERROR = "COMMENT_DELETE_ERROR"
