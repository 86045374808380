import { takeEvery, put, call, all, takeLatest } from "redux-saga/effects";
import { push, goBack } from "react-router-redux";
import each from "lodash/each";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";

import {
  LIST_REQUEST,
  LIST_SUCCESS,
  LIST_ERROR,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_ERROR,
  EDIT_REQUEST,
  EDIT_SUCCESS,
  EDIT_ERROR,
  REMOVE_REQUEST,
  REMOVE_SUCCESS,
  REMOVE_ERROR,
  UNSET_ALTERATION_STATUS_REQUEST,
  UNSET_ALTERATION_STATUS_SUCCESS,
  UNSET_ALTERATION_STATUS_ERROR,
  UNDO_CANCEL_REQUEST,
  UNDO_CANCEL_SUCCESS,
  UNDO_CANCEL_ERROR,
  SEND_INVITATION_EMAIL
} from "../types";
import {
  getList as getListAction,
  edit as editAction,
  create as createAction,
  remove as removeAction,
  unsetAlterationStatus as unsetAlterationStatusAction,
  undoCancel as undoCancelAction,
  sendInvitationEmail as sendInvitationEmailAction,
  createSchoolTemplateAttributes,
  removeSchoolTemplateAttributes,
  updateSchoolTemplateAttributes
} from "../actions/admin";

export function* getList({ payload }) {
  const { type } = payload;
  try {
    const data = yield call(getListAction, type);
    yield put({ type: LIST_SUCCESS, payload: { data, type } });
  } catch (e) {
    yield put({ type: LIST_ERROR, payload: { message: e.message } });
  }
}

export function* edit({ payload }) {
  const { data, type } = payload;

  let finalData = data;
  try {
    if (type === "schools") {
      const {
        specific_agreement_printing_templates: saAttributes,
        general_agreement_printing_templates: gaAttributes,
        ...other
      } = data;

      finalData = other;
      yield all(
        saAttributes.map(attributes => {
          const { file, isNew, isUpdated, destroy, ...rest } = {
            ...attributes,
            school_id: data.id
          };
          if (isNew) {
            return call(
              createSchoolTemplateAttributes,
              file,
              rest,
              "specific_agreement"
            );
          } else if (destroy) {
            return call(
              removeSchoolTemplateAttributes,
              rest.id,
              "specific_agreement"
            );
          } else if (isUpdated) {
            return call(
              updateSchoolTemplateAttributes,
              file,
              rest,
              "specific_agreement"
            );
          }
          return null;
        })
      );
      yield all(
        gaAttributes.map(attributes => {
          const { file, isNew, isUpdated, destroy, ...rest } = {
            ...attributes,
            school_id: data.id
          };
          if (isNew) {
            return call(
              createSchoolTemplateAttributes,
              file,
              rest,
              "general_agreement"
            );
          } else if (destroy) {
            return call(
              removeSchoolTemplateAttributes,
              rest.id,
              "general_agreement"
            );
          } else if (isUpdated) {
            return call(
              updateSchoolTemplateAttributes,
              file,
              rest,
              "general_agreement"
            );
          }
          return null;
        })
      );
    }
    yield call(editAction, data.id, finalData, type);
    yield put({ type: EDIT_SUCCESS });
    yield put(push(`/admin/${type}`));
    yield put({ type: LIST_REQUEST, payload: { type } });
  } catch (e) {
    yield put({ type: EDIT_ERROR, errors: e });
  }
}

export function* create({ payload }) {
  const { data, type } = payload;

  try {
    yield call(createAction, data, type);
    yield put({ type: CREATE_SUCCESS });
    yield put(push(`/admin/${type}`));
    yield put({ type: LIST_REQUEST, payload: { type } });
  } catch (e) {
    yield put({ type: CREATE_ERROR });
  }
}

export function* remove({ payload }) {
  const { id, type } = payload;

  try {
    yield call(removeAction, id, type);
    yield put({ type: REMOVE_SUCCESS });
    yield put({ type: LIST_REQUEST, payload: { type } });
    if (type === "specific_agreements") {
      yield put(push(`/specific_agreements/list/in_school_review`));
    } else if (type === "general_agreements") {
      yield put(push(`/general_agreements/list/school_review`));
    }
  } catch (e) {
    yield put({ type: REMOVE_ERROR });
  }
}

export function* unsetAlterationStatus({ payload }) {
  const { id, type } = payload;

  try {
    yield call(unsetAlterationStatusAction, id, type);
    yield put({ type: UNSET_ALTERATION_STATUS_SUCCESS });
    yield put({ type: LIST_REQUEST, payload: { type } });
    yield put(goBack())
  } catch (e) {
    yield put({ type: UNSET_ALTERATION_STATUS_ERROR });
  }
}

export function* undoCancel({ payload }) {
  const { id, type } = payload;

  try {
    yield call(undoCancelAction, id, type);
    yield put({ type: UNDO_CANCEL_SUCCESS });
    yield put({ type: LIST_REQUEST, payload: { type } });
    yield put(goBack())
  } catch (e) {
    yield put({ type: UNDO_CANCEL_ERROR });
  }
}

export function* sendInvitationEmail({ payload }) {
  const { id, redirectUrl } = payload;

  try {
    yield call(sendInvitationEmailAction, id, redirectUrl);
  } catch (e) {
    console.error(e);
  }
}

function* adminSaga() {
  yield all([
    takeEvery(LIST_REQUEST, getList),
    takeLatest(EDIT_REQUEST, edit),
    takeLatest(CREATE_REQUEST, create),
    takeLatest(REMOVE_REQUEST, remove),
    takeLatest(UNSET_ALTERATION_STATUS_REQUEST, unsetAlterationStatus),
    takeLatest(UNDO_CANCEL_REQUEST, undoCancel),
    takeLatest(SEND_INVITATION_EMAIL, sendInvitationEmail)
  ]);
}

export default adminSaga;
