import React from "react"
import ReactPaginate from "react-paginate"

export default class ListPagination extends React.PureComponent {
  handlePageChange = ({ selected }) => {
    const { onPage } = this.props

    onPage(selected)
  }

  render() {
    const { itemsCount, currentPage, itemsPerPage } = this.props
    const pageCount = Math.ceil(itemsCount / itemsPerPage)

    return (
      <ReactPaginate
        breakLabel={ "\u2026" }
        breakClassName={ "break-me" }
        previousLabel={ "\u2039" }
        pageCount={ pageCount }
        marginPagesDisplayed={ 1 }
        pageRangeDisplayed={ 5 }
        onPageChange={ this.handlePageChange }
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
        pageClassName="paginate_button page-item"
        pageLinkClassName="page-link"
        forcePage={ currentPage }
        nextLabel={ "\u203a" }
      />
    )
  }
}
