import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import i18n from "i18next"
import { GET_SPECIFIC_AGREEMENTS_REQUEST, SET_SPECIFIC_AGREEMENTS_FILTERS } from "../types"

import CountriesFilter from "../components/CountriesFilter"

class JobCountryListContainer extends React.PureComponent {
  render() {
    const { jobCountryFilters, setJobCountryFilters, triggerSpecificAgreementListFetch, match, ...props } = this.props
    return (
      <CountriesFilter
        onChange={ (value) => {
          setJobCountryFilters(value.map(country => country.value))
          setImmediate(() => triggerSpecificAgreementListFetch(match.params.type))
        } }
        selectedCountries={ jobCountryFilters }
        noOptions={ i18n.t("spa:header.job_countries_filter.no_options") }
        placeholder={ i18n.t("spa:header.job_countries_filter.placeholder") }
        { ...props }
      />
    )
  }
}

const mapStateToProps = state => ({
  jobCountryFilters: state.jt.specificAgreementsFilters.jobCountry,
})

const mapDispatchToProps = dispatch => ({
  setJobCountryFilters: filters => dispatch({ type: SET_SPECIFIC_AGREEMENTS_FILTERS, payload: { jobCountry: filters } }),
  triggerSpecificAgreementListFetch: type => dispatch({ type: GET_SPECIFIC_AGREEMENTS_REQUEST, payload: { type } }),
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(JobCountryListContainer))
