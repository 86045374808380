import React from "react"
import PropTypes from "prop-types"

import DefaultAvatarUser from "../../assets/default-avatar.png"

import { Avatar, ToolTip, Button, Icon } from "../Common"
import i18n from "i18next"
import Toaster from "../../helpers/Toaster"

import "./UserContact.css"

const UserContact = ({ avatarUrl, emailAddress, phoneNumber }) => (
  <div className="user-contact__container">
    <div>
      <Avatar size={ 34 } src={ avatarUrl || DefaultAvatarUser } />
    </div>
    <div>
      <ToolTip id="tooltip-email" placement="left" renderTooltip={ () => <span>{emailAddress}</span> }>
        <Button
          height={ 34 }
          rounded
          bsStyle="default"
          onClick={ () => {
            navigator.clipboard.writeText(emailAddress)
            new Toaster.Builder()
              .setMessage(i18n.t('spa:specific_agreements.copy_email.success_toast.message'))
              .setOptions({
                className: "toaster-success",
              })
              .setType(Toaster.TYPES.SUCCESS)
              .build()
              .show()
          } }
        >
          <Icon iconName="envelope-o" />
        </Button>
      </ToolTip>
    </div>
    {phoneNumber && (
      <div>
        <ToolTip id="tooltip-phone-number" placement="left" renderTooltip={ () => <span>{phoneNumber}</span> }>
          <Button height={ 34 } rounded bsStyle="default">
            <Icon iconName="phone" />
          </Button>
        </ToolTip>
      </div>
    )}
  </div>
)

UserContact.propTypes = {
  avatarUrl: PropTypes.string,
  emailAddress: PropTypes.string,
  phoneNumber: PropTypes.string,
}

UserContact.defaultProps = {
  avatarUrl: DefaultAvatarUser,
  emailAddress: null,
  phoneNumber: null,
}

export default UserContact
