import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Image } from "react-bootstrap"
import classname from "classnames"

export default class Avatar extends PureComponent {
  static propTypes = {
    ...Image.propTypes,
    size: PropTypes.number,
    rounded: PropTypes.bool,
  }

  static defaultProps = {
    size: 40,
    rounded: false,
  }

  render() {
    const { src, size, className, rounded, ...props } = this.props

    return (
      <Image
        src={ src }
        width={ size }
        height={ size }
        className={ classname({ "img-circle": rounded }, className) }
        { ...props }
      />
    )
  }
}
