import { Profile } from "../api"

export async function getProfiles(type) {
  try {
    return await Profile.getProfiles(type)
  } catch (e) {
    throw e
  }
}

export async function editProfile(data, type) {
  try {
    return await Profile.editProfile(data, type)
  } catch (e) {
    throw e
  }
}

export async function getProfileById(id, type) {
  try {
    return await Profile.getProfileById(id, type)
  } catch (e) {
    throw e
  }
}
