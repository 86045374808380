export const GET_FUNCTIONS_SUCCESS = "GET_FUNCTIONS_SUCCESS"
export const GET_FUNCTIONS_ERROR = "GET_FUNCTIONS_ERROR"

export const GET_COMPANY_SECTORS_REQUEST = "GET_COMPANY_SECTORS_REQUEST"
export const GET_COMPANY_SECTORS_SUCCESS = "GET_COMPANY_SECTORS_SUCCESS"
export const GET_COMPANY_SECTORS_ERROR = "GET_COMPANY_SECTORS_ERROR"

export const GET_CURRICULUMS_REQUEST = "GET_CURRICULUMS_REQUEST"
export const GET_CURRICULUMS_SUCCESS = "GET_CURRICULUMS_SUCCESS"
export const GET_CURRICULUMS_ERROR = "GET_CURRICULUMS_ERROR"

export const SET_CURRICULUMS_FILTERS = "SET_CURRICULUMS_FILTERS"

export const GET_ASSIGNEDS_REQUEST = "GET_ASSIGNEDS_REQUEST"
export const GET_ASSIGNEDS_SUCCESS = "GET_ASSIGNEDS_SUCCESS"
export const GET_ASSIGNEDS_ERROR = "GET_ASSIGNEDS_ERROR"

export const SET_SPECIFIC_AGREEMENTS_FILTERS = "SET_SPECIFIC_AGREEMENTS_FILTERS"
export const CLEAR_SPECIFIC_AGREEMENTS_FILTERS = "CLEAR_SPECIFIC_AGREEMENTS_FILTERS"
