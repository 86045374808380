export default {
  SIGNIN: "/signin",
  SIGNUP: "/signup",
  NEW_PASSWORD: "/password",
  CONFIRM_ACCOUNT: "/confirm",
  SPECIFIC_AGREEMENT_INDEX: "/specific_agreements/list/:type",
  SPECIFIC_AGREEMENT_SHOW: "/specific_agreements/:id",
  SPECIFIC_AGREEMENT_CREATE: "/specific_agreements/new",
  SPECIFIC_AGREEMENT_PRINTING_TEMPLATE_PREVIEW: "/specific_agreements/:id/preview",
  SPECIFIC_AGREEMENT_STUDENT_FORM: "/specific_agreements/:id/student",
  GENERAL_AGREEMENT_INDEX: "/general_agreements/list/:type",
  GENERAL_AGREEMENT_SHOW: "/general_agreements/:id",
  GENERAL_AGREEMENT_CREATE: "/general_agreements/new",
  GENERAL_AGREEMENT_PRINTING_TEMPLATE_PREVIEW: "/general_agreements/:id/preview",
  ADMIN_INDEX: "/admin",
  ADMIN_USERS: "/admin/users",
  ADMIN_USERS_UPDATE: "/admin/users/:id",
  ADMIN_USERS_CREATE: "/admin/users/invite",
  ADMIN_SCHOOLS: "/admin/schools",
  ADMIN_SCHOOLS_CREATE: "/admin/schools/new",
  ADMIN_SCHOOLS_UPDATE: "/admin/schools/:id",
  PROFILE_USER: "/profile",
  COMPANY_CREATION: "/company/new",
  COMPANY_EDITION: "/company/:id",
}
