import React, { Component } from "react"
import PropTypes from "prop-types"

import { List } from "../../Common"
import AdminUsersListItem from "./AdminUsersListItem"

export default class AdminUsersList extends Component {
  static propTypes = {
    onUserSelected: PropTypes.func,
    onRemoveUser: PropTypes.func,
    users: PropTypes.array.isRequired,
  }

  static defaultProps = {
    onUserSelected: () => true,
    onRemoveUser: () => true,
  }

  renderRow = (item) => {
    const { onUserSelected, onRemoveUser, onSendRegistrationEmail } = this.props
    return (
      <AdminUsersListItem
        onDelete={ () => onRemoveUser(item.id) }
        onEdit={ () => onUserSelected(item) }
        onSendRegistrationEmail={ () => onSendRegistrationEmail(item.id, item.status) }
        key={ item.id }
        data={ item }
        canSendEmail={ item.status !== "registration completed" }
      />
    )
  }

  render() {
    const { users } = this.props
    return (
      <List
        data={ users }
        titles={ [ "User", "Email", "Role", "School", "Status", { title: "", className: "col-xs-7" } ] }
        titlesClassName="col-xs-1"
        renderRow={ this.renderRow }
      />
    )
  }
}
