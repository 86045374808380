import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import i18n from "i18next";

import GenericFilter from "../components/GenericFilter";
import {
  GET_SPECIFIC_AGREEMENTS_REQUEST,
  SET_SPECIFIC_AGREEMENTS_FILTERS
} from "../types";

class AcademicSchoolYearsListContainer extends React.PureComponent {
  render() {
    const {
      academicSchoolYearsFilters,
      setAcademicSchoolYearsFilters,
      triggerSpecificAgreementListFetch,
      match,
      ...props
    } = this.props;

    // Generate a list from 2018
    const options = Array.from(new Array(12), (x, i) => (i + 2018).toString())
    options.unshift('')

    return (
      <GenericFilter
        isMulti={false}
        onChange={value => {
          setAcademicSchoolYearsFilters([value]);
          setImmediate(() =>
            triggerSpecificAgreementListFetch(match.params.type)
          );
        }}
        options={options}
        getOptionLabel={option => option}
        getOptionValue={option => option}
        value={academicSchoolYearsFilters}
        noOption={i18n.t("spa:header.academic_school_years_filter.no_options")}
        placeholder={i18n.t(
          "spa:header.academic_school_years_filter.placeholder"
        )}
        {...props}
      />
    );
  }
}

const mapStateToProps = state => ({
  academicSchoolYearsFilters:
    state.jt.specificAgreementsFilters.academicSchoolYears
});

const mapDispatchToProps = dispatch => ({
  setAcademicSchoolYearsFilters: filters =>
    dispatch({
      type: SET_SPECIFIC_AGREEMENTS_FILTERS,
      payload: { academicSchoolYears: filters }
    }),
  triggerSpecificAgreementListFetch: type =>
    dispatch({ type: GET_SPECIFIC_AGREEMENTS_REQUEST, payload: { type } })
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AcademicSchoolYearsListContainer)
);
