import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router"
import i18n from "i18next"

import TopBar from "../TopBar"
import { List } from "../Common"

import GeneralAgreementsListItem from "./GeneralAgreementsListItem"

import { ConnectWithSidebar, withListPagination } from "../../hoc"

import Routes from "../../routes"

import Session from "../../helpers/SessionHelper"

export class GeneralAgreementsList extends Component {
  static propTypes = {
    agreements: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAgreements: PropTypes.func.isRequired,
    onUpdateSidebar: PropTypes.func,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    companiesWithoutGeneralAgreement: PropTypes.arrayOf(PropTypes.object).isRequired,
    getCompaniesWithoutGeneralAgreement: PropTypes.func.isRequired,
    category: PropTypes.string,
    subcategory: PropTypes.string,
  }

  static defaultProps = {
    onUpdateSidebar: () => true,
    category: "",
    subcategory: "",
  }

  tableTitlesAgreements = [
    i18n.t("spa:general_agreements.index.header.agreement"),
    Session.isRecruiter() ? i18n.t("spa:general_agreements.index.header.school") : null,
    i18n.t("spa:general_agreements.index.header.company"),
    i18n.t("spa:general_agreements.index.header.created_on"),
  ]

  componentDidMount() {
    const { match } = this.props

    this.getList(match.params.type)
  }

  componentWillReceiveProps(nextProps) {
    const { match } = this.props
    const { match: nextMatch } = nextProps

    if (nextMatch.params.type !== match.params.type) {
      this.getList(nextMatch.params.type)
    }
  }

  getList(type) {
    const { getAgreements, onUpdateSidebar, getCompaniesWithoutGeneralAgreement } = this.props

    onUpdateSidebar(type, "general_agreement")
    if (type === "company_without_general_agreement") {
      getCompaniesWithoutGeneralAgreement()
    } else {
      getAgreements(type)
    }
  }

  render() {
    const {
      agreements,
      history,
      companiesWithoutGeneralAgreement,
      match,
      category,
      subcategory,
      currentPage,
      changePage,
    } = this.props
    const data =
      match.params.type === "company_without_general_agreement" ? companiesWithoutGeneralAgreement : agreements
    const slicedData = data.slice(50 * currentPage, 50 * currentPage + 50)

    return (
      <div>
        <TopBar title={ subcategory } subtitle={ category } canCreateCompany={ Session.isSchoolAdmin() } />
        <div className="agreements-list-container">
          <List
            data={ slicedData }
            totalCount={ data.length }
            currentPage={ currentPage }
            changePage={ changePage }
            titles={ this.tableTitlesAgreements }
            className="agreements-list-table"
            titlesClassName="agreements-list-table-title"
            titlesContainerClassName="agreements-list-table-header"
            renderRow={ item => (
              <GeneralAgreementsListItem
                item={ item }
                onClick={ () =>
                  (match.params.type !== "company_without_general_agreement"
                    ? history.push(Routes.GENERAL_AGREEMENT_SHOW.replace(":id", item.id))
                    : history.push(Routes.COMPANY_EDITION.replace(":id", item.company_id)))
                }
              />
            ) }
          />
        </div>
      </div>
    )
  }
}

export default withRouter(ConnectWithSidebar(withListPagination(GeneralAgreementsList)))
