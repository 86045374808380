import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Col } from "react-bootstrap"
import i18n from "i18next"

import { Logo, Title, Button, PasswordInput, FormInput } from "../Common"

import Routes from "../../routes"

export default class SignupInvitation extends PureComponent {
  static propTypes = {
    submitInvitation: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor() {
    super()
    this.state = {
      data: {
        password: "",
        password_confirmation: "",
      },
      passwordConfirmValidationState: null,
      buttonDisabled: true,
    }
  }

  validatePasswordConfirmation() {
    if (this.state.data.password !== this.state.data.password_confirmation) return "error"
    return null
  }

  onInputChange(e) {
    this.setState(
      {
        data: {
          ...this.state.data,
          [e.target.name]: e.target.value,
        },
      },
      () =>
        this.setState({ passwordConfirmValidationState: this.validatePasswordConfirmation() }, () =>
          this.setState({
            buttonDisabled: this.pwdInputRef.hasError() || this.confirmPwdRef.hasError(),
          })
        )
    )
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { submitInvitation, location } = this.props

    const search = location.search
    // eslint-disable-next-line
    const params = new URLSearchParams(search)

    submitInvitation(this.state.data, params.get("invitation_token"))
  }

  render() {
    const { buttonDisabled, passwordConfirmValidationState } = this.state

    return (
      <Col xs={ 12 } sm={ 6 } smPush={ 3 } md={ 4 } mdPush={ 4 } className="center-block text-center signup-container">
        <Logo />
        <Title>{i18n.t("spa:authentication.signup_invitation.title")}</Title>
        <form onSubmit={ this.onSubmit }>
          <PasswordInput
            wrapperRef={ (ref) => {
              this.pwdInputRef = ref
            } }
            onChange={ e => this.onInputChange(e) }
            placeholder={ i18n.t("spa:authentication.signup_invitation.form.password.placeholder") }
            required
            name="password"
            autoComplete="new-password"
          />
          <FormInput
            wrapperRef={ (ref) => {
              this.confirmPwdRef = ref
            } }
            onChange={ e => this.onInputChange(e) }
            placeholder={ i18n.t("spa:authentication.signup_invitation.form.confirm_password.placeholder") }
            required
            type="password"
            name="password_confirmation"
            autoComplete="new-password"
            validationState={ passwordConfirmValidationState }
            errorMessage={ i18n.t("spa:authentication.signup_invitation.form.confirm_password.error") }
          />
          <Button block disabled={ buttonDisabled } bsStyle="primary" type="submit">
            {i18n.t("spa:authentication.signup_invitation.form.buttons.submit")}
          </Button>
          <br />
          <Link to={ Routes.SIGNIN }>
            <Button block bsStyle="default">
              {i18n.t("spa:authentication.signup_invitation.form.buttons.signin")}
            </Button>
          </Link>
        </form>
      </Col>
    )
  }
}
