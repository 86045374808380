import { SpecificAgreements } from "../api";

export async function getSpecificAgreements(
  type,
  byField,
  byOrder,
  specificAgreementsFilters,
  page
) {
  try {
    const dataAgreements = await SpecificAgreements.getAgreements(
      type,
      byField,
      byOrder,
      specificAgreementsFilters,
      page
    );
    return dataAgreements;
  } catch (e) {
    throw e;
  }
}

export async function getSpecificAgreementById(id) {
  try {
    const dataAgreement = await SpecificAgreements.getAgreement(id);
    return dataAgreement;
  } catch (e) {
    throw e;
  }
}

export async function editSpecificAgreement(data, action, url) {
  try {
    const dataAgreement = await SpecificAgreements.editAgreement(
      data,
      action,
      url
    );
    return dataAgreement;
  } catch (e) {
    throw e;
  }
}

export async function createSpecificAgreement(data, redirectUrl) {
  try {
    const dataCreate = await SpecificAgreements.createAgreement(
      data,
      redirectUrl
    );
    return dataCreate;
  } catch (e) {
    throw e;
  }
}

export async function createApproval(agreementId, userIds, state) {
  try {
    await SpecificAgreements.createApproval(userIds, agreementId, state);
  } catch (e) {
    throw e;
  }
}

export async function editApproval(agreementId, userId, state, id) {
  try {
    const data = await SpecificAgreements.editApproval(
      agreementId,
      userId,
      state,
      id
    );
    return data;
  } catch (e) {
    throw e;
  }
}

export async function sendForSignature(url, id, data, byPassSignature = false) {
  try {
    await SpecificAgreements.sendAgreementForSignature(
      url,
      id,
      data,
      byPassSignature
    );
  } catch (e) {
    throw e;
  }
}

export async function signSpecificAgreement(url, data) {
  try {
    await SpecificAgreements.signAgreement(url, data);
  } catch (e) {
    throw e;
  }
}

export async function printSpecificAgreement(url) {
  try {
    const toOpen = await SpecificAgreements.printAgreement(url);
    return toOpen;
  } catch (e) {
    throw e;
  }
}

export async function removeSpecificAgreement(id) {
  try {
    await SpecificAgreements.removeAgreement(id);
  } catch (e) {
    throw e;
  }
}

export async function getActivities(id) {
  try {
    const activies = await SpecificAgreements.getActivities(id);
    return activies;
  } catch (e) {
    throw e;
  }
}

export async function getStudentInfos(token, id) {
  try {
    const data = await SpecificAgreements.getStudentInfo(token, id);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function modifyAgreement(id, reason, jobRealEndDate) {
  try {
    const data = await SpecificAgreements.modifyAgreement(
      id,
      reason,
      jobRealEndDate
    );
    return data;
  } catch (e) {
    throw e;
  }
}

export async function cancelAgreement(id, reason) {
  try {
    const data = await SpecificAgreements.cancelAgreement(
      id,
      reason
    );
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateStudentInfos(data, url) {
  try {
    await SpecificAgreements.updateStudentInfos(data, url);
  } catch (e) {
    throw e;
  }
}

export async function extendAgreement(id, reason, jobRealEndDate) {
  try {
    const data = await SpecificAgreements.extendAgreement(
      id,
      reason,
      jobRealEndDate
    );
    return data;
  } catch (e) {
    throw e;
  }
}

export async function terminateSpecificAgreement(
  id,
  reason,
  realEndDate,
  hoursDone
) {
  try {
    const data = await SpecificAgreements.terminateAgreement(
      id,
      reason,
      realEndDate,
      hoursDone
    );
    return data;
  } catch (e) {
    throw e;
  }
}

export async function skipApprovalValidation(id) {
  try {
    const data = await SpecificAgreements.skipApprovalValidation(id);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function cancelSignature(id) {
  try {
    const data = await SpecificAgreements.cancelSignature(id);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function SendEmailToStudent(data) {
  try {
    const dataAgreement = await SpecificAgreements.SendEmailToStudent(
      data
    );
    return dataAgreement;
  } catch (e) {
    throw e;
  }
}

export async function sendReminder(id) {
  try {
    const data = await SpecificAgreements.sendReminder(id);
    return data;
  } catch (e) {
    throw e;
  }
}
