import React from "react"
import PropTypes from "prop-types"

import CompanyFormAutocompleteItem from "./CompanyFormAutocompleteItem"

const CompanyFormAutocompleteSection = ({ title, data, onSelect }) =>
  data.length > 0 && (
    <div>
      <div className="company-form-autocomplete__section-title">
        <span>{title}</span>
      </div>
      <div>
        {data.map(elem => (
          <CompanyFormAutocompleteItem key={ elem.id } item={ elem } onSelect={ () => onSelect(elem.name, elem) } />
        ))}
      </div>
    </div>
  )

CompanyFormAutocompleteSection.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
}

CompanyFormAutocompleteSection.defaultProps = {
  data: [],
}

export default CompanyFormAutocompleteSection
