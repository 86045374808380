import Network from "../helpers/Network"

const SIDEBAR_COUNT_ROUTE = "/agreement_count"

export default class SidebarAPI {
  static getAgreementCounts({
    curriculums,
    assigneds,
    companySectors,
    jobCountry,
    companyCountries,
    studentSchoolYears,
    academicSchoolYears,
    jobStartDates: { startDate, endDate },
  }) {
    return Network.get(SIDEBAR_COUNT_ROUTE, {
      params: {
        by_curriculum_permalinks: curriculums,
        by_assigned_ids: assigneds,
        by_job_country: jobCountry,
        by_company_country: companyCountries,
        by_company_sector: companySectors,
        by_student_school_year: studentSchoolYears,
        by_academic_school_year: academicSchoolYears,
        "by_job_started_date_range[start_date]": startDate,
        "by_job_started_date_range[end_date]": endDate,
      },
    })
  }
}
