import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"

import { Button } from "../Common"

export default class SpecificAgreementCancelButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
  }

  static defaultProps = {
    onClick: () => true,
  }

  render() {
    const { onClick } = this.props

    return (
      <Button onClick={ onClick } className="specific-agreement__cancel-button btn-danger" bsStyle="default">
        {i18n.t("spa:specific_agreement_assignee_card.shared.buttons.cancel")}
      </Button>
    )
  }
}
