import React from "react"
import PropTypes from "prop-types"

import UserItem from "./UserItem"

import "./AutocompleteUserItem.css"

const AutocompleteUserItem = ({ firstName, lastName, avatarUrl, position, ...props }) => (
  <div className="autocomplete-user__container" { ...props }>
    <UserItem firstName={ firstName } lastName={ lastName } pictureUrl={ avatarUrl } position={ position } />
  </div>
)

AutocompleteUserItem.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatarUrl: PropTypes.string,
  position: PropTypes.string,
}

AutocompleteUserItem.defaultProps = {
  firstName: "",
  lastName: "",
  avatarUrl: null,
  position: null,
}

export default AutocompleteUserItem
