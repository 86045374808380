import React from "react"
import PropTypes from "prop-types"
import { NavDropdown, MenuItem } from "react-bootstrap"
import i18n from "i18next"

import SideBarListItem from "./SideBarListItem"
import { Icon } from "../Common"

import Session from "../../helpers/SessionHelper"

import "./SideBarCategory.css"

const SideBarCategory = ({
  title,
  elements,
  category,
  onExport,
  onExportByState,
  isExporting,
}) => (
  <div className="sidebar-category">
    {title && (
      <div className="sidebar-category-title">
        <span>{title}</span>
        {Session.isSchoolAdmin() && (
          <NavDropdown
            className="sidebar-category__dropdown inline-block"
            id={ `export_${category}` }
            title={
              isExporting ? (
                <div className="sk-spinner sk-spinner-rotating-plane" />
              ) : (
                <Icon iconName="cog" />
              )
            }
            disabled={ isExporting }
          >
            {Session.isSchoolAdmin() && category === "specific_agreement" && (
              <MenuItem className="sidebar-category__menu-item" onClick={ onExportByState }>
                <div>
                  <Icon className="sidebar-category__menu-item-icon" iconName="file-excel-o" />
                  <span>{i18n.t(`spa:sidebar.${category}.export_by_state`)}</span>
                </div>
              </MenuItem>
            )}
            {Session.isSchoolAdmin() && (
              <MenuItem className="sidebar-category__menu-item" onClick={ onExport }>
                <div>
                  <Icon className="sidebar-category__menu-item-icon" iconName="file-excel-o" />
                  <span>{i18n.t(`spa:sidebar.shared.export`)}</span>
                </div>
              </MenuItem>
            )}
          </NavDropdown>
        )}
      </div>
    )}
    {elements
      .filter(e => e.visible)
      .map(elem => (
        <SideBarListItem
          key={ elem.agreementState }
          category={ category }
          type={ elem.agreementState }
          label={ elem.label }
          number={ elem.count }
          selected={ !!elem.selected }
        />
      ))}
  </div>
)

SideBarCategory.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      number: PropTypes.number,
      selected: PropTypes.bool,
    })
  ),
  onExport: PropTypes.func,
  isExporting: PropTypes.bool,
}

SideBarCategory.defaultProps = {
  elements: [],
  title: null,
  category: null,
  onExport: () => true,
  onExportByState: () => true,
  isExporting: false,
}

export default SideBarCategory
