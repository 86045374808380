import { Authentication } from "../api"

export async function resetPasswordEmailRequest(email) {
  try {
    await Authentication.resetPasswordEmailRequest(email)
  } catch (e) {
    throw e
  }
}

export async function resetPassword(password, client, uid, accessToken) {
  try {
    await Authentication.resetPassword(password, client, uid, accessToken)
  } catch (e) {
    throw e
  }
}
