import React from "react"
import { Col } from "react-bootstrap"
import i18n from "i18next"
import PropTypes from "prop-types"

import { FormGroup } from "../Common"

import { consumeSpecificAgreementForm } from "../../hoc"

export const SpecificAgreementRecruiterDirections = ({
  data: {
    school: { recruiter_directions: directions },
  },
}) => (
  <Col xs={ 12 } sm={ 5 } className="agreement-form__recruiter-directions-container clearfix">
    <FormGroup
      formTitle={ i18n.t("spa:specific_agreements.recruiter_directions.title") }
      className="agreement-form__recruiter-directions-content"
    >
      <div dangerouslySetInnerHTML={ { __html: directions } } />
    </FormGroup>
  </Col>
)

SpecificAgreementRecruiterDirections.propTypes = {
  data: PropTypes.shape({
    school: PropTypes.shape({
      recruiter_directions: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default consumeSpecificAgreementForm(SpecificAgreementRecruiterDirections, [ "school" ])
