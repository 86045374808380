import React from "react"
import Select, { components } from "react-select"
import PropTypes from "prop-types"
import classnames from "classnames"

import "./index.css"

const MultiValueLabel = props => (
  <div title={ props.data.title } className="generic-filter-select__multi-value__tooltip">
    <components.MultiValueLabel { ...props } />
  </div>
)

export default class GenericFilter extends React.Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    noOptions: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    className: "",
  }

  render() {
    const { options, value, disabled, noOptions, placeholder, className, ...props } = this.props

    return (
      <div className={ classnames("generic-filter-container", { disabled, className }) }>
        <Select
          isMulti
          components={ { MultiValueLabel } }
          options={ options }
          value={ value }
          noOptionsMessage={ () => noOptions }
          placeholder={ placeholder }
          classNamePrefix="generic-filter-select"
          disabled={ disabled }
          { ...props }
        />
      </div>
    )
  }
}
