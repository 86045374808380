import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, Checkbox } from "react-bootstrap";
import i18n from "i18next";
import each from "lodash/each";
import isObject from "lodash/isObject";

import { Admin } from "../../../api";

import DynamicInputGroup from "../../DynamicInputGroup";
import {
  ButtonGroup,
  FormError,
  FormGroup,
  FormInput,
  RadioGroup,
  ImageUpload
} from "../../Common";
import { GenericFormInput } from "../../GenericInputs";

import "./AdminSchoolsForm.css";
import AdminSchoolsTemplates from "./AdminSchoolsTemplates";
import AdminSchoolsApiKeys from "./AdminSchoolsApiKeys";
import AdminSchoolsSpecificCompetenciesTemplates from "./AdminSchoolsSpecificCompetenciesTemplates";

export default class AdminSchoolsForm extends Component {
  static propTypes = {
    defaultData: PropTypes.object,
    isCreation: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCloseForm: PropTypes.func,
    schoolId: PropTypes.string,
    errors: PropTypes.object
  };

  static defaultProps = {
    defaultData: {},
    isCreation: false,
    onSubmit: () => true,
    onCloseForm: () => true,
    schoolId: null,
    errors: null
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    if (!props.isCreation && props.schoolId) {
      this.retrieveSchoolData(props.schoolId);
    }
    this.imageUpload = React.createRef();
    this.dynamicInput = React.createRef();
    this.onAddSpecificAgreementTemplate = this.onAddTemplate.bind(
      this,
      "specific_agreement"
    );
    this.onAddGeneralAgreementTemplate = this.onAddTemplate.bind(
      this,
      "general_agreement"
    );
    this.onChangeSpecificAgreementTemplateAttribute = (...args) =>
      this.onChangeTemplateAttribute(...args, "specific_agreement");
    this.onChangeGeneralAgreementTemplateAttribute = (...args) =>
      this.onChangeTemplateAttribute(...args, "general_agreement");
    this.onRemoveSpecificAgreementTemplate = index =>
      this.onRemoveTemplate("specific_agreement", index);
    this.onRemoveGeneralAgreementTemplate = index =>
      this.onRemoveTemplate("general_agreement", index);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.schoolId !== this.props.schoolId)
      this.retrieveSchoolData(nextProps.schoolId);
  }

  async retrieveSchoolData(id) {
    const {
      specific_competencies_templates: competenciesTemplates,
      ...data
    } = await Admin.getById(id, "schools");
    this.setState({
      data: {
        ...this.state.data,
        ...data,
        departments_attributes: data.departments,
        specific_competencies_templates_attributes: competenciesTemplates || [],
        ...data.api_tokens.reduce(
          (obj, val) => {
            obj.clientIds.push(val.client_id);
            obj.clientSecrets.push(val.client_secret);
            return obj;
          },
          { clientIds: [], clientSecrets: [] }
        )
      }
    });
    this.dynamicInput.current.updateInputValues(
      data.departments.map(dept => dept.name)
    );
  }

  getInitialState() {
    const { defaultData } = this.props;
    return {
      submitBtnDisabled: true,
      data: {
        ...defaultData,
        departments_attributes: defaultData.departments || [],
        logo_url: null,
        specific_agreement_printing_templates: [],
        general_agreement_printing_templates: [],
        clientIds: [],
        clientSecrets: [],
        specific_competencies_templates_attributes: [],
        use_e_signature: true
      },
    };
  }

  handleChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value
      }
    });
  }

  handleRadioChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value === "true"
      }
    });
  }

  async onSubmit() {
    const { onSubmit } = this.props;
    const { data } = this.state;

    const logoUrl = await this.imageUpload.current.triggerUpload();
    onSubmit({
      ...data,
      logo_url: logoUrl
    });
  }

  formatDeptAttributes(deptArray) {
    const { data } = this.state;
    const { departments_attributes: departmentsAttributes } = data;
    const deptAttrs = departmentsAttributes.slice();

    each(deptArray, (dept, index) => {
      deptAttrs[index] = { ...(deptAttrs[index] || {}), name: dept };
    });
    this.setState({
      data: { ...this.state.data, departments_attributes: deptAttrs }
    });
  }

  onRemoveDepartmentAttribute(index) {
    const { data } = this.state;
    const { departments_attributes: departmentsAttributes } = data;
    const deptAttrs = departmentsAttributes.slice();

    if (!deptAttrs[index].id) {
      deptAttrs.splice(index, 1);
    } else {
      // eslint-disable-next-line
      deptAttrs[index]._destroy = true;
    }
    this.setState({
      data: { ...this.state.data, departments_attributes: deptAttrs }
    });
  }

  renderGenericInput({
    name,
    options,
    onChange = e => this.handleChange(e),
    ...props
  }) {
    const { data } = this.state;

    return (
      <GenericFormInput
        name={name}
        data={data}
        onChange={onChange}
        options={options}
        {...props}
      />
    );
  }

  onAddTemplate = type => {
    this.setState({
      data: {
        ...this.state.data,
        [`${type}_printing_templates`]: [
          ...this.state.data[`${type}_printing_templates`],
          {
            name: "",
            locale: "en",
            is_default: !this.state.data[`${type}_printing_templates`].find(
              tpl => tpl.is_default
            ),
            isNew: true,
            file: null
          }
        ]
      }
    });
  };

  onRemoveTemplate = (type, index) => {
    const { data } = this.state;
    const templatesAttributes = data[`${type}_printing_templates`].slice();

    if (templatesAttributes[index].id) {
      templatesAttributes[index].destroy = true;
    } else {
      templatesAttributes.splice(index, 1);
    }

    this.setState({
      data: {
        ...data,
        [`${type}_printing_templates`]: templatesAttributes
      }
    });
  };

  onChangeTemplateAttribute = (name, value, index, type) => {
    const templates = this.state.data[`${type}_printing_templates`].slice();
    templates[index] = {
      ...templates[index],
      [name]: value,
      isUpdated: true
    };
    if (name === "is_default") {
      templates.forEach((template, idx) => {
        if (idx !== index) {
          template.is_default = false;
        }
      });
    }
    this.setState({
      data: {
        ...this.state.data,
        [`${type}_printing_templates`]: templates
      }
    });
  };

  onAddSpecificCompetenciesTemplate = () => {
    this.setState({
      data: {
        ...this.state.data,
        specific_competencies_templates_attributes: [
          ...this.state.data.specific_competencies_templates_attributes,
          {
            title: "",
            content: ""
          }
        ]
      }
    });
  };

  onRemoveSpecificCompetenciesTemplate = index => {
    const { data } = this.state;
    const templatesAttributes = data.specific_competencies_templates_attributes.slice();

    if (templatesAttributes[index].id) {
      templatesAttributes[index]._destroy = true;
    } else {
      templatesAttributes.splice(index, 1);
    }

    this.setState({
      data: {
        ...data,
        specific_competencies_templates_attributes: templatesAttributes
      }
    });
  };

  onChangeSpecificCompetenciesData = (name, value, index) => {
    const { data } = this.state;
    const templatesAttributes = data.specific_competencies_templates_attributes.slice();

    templatesAttributes[index] = {
      ...templatesAttributes[index],
      [name]: value
    };
    this.setState({
      data: {
        ...data,
        specific_competencies_templates_attributes: templatesAttributes
      }
    });
  };

  renderGeneralInformation() {
    const { data } = this.state;

    return (
      <Col xs={12} className="admin-schools-form__form-container">
        <FormGroup formTitle="General information">
          <Col xs={12} sm={8}>
            <ImageUpload
              label="School logo"
              ref={this.imageUpload}
              defaultSource={
                isObject(data.logo_url) &&
                data.logo_url.hasOwnProperty("base64")
                  ? data.logo_url.base64
                  : data.logo_url
              }
              onImageSet={image =>
                this.setState({ data: { ...this.state.data, logo_url: image } })
              }
            />
            {[
              { name: "name", label: "Label", placeholder: "Name" },
              {
                name: "short_name",
                label: "Short name",
                placeholder: "Short name"
              },
              { name: "primary_color", label: "Primary color", type: "color" },
              {
                name: "jobteaser_career_center_id",
                label: "Jobteaser career center ID",
                placeholder: "JT Career center ID",
                inputmode: "numeric"
              },
              {
                name: "jobteaser_career_center_permalink",
                label: "Jobteaser career center permalink",
                placeholder: "JT Career center permalink"
              }
            ].map((elem, index) =>
              React.cloneElement(this.renderGenericInput(elem), { key: index })
            )}
          </Col>
        </FormGroup>
        <FormGroup formTitle="Api Keys">
          <Col xs={12}>
            <AdminSchoolsApiKeys
              clientIds={data.clientIds}
              clientSecrets={data.clientSecrets}
            />
          </Col>
        </FormGroup>
        <FormGroup formTitle="Faculties / Department">
          <Col xs={12} sm={8}>
            <DynamicInputGroup
              ref={this.dynamicInput}
              values={data.departments_attributes.map(dept => dept.name)}
              namePrefix="faculties"
              inputProps={{ placeholder: "Enter faculty or department" }}
              onChange={depts => this.formatDeptAttributes(depts)}
              onRemove={idx => this.onRemoveDepartmentAttribute(idx)}
            />
          </Col>
        </FormGroup>
      </Col>
    );
  }

  renderSpecificAgreementConfiguration() {
    const { data } = this.state;
    const { validation_from_oap_required: validationFromOapRequired } = data;
    return (
      <Col xs={12} className="admin-schools-form__form-container">
        <FormGroup formTitle="Specific agreement configuration">
          {this.renderGenericInput({
            name: "validation_from_oap_required",
            onChange: e => this.handleRadioChange(e),
            inputComponentClass: RadioGroup,
            labelClassName: "admin-schools-form__radio-group-label",
            radioData: [
              {
                label: "Yes",
                value: "true",
                checked: validationFromOapRequired
              },
              {
                label: "No",
                value: "false",
                checked: !validationFromOapRequired
              }
            ],
            inline: true,
            label: "Is validation from OAP required"
          })}
          {this.renderGenericInput({
            name: "student_email_domain",
            label: "Force student email to end by",
            withIcon: true,
            iconName: "at",
            placeholder: "Student email domain"
          })}
          {this.renderGenericInput({
            name: "recruiter_directions",
            label: "Recruiter directions",
            componentClass: "textarea",
            placeholder: "Type some text (html accepted)",
            rows: 5
          })}
          <Checkbox
            onChange={e => this.handleChange(e)}
            className="admin-schools__e-signature-checkbox checkbox-primary"
            name="job_number_of_weekly_hours_required"
            checked={data.job_number_of_weekly_hours_required}
          >
            <span>Require number of weekly hours</span>
          </Checkbox>
          <Checkbox
            onChange={e => this.handleChange(e)}
            className="admin-schools__e-signature-checkbox checkbox-primary"
            name="job_other_benefits_enabled"
            checked={data.job_other_benefits_enabled}
          >
            <span>Display "other benefits" field</span>
          </Checkbox>
          <Checkbox
            onChange={e => this.handleChange(e)}
            className="admin-schools__e-signature-checkbox checkbox-primary"
            name="company_tutor_dni_enabled"
            checked={data.company_tutor_dni_enabled}
          >
            <span>Display "tutor DNI" field</span>
          </Checkbox>
          <Checkbox
            onChange={e => this.handleChange(e)}
            className="admin-schools__e-signature-checkbox checkbox-primary"
            name="job_total_days_worked_enabled"
            checked={data.job_total_days_worked_enabled}
          >
            <span>Display "Total number of days worked" field</span>
          </Checkbox>
          <FormInput
            name="job_salary_mode"
            withLabel
            label="Job Salary Mode"
            componentClass="select"
            value={ data.job_salary_mode }
            onChange={ e => this.handleChange(e) }
          >
            <option value="monthly">Monthly</option>
            <option value="hourly">Hourly</option>
          </FormInput>
          <Checkbox
            onChange={e => this.handleChange(e)}
            className="admin-schools__e-signature-checkbox checkbox-primary"
            name="use_e_signature"
            checked={data.use_e_signature}
          >
            <span>Use electronic signature</span>
          </Checkbox>
          {this.renderGenericInput({
            name: "default_e_signatory_email",
            label: "Default electronic signatory email",
            placeholder: "Type an email"
          })}
          {this.renderGenericInput({
            name: "signaturit_token",
            label: "Signaturit token",
            placeholder: "signaturit_token"
          })}
          <Checkbox
            onChange={e => this.handleChange(e)}
            className="admin-schools__signaturit-prod-env-checkbox checkbox-primary"
            name="signaturit_prod_env"
            checked={data.signaturit_prod_env}
          >
            <span>Use signaturit production environnment</span>
          </Checkbox>
        </FormGroup>
        <FormGroup formTitle="StudentForm">
          {this.renderGenericInput({
            name: "student_data_form_message",
            placeholder: "Type Text",
            label: "Instructions for students",
            componentClass: "textarea",
            rows: 5
          })}
          <Checkbox
            onChange={e => this.handleChange(e)}
            className="admin-schools__e-signature-checkbox checkbox-primary"
            name="postal_address_from_student_required"
            checked={data.postal_address_from_student_required}
          >
            <span>Always ask for the student postal address</span>
          </Checkbox>
          <Checkbox
            onChange={e => this.handleChange(e)}
            className="admin-schools__e-signature-checkbox checkbox-primary"
            name="student_social_security_number_required"
            checked={data.student_social_security_number_required}
          >
            <span>Require spanish social security number</span>
          </Checkbox>
          <Checkbox
            onChange={e => this.handleChange(e)}
            className="admin-schools__e-signature-checkbox checkbox-primary"
            name="student_identity_number_file_key_required"
            checked={data.student_identity_number_file_key_required}
          >
            <span>Require DNI / Passport / NIE document</span>
          </Checkbox>
          <Checkbox
            onChange={e => this.handleChange(e)}
            className="admin-schools__e-signature-checkbox checkbox-primary"
            name="student_phone_number_required"
            checked={data.student_phone_number_required}
          >
            <span>Require student phone number</span>
          </Checkbox>
        </FormGroup>
        <FormGroup formTitle="Emails">
          {this.renderGenericInput({
            name: "specific_agreement_send_for_signature_email_text",
            placeholder: "Type Text",
            label: "Message requesting signature from recruiter",
            componentClass: "textarea",
            rows: 5
          })}
          {this.renderGenericInput({
            name: "mailer_email",
            label: "Email address used to send emails",
            placeholder: "Type an email"
          })}
        </FormGroup>
        <FormGroup formTitle="Templates">
          <Col xs={12}>
            <AdminSchoolsTemplates
              onAddTemplate={this.onAddSpecificAgreementTemplate}
              templates={data.specific_agreement_printing_templates.filter(
                tpl => !tpl.destroy
              )}
              onChangeData={this.onChangeSpecificAgreementTemplateAttribute}
              onRemoveTemplate={this.onRemoveSpecificAgreementTemplate}
              kindIsSpecificAgreement
            />
          </Col>
        </FormGroup>
      </Col>
    );
  }

  renderGeneralAgreementConfiguration() {
    const { data } = this.state;
    const { use_general_agreement: useGeneralAgreement } = data;
    return (
      <Col xs={12} className="admin-schools-form__form-container">
        <FormGroup formTitle="General agreement configuration">
          {this.renderGenericInput({
            name: "use_general_agreement",
            label: "Is this feature activated",
            inputComponentClass: RadioGroup,
            labelClassName: "admin-schools-form__radio-group-label",
            inline: true,
            radioData: [
              {
                label: "Yes",
                value: "true",
                checked: useGeneralAgreement
              },
              {
                label: "No",
                value: "false",
                checked: !useGeneralAgreement
              }
            ],
            onChange: e => this.handleRadioChange(e)
          })}
        </FormGroup>
        <FormGroup formTitle="Templates">
          <Col xs={12}>
            <AdminSchoolsTemplates
              onAddTemplate={this.onAddGeneralAgreementTemplate}
              templates={data.general_agreement_printing_templates.filter(
                tpl => !tpl.destroy
              )}
              onChangeData={this.onChangeGeneralAgreementTemplateAttribute}
              onRemoveTemplate={this.onRemoveGeneralAgreementTemplate}
              kindIsSpecificAgreement={false}
            />
          </Col>
        </FormGroup>
      </Col>
    );
  }

  renderSpecificCompetencies = () => {
    const { data } = this.state;
    return (
      <Col
        xs={12}
        style={{ marginTop: 20 }}
        className="admin-schools-form__form-container"
      >
        <FormGroup formTitle="Specific competencies configuration">
          <AdminSchoolsSpecificCompetenciesTemplates
            templates={data.specific_competencies_templates_attributes.filter(
              tpl => !tpl._destroy
            )}
            onAddTemplate={this.onAddSpecificCompetenciesTemplate}
            onRemoveTemplate={this.onRemoveSpecificCompetenciesTemplate}
            onChangeData={this.onChangeSpecificCompetenciesData}
          />
        </FormGroup>
      </Col>
    );
  };

  render() {
    const { onCloseForm, isCreation, errors } = this.props;

    return (
      <Col xs={12}>
        <FormError visible={errors !== null}>
          {i18n.t("spa:shared.error.bad_request")}
          <br/>
          {JSON.stringify(errors)}
        </FormError>
        <Row>
          <form name="school">
            <Col xs={12} sm={4}>
              {this.renderGeneralInformation()}
            </Col>
            <Col xs={12} sm={4}>
              {this.renderSpecificAgreementConfiguration()}
            </Col>
            <Col xs={12} sm={4}>
              <Row>
                <Col xs={12}>{this.renderGeneralAgreementConfiguration()}</Col>
                <Col xs={12}>{this.renderSpecificCompetencies()}</Col>
              </Row>
            </Col>
          </form>
        </Row>
        <ButtonGroup
          style={{ float: "right" }}
          buttonSpacing={10}
          buttonsData={[
            {
              title: isCreation ? "Create" : "Save",
              bsStyle: "primary",
              onClick: () => this.onSubmit()
            },
            {
              title: "Cancel",
              bsStyle: "danger",
              onClick: onCloseForm
            }
          ]}
        />
      </Col>
    );
  }
}
