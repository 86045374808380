/* eslint-disable no-undef */
import { LOCATION_CHANGE } from "react-router-redux"

import Routes from "../routes"
import Session from "../helpers/SessionHelper"

export default function segmentReducer(state = {}, { type, payload }) {
  switch (type) {
    case LOCATION_CHANGE: {
      const blackList = [
        Routes.SIGNIN,
        Routes.SIGNUP,
        Routes.CONFIRM_ACCOUNT,
        Routes.NEW_PASSWORD,
        Routes.ADMIN_INDEX,
        Routes.ADMIN_SCHOOLS,
        Routes.ADMIN_SCHOOLS_CREATE,
        Routes.ADMIN_SCHOOLS_UPDATE,
        Routes.ADMIN_USERS,
        Routes.ADMIN_USERS_CREATE,
        Routes.ADMIN_USERS_UPDATE,
      ]
      if (!blackList.includes(payload.pathname)) {
        analytics.page({
          path: payload.pathname,
        })
      }
      hj("trigger", Session.role)
      return state
    }
    default:
      return state
  }
}
