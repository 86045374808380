import { LOCATION_CHANGE } from "react-router-redux"

import {
  SCHOOL_DATA_REQUEST,
  SCHOOL_DATA_SUCCESS,
  SCHOOL_DATA_ERROR,
} from "../types"

export const initialState = {
  schoolData: {
    data: {},
    errors: {},
  },
}

export default function schoolsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SCHOOL_DATA_REQUEST: {
      return {
        ...state,
        schoolData: {
          ...state.schoolData,
          data: {},
        },
      }
    }
    case SCHOOL_DATA_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        schoolData: {
          ...state.schoolData,
          data,
        },
      }
    }
    case SCHOOL_DATA_ERROR: {
      return {
        ...state,
        schoolData: {
          ...state.schoolData,
        },
      }
    }
    case LOCATION_CHANGE: {
      return {
        ...state,
        schoolData: { data: {}, errors: {} },
      }
    }
    default:
      return { ...state }
  }
}
