import React from "react"
import PropTypes from "prop-types"
import { Tooltip as BSTooltip, OverlayTrigger } from "react-bootstrap"

const Tooltip = ({ renderTooltip, id, children, ...props }) => {
  const renderedTooltip = renderTooltip && renderTooltip()

  if (!renderedTooltip) return children
  return (
    <OverlayTrigger overlay={ <BSTooltip id={ id }>{renderedTooltip}</BSTooltip> } { ...props }>
      {children}
    </OverlayTrigger>
  )
}

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  renderTooltip: PropTypes.func,
  children: PropTypes.node.isRequired,
}

Tooltip.defaultProps = {
  renderTooltip: () => null,
}

export default Tooltip
