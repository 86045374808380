import isArray from "lodash/isArray";

import Network from "../helpers/Network";
import Routes from "../routes";

export const SPECIFIC_AGREEMENTS_ROUTE = "/specific_agreements";
export const SPECIFIC_AGREEMENT_PRINTING_TEMPLATES_ROUTE = "/specific_agreement_printing_templates";
export const GENERAL_AGREEMENT_PRINTING_TEMPLATES_ROUTE = "/general_agreement_printing_templates";
export const APPROVALS_ROUTE = "/approvals";

export default class SpecificAgreementsAPI {
  static getAgreements(
    type,
    byField,
    byOrder,
    specificAgreementsFilters,
    page
  ) {
    const additionnalParams = {};

    if (byField && byOrder) {
      additionnalParams["by_sort_order[field]"] = byField;
      additionnalParams["by_sort_order[by]"] = byOrder;
    }

    const {
      curriculums,
      assigneds,
      jobCountry,
      companyCountries,
      companySectors,
      studentSchoolYears,
      academicSchoolYears,
      jobStartDates,
    } = specificAgreementsFilters

    if (curriculums && curriculums.length > 0) {
      additionnalParams.by_curriculum_permalinks = curriculums
    }
    if (assigneds && assigneds.length > 0) {
      additionnalParams.by_assigned_ids = assigneds
    }
    if (jobCountry && jobCountry.length > 0) {
      additionnalParams.by_job_country = jobCountry
    }
    if (companyCountries && companyCountries.length > 0) {
      additionnalParams.by_company_country = companyCountries
    }
    if (companySectors && companySectors.length > 0) {
      additionnalParams.by_company_sector = companySectors
    }
    if (studentSchoolYears && studentSchoolYears.length > 0) {
      additionnalParams.by_student_school_year = studentSchoolYears
    }
    if (academicSchoolYears && academicSchoolYears.length > 0) {
      additionnalParams.by_academic_school_year = academicSchoolYears
    }
    if (jobStartDates) {
      additionnalParams["by_job_started_date_range[start_date]"] = jobStartDates.startDate
      additionnalParams["by_job_started_date_range[end_date]"] = jobStartDates.endDate
    }
    return Network.get(SPECIFIC_AGREEMENTS_ROUTE, {
      params: {
        by_states: isArray(type) ? type : [type],
        page: page || 0,
        ...additionnalParams
      }
    });
  }

  static createAgreement(data, redirect_url) {
    return Network.post(SPECIFIC_AGREEMENTS_ROUTE, {
      specific_agreement: data,
      redirect_url
    });
  }

  static getAgreement(id) {
    return Network.get(`${SPECIFIC_AGREEMENTS_ROUTE}/${id}`);
  }

  static editAgreement(data, action, url) {
    return Network.put(url, {
      ...((action && { specific_agreement_action: action }) || {}),
      specific_agreement: data,
      redirect_student_url: `${
        window.location.origin
      }${Routes.SPECIFIC_AGREEMENT_STUDENT_FORM.replace(":id", data.id)}`
    });
  }

  static createApproval(userIds, agreementId, state) {
    return Promise.all(
      userIds.map(userId =>
        Network.post(APPROVALS_ROUTE, {
          approval: {
            user_id: userId,
            specific_agreement_id: agreementId,
            state,
            specific_agreement_url: `${
              window.location.origin
            }${Routes.SPECIFIC_AGREEMENT_SHOW.replace(":id", agreementId)}`
          }
        })
      )
    );
  }

  static editApproval(agreementId, userId, state, id) {
    return Network.put(`${APPROVALS_ROUTE}/${id}`, {
      approval: {
        state,
        specific_agreement_id: agreementId,
        user_id: userId
      }
    });
  }

  static sendAgreementForSignature(url, id, data, byPassSignature = false) {
    return Network.put(url, {
      bypass_signature: byPassSignature,
      specific_agreement_url: `${
        window.location.origin
      }${Routes.SPECIFIC_AGREEMENT_SHOW.replace(":id", id)}`,
      specific_agreement: data
    });
  }

  static removeApproval(id) {
    return Network.delete(`${APPROVALS_ROUTE}/${id}`);
  }

  static signAgreement(url, data) {
    return Network.put(url, {
      specific_agreement: data
    });
  }

  static printAgreement(url) {
    return Network.get(url);
  }

  static removeAgreement(id) {
    return Network.delete(`${SPECIFIC_AGREEMENTS_ROUTE}/${id}`);
  }

  static getActivities(id) {
    return Network.get(`${SPECIFIC_AGREEMENTS_ROUTE}/${id}/activities`);
  }

  static getStudentInfo(studentToken, id) {
    return Network.get(`${SPECIFIC_AGREEMENTS_ROUTE}/${id}/show_by_token`, {
      params: {
        student_token: studentToken
      }
    });
  }

  static updateStudentInfos(data, url) {
    return Network.put(url, {
      specific_agreement: data,
      action: "review"
    });
  }

  static modifyAgreement(id, reason, jobRealEndDate) {
    return Network.post(`${SPECIFIC_AGREEMENTS_ROUTE}/${id}/modify_agreement`, {
      specific_agreement: {
        reason_for_alteration: reason,
        job_real_ended_at: jobRealEndDate
      }
    });
  }

  static cancelAgreement(id, reason) {
    return Network.post(`${SPECIFIC_AGREEMENTS_ROUTE}/${id}/cancel`, {
      specific_agreement: {
        reason_for_cancellation: reason,
      }
    });
  }

  static extendAgreement(id, reason, jobRealEndDate) {
    return Network.post(`${SPECIFIC_AGREEMENTS_ROUTE}/${id}/extend_agreement`, {
      specific_agreement: {
        reason_for_alteration: reason,
        job_real_ended_at: jobRealEndDate
      }
    });
  }

  static terminateAgreement(id, reason, realEndDate, hoursDone) {
    return Network.post(
      `${SPECIFIC_AGREEMENTS_ROUTE}/${id}/terminate_agreement`,
      {
        specific_agreement: {
          reason_for_alteration: reason,
          job_real_ended_at: realEndDate,
          hours_done: hoursDone
        }
      }
    );
  }

  static export(filters) {
    const {
      states,
      curriculums,
      assigneds,
      jobCountry,
      companyCountries,
      companySectors,
      studentSchoolYears,
      academicSchoolYears,
      jobStartDates,
    } = filters

    const additionnalParams = {}

    if (states && states.length > 0) {
      additionnalParams.by_states = states
    }
    if (curriculums && curriculums.length > 0) {
      additionnalParams.by_curriculum_permalinks = curriculums
    }
    if (assigneds && assigneds.length > 0) {
      additionnalParams.by_assigned_ids = assigneds
    }
    if (jobCountry && jobCountry.length > 0) {
      additionnalParams.by_job_country = jobCountry
    }
    if (companyCountries && companyCountries.length > 0) {
      additionnalParams.by_company_country = companyCountries
    }
    if (companySectors && companySectors.length > 0) {
      additionnalParams.by_company_sector = companySectors
    }
    if (studentSchoolYears && studentSchoolYears.length > 0) {
      additionnalParams.by_student_school_year = studentSchoolYears
    }
    if (academicSchoolYears && academicSchoolYears.length > 0) {
      additionnalParams.by_academic_school_year = academicSchoolYears
    }
    if (jobStartDates) {
      additionnalParams["by_job_started_date_range[start_date]"] = jobStartDates.startDate
      additionnalParams["by_job_started_date_range[end_date]"] = jobStartDates.endDate
    }

    return Network.get(`${SPECIFIC_AGREEMENTS_ROUTE}/export`, {
      params: additionnalParams,
    })
  }

  static skipApprovalValidation(id) {
    return Network.put(`${SPECIFIC_AGREEMENTS_ROUTE}/${id}/approve`);
  }

  static getPDF(id) {
    return Network.get(`${SPECIFIC_AGREEMENTS_ROUTE}/${id}/download_pdf`, {
      responseType: "blob"
    });
  }

  static cancelSignature(id) {
    return Network.put(`${SPECIFIC_AGREEMENTS_ROUTE}/${id}/cancel_signature`);
  }

  static SendEmailToStudent(data) {
    return Network.put(`${SPECIFIC_AGREEMENTS_ROUTE}/${data.id}/send_email_to_student`, {
      specific_agreement: data,
      redirect_student_url: `${
        window.location.origin
      }${Routes.SPECIFIC_AGREEMENT_STUDENT_FORM.replace(":id", data.id)}`
    });
  }

  static downloadSpecificAgreementPrintingTemplate(id) {
    return Network.get(`${SPECIFIC_AGREEMENT_PRINTING_TEMPLATES_ROUTE}/${id}/download`);
  }

  static downloadGeneralAgreementPrintingTemplate(id) {
    return Network.get(`${GENERAL_AGREEMENT_PRINTING_TEMPLATES_ROUTE}/${id}/download`);
  }

  static sendReminder(id) {
    return Network.put(`${SPECIFIC_AGREEMENTS_ROUTE}/${id}/send_signaturit_reminder`);
  }
}
