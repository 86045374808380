import React from "react";
import qs from "querystring-es3";

import { history } from "../../configureStore";

const ListPagination = React.createContext({
  currentPage: 0
});

let prevPath = null;

export default class ListPaginationProvider extends React.Component {
  state = {
    currentPage: 0
  };

  componentDidMount() {
    this.parseSearchParams(history.location);
    history.listen(this.parseSearchParams);
  }

  parseSearchParams = location => {
    const searchParams = qs.parse(location.search.substring(1));
    const prevPathIncludesSpecificOrGeneral =
      prevPath &&
      (prevPath.includes("general_agreements") ||
        prevPath.includes("specific_agreements"));
    let isSimilarLocation = false;
    if (
      prevPathIncludesSpecificOrGeneral &&
      ((prevPath.includes("general_agreements") &&
        location.pathname.includes("general_agreements")) ||
        (prevPath.includes("specific_agreements") &&
          location.pathname.includes("specific_agreements")))
    ) {
      isSimilarLocation = true;
    }
    // Received in case we click on an element in the sidebar or when agreement state changes
    if (location.state && location.state.resetPage) {
      this.setState({ currentPage: 0 });
      document.querySelector("#main-container > div").scrollTo(0, 0);
    } else if ((searchParams && searchParams.page) || isSimilarLocation) {
      if (searchParams && searchParams.page) {
        // There is a page query search
        this.setState({
          currentPage: Number(searchParams.page) - 1
        });
      }
    } else {
      this.setState({ currentPage: 0 });
    }
    prevPath = location.pathname;
  };

  changePage = pageNumber => {
    history.replace({
      pathname: history.location.pathname,
      search: `?page=${pageNumber + 1}`
    });
  };

  render() {
    return (
      <ListPagination.Provider
        value={{
          currentPage: this.state.currentPage,
          changePage: this.changePage
        }}
      >
        {this.props.children}
      </ListPagination.Provider>
    );
  }
}

export const ListPaginationConsumer = ListPagination.Consumer;
