import PropTypes from "prop-types"
import i18n from "i18next"

import { generateButtonGroup } from "./SpecificAgreementActionButtonsHelper"
import { generateDataButton } from "../../utils"

const ButtonsSigned = ({
  canSave, onSave, canPrint, onPrint,
}) => {
  const finalDataButtons = []
  if (canSave) {
    finalDataButtons.push(generateDataButton(i18n.t("spa:specific_agreements.form.buttons.shared.save"), onSave, "success"))
  }
  if (canPrint) {
    finalDataButtons.push(generateDataButton(i18n.t("spa:specific_agreements.form.buttons.shared.print"), onPrint, "success"))
  }

  return generateButtonGroup(finalDataButtons)
}

ButtonsSigned.propTypes = {
  canSave: PropTypes.bool,
  onSave: PropTypes.func,
  canPrint: PropTypes.bool,
  onPrint: PropTypes.func,
}

ButtonsSigned.defaultProps = {
  canSave: true,
  onSave: () => true,
  canPrint: true,
  onPrint: () => true,
}

export default ButtonsSigned
