import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import { withRouter, Route } from "react-router"
import { Col, Navbar, NavDropdown, NavItem, MenuItem } from "react-bootstrap"
import classnames from "classnames"

import { Avatar, Badge, Button, Icon } from "../Common"

import SearchBar from "../SearchBar"

import "./HeaderBar.css"
import Routes from "../../routes"

import Session from "../../helpers/SessionHelper"
import SpecificAgreementsFilters from "../SpecificAgreementsFilters"
import { AssignedsListContainer, CurriculumsListContainer } from "../../containers"
import { withSpecificAgreementsFilters } from "../../hoc"

export class HeaderBar extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {
    Session.addOnChangeListener("logo", this.onLogoChange)
  }

  componentWillUnmount() {
    Session.removeOnChangeListener("logo", this.onLogoChange)
  }

  onLogoChange = () => this.forceUpdate()

  render() {
    const { logout, history, nbActiveFilters } = this.props

    return (
      <React.Fragment>
        <Navbar className="header-bar navbar navbar-fixed-top" fluid>
          <div className="navbar-header header-bar-title">
            <Col xs={ 12 } sm={ 3 }>
              <div className="header-bar__autocomplete-container">
                <SearchBar />
              </div>
            </Col>
            {(Session.isOAP() || Session.isSchoolAdmin()) &&
              <React.Fragment>
                <Col xs={ 12 } sm={ 3 }>
                  <Route
                    exact
                    path={ Routes.SPECIFIC_AGREEMENT_INDEX }
                    component={ props => <CurriculumsListContainer isMulti { ...props } /> }
                  />
                </Col>
                <Col xs={ 12 } sm={ 3 }>
                  <Route
                    exact
                    path={ Routes.SPECIFIC_AGREEMENT_INDEX }
                    render={ props => <AssignedsListContainer isMulti { ...props } /> }
                  />
                </Col>
                <Col sm={ 3 }>
                  <Route
                    exact
                    path={ Routes.SPECIFIC_AGREEMENT_INDEX }
                    render={ () =>
                      (<Button type="button" className={ classnames("header-bar__filters-trigger", { "is-expanded": this.state.isOpen }) } bsStyle={ nbActiveFilters ? "primary" : "default" } height={ 37 } onClick={ () => this.setState({ isOpen: !this.state.isOpen }) }>
                        <Icon iconName="filter" iconSize={ 16 } />
                        {i18n.t("spa:header.filters_toggle")}
                        {nbActiveFilters ? <Badge>{nbActiveFilters}</Badge> : null}
                      </Button>)
                    }
                  />
                </Col>
              </React.Fragment>
            }
          </div>
          <div className="navbar-right header-bar-avatar">
            <NavDropdown className="inline-block" eventKey="4" id="nav-dropdown" title={ <Avatar src={ Session.logoUrl } /> }>
              <MenuItem eventKey="4.1" onClick={ () => history.push(Routes.PROFILE_USER) }>
                {i18n.t("spa:topbar.menu.edit.profile")}
              </MenuItem>
            </NavDropdown>
            <NavItem
              className="inline-block header-bar-logout"
              style={ {
                verticalAlign: "middle",
                cursor: "pointer",
                marginLeft: 10,
              } }
              onClick={ logout }
            >
              <Icon iconName="power-off" iconSize={ 20 } />
            </NavItem>
          </div>
        </Navbar>
        <Route
          exact
          path={ Routes.SPECIFIC_AGREEMENT_INDEX }
          render={
            props => (
              <SpecificAgreementsFilters
                isOpen={ this.state.isOpen }
                onClose={ () => this.setState({ isOpen: false }) }
                { ...props }
              />
            )
          }
        />
      </React.Fragment>
    )
  }
}

HeaderBar.propTypes = {
  logout: PropTypes.func.isRequired,
}

export default withRouter(withSpecificAgreementsFilters(HeaderBar))
