import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"
import { Col, Checkbox } from "react-bootstrap"
import { Link } from "react-router-dom"
import i18n from "i18next"

import "./Signup.css"
import { Logo, Title, Button, FormError, PasswordInput, EmailInput } from "../Common"
import { GenericFormInput } from "../GenericInputs"
import { defaultRenderOptions } from "../../utils"

export default class Signup extends PureComponent {
  static propTypes = {
    onPressSignup: PropTypes.func,
    signupData: PropTypes.shape({
      isInProgress: PropTypes.bool,
      isRequestDone: PropTypes.bool,
      isValid: PropTypes.bool,
      email: PropTypes.string,
    }).isRequired,
    resendData: PropTypes.shape({
      isInProgress: PropTypes.bool,
      isRequestDone: PropTypes.bool,
      isValid: PropTypes.bool,
    }).isRequired,
    resendEmail: PropTypes.func.isRequired,
  }

  static defaultProps = {
    onPressSignup: () => true,
  }

  constructor() {
    super()
    this.state = {
      submitButtonDisabled: true,
      data: {
        email: "",
        password: "",
        locale: "es",
        terms: false,
      },
    }
    i18n.changeLanguage(window.navigator.language.replace(/[_-].*/, ""))
  }

  onSignup(evt) {
    evt.preventDefault()
    const { onPressSignup } = this.props
    const { data } = this.state

    onPressSignup(data.email, data.password, data.locale)
  }

  onInputChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    })
  }

  isButtonDisabled() {
    const { terms, email, password } = this.state.data
    return (
      !terms ||
      email.length === 0 ||
      password.length === 0 ||
      this.emailInputWrapper.hasError() ||
      this.passwordInputWrapper.hasError()
    )
  }

  renderGenericInput({ name, options, ...props }) {
    const { data } = this.state

    return (
      <GenericFormInput
        name={ name }
        options={ options }
        data={ data }
        baseTranslationKey="authentication.shared"
        onChange={ e => this.onInputChange(e) }
        renderOptions={ () =>
          defaultRenderOptions({
            options,
            keyProperty: "value",
            valueKey: "value",
            contentKey: "name",
          })
        }
        { ...props }
      />
    )
  }

  renderForm() {
    const { signupData } = this.props
    const { isRequestDone, isValid } = signupData

    return (
      <Fragment>
        <Title
          dangerouslySetInnerHTML={ {
            __html: i18n.t("spa:authentication.signup.title"),
          } }
        />
        <Col sm={ 12 }>
          <form className="form" onSubmit={ e => this.onSignup(e) } noValidate>
            {[
              {
                name: "email",
                component: EmailInput,
                wrapperRef: (ref) => {
                  this.emailInputWrapper = ref
                },
                withLabel: false,
              },
              {
                name: "password",
                component: PasswordInput,
                wrapperRef: (ref) => {
                  this.passwordInputWrapper = ref
                },
                autoComplete: "new-password",
                withLabel: false,
              },
              {
                name: "locale",
                options: [
                  { value: "es", name: "Spanish" },
                  { value: "en", name: "English" },
                  { value: "ca", name: "Catalan" },
                ],
                componentClass: "select",
                withLabel: false,
              },
            ].map((elem, index) => React.cloneElement(this.renderGenericInput(elem), { key: index }))}
            <Checkbox
              onChange={ e => this.onInputChange(e) }
              required
              className="signup-checkbox-agreement checkbox-primary"
              name="terms"
            >
              <span>
                <a rel="noopener noreferrer" target="_blank" href={ i18n.t("spa:authentication.signup.legal.url") }>
                  {i18n.t("spa:authentication.signup.legal.link")}
                </a>
              </span>
            </Checkbox>
            <FormError visible={ isRequestDone && !isValid }>
              {signupData.errorData || i18n.t("spa:shared.error.bad_request")}
            </FormError>
            <Button block type="submit" bsStyle="primary" disabled={ this.isButtonDisabled() }>
              {i18n.t("spa:authentication.signup.submit")}
            </Button>
          </form>
          <span className="descriptor">{i18n.t("spa:authentication.signup.signin.title")}</span>
          <Link to="/signin">
            <Button type="button" block className="signup__signin-button">
              {i18n.t("spa:authentication.signup.signin.link")}
            </Button>
          </Link>
        </Col>
      </Fragment>
    )
  }

  renderConfirmation() {
    const { signupData, resendData, resendEmail } = this.props
    const { isValid, isInProgress, isRequestDone } = resendData

    return (
      <Fragment>
        <Title>Nice ! An email has been sent to {signupData.email} to activate your account</Title>
        <Col sm={ 12 }>
          <p className="descriptor">Didn't receive your confirmation email ?</p>
          {isInProgress && <span>Sending your email...</span>}
          {isRequestDone && !isValid && <span>{i18n.t("spa:shared.error.bad_request")}</span>}
          {isRequestDone && isValid && <span>Your email has been sent</span>}
          <Button block type="submit" bsStyle="primary" onClick={ () => resendEmail(signupData.email) }>
            Re-send confirmation email
          </Button>
        </Col>
      </Fragment>
    )
  }

  render() {
    const { signupData } = this.props
    const { isRequestDone, isInProgress, isValid } = signupData

    return (
      <Col xs={ 12 } sm={ 6 } smPush={ 3 } md={ 4 } mdPush={ 4 } className="center-block text-center signup-container">
        <Logo />
        {((!isInProgress && isRequestDone) || (!isInProgress && !isRequestDone) || (isInProgress && !isRequestDone)) &&
          !isValid &&
          this.renderForm()}
        {isRequestDone && !isInProgress && isValid && this.renderConfirmation()}
      </Col>
    )
  }
}
