import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import i18n from "i18next";
import { Col } from "react-bootstrap";
import moment from "moment";

import { Modal, Icon, FormInput, ButtonGroup, DatePicker } from "../Common";

export default class SpecificAgreementTerminationModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    studentFullName: PropTypes.string,
    reasonForAlteration: PropTypes.string,
    jobRealEndDate: PropTypes.string,
    hoursDone: PropTypes.number,
    alterationStatus: PropTypes.string,
  };

  static defaultProps = {
    isOpen: false,
    onSubmit: () => true,
    onCancel: () => true,
    studentFullName: "",
    reasonForAlteration: "",
    jobRealEndDate: undefined,
    hoursDone: 0,
    alterationStatus: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      reason_for_alteration: props.reasonForAlteration,
      job_real_ended_at: props.jobRealEndDate || moment().toDate(),
      hours_done: props.hoursDone
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
      // eslint-disable-next-line
      this.setState({ reason_for_alteration: this.props.reasonForAlteration });
    }
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleDayChange = day => {
    this.setState({
      job_real_ended_at: day
    });
  };

  resetText() {
    this.setState({
      reason_for_alteration: "",
      job_real_ended_at: moment().toDate(),
      hours_done: 0
    });
  }

  onSubmit = () => {
    this.props.onSubmit(
      this.state.reason_for_alteration,
      this.state.job_real_ended_at,
      this.state.hours_done
    );
  };

  onCancel = () => {
    this.props.onCancel();
  };

  submitDisabled = () => {
    return !(
      this.state.reason_for_alteration &&
      this.state.reason_for_alteration.length > 0
    );
  };

  render() {
    const { isOpen, studentFullName, alterationStatus } = this.props;
    const {
      reason_for_alteration: reason,
      job_real_ended_at: endDate,
      hours_done: hoursDone
    } = this.state;

    let submitButtonLabel = i18n.t(
      "spa:specific_agreements.school_panel.alteration.fields.shared.buttons.terminate"
    )
    let submitButtonStyle = "danger"

    if (alterationStatus && alterationStatus.length > 0) {
      submitButtonLabel = i18n.t(
        "spa:specific_agreements.school_panel.alteration.fields.shared.buttons.update"
      )
      submitButtonStyle = "success"
    }

    return (
      <Modal isOpen={isOpen} className="specific-agreement-alteration__modal">
        <Modal.Title>
          <Icon
            iconName="ban"
            className="specific-agreement-alteration__icon-title"
          />
          {i18n.t(
            "spa:specific_agreements.school_panel.alteration.terminate_internship"
          )}
        </Modal.Title>
        <Modal.Content>
          <Col
            xs={12}
            className="specific-agreement-alteration__modal-extension-content"
          >
            {(!alterationStatus || alterationStatus.length === 0) && (
              <React.Fragment>
                <span>
                  {i18n.t(
                    "spa:specific_agreements.school_panel.alteration.termination_title",
                    {
                      student_name: studentFullName,
                    }
                  )}
                </span>
                <br />
                <br />
              </React.Fragment>
            )}
            <DatePicker
              inputProps={{
                name: "job_real_ended_at",
                withLabel: true,
                label: `${i18n.t(
                  "spa:specific_agreements.school_panel.alteration.fields.real_end_date.label"
                )} :`
              }}
              defaultValue={endDate}
              onDayChange={this.handleDayChange}
            />
            <FormInput
              name="reason_for_alteration"
              value={reason}
              withLabel
              label={`* ${i18n.t(
                "spa:specific_agreements.school_panel.alteration.fields.termination_reason.label"
              )} :`}
              onChange={this.handleInputChange}
              componentClass="textarea"
              rows={6}
            />
            <FormInput
              name="hours_done"
              value={hoursDone}
              withLabel
              label={`${i18n.t(
                "spa:specific_agreements.school_panel.alteration.fields.hours_done.label"
              )} :`}
              type="number"
              onChange={this.handleInputChange}
            />
          </Col>
        </Modal.Content>
        <Modal.Footer>
          <Col xs={12}>
            <ButtonGroup
              buttonSpacing={10}
              buttonsData={[
                {
                  title: i18n.t(
                    "spa:specific_agreements.school_panel.alteration.fields.shared.buttons.cancel"
                  ),
                  onClick: this.onCancel,
                  bsStyle: "default"
                },
                {
                  title: submitButtonLabel,
                  onClick: this.onSubmit,
                  bsStyle: submitButtonStyle,
                  disabled: this.submitDisabled,
                }
              ]}
            />
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
