import React from "react"
import PropTypes from "prop-types"

import Avatar from "./Avatar"

import "./Card.css"

const Card = ({ avatarUrl, avatarSize, title, description }) => (
  <div className="card-container">
    {avatarUrl && (
      <div>
        <Avatar size={ avatarSize } src={ avatarUrl } />
      </div>
    )}
    <div className="card-content-container">
      <div className="card-title">
        <span>{title}</span>
      </div>
      <div className="card-description">
        <span>{description}</span>
      </div>
    </div>
  </div>
)

Card.propTypes = {
  avatarUrl: PropTypes.string,
  avatarSize: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
}

Card.defaultProps = {
  avatarUrl: null,
  avatarSize: Avatar.defaultProps.width,
  title: "",
  description: "",
}

export default Card
