import { GET_FUNCTIONS_SUCCESS, GET_COMPANY_SECTORS_SUCCESS, GET_CURRICULUMS_SUCCESS, GET_ASSIGNEDS_SUCCESS, SET_SPECIFIC_AGREEMENTS_FILTERS, CLEAR_SPECIFIC_AGREEMENTS_FILTERS } from "../types"

export const initialState = {
  functions: [],
  companySectors: [],
  curriculums: [],
  assigneds: [],
  specificAgreementsFilters: {
    companySectors: [],
    curriculums: [],
    assigneds: [],
    jobCountry: [],
    companyCountries: [],
    studentSchoolYears: [],
    academicSchoolYears: [],
    jobStartDates: {
      startDate: "",
      endDate: "",
    },
  },
}

export default function jtreducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_FUNCTIONS_SUCCESS: {
      const { data } = payload
      return {
        ...state,
        functions: data,
      }
    }
    case GET_COMPANY_SECTORS_SUCCESS: {
      const { data } = payload
      return {
        ...state,
        companySectors: data,
      }
    }
    case GET_CURRICULUMS_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        curriculums: data,
      }
    }
    case GET_ASSIGNEDS_SUCCESS: {
      const { data } = payload

      return {
        ...state,
        assigneds: data,
      }
    }
    case SET_SPECIFIC_AGREEMENTS_FILTERS: {
      return {
        ...state,
        specificAgreementsFilters: {
          ...state.specificAgreementsFilters,
          ...payload,
        },
      }
    }
    case CLEAR_SPECIFIC_AGREEMENTS_FILTERS: {
      return {
        ...state,
        specificAgreementsFilters: {
          ...initialState.specificAgreementsFilters,
        },
      }
    }
    default:
      return { ...state }
  }
}
