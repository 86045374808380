import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"

import { UserItem, Button } from "../Common"

const AssigneeShow = ({ firstName, lastName, pictureUrl, onEdit }) => (
  <React.Fragment>
    <UserItem firstName={ firstName } lastName={ lastName } pictureUrl={ pictureUrl } />
    <Button onClick={ onEdit } className="specific-agreement-assignee-card__edit-button" bsStyle="default">
      {i18n.t("spa:specific_agreement_assignee_card.shared.buttons.edit")}
    </Button>
  </React.Fragment>
)

AssigneeShow.propTypes = {
  ...UserItem.propTypes,
  onEdit: PropTypes.func.isRequired,
}

AssigneeShow.defaultProps = {
  ...UserItem.defaultProps,
}

export default AssigneeShow
