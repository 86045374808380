import React from "react"
import PropTypes from "prop-types"
import BaseModal from "react-modal"
import classname from "classnames"

import "./Modal.css"

const ModalChildrenPropTypes = {
  children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]).isRequired,
}

const ModalTitle = ({ children }) => (
  <div className="modal__title-container">
    <h3 className="modal__title">{children}</h3>
  </div>
)

ModalTitle.propTypes = {
  ...ModalChildrenPropTypes,
}

ModalTitle.displayName = "ModalTitle"

const ModalContent = ({ children }) => <div className="modal__content-container clearfix">{children}</div>

ModalContent.propTypes = {
  ...ModalChildrenPropTypes,
}

ModalContent.displayName = "ModalContent"

const ModalFooter = ({ children }) => <div className="modal__content-footer clearfix">{children}</div>

ModalFooter.propTypes = {
  ...ModalChildrenPropTypes,
}

ModalFooter.displayName = "ModalFooter"

export default class Modal extends React.Component {
  static propTypes = {
    ...BaseModal.propTypes,
  }

  findChild(childName) {
    let childComponent = null
    React.Children.forEach(this.props.children, (child) => {
      if (child.type.displayName === childName) {
        childComponent = child
        return false
      }
      return true
    })
    return childComponent
  }

  render() {
    const { className, ...props } = this.props

    return (
      <BaseModal overlayClassName="modal__overlay" className={ classname("modal__container", className) } { ...props }>
        {this.findChild(Modal.Title.displayName)}
        {this.findChild(Modal.Content.displayName)}
        {this.findChild(Modal.Footer.displayName)}
      </BaseModal>
    )
  }
}

Modal.Title = ModalTitle
Modal.Content = ModalContent
Modal.Footer = ModalFooter
