import React from "react";
import i18n from "i18next";
import PropTypes from "prop-types";
import find from "lodash/find";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { REMOVE_REQUEST, UNSET_ALTERATION_STATUS_REQUEST, UNDO_CANCEL_REQUEST } from "../../types";
import AgreementCard from "../AgreementCard";
import { consumeSpecificAgreementForm } from "../../hoc";
import {
  computeFullName,
  computeAgreementRequestedBy,
  computeAgreementId
} from "../../utils";

import Session from "../../helpers/SessionHelper";
import Routes from "../../routes";

const getAgreementCardBadgeStyle = state => {
  if (state !== "approved" && state !== "denied") {
    return state === "draft" ? "default" : "success";
  }
  return state === "approved" ? "primary" : "danger";
};

export const SpecificAgreementCard = ({
  data: {
    job: { job_title: jobTitle },
    agreementId,
    created_at: createdAt,
    company,
    state,
    approvals,
    school: {
      name: schoolName,
      logo_url: schoolLogoUrl,
      general_agreements: schoolGeneralAgreements,
      school_id: schoolId
    },
    student: {
      student_first_name: studentFirstName,
      student_last_name: studentLastName
    },
    user,
    alterated_agreement_id: alteratedId,
    alterated_agreement_status: alteratedStatus,
    alterated_agreement_created_at: alteratedCreatedAt,
    alteration_agreement_id: alterationId,
    alteration_status: alterationStatus,
    alteration_agreement_created_at: alterationCreatedAt
  },
  history: { push },
  ...props
}) => {
  let generalAgreement = null;
  const generalAgreementData = {};
  const {
    company_name: companyName,
    logo_url: companyLogoUrl,
    company_id: companyId
  } = company;

  if (schoolGeneralAgreements) {
    generalAgreement = find(
      schoolGeneralAgreements,
      val => val.company_id === companyId && val.school_id === schoolId
    );
  }

  if (generalAgreement) {
    generalAgreementData.state = generalAgreement.state;
    generalAgreementData.end_date = generalAgreement.end_date;
  }

  const { removeSpecificAgreement, unsetAlterationStatusSpecificAgreement, undoCancelSpecificAgreement } = props;

  return (
    <AgreementCard
      agreementId={
        agreementId &&
        createdAt &&
        computeAgreementId({
          agreementId,
          createdAt
        })
      }
      company={agreementId && companyName}
      title={jobTitle || i18n.t("spa:specific_agreements.form.new.title")}
      agreementState={i18n.t(
        `spa:card.specific_agreement_state.${state}.${Session.role}`
      )}
      agreementStateBadgeStyle={getAgreementCardBadgeStyle(state)}
      approvalState={approvals.length > 0 && approvals[0].state}
      schoolName={agreementId && schoolName}
      studentName={computeFullName({
        first_name: studentFirstName,
        last_name: studentLastName
      })}
      requestBy={user && computeAgreementRequestedBy(user)}
      alterationData={{
        alteratedId,
        alteratedCreatedAt,
        alteratedStatus,
        alterationCreatedAt,
        alterationId,
        alterationStatus
      }}
      showStatus={Session.isRecruiter()}
      organizationLogo={
        agreementId &&
        (Session.isSchoolAdmin() || Session.isOAP()
          ? companyLogoUrl
          : schoolLogoUrl)
      }
      generalAgreementData={generalAgreementData}
      showDeleteButton={true}
      showUnsetAlterationStatusButton={alterationStatus && alterationStatus.length > 0}
      showUndoCancelButton={state==="cancelled"}
      onRequestGeneralAgreement={(redirectToId) => {
        if (!redirectToId) {
          push(Routes.GENERAL_AGREEMENT_CREATE, {
            company,
            schoolId,
            schoolName,
            agreementId,
            specificAgreementId: agreementId,
          });
        } else {
          push(
            Routes.GENERAL_AGREEMENT_SHOW.replace(":id", generalAgreement.id)
          );
        }
      }}
      onDelete={() => {
        if (
          window.confirm(
            `Are you sure you want to remove specific_agreement with ID ${agreementId} ?`
          )
        ) {
          removeSpecificAgreement(agreementId);
        }
      }}
      onUnsetAlterationStatus={() => {
        if (
          window.confirm(
            `Are you sure you want to unset terminated early for specific_agreement with ID ${agreementId} ?`
          )
        ) {
          unsetAlterationStatusSpecificAgreement(agreementId);
        }
      }}
      onUndoCancel={() => {
        if (
          window.confirm(
            `Are you sure you want to undo cancellation for specific_agreement with ID ${agreementId} ?`
          )
        ) {
          undoCancelSpecificAgreement(agreementId);
        }
      }}
      {...props}
    />
  );
};

SpecificAgreementCard.propTypes = {
  data: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  removeSpecificAgreement: id =>
    dispatch({
      type: REMOVE_REQUEST,
      payload: { id, type: "specific_agreements" }
    })
  ,
  unsetAlterationStatusSpecificAgreement: id =>
    dispatch({
      type: UNSET_ALTERATION_STATUS_REQUEST,
      payload: { id, type: "specific_agreements" }
    })
  ,
  undoCancelSpecificAgreement: id =>
    dispatch({
      type: UNDO_CANCEL_REQUEST,
      payload: { id, type: "specific_agreements" }
    })
});

export default withRouter(
  consumeSpecificAgreementForm(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(SpecificAgreementCard)
  )
);
