import React from "react"
import { Row, Col } from "react-bootstrap"
import i18n from "i18next"
import FileDownload from "js-file-download"

import { FormGroup, Icon, Button } from "../Common"
import { GenericFormInput } from "../GenericInputs"

import "./ElectronicSignature.css"
import Signatory from "./Signatory"
import CancelSignatureModal from "./CancelSignatureModal"
import BypassSignatureModal from "./BypassSignatureModal"

export default class ElectronicSignature extends React.Component {
  cancelModal = React.createRef()
  byPassModal = React.createRef()

  openCancelModal = () => {
    this.cancelModal.current.open()
  }

  openByPassModal = () => {
    this.byPassModal.current.open()
  }

  onChangeField = (event) => {
    const { onChange } = this.props

    onChange(event.target.name, event.target.value)
  }

  dowloadPdf = async () => {
    const { getPDF, pdfFileName } = this.props

    const data = await getPDF()
    FileDownload(data, pdfFileName)
  }

  submitDisabled = () => {
    return (
      this.props.isSendingSignature ||
      this.props.student_email.length === 0 ||
      this.props.company_signatory_email.length === 0 ||
      this.props.school_signatory_email.length === 0
    )
  }

  sendForSignatureClicked = () => {
    this.props.sendForSignature()
  }

  sendReminder = () => {
    this.props.sendReminder()
  }

  renderForm = () => (
    <Col xs={ 12 }>
      {[ "student_email", "company_signatory_email", "school_signatory_email", "academic_secretary_email" ].map(name => (
        <GenericFormInput
          name={ name }
          onChange={ this.onChangeField }
          required={ name !== "academic_secretary_email" }
          data={ this.props }
          baseTranslationKey="electronic_signature.form.fields"
          getValidationState={ this.props.getValidationState }
          getErrorMessage={ this.props.getErrorMessage }
        />
      ))}
      <Button bsStyle="primary" block onClick={ this.sendForSignatureClicked } disabled={ this.submitDisabled() }>
        {i18n.t("spa:electronic_signature.form.buttons.submit")}
      </Button>
      <p className="electronic-signature__skip-validation" onClick={ this.openByPassModal }>
        {i18n.t("spa:electronic_signature.form.buttons.signature_not_needed")}
      </p>
      <BypassSignatureModal onConfirmBypass={ this.props.ignoreSignature } ref={ this.byPassModal } />
    </Col>
  )

  renderShow = () => (
    <React.Fragment>
      <Col xs={ 12 }>
        <Signatory
          label={ i18n.t("spa:electronic_signature.form.fields.company_signatory_email.label") }
          signed={ this.props.signed_by_company }
          signatureDate={ this.props.signed_by_company_date }
          value={ this.props.company_signatory_email }
          shouldSign
        />
        <Signatory
          label={ i18n.t("spa:electronic_signature.form.fields.student_email.label") }
          signed={ this.props.signed_by_student }
          signatureDate={ this.props.signed_by_student_date }
          value={ this.props.student_email }
          shouldSign
        />
        <Signatory
          label={ i18n.t("spa:electronic_signature.form.fields.school_signatory_email.label") }
          signed={ this.props.signed_by_school }
          signatureDate={ this.props.signed_by_school_date }
          value={ this.props.school_signatory_email }
          shouldSign
        />
        <Signatory
          label={ i18n.t("spa:electronic_signature.form.fields.academic_secretary_email.label") }
          value={ this.props.academic_secretary_email }
        />
        {this.props.canCancelSignature && (
          <div className="electronic-signature__send-reminder-ctn">
            <Button bsStyle="default" onClick={ this.sendReminder }>
              <Icon iconName="paper-plane-o" style={ { marginRight: 10 } } />
              {i18n.t("spa:electronic_signature.form.buttons.send_reminder")}
            </Button>
          </div>
        )}
        {this.props.canCancelSignature && (
          <p className="electronic-signature__skip-validation" onClick={ this.openCancelModal }>
            {i18n.t("spa:electronic_signature.shared.buttons.cancel_signature")}
          </p>
        )}
      </Col>
      {this.props.canCancelSignature && (
        <CancelSignatureModal ref={ this.cancelModal } onConfirmCancellation={ this.props.cancelSignature } />
      )}
    </React.Fragment>
  )

  render() {
    const { mode } = this.props

    return (
      <Row className="electronic-signature__container">
        <Col xs={ 12 }>
          <FormGroup
            className="electronic-signature__form-container"
            formTitle={ i18n.t("spa:electronic_signature.form.title") }
            renderTitle={ title => (
              <div className="electronic-signature__form-title">
                <span>
                  <Icon iconName="pencil-square-o" /> {title}
                </span>
                <Button bsStyle="default" onClick={ this.dowloadPdf }>
                  <Icon iconName="download" style={ { marginRight: 10 } } />
                  {i18n.t("spa:electronic_signature.form.buttons.download_pdf")}
                </Button>
              </div>
            ) }
          >
            {mode === "form" && this.renderForm()}
            {mode === "show" && this.renderShow()}
          </FormGroup>
        </Col>
      </Row>
    )
  }
}
