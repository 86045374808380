import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import i18n from "i18next";

import Approval from "../Approval";
import Comments from "../Comments";
import Tags from "../Tags";

import SpecificAgreementActivities from "./SpecificAgreementActivities";
import SpecificAgreementAlteration from "../SpecificAgreementAlteration";

import { NavTabs, NavTab } from "../NavTabs";

import Session from "../../helpers/SessionHelper";
import ElectronicSignature from "../ElectronicSignature/ElectronicSignature";
import { consumeSpecificAgreementForm } from "../../hoc";
import { computeFullName, computeAgreementId } from "../../utils";
import SpecificAgreementsAPI from "../../api/SpecificAgreements";

export const SpecificAgreementFormSchoolPanel = ({
  onApprove,
  onDeny,
  onCancel,
  onSelectApproval,
  onRemoveApproval,
  comments,
  onSubmitComment,
  onDeleteComment,
  onDeleteTag,
  onCreateTag,
  activities,
  onModify,
  onExtend,
  onTerminate,
  onEditReason,
  onSkipValidation,
  data: {
    state,
    approvals,
    reason_for_alteration: reasonForAlteration,
    alteration_status: alterationStatus,
    alteration_agreement_id: alterationAgreementId,
    alteration_agreement_created_at: alterationAgreementCreatedAt,
    student: {
      student_first_name: studentFirstName,
      student_last_name: studentLastName,
    },
    job: { job_real_ended_at: jobRealEndedAt },
    signature,
    school,
    agreementId,
    company: {
      company_name: companyName,
    },
    created_at: createdAt,
    hours_done: hoursDone,
    tag_list: tagList,
    available_tags: availableTags,
  },
  updateData,
  sendForSignature,
  getValidationState,
  getErrorMessage,
  cancelSignature,
  ignoreSignature,
  isSendingSignature,
  sendReminder,
}) => (
  <Col xs={12} sm={5} className="agreement-form__approval-container">
    {(state === "approved" ||
      ((state === "sent_for_signature" || state === "signed") &&
        !!signature.created_at)) &&
      school.use_e_signature && (
        <ElectronicSignature
          mode={state === "approved" ? "form" : "show"}
          {...signature}
          onChange={(name, value) => {
            updateData("signature", { [name]: value });
          }}
          sendForSignature={sendForSignature}
          getValidationState={getValidationState}
          getErrorMessage={getErrorMessage}
          ignoreSignature={ignoreSignature}
          getPDF={() => SpecificAgreementsAPI.getPDF(agreementId)}
          canCancelSignature={state !== "signed"}
          cancelSignature={cancelSignature}
          pdfFileName={`${computeAgreementId({
            agreementId,
            createdAt
          })} - ${computeFullName({
            first_name: studentFirstName,
            last_name: studentLastName
          })} - ${companyName}.pdf`}
          isSendingSignature={isSendingSignature}
          sendReminder={sendReminder}
        />
      )}
    {state === "signed" && (
      <SpecificAgreementAlteration
        studentFullName={computeFullName({
          first_name: studentFirstName,
          last_name: studentLastName
        })}
        onModify={onModify}
        onTerminate={onTerminate}
        onExtend={onExtend}
        reasonForAlteration={reasonForAlteration}
        alterationStatus={alterationStatus}
        alterationId={alterationAgreementId}
        onEditReason={onEditReason}
        jobRealEndDate={jobRealEndedAt}
        alterationCreatedAt={alterationAgreementCreatedAt}
        hoursDone={hoursDone}
      />
    )}
    {state !== "draft" && state !== "student_info_completion" && state !== "cancelled" && (
      <Approval
        defaultData={approvals}
        state={state}
        onApprove={onApprove}
        onDeny={onDeny}
        onCancel={onCancel}
        onSelectApproval={onSelectApproval}
        onRemoveApproval={onRemoveApproval}
        onSkipValidation={onSkipValidation}
      />
    )}
    <NavTabs>
      <NavTab
        iconName="comments"
        title={i18n.t("spa:specific_agreements.school_panel.tabs.comments", {
          count: comments.length
        })}
      >
        <Comments
          comments={comments}
          onDelete={onDeleteComment}
          onSubmit={onSubmitComment}
        />
      </NavTab>
      {Session.isSchoolAdmin() && (
        <NavTab
          iconName="heartbeat"
          title={i18n.t("spa:specific_agreements.school_panel.tabs.activity", {
            count: activities.length
          })}
        >
          <SpecificAgreementActivities
            activities={activities}
            agreementState={state}
          />
        </NavTab>
      )}
    </NavTabs>
    <Tags
      availableTags={ availableTags }
      tagList={ tagList }
      onDeleteTag={ onDeleteTag }
      onCreateTag={ onCreateTag }
    />
  </Col>
);

SpecificAgreementFormSchoolPanel.propTypes = {
  data: PropTypes.any.isRequired,
  onApprove: PropTypes.func,
  onDeny: PropTypes.func,
  onCancel: PropTypes.func,
  onSelectApproval: PropTypes.func,
  onRemoveApproval: PropTypes.func,
  onSubmitComment: PropTypes.func,
  onDeleteComment: PropTypes.func,
  comments: PropTypes.array,
  activities: PropTypes.array,
  onExtend: PropTypes.func,
  onModify: PropTypes.func,
  onTerminate: PropTypes.func,
  onEditReason: PropTypes.func,
  onSkipValidation: PropTypes.func,
  updateData: PropTypes.func.isRequired,
  sendForSignature: PropTypes.func,
  getValidationState: PropTypes.func.isRequired,
  getErrorMessage: PropTypes.func.isRequired,
  sendReminder: PropTypes.func,
};

SpecificAgreementFormSchoolPanel.defaultProps = {
  onApprove: () => true,
  onDeny: () => true,
  onCancel: () => true,
  onSelectApproval: () => true,
  onRemoveApproval: () => true,
  onSubmitComment: () => true,
  onDeleteComment: () => true,
  comments: [],
  activities: [],
  onExtend: () => true,
  onModify: () => true,
  onTerminate: () => true,
  onEditReason: () => true,
  onSkipValidation: () => true,
  sendForSignature: () => true,
  sendReminder: () => true,
};

export default consumeSpecificAgreementForm(SpecificAgreementFormSchoolPanel);
