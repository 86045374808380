import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import moment from "moment"
import { Link } from "react-router-dom"

import Routes from "../../routes"

const SpecificAgreementAlterationExtensionReason = ({
  reasonForAlteration,
  alterationCreatedAt,
  alterationId,
  alterationStatus,
  jobRealEndDate,
}) => (
  <div className="specific-agreement-alteration__description-container">
    <span>
      {i18n.t(`spa:specific_agreements.school_panel.alteration.descriptions.titles.${alterationStatus}`, {
        created_at: moment(alterationCreatedAt).format("LL"),
      })}
    </span>
    <br />
    <br />
    <span className="specific-agreement-alteration__description-label">
      {i18n.t("spa:specific_agreements.school_panel.alteration.descriptions.labels.new_version_location")} :
    </span>
    <br />
    <Link to={ Routes.SPECIFIC_AGREEMENT_SHOW.replace(":id", alterationId) }>{alterationId}</Link>
    <br />
    <br />
    <span className="specific-agreement-alteration__description-label">
      {i18n.t("spa:specific_agreements.school_panel.alteration.descriptions.labels.job_real_end_date")} :
    </span>
    <br />
    <span>{moment(jobRealEndDate).format("LL")}</span>
    <br />
    <br />
    <span className="specific-agreement-alteration__description-label">
      {i18n.t("spa:specific_agreements.school_panel.alteration.descriptions.labels.alteration")} :
    </span>
    <br />
    <span>{reasonForAlteration}</span>
  </div>
)

SpecificAgreementAlterationExtensionReason.propTypes = {
  reasonForAlteration: PropTypes.string,
  alterationCreatedAt: PropTypes.string.isRequired,
  alterationId: PropTypes.number.isRequired,
  alterationStatus: PropTypes.string.isRequired,
  jobRealEndDate: PropTypes.string.isRequired,
}

SpecificAgreementAlterationExtensionReason.defaultProps = {
  reasonForAlteration: "",
}

export default SpecificAgreementAlterationExtensionReason
