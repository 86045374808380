import React, { Component } from "react"
import PropTypes from "prop-types"
import Autocomplete from "react-autocomplete"
import debounce from "lodash/debounce"
import i18n from "i18next"
import { withRouter } from "react-router"

import { Autocomplete as AutocompleteAPI } from "../../api"

import { FormInput, Icon } from "../Common"

import Routes from "../../routes"

import "./SearchBar.css"

export class SearchBar extends Component {
  static propTypes = {
    history: PropTypes.object,
  }

  static defaultProps = {
    history: {},
  }

  constructor() {
    super()
    this.state = {
      value: "",
      items: [],
    }
    this.autocomplete = debounce(this.autocomplete, 350)
  }

  async autocomplete() {
    if (this.state.value.length === 0) {
      this.setState({ items: [] })
    }
    const data = await AutocompleteAPI.autocompleteAgreements(this.state.value)
    this.setState({ items: data.results })
  }

  renderInput({ ref, ...props }) {
    return <FormInput { ...props } inputRef={ ref } />
  }

  renderItem(item) {
    return (
      <div className="search-bar__item-container" key={ item.id }>
        <div className="search-bar__item-icon-container">
          <Icon iconName={ item.type === "GeneralAgreement" ? "building" : "graduation-cap" } />
        </div>
        <div className="search-bar__item-data">
          <p className="search-bar__item-data-title">{item.title}</p>
          <p className="search-bar__item-data-subtitle">{item.subtitle}</p>
        </div>
      </div>
    )
  }

  onChange(value) {
    this.setState(
      {
        value,
      },
      () => this.autocomplete()
    )
  }

  onSelect(item) {
    const { history } = this.props
    let pathname

    if (item.type === "GeneralAgreement") {
      pathname = Routes.GENERAL_AGREEMENT_SHOW
    } else {
      pathname = Routes.SPECIFIC_AGREEMENT_SHOW
    }
    this.setState({ value: "" })
    history.push(pathname.replace(":id", item.id))
  }

  render() {
    const { value, items } = this.state

    return (
      <Autocomplete
        renderInput={ this.renderInput }
        items={ items }
        value={ value }
        renderItem={ item => this.renderItem(item) }
        getItemValue={ item => item.title }
        inputProps={ {
          containerClassName: "search-bar__form-input-container",
          placeholder: i18n.t("spa:search_bar.input.placeholder"),
          withIcon: true,
          iconName: "search",
        } }
        wrapperProps={ {
          className: "search-bar__form-input-wrapper",
        } }
        onChange={ (evt, val) => this.onChange(val) }
        onSelect={ (val, item) => this.onSelect(item) }
      />
    )
  }
}

export default withRouter(SearchBar)
