import React from "react"
import PropTypes from "prop-types"
import DayPickerInput from "react-day-picker/DayPickerInput"
import moment from "moment"
import "react-day-picker/lib/style.css"
import MomentLocaleUtils, { formatDate, parseDate } from "react-day-picker/moment"
import "moment/locale/ca"
import "moment/locale/en-gb"
import "moment/locale/es"
import i18n from "i18next"

import FormInput from "./FormInput"

import "./DatePicker.css"

const DatePicker = ({ inputProps, onDayChange, defaultValue, ...props }) => (
  <DayPickerInput
    onDayChange={ onDayChange }
    component={ FormInput }
    value={ defaultValue }
    inputProps={ inputProps }
    formatDate={ formatDate }
    parseDate={ parseDate }
    dayPickerProps={ {
      ...props,
      localeUtils: MomentLocaleUtils,
      locale: i18n.language,
    }}
    format="L"
  />
)

DatePicker.propTypes = {
  defaultValue: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  inputProps: PropTypes.shape({ ...FormInput.propTypes }),
  ...DayPickerInput.propTypes,
}

DatePicker.defaultProps = {
  defaultValue: moment().toDate(),
  inputProps: {},
}

export default DatePicker
