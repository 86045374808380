import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import moment from "moment"
import { Col, Row } from "react-bootstrap"
import i18n from "i18next"
import { GET_SPECIFIC_AGREEMENTS_REQUEST, SET_SPECIFIC_AGREEMENTS_FILTERS } from "../types"
import { DatePicker } from "../components/Common"

class JobStartDatesListContainer extends React.PureComponent {
  render() {
    const { jobStartDatesFilters, setJobStartDatesFilters, triggerSpecificJobListFetch, match, ...props } = this.props

    const { startDate, endDate } = jobStartDatesFilters

    return (
      <Row>
        <Col md={ 6 }>
          <DatePicker
            onDayChange={ (day) => {
              setJobStartDatesFilters({ startDate: day ? moment(day).format("YYYYMMDD") : "", endDate })
              setImmediate(() => triggerSpecificJobListFetch(match.params.type))
            } }
            inputProps={ {
              label: i18n.t("spa:header.job_start_dates_filter.start_date.label"),
              placeholder: i18n.t("spa:header.job_start_dates_filter.start_date.placeholder"),
            } }
            defaultValue={ startDate ? moment(startDate).format("MM/DD/YYYY") : "" }
            { ...props }
          />
        </Col>
        <Col md={ 6 }>
          <DatePicker
            onDayChange={ (day) => {
              setJobStartDatesFilters({ startDate, endDate: day ? moment(day).format("YYYYMMDD") : "" })
              setImmediate(() => triggerSpecificJobListFetch(match.params.type))
            } }
            inputProps={ {
              label: i18n.t("spa:header.job_start_dates_filter.end_date.label"),
              placeholder: i18n.t("spa:header.job_start_dates_filter.end_date.placeholder"),
            } }
            defaultValue={ endDate ? moment(endDate).format("MM/DD/YYYY") : "" }
            { ...props }
          />
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => ({
  jobStartDatesFilters: state.jt.specificAgreementsFilters.jobStartDates,
})

const mapDispatchToProps = dispatch => ({
  setJobStartDatesFilters: filters => dispatch({ type: SET_SPECIFIC_AGREEMENTS_FILTERS, payload: { jobStartDates: filters } }),
  triggerSpecificJobListFetch: type => dispatch({ type: GET_SPECIFIC_AGREEMENTS_REQUEST, payload: { type } }),
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(JobStartDatesListContainer))
