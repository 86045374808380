import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  CLEAR_SPECIFIC_AGREEMENTS_FILTERS,
  GET_SPECIFIC_AGREEMENTS_REQUEST,
  SET_SPECIFIC_AGREEMENTS_FILTERS,
} from "../types"

export default function(Comp) {
  // eslint-disable-next-line
  class ConnectWithSpecificAgreementsFilterContainer extends PureComponent {
    static propTypes = {
      specificAgreementsFilters: PropTypes.shape({
        companySectors: PropTypes.arrayOf(PropTypes.string),
        curriculums: PropTypes.arrayOf(PropTypes.string),
        assigneds: PropTypes.arrayOf(PropTypes.string),
        jobCountry: PropTypes.arrayOf(PropTypes.string),
        companyCountries: PropTypes.arrayOf(PropTypes.string),
        studentSchoolYears: PropTypes.arrayOf(PropTypes.string),
        academicSchoolYears: PropTypes.arrayOf(PropTypes.string),
        jobStartDates: PropTypes.shape({
          startDate: PropTypes.string,
          endDate: PropTypes.string,
        }),
      }).isRequired,
      setSpecificAgreementsFilters: PropTypes.func.isRequired,
      clearSpecificAgreementsFilters: PropTypes.func.isRequired,
      triggerSpecificAgreementListFetch: PropTypes.func.isRequired,
    };

    getNbActiveFilters() {
      const { specificAgreementsFilters } = this.props

      return Object.values(specificAgreementsFilters).reduce((acc, value) => {
        if (value && Array.isArray(value)) {
          return value.length ? acc + 1 : acc
        }

        if (value && typeof value === "object") {
          return Object.values(value).find(val => Boolean(val)) ? acc + 1 : acc
        }

        if (value) {
          return acc + 1
        }

        return acc
      }, 0)
    }

    render() {
      const nbActiveFilters = this.getNbActiveFilters()

      return <Comp { ...this.props } ref={ this.props.forwardedRef } nbActiveFilters={ nbActiveFilters } />
    }
  }

  const mapStateToProps = state => ({
    specificAgreementsFilters: state.jt.specificAgreementsFilters,
  })

  const mapDispatchToProps = dispatch => ({
    setSpecificAgreementsFilters: filters =>
      dispatch({ type: SET_SPECIFIC_AGREEMENTS_FILTERS, payload: { filters } }),
    clearSpecificAgreementsFilters: () =>
      dispatch({ type: CLEAR_SPECIFIC_AGREEMENTS_FILTERS }),
    triggerSpecificAgreementListFetch: type =>
      dispatch({ type: GET_SPECIFIC_AGREEMENTS_REQUEST, payload: { type } }),
  })

  return connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
  })(ConnectWithSpecificAgreementsFilterContainer)
}
