import React from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"

import "./SpecificAgreementStatusCard.css"

import Session from "../../helpers/SessionHelper"
import SpecificAgreementStatus from "./SpecificAgreementStatus"
import SpecificAgreementAssignee from "../SpecificAgreementAssignee"
import SpecificAgreementCancelButton from "../SpecificAgreementCancelButton"
import { consumeSpecificAgreementForm } from "../../hoc"
import SpecificAgreementCancelModal from "./SpecificAgreementCancelModal"
import { computeFullName } from "../../utils";

export class SpecificAgreementStatusCard extends React.Component {
  static propTypes = {
    studentFullName: PropTypes.string,
    onSubmit: PropTypes.func,
    ...SpecificAgreementStatus.propTypes,
    ...SpecificAgreementAssignee.propTypes,
  }

  static defaultProps = {
    studentFullName: "",
    onSubmit: () => true,
    ...SpecificAgreementStatus.defaultProps,
  }

  state = {
    isCancelModalOpen: false,
  };

  openCancelModal = () => {
    this.setState({
      isCancelModalOpen: true,
    })
  };

  closeModal = () => {
    this.setState({
      isCancelModalOpen: false,
    })
  };

  render() {
    const {
      agreementId,
      agreementState,
      agreementStatus,
      agreementStateBadgeStyle,
      onAssign,
      onUnassign,
      schoolId,
      studentFullName,
      data: {
        assigned,
        assigned_id: assignedId,
        alterated_agreement_id: alteratedId,
        alterated_agreement_status: alteratedStatus,
        alterated_agreement_created_at: alteratedCreatedAt,
        alteration_agreement_id: alterationId,
        alteration_status: alterationStatus,
        alteration_agreement_created_at: alterationCreatedAt,
      },
      onSubmit,
    } = this.props

    const { isCancelModalOpen } = this.state

    return (
      <Col xs={ 12 } className="specific-agreement-status-card__container">
        <Col xs={ 12 } sm={ 5 }>
          <SpecificAgreementStatus
            agreementId={ agreementId }
            agreementStatus={ agreementStatus }
            agreementStateBadgeStyle={ agreementStateBadgeStyle }
            alterationData={ {
              alteratedCreatedAt,
              alteratedStatus,
              alteratedId,
              alterationCreatedAt,
              alterationId,
              alterationStatus,
            } }
          />
        </Col>
        <Col xs={ 12 } sm={ (Session.isSchoolAdmin() || Session.isAdmin()) && agreementState !== "signed" && agreementState !== "cancelled" ? 6 : 7 }>
          <SpecificAgreementAssignee
            onAssign={ onAssign }
            onUnassign={ onUnassign }
            assignee={ {
              ...assigned,
              id: assignedId,
            } }
            schoolId={ schoolId }
          />
        </Col>
        { (Session.isSchoolAdmin() || Session.isAdmin()) && agreementState !== "signed" && agreementState !== "cancelled" && <Col xs={ 12 } sm={ 1 }>
          <SpecificAgreementCancelButton
            onClick={ () => this.openCancelModal() }
          />
        </Col>
        }
        <SpecificAgreementCancelModal
          isOpen={ isCancelModalOpen }
          onCancel={ this.closeModal }
          onSubmit={ onSubmit }
          studentFullName={ studentFullName }
          // reasonForCancellation={reasonForCancellation}
        />
      </Col>
    )
  }
}

export default consumeSpecificAgreementForm(SpecificAgreementStatusCard, [
  "assigned",
  "assigned_id",
  "alterated_agreement_id",
  "alterated_agreement_status",
  "alterated_agreement_created_at",
  "alteration_agreement_id",
  "alteration_status",
  "alteration_agreement_created_at",
])
