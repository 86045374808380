import React from "react"
import { connect } from "react-redux"

import { RESET_PASSWORD_EMAIL_REQUEST, RESET_PASSWORD_REQUEST } from "../types"

import ResetPassword from "../components/ResetPassword"

const ResetPasswordContainer = ({ ...props }) => <ResetPassword { ...props } />

const mapDispatchToProps = dispatch => ({
  onSendEmail: email => dispatch({ type: RESET_PASSWORD_EMAIL_REQUEST, payload: { email } }),
  onResetPassword: (password, token, client, uid) =>
    dispatch({ type: RESET_PASSWORD_REQUEST, payload: { password, client, uid, token } }),
})

const mapStateToProps = state => ({
  resetRequestData: state.resetPassword.newPassword,
  emailRequestData: state.resetPassword.emailRequest,
  errors: state.resetPassword.newPassword ? state.resetPassword.newPassword.errors : state.resetPassword.emailRequest.errors,
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer)
