import React, { Component } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "react-bootstrap"
import isEmpty from "lodash/isEmpty"
import i18n from "i18next"

import { ButtonGroup } from "../Common"
import AgreementCard from "../AgreementCard"

import { GeneralAgreements, SpecificAgreements } from "../../api"

import Session from "../../helpers/SessionHelper"

import { ConnectWithSidebar } from "../../hoc"

import "./AgreementPrintingTemplate.css"

export class AgreementPrintingTemplate extends Component {
  static propTypes = {
    agreementId: PropTypes.number.isRequired,
    getAgreementData: PropTypes.func.isRequired,
    agreement: PropTypes.object.isRequired,
    onUpdateSidebar: PropTypes.func,
    type: PropTypes.oneOf([ "specific_agreement", "general_agreement" ]).isRequired,
  }

  static defaultProps = {
    onUpdateSidebar: () => true,
  }

  constructor(props) {
    super(props)
    this.state = {
      printingTemplateUrl: null,
    }
    this.printingFrame = React.createRef()
  }

  componentDidMount() {
    const { agreementId, getAgreementData, agreement, onUpdateSidebar, type } = this.props

    if (agreementId && (isEmpty(agreement.data) || !agreement.data.print_url)) {
      getAgreementData(agreementId)
    } else if (Session.isSchoolAdmin()) {
      onUpdateSidebar(agreement.data[type].state, type)
      this.getPrintingTemplateUrl(agreement.data.print_url)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { onUpdateSidebar, type } = this.props
    if (
      nextProps.agreement.data &&
      nextProps.agreement.data.print_url &&
      nextProps.agreement.data.print_url !== this.props.agreement.data.print_url
    ) {
      this.getPrintingTemplateUrl(nextProps.agreement.data.print_url)
      onUpdateSidebar(nextProps.agreement.data[type].state, type)
    }
  }

  async getPrintingTemplateUrl(printUrl) {
    const ApiClass = this.props.type === "specific_agreement" ? SpecificAgreements : GeneralAgreements
    const url = await ApiClass.printAgreement(printUrl)

    this.setState({ printingTemplateUrl: url.print_url })
  }

  requestPrint() {
    const frame = this.printingFrame.current.contentWindow

    frame.focus()
    frame.print()
  }

  getCardTitle() {
    const { type } = this.props
    const agreement = this.props.agreement.data[type]

    if (type === "specific_agreement") {
      return (agreement && agreement.job_title) || ""
    }
    return (agreement && agreement.company.name) || ""
  }

  render() {
    const { printingTemplateUrl } = this.state

    return (
      <div className="agreement-printing-template__container">
        <AgreementCard title={ this.getCardTitle() } />
        <Row>
          <div>
            <Col xs={ 12 } className="agreement-printing-template__action-buttons-container">
              <ButtonGroup
                buttonSpacing={ 10 }
                style={ { float: "right" } }
                buttonsData={ [
                  {
                    title: i18n.t("spa:printing_templates.shared.buttons.print"),
                    bsStyle: "success",
                    onClick: () => this.requestPrint(),
                  },
                ] }
              />
            </Col>
            {printingTemplateUrl && (
              <Col xs={ 12 } className="agreement-printing-template__frame-container">
                <iframe src={ printingTemplateUrl } ref={ this.printingFrame } title="print_frame" />
              </Col>
            )}
          </div>
        </Row>
      </div>
    )
  }
}

export default ConnectWithSidebar(AgreementPrintingTemplate)
