import React from "react";
import PropTypes from "prop-types";
import classname from "classnames";

import Button from "./Button";

import "./ButtonGroup.css";
import Tooltip from "./Tooltip";

const ButtonGroup = ({ buttonSpacing, buttonsData, className, ...props }) => (
  <div className={classname(className, "btn-group-container")} {...props}>
    {buttonsData
      .filter(btn => !!btn)
      .map(
        ({
          title,
          withIcon,
          renderIcon,
          style,
          renderTooltip,
          tooltipId,
          tooltipPosition,
          disabled,
          ...btnProps
        }) => (
          <Tooltip
            renderTooltip={renderTooltip}
            id={tooltipId}
            placement={tooltipPosition}
          >
            <Button
              disabled={disabled && typeof disabled === "function" && disabled()}
              key={title}
              style={{
                marginLeft: buttonSpacing,
                marginRight: buttonSpacing,
                ...style
              }}
              {...btnProps}
            >
              {withIcon && renderIcon && renderIcon()}
              {title}
            </Button>
          </Tooltip>
        )
      )}
  </div>
);

ButtonGroup.propTypes = {
  buttonsData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      withIcon: PropTypes.bool,
      renderIcon: PropTypes.func,
      disabled: PropTypes.func,
      ...Button.propTypes
    })
  ).isRequired,
  buttonSpacing: PropTypes.number
};

ButtonGroup.defaultProps = {
  buttonSpacing: 0,
};

export default ButtonGroup;
