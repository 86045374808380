import { Authentication } from "../api"
import Session from "../helpers/SessionHelper"

export async function signin(signinFn) {
  try {
    const data = await signinFn
    Session.isConnected = true
    return data
  } catch (e) {
    Session.isConnected = false
    throw e
  }
}

export async function signup(registerUserFn) {
  try {
    const data = await registerUserFn
    return data
  } catch (e) {
    throw e.response.data
  }
}

export async function resendSignupEmail(email) {
  try {
    await Authentication.resendSignupEmail(email)
  } catch (e) {
    throw e
  }
}

export async function acceptInvitation(password, token) {
  try {
    await Authentication.acceptInvitation(password, token)
  } catch (e) {
    throw e
  }
}

export async function signout() {
  try {
    await Authentication.signout()
    localStorage.removeItem("access-token")
    localStorage.removeItem("client")
    localStorage.removeItem("uid")
  } catch (e) {
    throw e
  }
}

export async function fillProfile(token, data) {
  try {
    await Authentication.updateByToken(token, data)
  } catch (e) {
    throw e
  }
}
