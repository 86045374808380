import { Roles } from "../Constants"

import DefaultLogo from "../assets/logo.png"
import DefaultAVatar from "../assets/default-avatar.png"

const DEFAULT_PRIMARY_COLOR = "#4DC942"

class Session {
  role = null
  isConnected = false
  linkedCompanies = []
  id = null
  lastName = null
  firstName = null
  organizationName = null
  position = null
  phoneNumber = null
  email = null
  schoolId = null
  primaryColor = DEFAULT_PRIMARY_COLOR
  schoolLogoUrl = DefaultLogo
  logoUrl = DefaultAVatar
  useGeneralAgreement = true
  schoolShortName = null
  locale = "en"

  listeners = {}

  isAdmin() {
    return this.role === Roles.ADMIN
  }

  isSchoolAdmin() {
    return this.role === Roles.SCHOOL_ADMIN
  }

  isOAP() {
    return this.role === Roles.OAP
  }

  isRecruiter() {
    return this.role === Roles.RECRUITER
  }

  resetPrimaryColor() {
    this.primaryColor = DEFAULT_PRIMARY_COLOR
    return this
  }

  resetLogos() {
    this.schoolLogoUrl = DefaultLogo
    this.logoUrl = DefaultAVatar
    return this
  }

  addOnChangeListener(name, listener) {
    this.listeners[name] = this.listeners[name] || []
    this.listeners[name].push(listener)
  }

  removeOnChangeListener(name, listenerToRemove) {
    if (this.listeners[name]) {
      const index = this.listeners[name].findIndex(listener => listener === listenerToRemove)
      if (index > -1) {
        this.listeners[name].splice(index, 1)
      }
    }
  }

  triggerChange(name) {
    if (this.listeners[name]) {
      this.listeners[name].forEach(listener => listener())
    }
  }

  hasLogo() {
    return this.schoolLogoUrl !== DefaultLogo
  }
}

export default new Session()
