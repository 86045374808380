import { LOCATION_CHANGE } from "react-router-redux"

import {
  SIGNUP_ERROR,
  SIGNUP_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_RESEND_EMAIL_REQUEST,
  SIGNUP_RESEND_EMAIL_SUCCESS,
  SIGNUP_RESEND_EMAIL_ERROR,
  SIGNUP_FILL_PROFILE_SUCCESS,
  SIGNUP_FILL_PROFILE_ERROR,
} from "../types"

export const initialState = {
  signupRequestData: {
    isInProgress: false,
    isRequestDone: false,
    isValid: false,
    email: "",
    errorData: null,
  },
  resendEmailRequestData: {
    isInProgress: false,
    isRequestDone: false,
    isValid: false,
  },
  profileEdition: {
    errors: {},
  },
}

export default function signupReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SIGNUP_REQUEST: {
      return {
        ...state,
        signupRequestData: {
          ...state.signupRequestData,
          isInProgress: true,
          isRequestDone: false,
          errorData: null,
        },
      }
    }
    case SIGNUP_SUCCESS: {
      const { email } = payload
      return {
        ...state,
        signupRequestData: {
          isInProgress: false,
          isRequestDone: true,
          isValid: true,
          email,
          errorData: null,
        },
      }
    }
    case SIGNUP_ERROR: {
      const { error } = payload
      return {
        ...state,
        signupRequestData: {
          isInProgress: false,
          isRequestDone: true,
          isValid: false,
          errorData: error,
        },
      }
    }
    case SIGNUP_RESEND_EMAIL_REQUEST: {
      return {
        ...state,
        resendEmailRequestData: {
          ...state.resendEmailRequestData,
          isInProgress: true,
          isRequestDone: false,
        },
      }
    }
    case SIGNUP_RESEND_EMAIL_SUCCESS: {
      return {
        ...state,
        resendEmailRequestData: {
          isInProgress: false,
          isRequestDone: true,
          isValid: true,
        },
      }
    }
    case SIGNUP_RESEND_EMAIL_ERROR:
      return {
        ...state,
        resendEmailRequestData: {
          isInProgress: false,
          isRequestDone: true,
          isValid: false,
        },
      }
    case SIGNUP_FILL_PROFILE_ERROR: {
      const { errors } = payload
      return {
        ...state,
        profileEdition: {
          errors,
        },
      }
    }
    case SIGNUP_FILL_PROFILE_SUCCESS:
      return { ...state, profileEdition: { errors: {} } }
    case LOCATION_CHANGE: {
      const { pathname } = payload

      if (pathname.startsWith("/confirm")) return { ...state, profileEdition: { errors: {} } }
      else if (pathname === "/signup") {
        return {
          ...state,
          signupRequestData: {
            isInProgress: false,
            isRequestDone: false,
            isValid: false,
            email: "",
          },
          resendEmailRequestData: {
            isInProgress: false,
            isRequestDone: false,
            isValid: false,
          },
        }
      }
      return { ...state }
    }
    default:
      return { ...state }
  }
}
