import React from "react"
import { Col, ControlLabel } from "react-bootstrap"
import classnames from "classnames"
import i18n from "i18next"
import moment from "moment"

import { Icon } from "../Common"

const Signatory = ({ label, value, signed, signatureDate, shouldSign }) => (
  <div className="electronic-signature__signatory-container">
    <ControlLabel>{label}</ControlLabel>
    <div className="electronic-signature__data-container">
      <span className="electronic-signature__data-value">{value}</span>
      { shouldSign && (
        <span
          className={ classnames("electronic-signature__data-status", { "text-info": signed, "text-danger": !signed }) }
        >
          {i18n.t(`spa:electronic_signature.shared.status.${signed ? "signed" : "not_signed"}`, {
            date: signatureDate ? moment(signatureDate).format("L") : undefined,
            interpolation: { escapeValue: false }
          })}
          <Icon iconName="circle" />
        </span>
      )}
    </div>
  </div>
)

export default Signatory
