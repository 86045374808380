import React from "react"
import PropTypes from "prop-types"

import StudentCard from "../StudentCard"
import { consumeSpecificAgreementForm } from "../../hoc"
import { computeFullName } from "../../utils"

export const SpecificAgreementStudentCard = ({
  data: {
    student: {
      student_first_name: studentFirstName,
      student_last_name: studentLastName,
      student_other_specific_agreements: history,
      student_curriculum_value_in_user_language: studentCurriculumTranslated
    },
  },
  ...props
}) => (
  <StudentCard
    studentFullName={ computeFullName({
      first_name: studentFirstName,
      last_name: studentLastName,
    }) }
    studentCurriculumTranslated={ studentCurriculumTranslated }
    history={ history }
    { ...props }
  />
)

SpecificAgreementStudentCard.propTypes = {
  data: PropTypes.object.isRequired,
}

export default consumeSpecificAgreementForm(SpecificAgreementStudentCard, [ "student", "job" ])
