import React from "react"
import PropTypes from "prop-types"

import ButtonsDefaultState from "./SpecificAgreementActionButtonDefaultState"
import ButtonsSchoolReview from "./SpecificAgreementActionButtonSchoolReview"
import ButtonsPendingApprovals from "./SpecificAgreementActionButtonsPendingApprovals"
import ButtonsApprovalDone from "./SpecificAgreementActionButtonsApprovalDone"
import ButtonsSigned from "./SpecificAgreementActionButtonsSigned"
import ButtonsSentForSignature from "./SpecificAgreementActionButtonSentForSignature"
import ButtonStudentCompletion from "./SpecificAgreementActionButtonStudentCompletion"

const SpecificAgreementActionButtons = ({
  state,
  canSave,
  onSave,
  onSend,
  onSendEmailToStudent,
  canSendForSignature,
  onSendForSignature,
  canPrint,
  onPrint,
  canMarkAsSignedAndClosed,
  onMarkAsSignedAndClosed,
  showRemoveButton,
  onRemove,
  onBack,
  backDisabled,
  lastStep,
  onNext,
  nextDisabled,
  bySchoolAdmin,
  hasElectronicSignature,
}) => {
  switch (state) {
    case "student_info_completion":
      return <ButtonStudentCompletion canSave={ canSave } onSave={ onSave } onSend={ onSend } onSendEmailToStudent={ onSendEmailToStudent } />
    case "in_school_review":
      return <ButtonsSchoolReview canSave={ canSave } onSave={ onSave } />
    case "pending_approvals":
      return <ButtonsPendingApprovals canSave={ canSave } onSave={ onSave } />
    case "approved":
      return (
        <ButtonsApprovalDone
          canSave={ canSave }
          onSave={ onSave }
          canSendForSignature={ canSendForSignature }
          onSendForSignature={ onSendForSignature }
          canPrint={ canPrint }
          onPrint={ onPrint }
          hasElectronicSignature={ hasElectronicSignature }
        />
      )
    case "rejected":
      return (
        <ButtonsApprovalDone
          canSave={ canSave }
          onSave={ onSave }
          canSendForSignature={ false }
          canPrint={ canPrint }
          onPrint={ onPrint }
        />
      )
    case "sent_for_signature":
      return (
        <ButtonsSentForSignature
          canSave={ canSave }
          onSave={ onSave }
          canMarkAsSignedAndClosed={ canMarkAsSignedAndClosed }
          onMarkAsSignedAndClosed={ onMarkAsSignedAndClosed }
          canPrint={ canPrint }
          onPrint={ onPrint }
        />
      )
    case "signed":
      return <ButtonsSigned canSave={ canSave } onSave={ onSave } canPrint={ canPrint } onPrint={ onPrint } />
    case "cancelled":
      return null
    default:
      return (
        <ButtonsDefaultState
          showRemoveButton={ showRemoveButton }
          onRemove={ onRemove }
          onBack={ onBack }
          backDisabled={ backDisabled }
          lastStep={ lastStep }
          onNext={ onNext }
          nextDisabled={ nextDisabled }
          bySchoolAdmin={ bySchoolAdmin }
          onSave={ onSave }
        />
      )
  }
}

SpecificAgreementActionButtons.propTypes = {
  state: PropTypes.oneOf([
    null,
    "draft",
    "student_info_completion",
    "in_school_review",
    "pending_approvals",
    "approved",
    "rejected",
    "sent_for_signature",
    "signed",
  ]).isRequired,
  canSave: PropTypes.bool,
  onSave: PropTypes.func,
  onSend: PropTypes.func,
  onSendEmailToStudent: PropTypes.func,
  canSendForSignature: PropTypes.bool,
  onSendForSignature: PropTypes.func,
  canPrint: PropTypes.bool,
  onPrint: PropTypes.func,
  canMarkAsSignedAndClosed: PropTypes.bool,
  onMarkAsSignedAndClosed: PropTypes.func,
  showRemoveButton: PropTypes.bool,
  onRemove: PropTypes.func,
  onBack: PropTypes.func,
  backDisabled: PropTypes.bool,
  lastStep: PropTypes.bool,
  onNext: PropTypes.func,
  nextDisabled: PropTypes.bool,
  bySchoolAdmin: PropTypes.bool,
}

SpecificAgreementActionButtons.defaultProps = {
  canSave: true,
  onSave: () => true,
  onSend: () => true,
  onSendEmailToStudent: () => true,
  canSendForSignature: true,
  onSendForSignature: () => true,
  canPrint: true,
  onPrint: () => true,
  canMarkAsSignedAndClosed: true,
  onMarkAsSignedAndClosed: () => true,
  showRemoveButton: true,
  onRemove: () => true,
  onBack: () => true,
  backDisabled: true,
  lastStep: true,
  onNext: () => true,
  nextDisabled: true,
  bySchoolAdmin: false,
}

export default SpecificAgreementActionButtons
