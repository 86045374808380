export const SIGNUP_REQUEST = "SIGNUP_REQUEST"
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS"
export const SIGNUP_ERROR = "SIGNUP_ERROR"

export const SIGNUP_RESEND_EMAIL_REQUEST = "SIGNUP_RESEND_EMAIL_REQUEST"
export const SIGNUP_RESEND_EMAIL_SUCCESS = "SIGNUP_RESEND_EMAIL_SUCCESS"
export const SIGNUP_RESEND_EMAIL_ERROR = "SIGNUP_RESEND_EMAIL_ERROR"

export const SIGNUP_ACCEPT_INVITATION_REQUEST = "SIGNUP_ACCEPT_INVITATION_REQUEST"
export const SIGNUP_ACCEPT_INVITATION_SUCCESS = "SIGNUP_ACCEPT_INVITATION_SUCCESS"
export const SIGNUP_ACCEPT_INVITATION_ERROR = "SIGNUP_ACCEPT_INVITATION_ERROR"

export const SIGNUP_FILL_PROFILE_REQUEST = "SIGNUP_FILL_PROFILE_REQUEST"
export const SIGNUP_FILL_PROFILE_SUCCESS = "SIGNUP_FILL_PROFILE_SUCCESS"
export const SIGNUP_FILL_PROFILE_ERROR = "SIGNUP_FILL_PROFILE_ERROR"
