import React from "react"
import { connect } from "react-redux"
import { Route, Switch } from "react-router"

import Signup from "../components/Signup"
import SignupInvitation from "../components/Signup/SignupInvitation"
import { SIGNUP_REQUEST, SIGNUP_RESEND_EMAIL_REQUEST, SIGNUP_ACCEPT_INVITATION_REQUEST } from "../types"

const SignupContainer = ({ signup, ...props }) => (
  <Switch>
    <Route exact path="/signup" render={ () => <Signup onPressSignup={ signup } { ...props } /> } />
    <Route exact path="/signup/invitation" render={ () => <SignupInvitation { ...props } /> } />
  </Switch>
)

const mapDispatchToProps = dispatch => ({
  signup: (email, password, locale) => dispatch({ type: SIGNUP_REQUEST, payload: { email, password, locale } }),
  resendEmail: email => dispatch({ type: SIGNUP_RESEND_EMAIL_REQUEST, payload: { email } }),
  submitInvitation: (password, token) =>
    dispatch({ type: SIGNUP_ACCEPT_INVITATION_REQUEST, payload: { password, token } }),
})

const mapStateToProps = nextState => ({
  signupData: nextState.signup.signupRequestData,
  resendData: nextState.signup.resendEmailRequestData,
})

export default connect(mapStateToProps, mapDispatchToProps)(SignupContainer)
