import React from "react"
import { Switch, Route } from "react-router"
import { Link } from "react-router-dom"
import { Col } from "react-bootstrap"
import FileDownload from "js-file-download"

import { Button } from "../../Common"

import { AdminUsersManagementContainer, AdminSchoolsManagementContainer } from "../../../containers"

import Routes from "../../../routes"

import "./AdminHomePage.css"
import { SpecificAgreements as SpecificAgreementsAPI } from "../../../api"

const downloadExcel = async () => {
  const data = await SpecificAgreementsAPI.export()
  FileDownload(data, "export.csv")
}

const AdminHomePage = () => (
  <Switch>
    <Route
      exact
      path={ Routes.ADMIN_INDEX }
      render={ () => (
        <Col xs={ 12 } sm={ 6 } smOffset={ 3 }>
          <Link to={ Routes.ADMIN_USERS }>
            <Button bsStyle="primary" block className="admin-home-page__nav-btn">
              Users
            </Button>
          </Link>
          <Link to={ Routes.ADMIN_SCHOOLS }>
            <Button bsStyle="primary" block className="admin-home-page__nav-btn">
              Schools
            </Button>
          </Link>
          <Button bsStyle="primary" block className="admin-home-page__nav-btn" onClick={ downloadExcel }>
            Export all Specific Agreements
          </Button>
        </Col>
      ) }
    />
    <Route path={ Routes.ADMIN_USERS } component={ AdminUsersManagementContainer } />
    <Route path={ Routes.ADMIN_SCHOOLS } component={ AdminSchoolsManagementContainer } />
  </Switch>
)

export default AdminHomePage
