import React from "react"
import PropTypes from "prop-types"
import { Radio } from "react-bootstrap"
import FileDownload from "js-file-download"

import { Button, Icon, FormInput } from "../../Common"
import { SpecificAgreements as SpecificAgreementsAPI } from "../../../api"

export default class AdminSchoolsTemplates extends React.Component {
  static propTypes = {
    templates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        locale: PropTypes.string,
        is_default: PropTypes.bool,
      })
    ),
    onAddTemplate: PropTypes.func.isRequired,
    onRemoveTemplate: PropTypes.func.isRequired,
    onChangeData: PropTypes.func.isRequired,
    kindIsSpecificAgreement: PropTypes.bool,
  }

  static defaultProps = {
    templates: [],
    kindIsSpecificAgreement: true,
  }

  downloadSpecificAgreementPrintingTemplate = async (index) => {
    const { templates, kindIsSpecificAgreement } = this.props
    let data;

    if (kindIsSpecificAgreement) {
      data = await SpecificAgreementsAPI.downloadSpecificAgreementPrintingTemplate(templates[index].id)
    } else {
      data = await SpecificAgreementsAPI.downloadGeneralAgreementPrintingTemplate(templates[index].id)
    }
    FileDownload(data, `${templates[index].name}.html`)
  }

  onChangeInput = (evt, index) => {
    const { onChangeData } = this.props
    let val

    if (evt.target.type === "file") {
      val = evt.target.files[0]
    } else if (evt.target.type === "radio") {
      val = Boolean(evt.target.value)
    } else {
      val = evt.target.value
    }
    onChangeData(evt.target.name, val, index)
  }

  renderTemplateForm = (template, index) => {
    const { onRemoveTemplate } = this.props

    return (
      <div key={ index } className="admin-schools-form__templates-form-container">
        <div className="admin-schools-form__templates-form-main-infos">
          <FormInput
            name="name"
            withLabel
            label="Name"
            placeholder="Name"
            value={ template.name }
            onChange={ evt => this.onChangeInput(evt, index) }
          />
          <FormInput
            name="locale"
            withLabel
            label="Locale"
            componentClass="select"
            value={ template.locale }
            onChange={ evt => this.onChangeInput(evt, index) }
          >
            <option value="en">English</option>
            <option value="ca">Catalan</option>
            <option value="es">Spanish</option>
            <option value="fr">French</option>
          </FormInput>
          <Radio
            inline
            value={ !template.is_default }
            defaultChecked={ template.is_default }
            name="is_default"
            onChange={ evt => this.onChangeInput(evt, index) }
          >
            <span>Is default</span>
          </Radio>
        </div>
        <input
          type="file"
          multiple={ false }
          accept="text/html,text/plain"
          name="file"
          onChange={ evt => this.onChangeInput(evt, index) }
        />
        {template.id && (
          <React.Fragment>
            <Button bsStyle="primary" onClick={ () => this.downloadSpecificAgreementPrintingTemplate(index) }>
              <Icon iconName="download" iconSize={ 20 } />
            </Button>
            <Button bsStyle="danger" onClick={ () => onRemoveTemplate(index) }>
              <Icon iconName="times" iconSize={ 20 } />
            </Button>
          </React.Fragment>
        )}
      </div>
    )
  }

  render() {
    const { templates, onAddTemplate } = this.props

    return (
      <div className="admin-schools-form__templates-list-container">
        <form>{templates.map(this.renderTemplateForm)}</form>
        <Button bsStyle="success" onClick={ onAddTemplate }>
          <Icon iconName="plus" iconSize={ 20 } />
        </Button>
      </div>
    )
  }
}
