import React from "react"
import PropTypes from "prop-types"
import { Table } from "react-bootstrap"
import classnames from "classnames"
import isObject from "lodash/isObject"
import ListPagination from "./ListPagination"
import "./List.css"

export const TableHeader = ({ titles, titlesClassName, titlesContainerClassName }) => (
  <thead>
    <tr className={ titlesContainerClassName }>
      {titles.filter(title => !!title).map((title, idx) => (
        <th key={ idx } className={ classnames(titlesClassName, isObject(title) ? title.className : "") }>
          {isObject(title) ? title.title : title}
        </th>
      ))}
    </tr>
  </thead>
)

export const TableBody = ({ renderRow, data }) => <tbody>{data.map((elem, index) => renderRow(elem, index))}</tbody>

export const TableRow = ({ children, ...props }) => <tr { ...props }>{children}</tr>

const List = ({
  data,
  totalCount,
  titles,
  titlesClassName,
  renderRow,
  titlesContainerClassName,
  currentPage,
  changePage,
  isLoading,
  ...props
}) => {
  const className = classnames("list-container", { loading: isLoading })
  const tableClassName = classnames("table", props.className, { loading: isLoading })

  return (
    <div className={ className }>
      <div className="sk-spinner-rotating-plane sk-spinner" />
      <Table className={ tableClassName } responsive>
        <TableHeader
          titles={ titles }
          titlesClassName={ titlesClassName }
          titlesContainerClassName={ titlesContainerClassName }
        />
        <TableBody renderRow={ renderRow } data={ data } />
      </Table>
      <ListPagination
        itemsCount={ totalCount }
        itemsPerPage={ 50 }
        onPage={ changePage }
        currentPage={ currentPage }
      />
    </div>
  )
}

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  titles: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        title: PropTypes.string,
        className: PropTypes.string,
      }),
    ])
  ).isRequired,
  titlesClassName: PropTypes.string,
  titlesContainerClassName: PropTypes.string,
  renderRow: PropTypes.func.isRequired,
  currentPage: PropTypes.number,
}

List.defaultProps = {
  titlesClassName: "",
  titlesContainerClassName: "",
  data: [],
  currentPage: 0,
}

export default List
