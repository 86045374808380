import React, { Component } from "react"
import i18n from "i18next"

import FormInput from "./FormInput"

const emailRegex = /^[^@\s]+@[^@\s]+$/

export default class EmailInput extends Component {
  constructor() {
    super()
    this.state = {
      validationState: null,
    }
  }

  validateEmail(email) {
    if (email.length === 0) return null
    return emailRegex.test(email) ? null : "error"
  }

  onInputChange(evt) {
    const { onChange } = this.props

    if (onChange) onChange(evt)
    this.setState({ validationState: this.validateEmail(evt.target.value) })
  }

  render() {
    const { onChange, ...props } = this.props

    return (
      <FormInput
        type="email"
        onChange={ e => this.onInputChange(e) }
        errorMessage={ i18n.t("spa:shared.error.email.format") }
        validationState={ this.state.validationState }
        { ...props }
      />
    )
  }
}
