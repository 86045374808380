import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import debounce from "lodash/debounce"
import i18n from "i18next";

import { Autocomplete as AutocompleteAPI } from "../../api"

import { Roles } from "../../Constants"

import { GenericFormInput } from "../GenericInputs";
import { EmailInput } from "../Common";
import PhoneInput from "../PhoneInput/PhoneInput";
import Session from "../../helpers/SessionHelper";
import TutorAutocomplete from "./TutorAutocomplete";

export default class TutorForm extends Component {
  static propTypes = {
    defaultData: PropTypes.object,
    tutorType: PropTypes.oneOf(["school", "company"]),
    getValidationState: PropTypes.func.isRequired,
    getErrorMessage: PropTypes.func.isRequired,
    editable: PropTypes.bool,
    onChange: PropTypes.func
  };

  static defaultProps = {
    defaultData: {
      company_tutor_first_name: "",
      company_tutor_last_name: "",
      company_tutor_position: "",
      company_tutor_phone_number: "",
      company_tutor_email_address: "",
      company_tutor_dni: "",
      school_tutor_first_name: "",
      school_tutor_last_name: "",
      school_tutor_position: "",
      school_tutor_phone_number: "",
      school_tutor_email_address: ""
    },
    tutorType: "company",
    editable: true,
    onChange: () => true
  };

  constructor(props) {
    super(props)
    this.state = {
      autocompletionData: [],
      autocompleteMenuWidth: 0,
    }

    this.autocompleteUser = debounce(this.autocompleteUser, 350)
  }

  autocompleteUser = async (value) => {

    const {
      defaultData: { school_tutor_first_name }
    } = this.props;

    const autocompleteValue = (value !== undefined && typeof(value) === "string") ? value : school_tutor_first_name

    const data = await AutocompleteAPI.autocompleteUsers(autocompleteValue, [ Roles.OAP, Roles.SCHOOL_ADMIN, Roles.SCHOOL_TUTOR ])
    this.setState({
      autocompletionData: data
    })
    this.forceUpdate();
  }

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props);
  }

  handleChange(e) {
    const { onChange } = this.props;

    onChange(e.target.name, e.target.value);
  }

  handleSchoolTutorFirstNameChange(e) {
    const { onChange } = this.props;

    onChange('school_tutor_first_name', e.target.value);

    this.autocompleteUser(e.target.value)
  }

  onSelectAutocomplete(e) {
    const { onChange } = this.props;

    const user = this.state.autocompletionData.find(obj => {
      return obj.id == e
    })

    if (user) {
      onChange('school_tutor_first_name', user.first_name);
      onChange('school_tutor_last_name', user.last_name);
      onChange('school_tutor_email_address', user.email);
      onChange('school_tutor_position', user.position);
      onChange('school_tutor_phone_number', user.phone_number);
    }
  }

  handlePhoneInput = (name, value) => {
    const { onChange } = this.props;

    onChange(name, value);
  };

  renderGenericInput({ name, ...props }, data) {
    const { getValidationState, getErrorMessage, editable } = this.props;

    return (
      <GenericFormInput
        name={name}
        data={data}
        getValidationState={getValidationState}
        getErrorMessage={getErrorMessage}
        editable={editable}
        onChange={e => this.handleChange(e)}
        {...props}
      />
    );
  }

  renderAutocompleteInput({ name, ...props }, data) {
    const { autocompletionData, autocompleteMenuWidth } = this.state
    const {
      getValidationState,
      getErrorMessage,
      editable,
      defaultData: { school_tutor_first_name }
    } = this.props;

    return (
      <React.Fragment>
        <label className="control-label">
          {
            i18n.t(
              "spa:specific_agreements.form.fields.school_tutor_first_name.label"
            )
          } *
        </label>
        <TutorAutocomplete
          // autocompleteRef={ref}
          name={name}
          items={ autocompletionData }
          onSelect={e => this.onSelectAutocomplete(e)}
          onChange={e => this.handleSchoolTutorFirstNameChange(e)}
          // onBlur={this.onBlurInput}
          renderInput={this.renderSchoolTutorFirstNameInput}
          onFocus={this.autocompleteUser}
          inputValue={school_tutor_first_name}
          menuWidth={autocompleteMenuWidth}
        />
      </React.Fragment>
    );
  }

  renderGenericForm(prefix, data) {
    const { companyTutorDniEnabled } = this.props;
    const prefixName = name => `${prefix}_tutor_${name}`;

    return (
      <React.Fragment>
        {[
          {
            isRow: true,
            children: [
              {
                name: prefixName("first_name"),
                required: true
              },
              { name: prefixName("last_name"), required: true }
            ]
          },
          { name: prefixName("position"), required: true },
          {
            name: prefixName("phone_number"),
            component: PhoneInput,
            onChange: this.handlePhoneInput,
            required: prefix === "company"
          },
          {
            name: prefixName("email_address"),
            component: EmailInput,
            required: true
          },
          {
            name: prefixName("dni"),
            visible: prefix === "company" && companyTutorDniEnabled
          }
        ].filter((elem) => elem.visible === undefined || elem.visible).map((elem, index) => {
          if (elem.isRow) {
            return (
              <Row key={index}>
                {elem.children.map((child, idx) => (
                  <Col xs={12} sm={6} key={`${index}-${idx}`}>
                    {child.name === "school_tutor_first_name"
                      ? this.renderAutocompleteInput(child, data)
                      : this.renderGenericInput(child, data)}
                  </Col>
                ))}
              </Row>
            );
          }
          return React.cloneElement(this.renderGenericInput(elem, data), {
            key: index
          });
        })}
      </React.Fragment>
    );
  }

  renderCompanyTutorForm() {
    const { defaultData: company } = this.props;

    return this.renderGenericForm("company", company);
  }

  renderSchoolTutorForm() {
    const { defaultData: school } = this.props;

    return this.renderGenericForm("school", school);
  }

  render() {
    const { tutorType } = this.props;

    switch (tutorType) {
      case "company":
        return this.renderCompanyTutorForm();
      case "school":
        return this.renderSchoolTutorForm();
      default:
        return null;
    }
  }
}
